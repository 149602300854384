import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ToastComponent } from '../../ui/repeatable/toast/toast.component';
import { formatDistanceToNowStrict } from 'date-fns';

declare var bootbox: any;
declare var rewardful: any;
declare var Rewardful: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent implements OnInit {
  @ViewChild(ToastComponent) toast: ToastComponent;


  user_projects: any = [];
  user_logged_in: boolean = true;
  user: any;
  modal_class_create_project: string = "modal-close";
  modal_class_delete_project: string = "modal-close";
  modal_add_key: string = "modal-close";

  project_name: string = "";
  i_agree = false;
  modal_class_declaration = "modal-close";
  declaration;
  user_declared;
  user_upgrade = false;
  plan = "";
  loading = true;
  can_create_new_project = false;
  p_strat: any = {};
  loading_pstrat = true;
  limits: any = {};
  tracker: any = {};
  loading_limits = true;
  key_string = "";
  should_user_customer_key = false;

  constructor(private dataService: DataService, private route: Router, private authenticatorService: AuthenticatorService) { }

  getMoment(datetime) {
    const dateString = datetime;
    const utcDate = new Date(Date.UTC(
      parseInt(dateString.substr(0, 4)),     // Year
      parseInt(dateString.substr(5, 2)) - 1, // Month (0-based)
      parseInt(dateString.substr(8, 2)),     // Day
      parseInt(dateString.substr(11, 2)),    // Hour
      parseInt(dateString.substr(14, 2)),    // Minute
      parseInt(dateString.substr(17, 2))     // Second
    ));

    return formatDistanceToNowStrict(utcDate, { addSuffix: true });
  }

  openModal() {

    this.modal_class_create_project = "modal-open";
  }

  closeModal() {
    this.modal_class_create_project = "modal-close";
  }

  resetText() {
    this.project_name = "";
    this.key_string = "";
  }

  isUpgraded() {
    this.dataService.isUpgraded().subscribe((data) => {

      console.log(data);

      if (data['isUpgraded'] == true) {
        this.user_upgrade = true;
        this.plan = data['plan'];
        // this.getAllowedFunctions();





      } else {
        this.user_upgrade = false;
        this.plan = "Basic";
      }

      setTimeout(() => {
        this.getLimits();
      }, 200);
    }
    );
  }


  ngOnInit(): void {

    rewardful('ready', function () {
      console.log('Rewardful--Ready!');
      if (Rewardful.referral) {
        console.log('Rewardful--Referral:', Rewardful.referral);
      }
      else {
        console.log('NONE:', Rewardful.referral);
      }
      console.log(Rewardful.referral)
    });




    this.authenticatorService.subscribe(() => {


      const { route } = this.authenticatorService;
      if (route === 'authenticated') {


        this.user_logged_in = true;

        this.isUpgraded();
        this.hasDeclared();


        this.dataService.getUser().subscribe((user) => {
          //console.log(user);
          this.user_logged_in = true;
          this.user = user;

          this.getUserProjects();
          this.trackUsage();
          // this.getAllowedFunctions();


        },
          (error) => {
            // //console.log(error);
          }
        );

      }
      else {
        this.route.navigate(["/"]);
      }

      // const { route } = this.authenticatorService;


    });

    // var script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'assets/helpcrunch.js';
    // document.head.appendChild(script);

  }

  logout() {
    // alert("logout");
    try {

      this.dataService.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  project_to_delete: any = "";
  deleteProject() {
    {

      this.dataService.cd_deleteProject(this.project_to_delete.project_uuid).subscribe((data: any) => {
        if (data.status === "success") {
          this.user_projects = this.user_projects.filter(project => project.project_uuid !== this.project_to_delete.project_uuid);
          this.modal_class_delete_project = "modal-close";
          this.toast.showToastNnotification("Project deletion successful!!", "success");
        }


        this.project_to_delete = "";
      }, err => {
        //console.log(err);
        this.toast.showToastNnotification("Project deletion failed!!", "failed");
      });
    }
  }

  openDeleteModal(e, p) {
    e.stopPropagation();
    this.modal_class_delete_project = "modal-open";
    this.project_to_delete = p;
  }

  trackUsage() {
    this.dataService.cd_trackUsage().subscribe(data => {
      //console.log("tracking...");
      //console.log(data);
    })
  }

  createProject(e) {



    // bootbox.alert('This is the default alert!');

    // bootbox.prompt('This is the default prompt!',
    //   function (result) {
    //     //console.log(result);
    //   });
    this.dataService.cd_createProject(this.project_name).subscribe((data: any) => {
      //console.log(data);
      window.location.href = '/project/' + data['project_id'];
    });
  }

  getUserProjects() {
    this.dataService.cd_getUserProjects().subscribe((data: any) => {
      //console.log(data);
      this.user_projects = data['projects'];
      this.loading = false;
    });
  }

  redirectToProject(p) {
    this.route.navigate([`/project/${p.project_uuid}`]);
  }

  hasDeclared() {
    this.dataService.hasDeclared().subscribe((data) => {

      //console.log(data);
      this.declaration = data;
      if (data['hasdeclared'] == true) {
        this.user_declared = true;
        this.modal_class_declaration = "modal-close";

      } else {
        this.user_declared = false;
        this.modal_class_declaration = "modal-open";
        // //console.log(this.modal_class_declaration);
      }
    }
    );
  }

  click_declared = false;
  declare() {
    this.click_declared = true;
    this.dataService.declare().subscribe((data) => {
      this.modal_class_declaration = 'modal-close';
      this.declaration['hasdeclared'] = true;
      this.click_declared = false;
    }, err => console.log(err));
  }

  getAllowedFunctions() {
    this.loading_pstrat = true;
    this.dataService.cd_getAllowedFunctions(this.plan).subscribe((data: any) => {
      this.loading_pstrat = false;
      //console.log(data);
      this.p_strat = data;
      this.can_create_new_project = data?.create_new_project;
      //console.log("can create new project: " + this.can_create_new_project);
    }, err => console.log("error:", err));
  }

  a_tot_storage = 0;
  a_used_storage = 0;

  a_tot_files = 0;
  a_tot_used_files = 0;

  a_tot_questions = 0;
  a_tot_used_questions = 0;

  a_tot_urls = 0;
  a_tot_used_urls = 0;

  getLimits() {
    this.loading_limits = true;
    this.dataService.cd_getLimit(this.plan).subscribe((data: any) => {
      this.loading_limits = false;
      console.log(data);
      this.limits = data['plan_details'];
      this.tracker = data['track_obj'];



      if (this.limits['is_own_key'] && !this.limits['is_key_generated']) {
        this.should_user_customer_key = true;
        // alert("Please add your key to continue");
        this.modal_add_key = "modal-open";
      }

      // if tracker doesnt have total_del_file_size or n_del_files or n_del_urls then set them to 0
      if (!this.tracker['total_del_file_size']) {
        this.tracker['total_del_file_size'] = 0;
      }
      if (!this.tracker['n_del_files']) {
        this.tracker['n_del_files'] = 0;
      }
      if (!this.tracker['n_del_urls']) {
        this.tracker['n_del_urls'] = 0;
      }


      var u_s = (this.tracker['total_file_size'] - this.tracker['total_del_file_size']) / 1024 / 1024;
      this.a_used_storage = Math.round((u_s + Number.EPSILON) * 100) / 100;
      this.a_tot_storage = this.limits['max_total_storage_mb'];

      // if a_used_storage is NaN then set it to 0
      if (isNaN(this.a_used_storage)) {
        this.a_used_storage = 0;
      }

      this.a_tot_files = this.limits['max_files'];
      this.a_tot_used_files = this.tracker['n_files'] - this.tracker['n_del_files'];

      if (this.a_tot_used_files < 0 || isNaN(this.a_tot_used_files)) {
        this.a_tot_used_files = 0;
      }


      this.a_tot_questions = this.limits['max_messages_per_month'];


      this.a_tot_used_questions = this.tracker['n_questions'];

      if (this.a_tot_used_questions < 0 || isNaN(this.a_tot_used_questions)) {
        this.a_tot_used_questions = 0;
      }



      this.a_tot_urls = this.limits['max_urls'];
      this.tracker['n_urls'] = this.tracker['n_urls'] ? this.tracker['n_urls'] : 0;
      this.a_tot_used_urls = this.tracker['n_urls'] - this.tracker['n_del_urls'];

      if (this.a_tot_used_urls < 0 || isNaN(this.a_tot_used_urls)) {
        this.a_tot_used_urls = 0;
      }






    }, err => console.log("error:", err));
  }

  add_key_error = false;
  add_key_clicked = false;

  addKey(e) {


    this.add_key_clicked = true;
    this.dataService.cd_addKey(this.key_string).subscribe((data: any) => {
      //console.log(data);
      this.modal_add_key = "modal-close";
      this.add_key_error = false;
      this.add_key_clicked = false;
      this.key_string = "";
      this.getLimits();
    }, err => {

      this.add_key_error = true;
      this.add_key_clicked = false;
      // alert("Invalid key");

    });

  }

  deleteKey() {
    this.dataService.cd_deleteKeyForAccount().subscribe((data: any) => {
      //console.log(data);
      this.modal_add_key = "modal-close";
      this.getLimits();
    }, err => console.log(err));
  }

  openKeyModal() {
    this.modal_add_key = "modal-open";
  }

  redirectToPricingPage() {
    this.route.navigate(['/pricing']);
  }


}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-add-new-project',
  templateUrl: './add-new-project.component.html',
  styleUrls: ['./add-new-project.component.css']
})
export class AddNewProjectComponent implements OnInit {

  project_name = ""
  key_string = ""
  modal_state = ""

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  open_modal() {
    this.modal_state = 'modal-open';
  }

  close_modal() {
    this.modal_state = 'modal-close';
  }

  resetText() {
    this.project_name = "";
    this.key_string = "";
  }

  createProject(e) {
    this.dataService.cd_createProject(this.project_name).subscribe((data: any) => {
      //console.log(data);
      window.location.href = '/project-new/' + data['project_id'];
    });
  }

}

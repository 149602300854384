import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatePipe'
})
export class TruncatePipePipe implements PipeTransform {

  transform(value: string, start: number, end?: number): unknown {
    // alert(value.split(" ").length, end);
    if (value == null) return null;


    // check if it's an email
    if (value.split('@').length > 1) {
      if (value.length < end) {
        return value;
      }
      var email = value.split('@');
      return email[0].substring(start, end) + '...' + email[1];
    }
    else if (value.split('.').length > 1) {
      if (value.length < end) {
        return value;
      }

      var extension = value.split('.');

      return extension[0].substring(start, end) + '...' + extension[1];
    }
    else {
      // check if number of characters is less than end
      if (value.length < end) {
        return value;
      }
      return value.substring(start, end) + '...';
    }




    // if length is less than end, return value


  }

}

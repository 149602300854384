import { Component, OnInit } from '@angular/core';
import { Input, Output, ViewChild, EventEmitter } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import CodeTool from '@editorjs/code';
import { ExamplePlugin } from './ExamplePlugin';
import ExampleInline from './ExampleInline';
import { DataService } from '../../../services/data.service';
import SimpleImage from '@editorjs/simple-image';

@Component({
  selector: 'app-ieditor',
  templateUrl: './ieditor.component.html',
  styleUrls: ['./ieditor.component.css']
})
export class IEditorComponent implements OnInit {

  // Take input from parent
  @Input() edata: any;
  @Input() editable: any;
  @Output() contentChanged = new EventEmitter();


  editor: EditorJS;


  constructor(private dataService: DataService) { }

  ngOnInit(): void {

    this.dataService.chatGPT();

    const editorElement = document.getElementById('editor');
    this.editor = new EditorJS({
      holder: editorElement,
      data: this.edata,
      placeholder: 'Click here to start writing your notes...',
      readOnly: this.editable,
      tools: {
        header: Header,
        embed: Embed,
        image: SimpleImage,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        },
        code: CodeTool
      },
      onChange: () => {
        this.contentChanged.emit();
      }
    });
  }

  getContent() {
    // alert("Get Content");
    return this.editor.save();
  }

  setData(data) {
    this.editor.render(data);
  }

  getEditor() {
    return this.editor;
  }


}

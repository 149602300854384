import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../../services/data.service';
@Component({
  selector: 'app-add-webpages',
  templateUrl: './add-webpages.component.html',
  styleUrls: ['./add-webpages.component.css']
})
export class AddWebpagesComponent implements OnInit {

  @Input() project_uuid: any;
  @Output() notifyParentCloseUrlPage: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  add_url_current: string = "";
  add_url_current_element: any = {};
  show_abort_banner = false;
  sub_urls = [];
  show_add_url_loop = false;
  crawl_status = "Not Started";
  selected_url_count = 0;
  success_url_count = 0;
  upload_index_progress = false;

  selectAllSubUrls() {
    this.selected_url_count = 0;
    this.sub_urls.forEach((elem) => {
      elem.checked = true;
      this.selected_url_count++;
    });

  }

  unselectAllSubUrls() {
    this.sub_urls.forEach((elem) => {
      elem.checked = false;
    });
    this.selected_url_count = 0;
  }

  updateSelectedUrlCount() {
    this.selected_url_count = 0;
    this.sub_urls.forEach((elem) => {
      if (elem.checked)
        this.selected_url_count++;
    });
  }

  startOver() {
    this.show_add_url_loop = false;
    this.sub_urls = [];
    this.crawl_status = "Not Started";
    this.selected_url_count = 0;
    this.add_url_current = "";
    this.add_url_current_element = {};
    this.show_abort_banner = false;
    this.success_url_count = 0;
  }


  getSubUrls() {
    this.crawl_status = "Scraping";
    this.dataService.cd_addUrlStartCrawler(this.add_url_current).subscribe((data: any) => {
      console.log(data);

      data['sub_urls'].forEach(element => {

        this.sub_urls.push({
          url: element,
          status: "not started",
          checked: true
        });
        this.selected_url_count++;


      });

      this.crawl_status = "Scraped";
      this.show_add_url_loop = true;
    },

      (error) => {

        alert("Error occured while scraping the url. Please try again.");

      });

    return;
  }

  isAllUrlsAdded() {

    // check if none of the urls are pending, then set crawl_status to "Indexed"
    var all_urls_added = true;
    this.sub_urls.forEach((elem) => {
      if (elem.status === "pending")
        all_urls_added = false;
    });

    if (all_urls_added) {
      this.crawl_status = "Indexed";
      // this.getObjectsForProject();
      // this.getLimits();
    }

  }


  addUrlLoop() {
    this.crawl_status = "Indexing";
    this.sub_urls.forEach((elem) => {
      if (elem.checked) {
        elem.status = "pending";
        this.add_url_current_element = elem;
        this.addUrl(elem);
      }

    }
    );


  }



  addUrl(elem) {
    if ('trial' in elem) {
      elem.trial = elem.trial + 1
    }
    else {
      elem.trial = 0;
    }
    this.show_abort_banner = false;
    if (elem.url.trim() === '' || elem.url === null) {
      console.error('No Url provided.');
      elem.status = "failed";
      // TODO: add toast here
      // this.toast.showToastNnotification("No Url provided...", "failed");
      return;
    }
    // this.upload_index_progress = true;
    //console.log(elem.url);


    this.dataService.cd_addUrlAndIndex(elem.url, this.project_uuid).subscribe((data: any) => {
      //console.log(data);



      if (data.status == "success") {
        elem.status = "success";
        // this.upload_index_progress = false;
        // this.closeAddUrlToCollectionModal();

        this.success_url_count++;

      }
      else if (data.status == "abort") {
        elem.status = "failed";
        // this.upload_index_progress = false;
        this.show_abort_banner = true;
        // this.toast.showToastNnotification(data.message, "success");
      } else {
        elem.status = "failed";
        // TODO this.toast.showToastNnotification("Invalid URL, please try again.", 'failed');
        console.error('Uknown error occured. Please try again.');
        return [];
      }

      this.isAllUrlsAdded();

    },
      (error) => {
        console.log("Got Error with" + elem.url + " : Try" + elem.trial);
        if (elem.trial <= 2) {
          setTimeout(() => {
            this.addUrl(elem);
          }, 2000);

        }
        else {

          elem.status = "failed";
          console.error('Uknown error occured. Please try again.');
          this.isAllUrlsAdded();

        }

      });
  }


  resetAddUrlPopUp() {
    this.add_url_current = '';
    this.startOver();
  }

  closePage() {
    this.resetAddUrlPopUp();
    this.notifyParentCloseUrlPage.emit("close");
  }

}

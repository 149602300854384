<div class="bg-gray-900 flex justify-between items-center">

  <a class="flex justify-center text-white items-center" href="/main">

    <!-- <i class="fa-brands fa-dochub ml-4 text-xl mr-1" style="color:#ffe1d0"></i> -->
    <div style="font-size: 23px;
                  font-family: 'Poppins';
                  font-weight: bold;     
                  color: #e2e0e0;" class=" ml-4 my-3">Knibble<span class="text-gray-400"
        style="color:var(--app-theme-color)">.</span>
      <!-- <span
                          class="text-sm ml-2 text-gray-300 -mt-3">Beta</span> -->
    </div>

    <!-- Home link -->

  </a>


  <!-- <div class="flex justify-center text-white items-center w-1/3">



  </div> -->


  <div class="flex justify-end text-white items-center w-1/2 px-4">

    <a class="flex justify-center text-white items-center mr-4" href="/main">
      <i class="fa-light fa-home ml-4 text-base mr-1" style="color:#ffe1d0"></i>
      <div style="font-size: 16px;
                font-family: 'Poppins';
                   
                color: #e2e0e0;" class=" ml-1 my-3">Home</div>
    </a>

    <div class="dropdown px-0 dropleft" *ngIf="user_logged_in">
      <button class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
        type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <div class="avatar mr-0">

          <div class="w-8 h-8 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-inherit"
            style="border:solid 1px var(--app-theme-color) !important;">
            <div class="flex justify-center items-center h-full">
              <div class="text-base text-white capitalize font-semibold">
                {{user?.user_uuid[0]}}
              </div>
            </div>
          </div>
        </div>



        <i _ngcontent-ilr-c103="" class="fa-solid text-base text-gray-100 fa-caret-down ml-1"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item font-semibold py-1 border-b">{{user?.user_uuid}}</a>
        <a class="dropdown-item" href="/account">Account</a>

        <a class="dropdown-item" href="https://tally.so/r/mRd6jJ">Contact Us</a>
        <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
        <a class="dropdown-item text-gray-500" href="#">Delete Account</a>
      </div>
    </div>

    <div *ngIf="user_logged_in" class="mx-8 flex">

      <div *ngIf="user_upgrade" class="text-sm flex justify-center items-center">
        <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
        {{plan}} plan
      </div>

      <div *ngIf="!user_upgrade" class="text-sm flex justify-center items-center">
        <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
        Basic plan
      </div>

      <div *ngIf="!user_upgrade" class="ml-4">
        <a href="/pricing" class="text-white bg-inherit hover:bg-gray-800 p-2 text-sm"
          style=" font-weight: 600px;text-decoration: none; border:solid 1px var(--app-theme-color)">
          Upgrade
        </a>
      </div>

    </div>


  </div>
</div>


<div id="main-wrapper" class="w-full h-screen overflow-scroll" style="background: #fff !important ;">

  <div class="content-wrapper w-full bg-white">

    <div id="content-container"
      class="flex flex-col justify-start bg-white items-start bg-whdite  px-4 pb-3 pt-10 mx-auto"
      style="max-width: 1300px;">

      <div class="w-full flex justify-start items-start mx-auto py-6 bg-white">

        <div class="w-2/12 px-16 py-0 pr-3 flex justify-end  flex-col items-end">

          <div class="flex flex-col justify-center items-center ">
            <a (click)="onImageClick()" class="p-0 m-0">
              <div class="avatar mr-0">
                <div class="w-24 rounded-full rihng ring-primary ring-offset-base-100 ring-offset-2"
                  *ngIf="user.picture">
                  <img src="{{user_pic_url}}" cors="anonymous" />
                </div>
                <div class="w-24 h-24 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-yellow-200"
                  *ngIf="!user.picture">
                  <div class="flex justify-center items-center h-full">
                    <div class="text-base text-gray-800 capitalize mt-1 font-semibold">{{user.user_id[0]}}</div>
                  </div>
                </div>
              </div>
            </a>

            <input type="file" accept="image/*" (change)="onFileSelected($event)" style="display: none;">

            <a (click)="onImageClick()" class="text-xs cursor-pointer">Edit Picture</a>



          </div>

        </div>
        <div class="w-5/12 pl-8 ">

          <!-- <form class="bg-white p-8 w-full"> -->
          <h2 class="text-2xl font-bold mb-6">Profile</h2>

          <!-- Full Name -->
          <div class="mb-4 w-full">
            <label class="block text-gray-700 font-semibold mb-2" for="full-name">
              Handle </label>
            <!-- <input class=" border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="full-name" type="text" [(ngModel)]="user.alias"> -->

            <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
              style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
              <i class="fa-light fa-at text-gray-500 ml-3"></i>
              <input type="text" [(ngModel)]="user.alias"
                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full focus:outline-none"
                style="padding-top:6px;padding-bottom:6px" placeholder="handle" />
            </div>
          </div>

          <div class="mb-4 w-full">
            <label class="block text-gray-700 font-semibold mb-2" for="full-name">
              Name </label>

            <!-- <input
              class="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="full-name" type="text" [(ngModel)]="user.given_name" placeholder="Your unique handle"> -->

            <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
              style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
              <i class="fa-light fa-user text-gray-500 ml-3"></i>
              <input type="text" [(ngModel)]="user.given_name"
                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full focus:outline-none"
                style="padding-top:6px;padding-bottom:6px" placeholder="name" />
            </div>
          </div>

          <!-- Email Address -->
          <div class="mb-4 w-full">
            <label class="block text-gray-700 font-semibold mb-2" for="email">
              Email Address
            </label>

            <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
              style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
              <i class="fa-light fa-envelope text-gray-500 ml-3"></i>
              <input type="text" [(ngModel)]="user.email" disabled
                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full focus:outline-none"
                style="padding-top:6px;padding-bottom:6px" placeholder="email" />
            </div>
          </div>


          <div class="flex items-center justify-between">
            <button class=" text-white font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              (click)="updateUserNameAlias()" *ngIf="!updatingprofile" style="background: var(--app-theme-color);"
              type="submit">
              Save
            </button>
            <button class=" text-white font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              *ngIf="updatingprofile" style="background: #868687;" type="submit">
              <i class="fa-light fa-spinner-third fa-spin mr-2"></i>
              Saving
            </button>
          </div>
          <!-- </form> -->


        </div>

        <div class="w-5/12 pl-4 flex flex-col h-full justify-start items-start">


          <div class="bg-white p-0 w-full mt-0 py-8">
            <h2 class="text-2xl font-bold mb-6">Billing</h2>


          </div>

          <div class="flex flex-col w-96 h-auto border rounded justify-center items-center pt-4 pb-8"
            *ngIf="upgraded.isUpgraded">

            <img src="assets/{{upgraded.plan}}.svg" style="width: 63px;" class="mb-2">

            <div class="flex justify-start items-center text-xl" *ngIf="upgraded.isUpgraded">


              {{upgraded.plan}} plan

            </div>

            <div class="mt-2">

              <span class="font-normal text-gray-600">Renews on</span><span class="font-bold"> {{upgraded.expiration |
                date: 'mediumDate'}}</span>

            </div>

            <div class="mt-4 mb-3">

              <a class="text-white p-2 rounded text-sm cursor-pointer" style="background: var(--app-theme-color);"
                (click)="goToCustomerPortal()" target="_blank">Manage
                subscription</a>

            </div>

          </div>



          <div class="flex justify-start items-center text-lg" *ngIf="!upgraded.isUpgraded">

            <div class="flex flex-col w-96 h-auto border rounded justify-center items-center pt-4 pb-8">

              <img src="assets/Free.svg" style="width: 63px;" class="">
              Basic plan

              <div class="mt-4 mb-3">

                <a class="text-white p-2 rounded text-sm " style="background: var(--app-theme-color);"
                  href="/pricing">Upgrade</a>

              </div>

            </div>



          </div>

        </div>

      </div>


    </div>

  </div>
  <app-toast></app-toast>
</div>

<app-footer></app-footer>
import { Component, OnInit, Renderer2, Inject, ViewChild, ElementRef } from '@angular/core';
import { AuthServiceApp } from '../../services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService, widgetsquare } from '../../services/data.service';
import { DOCUMENT } from '@angular/common';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Hub, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('features') features: ElementRef;
  @ViewChild('pricing') pricing: ElementRef;

  showHomePage: boolean = false;
  yearly: boolean = false;
  expanded: boolean = true;

  constructor(public authservice: AuthServiceApp, private route: Router, public data: DataService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private authenticatorService: AuthenticatorService) { }

  sub_page = 'weather';
  page = 'create';
  mobile = false;

  uuid_e_c = "6966c86e-68c7-4206-86fb-b9e175fa3cc8";
  wuuid_e_c = "cfe37531-af72-474a-987b-f461e405c4a6";
  wuuid_e_ac = "00861b48-9017-480a-be0a-1ddd3913217f";

  create_category = 'All';

  sample_chat_messages = [
    // { "text": "👋 Hi! I am ChatDocs bot!, ask me anything about!", "actor": "bot" },
    { "text": "Hi, how are you?", "actor": "user" },
    { "text": "I'm good! How are you?", "actor": "bot" }

  ]

  embed = {

    'embed_type': 'web',
    'title': "My Chatbot",
    'logo': 'default',
    'logo_path': '',
    'default_message': '👋 Hi there. How can I help you?',
    'button_color': '#000000',
    'bg_color': '#fafafa',
    'header_color': '#111132',
    'header_text_color': '#ffffff',
    'rounded': 'rounded',
    'border_color': '#f0f0f0',
    'user_picture': 'default',
    'bot_picture': 'default',
    'user_msg_bg_color': '#ffffff',
    'bot_msg_bg_color': '#111132',
    'placeholder': 'Type a message...',
    'bubble_icon': 'fa-regular fa-message',
    'bubble_bg_color': '#111132',
    'bubble_text_color': '#fff',
    'user_msg_text_color': '#000000',
    'bot_msg_text_color': '#FFFFFF',
    'send_icon': 'fa-solid fa-paper-plane',
    'send_icon_color': '#fff',
    'send_icon_bg_color': '#111132',
    'send_bar_text_color': '#000000',
    'send_bar_background': '#ffffff',
    'subtitle': '💬 Chat with us',
    'sub_header_text': 'Message to the users',
    'sub_header_text_color': '#000000',
    'sub_header_bg_color': '#F3F4F6'
  }


  redirecturl: string = "";

  saveBotSettings() {
  }

  siginWithGoogle() {

    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

  }

  scroll(id) {
    document.querySelector('#' + id).scrollIntoView();
  }

  subject: string[] = ['Python', 'Machine Learning', 'Organic Chemistry', 'Algebra', 'Data Science', 'Python', 'Guitar', 'Biology', 'Physics', 'Chemistry', 'Calculus', 'Statistics', 'Geometry', 'Trigonometry', 'Statistics', 'Geometry'];
  typingSpeed: number = 200; // Delay between each character in milliseconds
  currentIndex: number = 0;
  currentWord: string = '';
  currentText: string = '';
  currentString = '';

  scrollToDiv(section) {
    const element = this._document.getElementById(section);
    element.scrollIntoView({ behavior: 'smooth' });
    // this.myDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  startTypingEffect() {
    this.currentWord = ' ';
    this.currentString = this.subject[this.currentIndex];
    const words = this.currentString.split(' ');
    words[0] = words[0] + ' ';

    let wordIndex = 0;
    let letterIndex = 0;

    const typingInterval = setInterval(() => {
      this.currentWord += words[wordIndex][letterIndex];
      letterIndex++;

      if (letterIndex === words[wordIndex].length) {
        wordIndex++;
        letterIndex = 0;

        if (wordIndex === words.length) {
          clearInterval(typingInterval);

          setTimeout(() => {


            if (this.currentIndex === this.subject.length - 1) {
              this.currentIndex = 0; // Restart from the beginning
            }

            setTimeout(() => {

              this.currentIndex++;
              this.startTypingEffect();
            }, 1000);

          }, this.typingSpeed);
        }
      }
    }, this.typingSpeed);
  }

  playing = false

  playVideo(video: HTMLVideoElement) {
    this.scroll('video');
    video.play();
    this.playing = true;

  }

  pauseVideo(video: HTMLVideoElement) {
    video.pause();
  }

  loop_timer = null;

  text_blocks = {
    "1": ["Get Instant Answers", "Get instant answers from your docs"],
    "2": ["Embed Chatbot", "Embeddable chatbot for your Website or Notion"],
    "3": ["Share Knowledge Base", "Share your knowledge base with others"]
  };


  block1_text = this.text_blocks["1"][1];
  block2_text = this.text_blocks["2"][0];
  block3_text = this.text_blocks["3"][0];


  clickBlock(block) {
    clearInterval(this.loop_timer);
    this.setBlock(block);
  }

  setBlock(block) {

    this.current_block = block;


    this.block1_text = this.text_blocks["1"][0];
    this.block2_text = this.text_blocks["2"][0];
    this.block3_text = this.text_blocks["3"][0];

    if (block == '1') {
      this.block1_text = this.text_blocks["1"][1];
    }
    else if (block == '2') {
      this.block2_text = this.text_blocks["2"][1];
    }
    else if (block == '3') {
      this.block3_text = this.text_blocks["3"][1];
    }


  }


  loopBlocks() {

    this.loop_timer = setInterval(() => {

      if (this.current_block == '1') {
        this.setBlock('2');
      }
      else if (this.current_block == '2') {
        this.setBlock('3');
      }
      else if (this.current_block == '3') {
        this.setBlock('1');
      }

    }, 5000);

  }

  current_block = "1";


  ngOnInit(): void {

    // this.authenticatorService.subscribe(() => {

    //   const { route } = this.authenticatorService;
    //   if (route !== 'authenticated') {


    this.loopBlocks();

    if (environment.production) {
      this.redirecturl = "https://knibble.ai/auth2/";
    } else {
      this.redirecturl = "https://dev.knibble.ai/auth2/";
      // this.redirecturl = 'http://localhost:4200/auth2/'
    }

    this.startTypingEffect();




    if (window.screen.width < 500) { // 768px portrait
      this.mobile = true;
    }



    this.authenticatorService.subscribe(() => {
      const { route } = this.authenticatorService;


      // //console.log(route);


      if (route === 'authenticated') {

        this.authservice.login(this.authenticatorService.user.attributes).subscribe(() => {

          this.route.navigate(["project-new"]);

        }, (err) => {

          // //console.log("Login Error");
          // //console.log(err);

        });


      } else {

        this.showHomePage = true;

      }
    });





    // } else {
    //   this.route.navigate(["/main"]);
    // }

    // });

    // //console.log("Production: " + environment.production);




    // const s = this.renderer2.createElement('script');
    // s.type = 'text/javascript';
    // s.src = 'assets/walloflove.js';
    // s.text = ``;
    // this.renderer2.appendChild(this._document.body, s);

    // var script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'assets/helpcrunch.js';
    // document.head.appendChild(script);

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://player.vimeo.com/api/player.js';
    document.head.appendChild(script);

  }

  setPage(page, subpage) {
    this.sub_page = subpage;
  }


  signOut() {
    try {
      Auth.signOut().then(() => {
        this.route.navigate(["/"]);
        location.reload();
      });
    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  setCreateCategory(c) {
    this.create_category = c;
  }

}

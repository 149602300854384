import { Component, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Auth } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../backend/services/auth.service';
import { ApiService } from '../../../backend/services/api.service';
import { PlansService } from '../../../backend/services/plans.service';
import { UtilsService } from '../../../backend/services/utils.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UploadFileComponent } from '../../../components/blocks/modals/upload-file/upload-file.component';
import { DeleteChatComponent } from '../../../components/blocks/modals/delete-chat/delete-chat.component';
import { DashboardComponentNew } from '../../../components/app-ui/main/dashboard/dashboard.component';
import { CustomizeComponent } from '../../../components/app-ui/main/customize/customize.component';
import { SidebarComponent } from '../../../components/blocks/sidebar/sidebar/sidebar.component';
import { Storage } from 'aws-amplify';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

declare var bootbox: any;
declare var rewardful: any;
declare var Rewardful: any;

@Component({
  selector: 'app-project-main',
  templateUrl: './project-main.component.html',
  styleUrls: ['./project-main.component.css']
})


export class ProjectMainComponent implements OnInit {

  @ViewChild(UploadFileComponent) uploadRef!: UploadFileComponent;
  @ViewChild(DeleteChatComponent) delChatRef!: DeleteChatComponent;
  @ViewChild(DashboardComponentNew) dashboardRef!: DashboardComponentNew;
  @ViewChild(SidebarComponent) sidebarRef!: SidebarComponent;
  @ViewChild(CustomizeComponent) customizeRef!: CustomizeComponent;

  declare $: any;




  // User Variables
  user: any;
  user_logged_in: boolean = false;
  user_upgrade: boolean = false;
  plan: string = "Basic";
  limits: any;
  tracker: any;
  user_pic_url: any;
  user_loaded: boolean = false;

  // Project Variables
  project_uuid: any;
  project: any;
  loading_project: boolean = true;
  no_access: boolean = false;
  share_code: string = "";
  chat_id: string = "";
  is_upgrade_call: boolean = false;
  get_project_call: boolean = false;
  get_objects_call: boolean = false;
  get_interactions_call: boolean = false;
  loading_project_progress: number = 0;
  upload_modal_state: boolean = false;
  user_interactions: any = [];
  page: string = "none";
  upgraded: any;

  // UI Variables
  resources_tab: string = "files";
  modal_share = 'modal-close';

  share_info: any;
  permissions = {
    'enable_sharing': false,
    'view_files': true,
    'download_files': true,
    'view_chats': true
  }
  byok_error = "";
  loading_dashboard: boolean = true;


  constructor(private dataService: DataService, private app_auth: AuthService, private api: ApiService,
    private aroute: ActivatedRoute, private sanitizer: DomSanitizer, private planService: PlansService,
    private utils: UtilsService, private authenticatorService: AuthenticatorService, private route: Router) { }

  ngOnInit(): void {


    this.authenticatorService.subscribe(() => {

      const { route } = this.authenticatorService;
      if (route === 'authenticated') {

        rewardful('ready', function () {
          console.log('Rewardful--Ready!');
          if (Rewardful.referral) {
            console.log('Rewardful--Referral:', Rewardful.referral);
          }
          else {
            console.log('NONE:', Rewardful.referral);
          }
          console.log(Rewardful.referral)
        });

        this.api.getUser().subscribe((user) => {
          console.log(user);
          this.user = user;

          if ('picture' in this.user && this.user.picture != null && this.user.picture != "") {
            console.log("Picture exists");

            // check if picture is a url
            if (this.user.picture.includes("https://")) {

              this.user_pic_url = this.user.picture;
            } else {

              this.getUserImage(this.user.picture);
            }
          } else {
            console.log("Picture doesnt exist");
            this.user_pic_url = "";
          }
          this.user_loaded = true;
        });

        this.byok_error = this.planService.getByokError();

        var upgraded_promise = this.isUpgraded();
        this.project_uuid = this.aroute.snapshot.paramMap.get('ppid');

        upgraded_promise.then(() => {
          this.is_upgrade_call = true;
          if (this.project_uuid != null && this.project_uuid != undefined) {
            this.page = "project";
            var project_promise = this.getProject();
            this.loading_project_progress = 25;
            project_promise.then(() => {
              this.get_project_call = true;
              this.loading_project = false;
              this.loading_project_progress = 50;
              var objects_promise = this.getObjectsForProject();
              this.getChatbotConfig();
              objects_promise.then(() => {

                this.get_objects_call = true;
                this.loading_project = false;
                this.loading_project_progress = 75;
                var interactions_promise = this.getUserInteractions();
                this.getShare();

                interactions_promise.then(() => {

                  this.get_interactions_call = true;
                  this.loading_project = false;

                  $('#loading-project').addClass('hidden');
                  $('#loading-project').removeClass('flex');
                  $('#sidebar').removeClass('hidden');
                  $("#desktop-main").removeClass("hidden");
                  $("#desktop-main").addClass("flex");
                  console.log("all promises resolved");
                  this.loading_project_progress = 100;
                  // this.customize();
                });

              }
              );
            });
          }
          else {

            this.page = "dashboard";
            this.project = {};
            this.project['embed_uuid'] = "";

            var upgraded_promise = this.isUpgraded();
            upgraded_promise.then(() => {


              console.log(this.limits);
              console.log(this.tracker);
              console.log(this.upgraded);


              this.loading_project_progress = 50;

              this.loading_project_progress = 100;
              this.loading_dashboard = false;


              console.log("Dashboard <<<<<<");

              // this.openDashboard();
              $('#loading-project').addClass('hidden');
              $('#loading-project').removeClass('flex');
              $('#sidebar').removeClass('hidden');
              $("#dashboard-main").removeClass("hidden");
              $("#dashboard-main").addClass("flex");

              // setTimeout(() => {

              this.dashboardRef.limits = this.limits;
              this.dashboardRef.tracker = this.tracker;
              this.dashboardRef.updateLimits();
              this.dashboardRef.upgraded = this.upgraded;
              this.dashboardRef.page = "dashboard";
              this.dashboardRef.initDashboard();
              // alert(this.sidebarRef.user_projects.length);
              this.dashboardRef.projects = this.sidebarRef.user_projects.length;



              // this.dashboardRef.test();
              // }, 2000);
            });
          }


        });

      } else {
        this.route.navigate(["/"]);
      }

    });





  }


  ngAfterViewInit() {
    (<any>$(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }))
  }

  getUserImage(fname) {
    Storage.get(fname, {
      level: 'public'
    }).then((url) => {
      this.user_pic_url = url;
      this.user.picture = fname;
    });

  }

  timerId = null;

  projectNameEdited() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      //console.log("project name edited....");
      // TODO: need to call the corresponding api to update the project name
      this.api.cd_updateUserProject(this.project).subscribe(data => {
        //console.log("data", data);
      }, err => console.log(err));
    }, 2000);
  }

  currentInteractionNameEdited(ci) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      //console.log("chat interaction name edited....");
      // TODO: need to call the corresponding api to update the chat interaction name
      this.api.cd_updateInteraction(ci).subscribe(data => {
        //console.log(data);
      }, err => console.log(err));
    }, 2000);
  }

  isUpgraded() {

    return new Promise((resolve, reject) => {
      this.api.cd_isUpgraded().subscribe((data) => {
        this.upgraded = data;
        console.log("upgraded", data);
        if (data['isUpgraded'] == true) {
          this.user_upgrade = true;
          this.plan = data['plan'];
        } else {
          this.plan = "Basic";
        }
        var limits_promise = this.getLimits();
        limits_promise.then(() => {
          resolve(null);
        });
      },
        err => {
          this.user_upgrade = false;
          this.plan = "Basic";
          var limits_promise = this.getLimits();
          limits_promise.then(() => {
            resolve(null);
          });
        }
      );
    }
    );

  }


  getLimits() {
    return new Promise((resolve, reject) => {
      console.log("getting limits");
      this.api.cd_getLimit(this.plan).subscribe((data: any) => {
        console.log(data);
        this.limits = data['plan_details'];
        this.tracker = data['track_obj'];
        resolve(null);
      }, err => console.log("error:", err));
    });
  }


  openCreateProjectModal() {
    // alert("openCreateProjectModal");
    this.sidebarRef.addProject();

  }




  getProject() {

    return new Promise((resolve, reject) => {
      this.api.cd_getUserProject(this.project_uuid, this.share_code, this.chat_id).subscribe((data: any) => {
        this.project = data.project;
        var color_id = this.utils.getUniqueColor(this.project['project_uuid']);
        console.log(color_id);
        this.project['color'] = 'bg' + color_id;
        console.log("hhh", this.project);
        resolve(null);
      }, err => {
        this.loading_project = false;
        this.no_access = true;
      }
      );

    });

  }

  project_objects: any = [];

  autosize() {
    var el = $('#chat-input')[0];
    el.style.cssText = 'overflow: hidden !important';

    if (el.scrollHeight > 120) {
      el.style.cssText = 'overflow: scroll !important';
      el.style.cssText = 'height: 120px';
      // el.removeEventListener('keydown', autosize);
    } else {
      setTimeout(function () {
        el.style.cssText = 'height:auto; padding:0';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }, 0);
    }
  }


  getObjectsForProject() {

    // alert("get objects for project");


    return new Promise((resolve, reject) => {

      this.dataService.cd_getObjectsForProject(this.project_uuid, this.share_code).subscribe((data: any) => {
        console.log(data['objects']);

        // this.atleast_one_indexing = false;

        this.project_objects = [];

        data['objects'].forEach((element: any) => {

          // add font awesome icon

          // alert(element.object_status)

          element = this.getIconAndColor(element);

          this.project_objects.push(element);
          // this.setCurentSubTab(this.current_subtab);

          if (element['object_status'] == "indexing") {
            // alert("indexing");
            // this.atleast_one_indexing = true;
            // this.num_indexing++;
          }


          //console.log(element);
        });

        // this.loading_project_objects = false;

        // this.indexing = this.atleast_one_indexing;

        // if (!this.indexing) {
        //   clearInterval(this.projects_polling);
        //   this.num_indexing = 0;
        // }
        //console.log("project_objects", this.project_objects);

        setTimeout(() => {

          if (this.project_objects.length == 0) {
            // this.toggleAddMenu();
            // $('#button-drawer').addClass('animate-ping');
          } else {
            // $('#button-drawer').removeClass('animate-ping');
          }

        }, 200);

        resolve(null);


      }
      );

    });

  }


  getUserInteractions() {
    return new Promise((resolve, reject) => {
      this.dataService.cd_getInteractions(this.project_uuid, this.share_code).subscribe((data: any) => {
        this.user_interactions = data['interactions'];
        console.log("user_interactions", this.user_interactions);
        if (this.user_interactions.length > 0) {
          this.current_interaction = this.user_interactions[0];
          console.log("current_interaction", this.current_interaction);
          this.getInteractionMessages();
        } else {
          this.addDefaultInteraction();
        }


        resolve(null);
      }
      );
    });
  }


  addDefaultInteraction() {
    return new Promise((resolve, reject) => {
      this.dataService.cd_addInteraction(this.project_uuid, 'chat', "Untitled Chat").subscribe((data: any) => {
        // TODO: check status
        this.user_interactions.push(data['interaction']);
        this.current_interaction = this.user_interactions[0];
        resolve(null);
      }
      );
    });
  }



  getIconAndColor(element: any) {
    if (element['file_type'] == "pdf") {
      element['icon'] = "file-pdf";
      element['iconcolor'] = "red";
    }
    else if (element['file_type'] == "docx" || element['file_type'] == "doc") {
      element['icon'] = "file-word";
      element['iconcolor'] = "blue";
    }
    else if (element['file_type'] == "pptx" || element['file_type'] == "ppt") {
      element['icon'] = "file-powerpoint";
      element['iconcolor'] = "orange";
    }
    else if (element['file_type'] == "xlsx" || element['file_type'] == "xls") {
      element['icon'] = "file-excel";
      element['iconcolor'] = "green";
    }
    else if (element['file_type'] == "txt") {
      element['icon'] = "file-lines";
      element['iconcolor'] = "gray";
    }

    return element;
  }


  uploadFile() {
    this.uploadRef.open_modal();
  }

  uploadUrl() {

    var validation = this.planService.validateAction("add_url", this.limits, this.tracker);

    var valid = validation.status;
    var error_message = validation.message;

    if (!valid) {
      bootbox.alert({
        size: 'medium',
        title: 'Oops!',
        message: error_message
      });
      return;
    }

    $('#chat_view').addClass('hidden');
    $('#chat_view').removeClass('flex');

    $('#upload_view').removeClass('hidden');
    $('#upload_view').addClass('flex');

  }

  urlClose() {
    this.getObjectsForProject();
    $('#chat_view').addClass('flex');
    $('#chat_view').removeClass('hidden');

    $('#upload_view').removeClass('flex');
    $('#upload_view').addClass('hidden');
  }

  backtoMain() {
    $('.main_view_window').addClass('hidden');
    $('.main_view_window').removeClass('flex');
    $('#chat_view').removeClass('hidden');
    $('#chat_view').addClass('flex');
  }

  uploadedFileCallback(file) {
    console.log("uploadedFileCallback", file);
    this.project_objects.unshift(file);
  }

  updateIndexingStatus(data) {

    console.log(data);

    this.project_objects.forEach(element => {
      if (element['file_uuid'] == data['file_uuid']) {
        element['object_status'] = data['status'];
        element['file_size'] = data['file_size'];
        element['object_id'] = data['object_id'];
      }
    });

  }


  // CHAT FUNCTIONS. MOVE TO SEPARATE FILE

  loading_messages: boolean = false;
  chat_messages: any = [];
  current_interaction: any = {};
  chathistory: any = [];

  goToChat(interaction) {
    this.current_interaction = interaction;
    this.getInteractionMessages();

  }

  getInteractionMessages() {

    this.chat_messages = [];

    this.dataService.cd_getInteractionMessages(this.project_uuid, this.current_interaction['interaction_uuid'], this.share_code).subscribe((data: any) => {
      console.log(data);
      this.current_interaction['messages'] = data['messages'];
      this.loading_messages = false;
      // this.chat_messages = data['messages'];
      data['messages'].forEach(element => {

        var msg = {}
        // msg['text'] = element['message'];

        this.chat_messages.push(element);

      }
      );

      // alert($('#chat-box')[0].scrollHeight);
      setTimeout(() => {
        this.scrollChatEnd('chat-box');
      }, 200);



      // this.scrollChatEnd('');
      this.makeChathistory();
    }
    );

  }

  scrollChatEnd(id) {

    setTimeout(() => {
      $('#' + id).scrollTop($('#' + id)[0].scrollHeight);
    }, 100);

  }


  makeChathistory() {
    let i = 0;
    let chat_len = this.chat_messages.length;
    this.chathistory = []
    // 1. find the first user message
    // 2. find the bot message
    while (i < chat_len) {
      let user_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'user') {
          user_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      let bot_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'bot') {
          bot_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      this.chathistory.push([user_message, this.removeATags(bot_message)]);
    }
    //console.log("chathistory", this.chathistory);
    this.scrollChatEnd('chat-box');
  }

  removeATags(text) {
    const regex = /<a\b[^>]*>(.*?)<\/a>/g;
    return text.replace(regex, '$1');
  }


  current_input_message: string = "";
  typing: boolean = false;
  current_message_tab: string = "";
  current_sources = [];
  take_last_n_messages = 3;


  saveChatMessage(message, actor) {
    console.log(this.current_interaction);
    if (this.current_interaction['messages'] == undefined) {
      this.current_interaction['messages'] = [];
    }
    this.current_interaction['messages'].push({ "message": message, "actor": actor });

    this.dataService.cd_addInteractionMessage(this.project_uuid, this.current_interaction['interaction_uuid'], message, actor).subscribe((data: any) => {
      // if (actor == 'bot') {

      //   this.dataService.cd_incrementNQuestions(this.project_uuid).subscribe((data: any) => {

      this.getLimits();

      //   }
      //   );

      // }

    }
    );

  }

  pushErrorMessage() {
    console.log("pushing error message");
    if (this.current_interaction['messages'] == undefined) {
      this.current_interaction['messages'] = [];
    }
    var message = { "message": { "text": '<span class="text-red-500">There was an error processing your request. Please try again later</span>', "actor": "bot" }, "actor": "bot" };
    this.current_interaction['messages'].push(message);
  }


  getAnswer(e) {
    e.preventDefault();
    this.typing = true;
    this.scrollChatEnd('chat-box');
    var question = this.current_input_message;
    this.current_input_message = "";

    $('#input-box').html('');

    var validation = this.planService.validateAction('answer', this.limits, this.tracker);
    var valid = validation.status;

    if (!valid) {
      this.pushErrorMessage();
      this.typing = false;
      return;
    }

    console.log("Here");

    if (1 == 1) {

      this.saveChatMessage({ "text": question, "actor": "user" }, "user");
      this.scrollChatEnd('chat-box');

      // console.log("Calling Get Answer", this.chathistory.slice(-this.take_last_n_messages));

      this.dataService.cd_getAnswer(this.project_uuid, question, this.chathistory.slice(-this.take_last_n_messages)).subscribe((data: any) => {
        //console.log(data);
        var sources = data['sources'];
        sources.forEach((element: any) => {
          this.project_objects.forEach((element2: any) => {
            if (element['object_id'] == element2['object_id']) {
              element['fileobj'] = element2;
            }
          });
        });
        //console.log({ "text": data['answer'], "actor": "bot", "sources": sources });
        this.saveChatMessage({ "text": data['answer'], "actor": "bot", "sources": sources }, "bot");
        this.scrollChatEnd('chat-box');
        this.typing = false;
        // Add to the chathistory
        this.chathistory.push([question, this.removeATags(data['answer'])])
      });

    } else {

    }

  }

  sources(sources) {
    this.current_sources = sources;
    //animate slide from right
    console.log("Sources...");
    console.log(this.current_sources);
    $('.sliders').addClass('hidden');
    $('#sources-slide').removeClass('hidden');
    $('#sources-slide').addClass('flex');

  }

  closeSources() {

    this.current_sources = [];
    $('#sources-slide').addClass('hidden');
    $('#sources-slide').removeClass('flex');
    // $('#sources-slide').addClass('hidden').animate({ width: 'toggle' }, 0);
  }

  closeFile() {

    $('#file-slide').addClass('hidden');
    $('#file-slide').removeClass('flex');
    // $('#sources-slide').addClass('hidden').animate({ width: 'toggle' }, 0);
  }


  goToSource(page, obj, text) {
    // $('.sliders').addClass('hidden');
    $('#file-slide').removeClass('hidden');
    $('#file-slide').addClass('flex');
    this.viewFile(obj);
  }

  seeFile(obj) {
    this.goToSource(1, obj, "");
  }

  current_view_obj = {};
  textData = "";
  current_tab = "";
  pdfSrc = "";
  viewFile(obj) {

    //console.log(obj);

    if (obj.file_type == "url") {
      // open url in new tab
      window.open(obj['file_name'], '_blank');
      return;
    }
    else if (obj.is_external) {
      window.open(obj['file_path'], '_blank');
      return;
    }
    else if (obj.file_type == "txt") {
      this.current_tab = "view_txt";
      this.textData = "";

      this.current_view_obj = this.getIconAndColor(obj);

      Storage.get(obj['file_path'])
        .then((url) => {

          //console.log(url);
          //  read data from url
          try {
            //console.log(url);
            fetch(url).then((response) => response.text()).then((data) => {

              this.textData = data;
              //console.log(data);

            }

            ).catch((err) => {
              //console.log(err);
            });;
          } catch (err) {
            //console.log(err);
          }
        },
          (error) => {
            //console.log(error);
          }
        );
      return;

    }



    this.current_tab = "view_pdf";

    this.current_view_obj = this.getIconAndColor(obj);
    console.log(obj, !obj['is_external'])
    if (!obj['is_external']) {
      Storage.get(obj['file_path']).then((data: any) => {
        console.log(data);
        this.pdfSrc = data;
      });

    }
    else {
      console.log("it's coming here")
      this.pdfSrc = obj['file_path'];
    }

  }

  viewUrl(obj) {

    //console.log(obj);

    this.current_tab = "view_url";

    this.current_view_obj = this.getIconAndColor(obj);




    // this.pdfSrc = obj['file_path'];
    // 
  }





  newChat() {

    var total_interactions = this.user_interactions.length;

    if (this.user_interactions.length >= this.limits['max_interactions']) {
      alert("You have reached the maximum number of chats you can create. Please upgrade your plan to add more Chats.");
      return;
    }

    this.dataService.cd_addInteraction(this.project_uuid, 'chat', 'Untitled Chat').subscribe((data: any) => {
      this.current_interaction = data['interaction'];
      this.chat_messages = [];
      this.chathistory = [];
      this.user_interactions.push(data['interaction']);
      this.getLimits();
      this.resources_tab = "history";
    }
    );
  }

  delCurrentProject(e) {
    this.sidebarRef.delProject(this.project, e);
  }

  delChat(chat_id) {

    this.delChatRef.setChatToDelete(chat_id);
    this.delChatRef.open_modal();

  }

  delChatConfirmed(chat_id) {
    this.user_interactions = this.user_interactions.filter(chat => chat.interaction_uuid !== chat_id);
    if (this.user_interactions.length == 0) {
      this.addDefaultInteraction().then((data) => {
        this.getInteractionMessages();
      });
    }
    else {
      this.current_interaction = this.user_interactions[0];
    }
  }

  keyAdded() {

    this.getLimits();
  }


  deleteObject(obj) {
    console.log("deleteObject_main");
    console.log(obj);
    this.api.cd_deleteObject(obj?.object_id, this.project_uuid, obj?.file_size).subscribe(data => {
      let doc_idx = this.project_objects.map(x => x?.object_id).indexOf(obj?.object_id);
      if (doc_idx != -1) {
        this.project_objects.splice(doc_idx, 1);
        this.getLimits();
      }
    }, err => console.log(err));
  }


  customize() {
    $('.main-view-box').addClass('hidden');
    $('#main-customize-window').removeClass('hidden');
    $('#main-customize-window').addClass('flex');

  }

  closeCustomize() {
    $('#main-customize-window').removeClass('flex');
    $('#main-customize-window').addClass('hidden');
    $('.main-view-box').removeClass('hidden');
    $('.main-view-box').addClass('flex');

  }

  openDashboard() {
    $('.main-view-box').addClass('hidden');
    $('#main-dashboard-window').removeClass('hidden');
    $('#main-dashboard-window').addClass('flex');
  }

  closeDashboard() {
    $('.main-view-box').addClass('hidden');
    $('#main-dashboard-window').removeClass('flex');
    $('#main-chat-window').removeClass('hidden');
  }





  filterAllTagsOtherThanLinks(message: string): SafeHtml {
    // Use DOM manipulation to filter and process the HTML content

    let message_formatted = message.replace(/([\n])/gs, '<br>').replace(/```([\s\S]*?)```/gs, (_, capturedText) => {
      capturedText = capturedText.replace(/<br>/g, '\n');
      // remove links from the code part
      capturedText = capturedText.replace(/<\/?a[^>]*>/g, '')
      return `<div class="code-div"><pre><code class="code-block">${capturedText}</code></pre></div>`;
    });
    // console.log(message_formatted);
    const wrapper = document.createElement('div');
    wrapper.innerHTML = message_formatted;

    // Recursively process child nodes
    this.processChildNodes(wrapper.childNodes);

    // Sanitize the processed HTML for security
    const processedHTML = wrapper.innerHTML;
    const sanitizedHTML = this.sanitizer.sanitize(SecurityContext.HTML, processedHTML);

    // sanitizedHTML = sanitizedHTML + `<a (click)="sources(message.message.sources)">sources</a>`;

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHTML);
  }

  private processChildNodes(nodes: NodeListOf<ChildNode>): void {
    nodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        if (element.tagName.toLowerCase() === 'a') {
          // If the node is an <a> element, make it clickable
          element.setAttribute('target', '_blank');
        } else {
          // If the node is not an <a> element, process its child nodes recursively
          this.processChildNodes(element.childNodes);
        }
      }
    });
  }


  showAccount() {
    $('.app-main').addClass('hidden');
    $('#account-main').removeClass('hidden');
    $('#account-main').addClass('flex');
  }



  persona_saved = false;
  chatBotConfig = {
    "persona": "",
    "temperature": "0"
  }

  // get chatbot context and open-ai parameters settings tab
  getChatbotConfig() {
    this.api.cd_getChatbotConfig(this.project_uuid).subscribe((data: any) => {
      this.chatBotConfig['persona'] = data?.persona;
      this.chatBotConfig['temperature'] = data?.temperature;
      console.log("chatBot config:", this.chatBotConfig);
    }, err => console.log("error:", err));
  }

  // put or update chatbot context and open-ai parameters settings tab
  updateChatbotConfig() {
    var persona = this.chatBotConfig['persona'];
    var temperature = this.chatBotConfig['temperature'];

    this.api.cd_updateChatbotConfig(this.project_uuid, persona = persona, temperature = temperature).subscribe((data: any) => {
      // do in case of success
      this.chatBotConfig['persona'] = persona;
      this.chatBotConfig['temperature'] = temperature;
      this.persona_saved = true;
      this.customizeRef.getChatbotConfig();
      setTimeout(() => {
        this.persona_saved = false;
      }, 1000);
    }, err => console.log("error:", err));
  }

  toggleChatConfig() {

    $('#chat-config').slideToggle();
    $('#chat-config-gear').toggle();
    $('#chat-config-close').toggle();

  }


  openShareModal() {
    this.modal_share = 'modal-open';
  }


  getShare() {
    try {
      this.api.cd_getShare(this.project_uuid).subscribe((data: any) => {
        if (data) {
          this.permissions = data['share']['permissions'];
          this.share_info = data['share'];
        }
      },
        (error) => {
          console.log("Error Getting Share");
        }
      );
    }
    catch {
      console.log("Erro getting Share");
    }
  }

  onSharingToggle(event: any) {

    //console.log(this.permissions, event);
    if (this.permissions['enable_sharing']) {

    }
    this.api.cd_createShare(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
      this.share_info = data['share'];
    }
    );
  }

  onPermissionChange(event: any) {
    //console.log(this.permissions, event);
    this.api.cd_updateSharePermissions(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
    }
    );
  }

  isCopied = false;
  copyied_text = false;

  copyTextToClipboard() {
    const code = `<knibble-bot [chatbot_id]="` + this.project['embed_uuid'] + `" #chatbot></knibble-bot>
                  <script src="https://s3.amazonaws.com/knibble.ai.assets/package.js" type="module"></script>`;
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

  copyNotionLink() {
    const code = `https://knibble.ai/embed/bot/` + this.project['embed_uuid'];

    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }




}

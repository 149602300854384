import { Component, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthServiceApp } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';





@Component({
  selector: 'app-authcatcher2',
  templateUrl: './authcatcher2.component.html',
  styleUrls: ['./authcatcher2.component.css']
})
export class Authcatcher2Component implements OnInit {



  constructor(public appauth: AuthServiceApp, private route: Router, private authenticatorService: AuthenticatorService, private http: HttpClient) { }

  ngOnInit(): void {


    Auth.currentAuthenticatedUser().then(user => {
      // //console.log(user);
    });

    this.authenticatorService.subscribe(() => {
      const { route } = this.authenticatorService;

      //console.log(route);

      if (route === 'authenticated') {

        // //console.log(this.authenticatorService.user);
        // // //console.log();

        // this.http.post(this.BASE_URL + "api/login", data)

        Auth.currentUserInfo().then(user => {
          // //console.log(user);
        })
          .catch(err => console.log(err + "<<<<<<<<<<<<<"));



        // this.http.post("https://apiv2.widgetbox.app/api/login", this.authenticatorService.user.attributes).subscribe((data) => {
        //   // //console.log(data);
        //   // this.route.navigate(["builder/weather/square"]);

        //   this.route.navigate(["dashboard/create"]);

        // });

        // //console.log(this.authenticatorService.user);

        var u = {
          "email": this.authenticatorService.user.attributes.email
        }


        this.appauth.login(this.authenticatorService.user.attributes).subscribe((data) => {
          // alert(data);
          //console.log(data);
          // this.route.navigate(["builder/weather/square"]);

          this.route.navigate(["project-new"]);

        });

      } else {

        this.route.navigate(["/"]);


      }
    });
  }

}

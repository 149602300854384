import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceWordsPipe'
})
export class SliceWordsPipePipe implements PipeTransform {

  transform(value: string, start: number, end?: number): unknown {
    // alert(value.split(" ").length, end);
    if (value == null) return null;


    // if length is less than end, return value
    if (value.split(" ").length < end) return value;

    return value
      .split(" ")
      .splice(start, end)
      .join(" ") + '...';
  }

}

<a class="p-4 rounded-lg  flex items-start  justify-between w-full mb-3 cursor-pointer" style="text-decoration: none;background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);" (click)="seeFileCliked()">
    <!-- Left Section: PDF Icon, File Name and Size -->
    <div class="flex justify-start items-start space-x-3">
        <!-- PDF Icon: You can replace this with an SVG or another icon -->
        <span class="p-0 pr-2 text-red-600" *ngIf="file.file_type === 'pdf'">
            <!-- <img src="assets/pdf-file-r.svg" class="w-8 p-0 mt-1 " alt=""> -->
            <i class="fa-solid fa-file-pdf text-red-600 " style="font-size:28px"></i>
        </span>

        <span class="p-0 text-red-600" *ngIf="file.file_type === 'txt'" style="min-width:18px">
            <img src="assets/txt-file-r.svg" class="w-8 p-0  " alt="">
        </span>

        <span class="p-0 text-red-600 min-w-min" *ngIf="file.file_type === 'url'">
            <img src="assets/html-file-r.svg" class="w-8 p-0 mt-1" alt="" style="min-width:30px;width:30px !important">
        </span>
        <!-- File Name and Size -->
        <div *ngIf="file.file_type === 'pdf' || file.file_type === 'txt' " class="pr-3">
            <h4 style="font-size:15px">{{file.file_name | truncatePipe:0:20}}</h4>
            <p class="text-xs text-gray-500 mt-2" *ngIf="file?.object_status === 'done'">
                {{this.formatFileSize(file?.file_size)}}</p>

            <p class="text-sm text-gray-500 mt-1" *ngIf="file?.object_status === 'indexing'">
                <span class="animate-pulse">Indexing...</span>
            </p>

            <div class="py-2" *ngIf="file.object_status === 'error'">

                <a (click)="retryFileIndexing($event)" class="px-3 py-2 text-xs  bg-red-300 mt-4 rounded-lg text-white">
                    <i class="fas fa-exclamation-circle text-red-500"></i>
                    Retry</a>

            </div>



        </div>
        <div *ngIf="file.file_type === 'url'">
            <h4 style="font-size:15px">{{file?.title | sliceWordsPipe:0:10}}</h4>
            <p class="text-xs text-gray-500 mt-2">{{get_domain(file.file_name)}}</p>
        </div>
    </div>
    <!-- Right Section: Delete Button -->
    <button class="text-gray-300 hover:text-red-500 transition duration-150" (click)="deleteObject($event)">
        <i class="fas fa-times-circle text-sm"></i>
    </button>
</a>
/// <reference types="chrome"/>
import { Component, OnInit } from '@angular/core';
// import { AuthService } from '@auth0/auth0-angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthServiceApp } from '../../../services/auth.service';
// import {chrome} from '@types/chrome';

@Component({
  selector: 'app-authcatcher',
  templateUrl: './authcatcher.component.html',
  styleUrls: ['./authcatcher.component.css']
})
export class AuthcatcherComponent implements OnInit {

  profileJson: string = null;

  constructor(private route: Router, public appauth: AuthServiceApp) { }



  ngOnInit(): void {
    // this.auth.user$.subscribe(
    //   (profile) => {



    //     // // //console.log(profile);

    //     if (profile != null) {

    //       // chrome.runtime.sendMessage("hnfkdddhnmaafapemplhjdcpllondicj", { jwt: profile }, response => {
    //       //   if (!response.success) {
    //       //     // //console.log('error sending message', response);
    //       //     return response;
    //       //   }
    //       //   // //console.log('Sucesss ::: ', response.message)
    //       // });




    //       this.profileJson = JSON.stringify(profile, null, 2);
    //       this.appauth.login(profile).subscribe((data) => {
    //         // // //console.log(data);
    //         // this.route.navigate(["builder/weather/square"]);

    //         this.route.navigate(["dashboard/create"]);

    //       });

    //     } else {

    //       chrome.runtime.sendMessage("hnfkdddhnmaafapemplhjdcpllondicj", { jwt: "loggedout" }, response => {
    //         if (!response.success) {
    //           // //console.log('error sending message', response);
    //           return response;
    //         }
    //         // //console.log('Sucesss ::: ', response.message)
    //       });

    //       this.route.navigate(["/"]);

    //     }


    //   }
    // );
  }

}

<div class="w-full  rounded-t-full rounded-b-full overflow-visible">

    <div class="w-full h-full flex justify-center items-center">
        <img src="assets/dots-loader.gif" class="w-10" *ngIf="loading_chatbot" />
    </div>

    <div class="w-full h-screen overflow-hidden flex flex-col p-0 items-stretch shadow-sm {{embed?.rounded}}"
        style="background:{{embed?.bg_color}}; border:solid 1px {{embed?.border_color}}; border-radius:{{embed?.rounded}}%"
        *ngIf="!loading_chatbot">

        <div class="p-3 flex justify-between items-center border-b" style="background:{{embed?.header_color}}">

            <div class="flex justify-start items-start ml-1">

                <div>
                    <img src="assets/default_logo.png" class="w-8 mt-1"
                        *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined" />
                    <img src="{{embed?.logo}}" class="w-8 mt-1"
                        *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined"
                        [ngClass]="{'rounded-full':embed?.logo_rounded}" />
                </div>
                <div class="flex flex-col justify-start">
                    <div class="ml-3 text-lg font-semibold" style="color: {{embed?.header_text_color}};">
                        {{embed?.title}}
                    </div>

                    <div class="font-normal ml-3 mt-1 text-sm" *ngIf="embed?.subtitle !== ''"
                        style="color: {{embed?.header_text_color}}; opacity: 70%;">
                        {{embed?.subtitle}}
                    </div>

                </div>

            </div>

            <div class="px-2" style="color: {{embed?.header_text_color}};">

                <a (click)="chat_messages = []" style="text-decoration: none;">
                    <i class="fa-light fa-eraser text-xl cursor-pointer"></i>

                </a>

            </div>


        </div>

        <div class="w-full px-4 py-2 bg-gray-100 text-sm"
            style="color: {{embed?.sub_header_text_color}}; background: {{embed?.sub_header_bg_color}}"
            *ngIf="embed?.sub_header_text !== '' && embed?.sub_header_text !== null && embed?.sub_header_text !== undefined">
            {{embed?.sub_header_text}}
        </div>


        <div class="p-3  h-auto flex-1 flex-grow overflow-y-scroll" id="chat-box">

            <div class="p-0   border-0 w-full  flex ">
                <div class="p-3 my-2 w-fit  text-white rounded-lg" style="background: {{embed?.bot_msg_bg_color}};">

                    <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                    <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                        {{embed?.default_message}}

                    </div>

                </div>
            </div>




            <div *ngFor="let message of chat_messages" class="p-0 border-0">

                <div class="p-0 rounded-lg  border-0 w-full flex justify-end items-end"
                    *ngIf="message.actor === 'user'">
                    <div class="p-3 my-2 w-fit rounded-lg" style="background: {{embed?.user_msg_bg_color}};">



                        <div class="text-sm" style="color:{{embed?.user_msg_text_color}}">

                            {{message?.text}}

                        </div>

                    </div>
                </div>

                <div class="p-0   border-0 w-full flex " *ngIf="message?.actor === 'bot'">
                    <div class="p-3 my-2 w-fit  text-white rounded-lg" style="background: {{embed?.bot_msg_bg_color}};">



                        <div [innerHTML]="filterAllTagsOtherThanLinks(message?.text)" class="text-sm"
                            style="color:{{embed?.bot_msg_text_color}}">



                        </div>

                    </div>
                </div>

            </div>

            <div class="w-full p-8 mx-auto flex justify-center items-center" *ngIf="typing">

                <img src="assets/dots-loader.gif" class="w-12 h-12 mx-auto" />
            </div>

            <div class="w-full h-40" id="end_of_chat">

            </div>

        </div>

        <div class="relative flex justify-between items-center rounded-lg px-0 py-0 overflow-hidden m-0  h-auto"
            style="background: {{embed?.send_bar_background}};">

            <div class="flex w-full justify-center items-center border-t py-2">

                <div class="textarea w-full py-3 px-4 text-base border-0 rounded bg-inherit shadow-none focus:outline-none input-box"
                    id="input-box" contenteditable="true" placeholder="Your message ..." (keyup.enter)="getAnswer()"
                    (input)="current_input_message = $event.target.textContent">
                </div>

                <!-- <button class="ml-4 mr-4" (click)="getAnswer()" style="background-image: linear-gradient(60deg, #de41de, #f0950c);
             -webkit-background-clip: text; background-clip: text;
             color: transparent !important;">
                    <i class="fa-solid fa-paper-plane-top text-xl"></i>
                </button> -->

                <button type="button" (click)="getAnswer()"
                    class="inline-flex items-center justify-center rounded-lg px-4 py-3 mx-2 transition duration-500 ease-in-out  bg-inherit focus:outline-none"
                    style="color:{{embed?.send_icon_color}}">

                    <i class=" {{embed?.send_icon}}"></i>
                </button>

            </div>
        </div>





    </div>


</div>
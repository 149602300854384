import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Component({
  selector: 'app-newauth',
  templateUrl: './newauth.component.html',
  styleUrls: ['./newauth.component.css']
})
export class NewauthComponent implements OnInit {

  constructor(private route: Router, private aroute: ActivatedRoute) { }

  services = {
    async handleSignUp(formData: Record<string, any>) {
      let { username, password, attributes } = formData;
      // //console.log("In Services", formData);
      // custom username
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
  };

  siginWithGoogle() {

    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

  }

  state = "";

  ngOnInit(): void {

    this.aroute.params.subscribe(params => {
      // //console.log(params['state']);
      this.state = params['state'];
    });

    Hub.listen('auth', (data) => {
      // //console.log(data.payload.event);
      switch (data.payload.event) {
        case 'signIn':
          // //console.log('user signed in');
          setTimeout(() => {
            this.route.navigate(["auth2"]);
          }, 1000);

          break;
        case 'signUp':
          // //console.log('user signed up', data.payload.data['user']['username']);
          data.payload.data['user']['username'] = data.payload.data['user']['username'].toLowerCase();
          break;
        case 'signOut':
          // //console.log('user signed out');
          break;
        case 'signIn_failure':



          // check if user exists in our dynamodb
          // //console.log(data);
          //   Auth.signUp({
          //     data.payload.data['user']['username'],
          //     password,
          //     attributes: {
          //         email,          // optional
          //         phone_number,   // optional - E.164 number convention
          //         // other custom attributes 
          //     },
          //     autoSignIn: { // optional - enables auto sign in after user is confirmed
          //         enabled: true,
          //     }
          // });
          // //console.log('user sign in failed');
          break;
        case 'configured':
        // //console.log('the Auth module is configured');
      }
    });

  }

}

import { Injectable } from '@angular/core';
import { el } from 'date-fns/locale';

const ERROR_ADD_API_KEY = " You have subscribed to the BYOK plan. Please add your OpenAI API key to continue using Knibble.ai."
const ERROR_MAX_FILES = "You have reached the maximum number of files allowed for your plan. Please upgrade to continue.";
const ERROR_MAX_STORAGE = "You have reached the maximum storage allowed for your plan. Please upgrade to continue.";
const ERROR_MAX_FILE_SIZE = "The size of the file you are trying to upload is greater than the maximum allowed for your plan. Please upgrade to continue.";
const ERROR_MAX_URLS = "You have reached the maximum number of URLs allowed for your plan. Please upgrade to continue.";
const ERROR_MAX_QUESTIONS = "You have reached the maximum number of questions allowed for your plan. Please upgrade to continue.";
const ERROR_MAX_PROJECTS = "You have reached the maximum number of projects allowed for your plan. Please upgrade to continue.";

@Injectable({
  providedIn: 'root'
})

export class PlansService {

  return_obj = {
    "status": true,
    "message": ""
  }

  constructor() { }


  getByokError() {
    return ERROR_ADD_API_KEY;
  }


  validateAction(action, limits, tracker, fetch_new = false) {

    if (limits.is_own_key) {
      if (!limits.is_key_generated) {
        return {
          "status": false,
          "message": ERROR_ADD_API_KEY
        }
      }

    }


    if (action == "upload_file") {
      var total_file_size = (tracker['total_file_size'] - tracker['total_del_file_size']) / 1024 / 1024;
      var allowed_file_size = limits['max_total_storage_mb'];
      var total_files_uploaded = tracker['n_files'] - tracker['n_del_files'];
      var allowed_files_uploaded = limits['max_files'];
      console.log("total_file_size", total_file_size);
      console.log("allowed_file_size", allowed_file_size);
      console.log("total_files_uploaded", total_files_uploaded);
      console.log("allowed_files_uploaded", allowed_files_uploaded);

      if (total_file_size >= allowed_file_size) {
        return {
          "status": false,
          "message": ERROR_MAX_STORAGE
        }
      }
      else if (total_files_uploaded >= allowed_files_uploaded) {

        return {
          "status": false,
          "message": ERROR_MAX_FILES
        }
      }
    }
    else if (action == "add_url") {
      var total_urls = (tracker['n_urls'] - tracker['n_del_urls']);
      var allowed_urls = limits['max_urls'];
      console.log("total_urls", total_urls);
      console.log("allowed_urls", allowed_urls);

      if (total_urls >= allowed_urls) {
        return {
          "status": false,
          "message": ERROR_MAX_URLS
        }
      }
    }
    else if (action == "answer") {
      if (parseInt(tracker['n_questions']) >= parseInt(limits['max_messages_per_month'])) {
        if (limits.is_key_generated) {
          return this.return_obj;
        }
        else {
          return {
            "status": false,
            "message": ERROR_MAX_QUESTIONS
          }
        }
      }
    }
    else if (action == "add_project") {

      var net_projects = (tracker['n_projects'] - tracker['n_del_projects']);
      var allowed_projects = limits['max_projects'];
      console.log("net_projects", net_projects);
      console.log("allowed_projects", allowed_projects);

      if (net_projects >= allowed_projects) {
        return {
          "status": false,
          "message": ERROR_MAX_PROJECTS
        }
      }
    }
    return this.return_obj;

  }

}

<div class="chatbot-popup">
    <!-- <div class="chatbot-header">
      <h4>Ask AI</h4>
      <button class="btn btn-secondary" (click)="closeChatbot()">X</button>
    </div> -->
    <div #chatContainer class="chatbot-body">
      <div class="chatbot-message" *ngFor="let message of messages">
        <div [innerHTML]="message.text" class="user-message" *ngIf="message.user" (click)="copyMessage(message.text)"></div>
        <div [innerHTML]="message.text" class="bot-message" *ngIf="!message.user" (click)="copyMessage(message.text)"></div>

      </div>
      <div class="message-content" [ngClass]="{'typing': isTyping   }" [hidden]="!isTyping">
        <div class="chat-message typing">
          <div class="typing-indicator">
            <span>...</span>
            <span>..</span>
            <span>.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="chatbot-input">
      <input type="text" [(ngModel)]="userMessage" placeholder="Type your message..." (keyup.enter)="sendMessage()">
      <button (click)="sendMessage()">Send</button>
    </div>
  </div>
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@aws-amplify/storage';
import { Observable, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { data } from 'jquery';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  BASE_URL: string;

  constructor(private http: HttpClient) {

    if (environment.production) {
      this.BASE_URL = "https://apiv2.knibble.ai/";
    } else {
      this.BASE_URL = "https://apiv2.knibble.ai/";
      // this.BASE_URL = "http://localhost:5002/";
    }

  }

  logout() {
    return this.http.get(this.BASE_URL + "api/logout", {
      withCredentials: true
    });
  }
}

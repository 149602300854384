import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../backend/services/api.service';

@Component({
  selector: 'app-delete-chat',
  templateUrl: './delete-chat.component.html',
  styleUrls: ['./delete-chat.component.css']
})
export class DeleteChatComponent implements OnInit {

  @Output() notifyDeletSuccess: EventEmitter<any> = new EventEmitter();

  constructor(private api: ApiService) { }

  modal_state = "modal-close";
  del_chat_id: any;

  ngOnInit(): void {
  }

  open_modal() {
    this.modal_state = 'modal-open';
  }

  close_modal() {
    this.modal_state = 'modal-close';
  }

  setChatToDelete(chat_id: any) {
    this.del_chat_id = chat_id;
  }

  deleteInteraction() {

    this.api.cd_deleteInteraction(this.del_chat_id).subscribe((data: any) => {
      this.notifyDeletSuccess.emit(this.del_chat_id);
      this.close_modal();
      this.del_chat_id = "";
    }
    );
  }

}

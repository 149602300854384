import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { AuthServiceApp } from '../../../services/auth.service'
// import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  email: any;
  password: any;

  constructor(private route: Router) { }

  ngOnInit(): void {



  }


  logout() {


    // alert("logout");


  }

}

<!-- <div id="slideover-container" class="w-1/2 absolute right-0 h-full invisible bg-gray-50 border-l"
    style="z-index: 99999999 !important;">
    <div (click)="toggleSlideover()" id="slideover-bg"
        class="w-full h-full duration-500 ease-out transition-all inset-0 bg-gray-900 opacity-0">
    </div>
    <div (click)="toggleSlideover()" id="slideover"
        class="w-96 bg-white h-full absolute right-0 duration-300 ease-out transition-all translate-x-full">
        <div
            class="absolute cursor-pointer text-gray-600 top-0 w-8 h-8 flex items-center justify-center right-0 mt-5 mr-5">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </div>
    </div>
</div> -->

<app-toast></app-toast>

<div class="w-full h-full" [hidden]="embed_show === true">


    <div class="flex flex-col w-full h-screen bg-gray-300" *ngIf="!no_access && !loading_project">
        <div class="bg-gray-900 flex justify-between items-center">
            <div class="flex  w-1/3 justify-start items-center">
                <a class="flex justify-start text-white items-center mr-4 " href="/main">

                    <!-- <i class="fa-brands fa-dochub ml-4 text-lg mr-1" style="color:#ffe1d0"></i> -->
                    <div style="font-size: 20px;
                    font-family: 'Poppins';
                    font-weight: bold;     margin-top: -10px;
                    color: #e2e0e0;" class=" ml-4">Knibble<span class="text-gray-400 text-4xl"
                            style="color:var(--app-theme-color)">.</span>
                        <!-- <span
                            class="text-sm ml-2 text-gray-300 -mt-3">Beta</span> -->
                    </div>

                </a>


                <div class="flex justify-center text-white items-center">

                    <!-- <div class="dropdown p-0 m-0">
                <button class="btn btn-secondary dropdown-toggle p-0 bg-none text-white text-base" type="button"
                    id="dropdownMenuButton" style="background: inherit !important;padding:0 !important"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Untitled Project <i class="fa-light fa-caret-down ml-2"></i> </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                </div>
            </div> -->
                    <div *ngIf="!is_shared" contenteditable="true"
                        class="py-2 pr-4 border-b border-dashed focus:outline-none" [textContent]="project.project_name"
                        (input)="project.project_name = $event.target.textContent" (keyup)="projectNameEdited()">
                    </div>

                    <div *ngIf="is_shared" class="py-2 pr-4 border-b border-dashed focus:outline-none">
                        {{project['project_name']}}
                    </div>

                </div>



                <!-- <div class="dropdown  px-0 ml-4" *ngIf="user_logged_in">
                    <button
                        class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                        type="button" id="dropdownMenuButton" style="background: inherit !important;"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">




                        <i _ngcontent-ilr-c103="" class="fa-light text-sm text-gray-100 fa-ellipsis-vertical ml-1"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        
                    </div>
                </div> -->

            </div>

            <div class="flex gap-x-4">
                <a class="bg-indigo-100 text-white rounded-sm px-0 py-2 text-sm mr-3 font-medium cursor-pointer"
                    (click)="openShareModal()" *ngIf="!is_shared"
                    style="background: inherit; border:solid 0px var(--app-theme-color)" aria-current="page">
                    <i class="fa-regular fa-share mr-1"></i>
                    Share</a>

                <a class="bg-indigo-100 text-white rounded-sm px-0 py-2 text-sm font-medium cursor-pointer"
                    (click)="openEmbedModal()" *ngIf="!is_shared"
                    style="background: inherit; border:solid 0px var(--app-theme-color)" aria-current="page">
                    <i class="fa-regular fa-code mr-1"></i>
                    Chatbot</a>


            </div>


            <div class="flex justify-end text-white items-center w-1/3 px-4">

                <a *ngIf="user_logged_in" class="mx-8 flex" href="/pricing">

                    <div *ngIf="user_upgrade" class="text-sm flex justify-center items-center">
                        <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                        {{plan}} plan
                    </div>

                    <div *ngIf="!user_upgrade" class="text-sm flex justify-center items-center">
                        <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                        Basic plan
                    </div>

                    <div *ngIf="!user_upgrade" class="ml-4">
                        <a href="/pricing" class="text-white bg-inherit hover:bg-gray-800 px-2 py-3 text-sm"
                            style=" font-weight: 600px;text-decoration: none; border:solid 0px var(--app-theme-color); background: #1b2438;;">
                            <i class="fa-regular fa-rocket mr-1"></i>
                            Upgrade
                        </a>
                    </div>

                </a>


                <div class="dropdown dropleft px-0 ml-1" *ngIf="user_logged_in">
                    <button
                        class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                        type="button" id="dropdownMenuButton" style="background: inherit !important;"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="avatar mr-0">

                            <div class="w-8 h-8 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-inherit"
                                style="border:solid 0px var(--app-theme-color) !important; background: #233047;">
                                <div class="flex justify-center items-center h-full">
                                    <div class="text-base text-white capitalize font-semibold">
                                        {{user?.user_uuid[0]}}
                                    </div>
                                </div>
                            </div>
                        </div>



                        <i _ngcontent-ilr-c103="" class="fa-solid text-base text-gray-100 fa-caret-down ml-1"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item font-semibold py-1 border-b">{{user?.user_uuid}}</a>
                        <a class="dropdown-item" href="/account">Account</a>

                        <a class="dropdown-item" href="https://tally.so/r/mRd6jJ">Contact Us</a>
                        <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                        <a class="dropdown-item text-gray-500" href="#">Delete Account</a>
                    </div>
                </div>


                <div class="dropdown dropleft px-0 ml-4" *ngIf="user_logged_in">
                    <button
                        class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                        type="button" id="dropdownMenuButton" style="background: inherit !important;"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">



                        <i _ngcontent-ilr-c103="" class="fa-solid text-sm text-gray-100 fa-ellipsis-vertical ml-1"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a href="/main"
                            class="bg-indigo-100  rounded-md px-3 py-2  text-sm font-medium cursor-pointer mr-2 text-gray-800  dropdown-item"
                            style=" background: inherit; border:solid 0px var(--app-theme-color)" aria-current="page">
                            <i class="fa-regular fa-arrow-left mr-1"></i>
                            Back to projects
                        </a>

                        <a class="bg-indigo-100  rounded-sm px-0 py-2 mr-3 ml-4 text-sm font-medium cursor-pointer text-gray-800 dropdown-item"
                            (click)="openDeleteModal()" *ngIf="!is_shared"
                            style="background: inherit; border:solid 0px var(--app-theme-color)" aria-current="page">
                            <i class="fa-regular fa-times mr-1"></i> Delete Project
                        </a>
                    </div>
                </div>




            </div>
        </div>

        <div class="flex justify-start items-center px-4 py-2 text-sm bg-red-50"
            *ngIf="limits?.is_own_key && !limits.is_key_generated">
            You are subscribed to {{limits?.plan_uuid}}. Please add your OpenAI API key to continue using Knibble. Go
            to&nbsp;
            <a href="/main" class="text-blue-500">Dashboard</a> &nbsp;to add your key.
        </div>

        <div class="bg-white flex w-full h-full m-0 rounded-lg overflow-hidden">

            <div class="left_div w-1/2 h-full bg-white flex flex-col border-r relative">

                <div *ngIf="!is_shared || (is_shared && permissions.view_files)" class="w-full h-full">

                    <div class="w-full  bg-gray-100 p-0 border-b border-gray-200 flex gap-x-4 justify-between items-center"
                        style="background: #F7FAFC !important;">
                        <div class="text-base flex justify-start items-center w-full"
                            *ngIf="current_tab === 'files' || current_tab === 'all' || current_tab === 'links'">
                            <button
                                class="bg-none text-gray-700 pl-4 pr-0 mr-0  py-0 border-gray-800 border-0 text-lg font-semibold">

                                Resources
                            </button>

                            <div class="mx-4 text-gray-300">
                                <i class="fa-regular fa-ellipsis-vertical"></i>
                            </div>

                            <div class=" border-0 mb-0 gap-x-4" style="font-family: proxima-nova !important;">
                                <button
                                    class="text-base font- bg-gray-100 rounded-none px-2 border-0 border-black py-3 my-0 mr-3"
                                    (click)="setCurentSubTab('all')"
                                    [ngClass]="{ 'subtab': current_subtab === 'all' }">All</button>
                                <button class="text-base font- rounded-none px-2 py-3 mr-2"
                                    (click)="setCurentSubTab('files')"
                                    [ngClass]="{ 'subtab': current_subtab === 'files' }">
                                    <i class="fa-light fa-file mr-0 text-sm"></i>
                                    Files</button>
                                <button class="text-base font- rounded-none px-2 py-3 mr-0 "
                                    (click)="setCurentSubTab('links')"
                                    [ngClass]="{ 'subtab': current_subtab === 'links' }">
                                    <i class="fa-light fa-browser mr-0 text-sm"></i>
                                    Links</button>
                                <!-- <button class="text-sm font- rounded-none px-3 py-3 mr-0">
                                <i class="fa-light fa-paragraph mr-1"></i>
                                Text</button> -->
                            </div>



                            <div class="w-20"></div>







                        </div>

                        <div class="pr-4 flex justify-end items-center gap-x-2"
                            *ngIf="current_tab === 'files' || current_tab === 'all' || current_tab === 'links'">


                            <!-- search bar -->
                            <div class="p-0 w-52  rounded-full bg-white flex justify-start items-center"
                                style="border: solid 1px #e3e3e3 !important;">
                                <i class="fa-light fa-search text-gray-500 ml-3"></i>
                                <input type="text" [(ngModel)]="search_display_objs" (keyup)="searchObjects()"
                                    class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-auto h-full focus:outline-none"
                                    style="padding-top:6px;padding-bottom:6px" placeholder="Search Resources" />

                            </div>





                        </div>

                        <!-- <div>


                    </div> -->



                    </div>

                    <div class="w-full px-4 py-2 bg-inherit flex justify-between items-center">

                        {{project_objects_display.length}} results
                        <div class="flex gap-x-2 mr-2">
                            <a (click)="resources_view_type = 'grid'"><i class="fa-light fa-grid-2"></i></a>
                            <a (click)="resources_view_type = 'list'"><i class="fa-light fa-list"></i></a>

                        </div>

                    </div>

                    <div class="w-full bg-yellow-50 px-4 py-2 text-sm text-gray-700 border-b" *ngIf="indexing">
                        Please wait. We are training our AI on one of your files. This may take a few minutes. Your
                        results may not
                        be accurate
                    </div>


                    <div class="w-full h-full bg-gray-50 pt-3 flex flex-col px-4  pb-0 overflow-y-scroll relative"
                        style="background: #F7FAFC !important;"
                        *ngIf="current_tab === 'all' || current_tab === 'files' || current_tab === 'links'">

                        <!-- <div class="w-screen h-screen flex items-center justify-center">
                            <div (click)="toggleSlideover()"
                                class="cursor-pointer px-5 py-2 text-sm border text-gray-500 hover:bg-gray-100 rounded border-gray-300">
                                Toggle Slide-over</div>

                        </div> -->

                        <div class="w-full h-full flex flex-col justify-center items-center"
                            *ngIf="project_objects_display.length === 0 && !loading_project_objects">

                            <img src="assets/404.gif" class="w-1/2 mb-2" alt="" style="max-width: 300px;">

                            <div>

                                <p class="text-sm text-gray-400">Start by adding resources to your project</p>
                            </div>

                        </div>

                        <div class="w-full h-full flex flex-col justify-center items-center"
                            *ngIf="project_objects_display.length === 0 && loading_project_objects">
                            <img src="assets/progress.gif" class="w-1/2 mb-2" alt="" style="max-width:30px;">
                        </div>


                        <div class="w-full h-full overflow-y-scroll">

                            <div class="w-full grid grid-cols-3 gap-y-4 gap-x-6" *ngIf="resources_view_type === 'grid'">

                                <!-- Grid View -->

                                <a class=" shadow-prop rounded-lg overflow-hidden flex flex-col cursor-pointer hover:bg-gray-50 relative bg-white r_res"
                                    style="z-index:0; text-decoration: none; border: solid 1px #f1f1f1 !important; min-height: 100px;"
                                    (click)="viewFile(file)" *ngFor="let file of project_objects_display">

                                    <button class="absolute top-2 right-2 w-8 h-8 rounded-full bg-gray-100 r_del hidden"
                                        (click)="deleteObject($event, file)" style="z-index:999999">
                                        <i class="fa-regular fa-times text-gray-500"></i>
                                    </button>

                                    <div class="flex flex-col w-full h-full" *ngIf="file?.file_type === 'pdf'">

                                        <div class="p-4">

                                            <!-- <i class="fa-regular fa-{{file.icon}} mr-3 text-{{file.iconcolor}}-500 text-3xl mt-1"></i> -->

                                            <img src="assets/pdf-file-3.svg" class="w-12 p-0  -ml-2" alt=""
                                                *ngIf="file.object_status !== 'indexing' && file.object_status !== 'error'">
                                            <img src="assets/progress.gif" class="w-16 p-0  -ml-2" alt=""
                                                *ngIf="file.object_status === 'indexing'">



                                            <div class="w-full justify-start items-start flex flex-col"
                                                *ngIf="file.object_status === 'error'">

                                                <i
                                                    class="fa-regular fa-exclamation-triangle text-red-500 text-3xl mt-1"></i>
                                                <span class="mt-1">Error</span>

                                            </div>


                                            <div class="text-sm font-normal leading-6 text-gray-900 mt-6 py-2 flex-wrap"
                                                style="word-wrap: break-word;">
                                                {{file.file_name}} {{file.status}}
                                            </div>

                                        </div>
                                        <div class="w-full h-full">

                                        </div>

                                        <div class="px-4 pb-3 pt-0" style="background: #fff;">


                                            <span class="text-xs text-gray-500"
                                                *ngIf="file.object_status !== 'error'">{{this.formatFileSize(file?.file_size)}}</span>

                                            <button class="font-normal rounded-lg px-2 py-1 bg-white"
                                                (click)="retyFileIndex(file, $event)"
                                                style="border:solid 1px var(--app-theme-color)"
                                                *ngIf="file.object_status === 'error'">
                                                <i class="fa-light fa-sync  mr-1"></i>
                                                Retry</button>

                                        </div>

                                    </div>

                                    <div class="flex flex-col w-full h-full" *ngIf="file?.file_type === 'txt'">

                                        <div class="p-4">

                                            <!-- <i class="fa-regular fa-{{file.icon}} mr-3 text-{{file.iconcolor}}-500 text-3xl mt-1"></i> -->

                                            <img src="assets/txt.svg" class="w-12 p-0  -ml-2" alt="">

                                            <div class="text-sm font-normal leading-6 text-gray-900 mt-6 flex-wrap"
                                                style="word-wrap: break-word;">
                                                {{file.file_name}}
                                            </div>

                                        </div>
                                        <div class="w-full h-full">

                                        </div>

                                        <div class="px-4 pb-3 pt-0" style="background: #fff;">


                                            <span
                                                class="text-xs text-gray-500">{{this.formatFileSize(file?.file_size)}}</span>

                                        </div>

                                    </div>

                                    <div class="flex flex-col w-full h-full" *ngIf="file?.file_type === 'url'">

                                        <div class="p-4">

                                            <!-- <i class="fa-regular fa-{{file.icon}} mr-3 text-{{file.iconcolor}}-500 text-3xl mt-1"></i> -->

                                            <img src="assets/html.svg" class="w-12 p-0 -ml-2" alt="">

                                            <div class="text-sm font-normal leading-6 text-gray-900 mt-6 flex-wrap"
                                                style="word-wrap: break-word;">
                                                {{file?.title | sliceWordsPipe:0:10}}
                                            </div>

                                        </div>
                                        <div class="w-full h-full">

                                        </div>

                                        <div class="px-4 pb-3 pt-0" style="background: #fff;">


                                            <span class="text-xs text-gray-500">{{get_domain(file.file_name)}}</span>

                                        </div>

                                    </div>





                                </a>



                            </div>


                            <!-- List View -->

                            <div *ngIf="resources_view_type === 'list'">

                                <a class="  overflow-hidden flex cursor-pointer hover:bg-gray-50 relative bg-white border-b r_res"
                                    style="z-index:0; text-decoration: none;;" (click)="viewFile(file)"
                                    *ngFor="let file of project_objects_display">



                                    <div class="flex justify-between p-4 items-center w-full h-full"
                                        *ngIf="file?.file_type === 'pdf'">


                                        <div class="flex justify-start items-center">
                                            <!-- <img src="assets/pdf-file-3.svg" class="w-6 p-0" alt=""
                                            *ngIf="file.object_status !== 'indexing' && file.object_status !== 'error'"> -->
                                            <i class="fa-light fa-file-pdf text-red-500 fa-fw"
                                                *ngIf="file.object_status !== 'indexing' && file.object_status !== 'error'"></i>
                                            <img src="assets/progress.gif" class="w-8 p-0 " alt=""
                                                *ngIf="file.object_status === 'indexing'">



                                            <div class="justify-start items-center flex"
                                                *ngIf="file.object_status === 'error'">

                                                <i
                                                    class="fa-regular fa-exclamation-triangle text-red-500 fa-fw mr-1"></i>
                                                <!-- <span class="mt-1">Error</span> -->

                                            </div>


                                            <div class="text-sm font-normal text-gray-900 ml-2  flex-wrap">
                                                {{file.file_name | sliceWordsPipe:0:4}} {{file.status}}
                                            </div>

                                        </div>



                                        <div class="px-4" style="background: #fff;">


                                            <span class="text-xs text-gray-500"
                                                *ngIf="file.object_status !== 'error'">{{this.formatFileSize(file?.file_size)}}</span>

                                            <button class="font-normal rounded-lg px-2 py-1 bg-white text-sm"
                                                (click)="retyFileIndex(file, $event)"
                                                style="border:solid 1px var(--app-theme-color)"
                                                *ngIf="file.object_status === 'error'">
                                                <i class="fa-light fa-sync  mr-1"></i>
                                                Retry</button>

                                        </div>

                                    </div>

                                    <div class="flex justify-between p-4 items-center w-full h-full"
                                        *ngIf="file?.file_type === 'txt'">



                                        <!-- <i class="fa-regular fa-{{file.icon}} mr-3 text-{{file.iconcolor}}-500 text-3xl mt-1"></i> -->

                                        <div class="gap-x-2 flex justify-start items-center">
                                            <!-- <img src="assets/txt.svg" class="w-6 p-0 " alt=""> -->
                                            <i class="fa-regular fa-file-alt text-gray-500 fa-fw"></i>

                                            <div class="text-sm font-normal leading-6 text-gray-900 flex-wrap"
                                                style="word-wrap: break-word;">
                                                {{file.file_name | sliceWordsPipe:0:4}}
                                            </div>

                                        </div>



                                        <div class="px-4 pb-2 pt-0" style="background: #fff;">


                                            <span
                                                class="text-xs text-gray-500">{{this.formatFileSize(file?.file_size)}}</span>

                                        </div>

                                    </div>

                                    <div class="flex justify-between items-center p-4 w-full h-full"
                                        *ngIf="file?.file_type === 'url'">



                                        <!-- <i class="fa-regular fa-{{file.icon}} mr-3 text-{{file.iconcolor}}-500 text-3xl mt-1"></i> -->
                                        <div class="gap-x-2 flex justify-start items-center">
                                            <!-- <img src="assets/html.svg" class="w-6 p-0" alt=""> -->
                                            <i class="fa-light fa-globe text-blue-500 fa-fw"></i>

                                            <div class="text-sm font-normal leading-6 text-gray-900 flex-wrap"
                                                style="word-wrap: break-word;">
                                                {{file?.title | sliceWordsPipe:0:6}}
                                            </div>
                                        </div>



                                        <div class="px-4 pb-2 pt-0" style="background: #fff;">


                                            <span class="text-xs text-gray-500">{{get_domain(file.file_name)}}</span>

                                        </div>

                                    </div>


                                    <button class="absolute top-2 right-2 w-8 h-8 rounded-full bg-gray-100 r_del hidden"
                                        (click)="deleteObject($event, file)" style="z-index:999999">
                                        <i class="fa-regular fa-times text-gray-500"></i>
                                    </button>





                                </a>

                            </div>



                            <div class="w-full h-96">

                            </div>

                        </div>

                    </div>


                    <div class="w-full px-0 flex justify-between items-center shadow-sm mb-0"
                        *ngIf="current_tab === 'view_pdf'">



                        <div class="truncate ml-4 font-semibold">
                            <i
                                class="fa-solid fa-{{current_view_obj['icon']}} mr-1 text-{{current_view_obj['iconcolor']}}-500"></i>
                            {{current_view_obj['file_name']}}

                        </div>

                        <div>

                        </div>

                        <button class="bg-none text-gray-900 px-4 cursor-hand py-3 text-sm" (click)="fileViewer()">
                            <i class="fa-regular fa-arrow-left mr-1 "></i>
                            Back to Resources
                        </button>



                    </div>

                    <div class="w-full h-full bg-white border-b flex flex-col p-0" *ngIf="current_tab === 'view_pdf'"
                        style="position: relative;">
                        <!-- <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [(page)]="pageVariable"
                        style="position: absolute; top:0; left:0; right:0; bottom: 0;"></pdf-viewer> -->
                        <ngx-extended-pdf-viewer [src]="pdfSrc" [height]="'99%'" [useBrowserLocale]="true"
                            [textLayer]="true" [(page)]="pageVariable" [showHandToolButton]="true" [showToolbar]="true"
                            [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="true"
                            [showDrawEditor]="false" [showTextEditor]="false" [showZoomButtons]="true"
                            [showPresentationModeButton]="true" [showOpenFileButton]="false" [showPrintButton]="false"
                            [showDownloadButton]="permissions.download_files" [showSecondaryToolbarButton]="true"
                            [showRotateButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
                            [showBorders]="false" [showSpreadButton]="false"
                            [showPropertiesButton]="false"></ngx-extended-pdf-viewer>


                        <!-- <ngx-doc-viewer [url]="pdfSrc" viewer="pdf" style="width:100%;height:50vh;"></ngx-doc-viewer> -->
                    </div>

                    <div class="w-full px-0 flex justify-between items-center shadow-sm mb-0"
                        *ngIf="current_tab === 'view_txt'">



                        <div class="truncate ml-4 font-semibold">
                            <i
                                class="fa-solid fa-{{current_view_obj['icon']}} mr-1 text-{{current_view_obj['iconcolor']}}-500"></i>
                            {{current_view_obj['file_name']}}

                        </div>

                        <div>

                        </div>

                        <button class="bg-none text-gray-900 px-4 cursor-hand py-3 text-sm" (click)="fileViewer()">
                            <i class="fa-regular fa-arrow-left mr-1 "></i>
                            Back to Resources
                        </button>



                    </div>

                    <div class="w-auto h-full bg-white border flex flex-col p-4 m-4" *ngIf="current_tab === 'view_txt'"
                        style="position: relative; overflow-y: scroll;">
                        <!-- <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [(page)]="pageVariable"
                        style="position: absolute; top:0; left:0; right:0; bottom: 0;"></pdf-viewer> -->
                        {{textData}}


                        <!-- <ngx-doc-viewer [url]="pdfSrc" viewer="pdf" style="width:100%;height:50vh;"></ngx-doc-viewer> -->
                    </div>

                </div>

                <div class="w-full h-full flex justify-center items-center"
                    *ngIf="!is_shared && !permissions['view_files']">

                    Resources View Disabled By the Author

                </div>




                <div class="border-t-0 border-gray-400 px-0 py-0 pb-8 bg-none mb-0 sm:mb-0 absolute bottom-0 flex justify-center items-center right-0"
                    *ngIf="!is_shared" style="padding-bottom: 6px !important; border-top:solid 0px #e4e7eb">

                    <div class="flex p-0 rounded-full border gap-x-0 mb-4 hidden"
                        *ngIf="current_tab !== 'view_txt' && current_tab !== 'view_pdf'"
                        style="border:solid 1px var(--app-theme-color) !important;" id="button-drawer">

                        <button class="bg-none text-gray-900 py-3 px-4 m-0 rounded-l-full text-sm" type="button"
                            (click)="openModal()" id="dropdownMenuButton"
                            style="border-right:solid 1px var(--app-theme-color) !important; background: #fff;"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa-regular fa-upload mr-1"></i> Upload
                            File</button>




                        <button class="bg-none text-gray-900 py-3 px-4 m-0 rounded-none text-sm" type="button"
                            (click)="openAddUrlToCollectionModal()"
                            style="border-right: solid 1px var(--app-theme-color) !important; background: #fff;"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa-light fa-browser mr-1"></i> Add Webpages</button>



                        <button class="bg-none text-gray-900 py-3 px-4 m-0 rounded-r-full text-sm" type="button"
                            (click)="openAddText()" id="dropdownMenuButton"
                            style="border: solid 0px var(--app-theme-color) !important; background: #fff;"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa-regular fa-text mr-1"></i> Add Text</button>

                        <!-- <button class="bg-none text-gray-900 py-3 px-4 m-0 rounded-r-full text-sm" type="button"
                            (click)="openAddText()" id="dropdownMenuButton"
                            style="border: solid 0px var(--app-theme-color) !important; background: #fff;"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            [hidden]="this.can_upload_new_file">
                            <i class="fa-regular fa-text mr-1"></i> Upgrade to Add Text</button> -->

                    </div>

                    <button
                        class="bg-none text-gray-900  mb-4 mx-3 w-12 h-12 rounded-full text-base flex justify-center items-center"
                        *ngIf="current_tab !== 'view_txt' && current_tab !== 'view_pdf'" type="button"
                        (click)="toggleAddMenu()" style="background: var(--app-theme-color) !important; color: #fff;"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-plus " id="button-plus"></i>
                        <i class="fa-solid fa-times hidden" id="button-minus"></i>

                    </button>

                </div>






            </div>



            <div class="right_div w-1/2 h-full max-h-max bg-white flex flex-col relative px-0" *ngIf="!is_shared">


                <div id="interact" *ngIf="mode === 'base'" class="flex flex-col w-full h-full border-b mb-1">



                    <div class="py-0 pl-4  pr-1 text-base w-full flex justify-between items-center border-b">



                        <a class="bg-white text-gray-800 rounded-none px-2 pt-3 pb-2 text-ls font-medium"
                            style="border-bottom: solid 0px var(--app-theme-color); padding-bottom: 16px !important;"
                            aria-current="page">
                            <i class="fa-solid fa-history mr-1"></i>
                            Chat History</a>


                        <button class="bg-none  py-2 px-3 my-2 mr-4 rounded text-white text-sm" type="button"
                            (click)="newChat()" id="dropdownMenuButton"
                            style="background:var(--app-theme-color) !important; margin-bottom:2px"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa-regular fa-plus mr-1"></i> New
                            Chat</button>
                        <!-- <button class="bg-none text-white py-2 px-3 my-2 mr-4 rounded text-sm" type="button"
                            (click)="redirectToPricingPage()" id="dropdownMenuButton"
                            style="background: var(--app-theme-color) !important; margin-bottom:2px"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [hidden]="this.can_create_new_chat">
                            <i class="fa-regular fa-plus mr-1"></i> Upgrade to Create</button> -->


                    </div>



                    <div class="w-full h-full overflow-y-scroll px-4 py-2 flex flex-col">


                        <!-- <div class="grid grid-cols-4 gap-x-4 gap-y-4">

                            <a *ngFor="let i of user_interactions" (click)="goToChat(i)"
                                class="p-2 py-4  rounded-xl shadow-prop flex flex-col justify-center items-center cursor-pointer"
                                style="text-align: center;border: solid 1px #f1f1f1 !important;">



                               
                                <i class="fa-light fa-messages mb-3 text-2xl" style="color: var(--app-theme-color)"></i>

                               

                                {{i['interaction_name'] | sliceWordsPipe:0:3}}
                            </a>

                        </div> -->

                        <!-- <div class="grid grid-cols-4 gap-x-4 gap-y-4"> -->


                        <a *ngFor="let i of user_interactions" (click)="goToChat(i)"
                            class="px-2 py-3 w-full flex justify-between items-center  border-b cursor-pointer"
                            style="text-align: center;">



                            <div>

                                <!-- <img src="assets/intr2.svg" class="w-8 h-8 rounded-full mb-3"> -->
                                <i class="fa-duotone fa-messages text-xl mr-2" style="color: #d4c6b5"></i>

                                <!-- <i class="fa-regular fa-messages"></i> -->

                                {{i['interaction_name'] | sliceWordsPipe:0:5}}

                            </div>

                            <a class="text-sm text-gray-500 hover:text-red-500"
                                (click)="deleteInteractionById(i['interaction_uuid'], $event)">
                                <i class="fa-regular fa-times mr-2"></i>
                            </a>

                        </a>

                        <!-- </div> -->

                        <!-- Delete link with icon -->






                    </div>



                </div>

                <div id="interact" *ngIf="mode === 'interact'" class="flex flex-col h-full">

                    <div class="w-full px-0  bg-white border-b flex gap-x-4 justify-between"
                        style="padding-top: 0px !important;">



                        <div class=" py-1 pl-3 pr-1 text-base w-full flex justify-between items-center">

                            <div class="hidden sm:block" *ngIf="current_message_tab !== 'sources'">
                                <nav class="flex space-x-4 py-1" aria-label="Tabs">
                                    <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

                                    <!-- <a href="#" class="bg-white text-gray-800 rounded-none px-0 py-0 text-lg font-medium"
                                    style="border-bottom: solid 0px var(--app-theme-color);" aria-current="page">
                                    <i class="fa-regular fa-message mr-1"></i>
                                    {{current_interaction['interaction_name']}}
                                </a> -->
                                    <div contenteditable="true" style="padding-top: 9px;
                                    padding-bottom: 9px;" class="pr-4"
                                        [textContent]="this.current_interaction['interaction_name']"
                                        (input)="this.current_interaction['interaction_name'] = $event.target.textContent"
                                        (keyup)="currentInteractionNameEdited()">
                                    </div>
                                    <!-- <a href="#"
                                    class="text-gray-800 hover:text-gray-700 rounded-md px-3 py-3 text-sm font-medium">
                                    <i class="fa-regular fa-file mr-1"></i>
                                    Document
                                    Mode</a> -->
                                </nav>
                            </div>

                            <div class="hidden sm:block" *ngIf="current_message_tab === 'sources'">
                                <nav class="flex font-semibold space-x-4 py-2" aria-label="Tabs">
                                    <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

                                    Sources
                                </nav>
                            </div>


                            <div class="hidden sm:block" *ngIf="current_message_tab === 'sources'">
                                <nav class="flex space-x-4" aria-label="Tabs">
                                    <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

                                    <a (click)="closeSources()" style="color:#045fb4"
                                        class="text-sm p-2 w-8 h-8 mr-8 flex justify-center items-center cursor-pointer">
                                        <i class="fa-regular fa-times text-lg mr-1"></i>Close
                                    </a>
                                </nav>
                            </div>






                            <div class="flex justify-center items-center" *ngIf="current_message_tab !== 'sources'">


                                <a (click)="backtoChats()"
                                    class="bg-indigo-100 text-gray-800 rounded-md  text-sm font-medium cursor-pointer"
                                    style="background: #fff; border:solid 0px var(--app-theme-color)"
                                    aria-current="page">
                                    <i class="fa-regular fa-arrow-left mr-1"></i>
                                    Back to chats</a>


                                <a (click)="deleteInteractionModalOpen()" class="py-2 ml-4 text-sm">
                                    <i class="fa-regular fa-trash mr-4 text-red-500"></i>
                                </a>




                                <!-- <div class="dropdown p-0 m-0 ml-4" *ngIf="!is_shared">
                                    <button
                                        class="btn btn-secondary dropdown-toggle p-0 bg-none text-gray-700 text-base"
                                        type="button" id="dropdownMenuButton"
                                        style="background: inherit !important;padding:0 !important"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa-light fa-gear ml-2"></i> </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                        <a class="dropdown-item" href="#">Save Chats</a>
                                        
                                    </div>
                                </div> -->







                            </div>


                        </div>






                    </div>
                    <!-- component -->
                    <div class=" overflow-y-scroll" style="bottom:100px" id="message_window">

                        <div *ngIf="current_message_tab === 'sources'">

                            <!-- <div class="flex justify-end items-end px-4">
                                <a (click)="closeSources()" style="color:#045fb4"
                                    class="text-sm p-2 w-8 h-8 rounded-full flex justify-center items-center border">
                                    <i class="fa-regular fa-times text-lg"></i>
                                </a>

                            </div> -->

                            <div *ngFor="let source of current_sources" class="p-8 border-b">

                                <div class="flex flex-col">

                                    <div class="flex justify-between items-center">
                                        <div class="text-base font-semibold">File: {{source.fileobj['file_name']}}</div>

                                        <a *ngIf="source.fileobj.is_external===false" class="cursor-pointer"
                                            (click)="goToSource(source.page, source.fileobj, source.source_text)"
                                            style="color:#045fb4">
                                            <i class="fa-regular fa-arrow-left mr-1"></i>
                                            See in file</a>
                                        <a *ngIf="source.fileobj.is_external===true" class="cursor-pointer"
                                            (click)="goToSource(source.page, source.fileobj, source.source_text)"
                                            style="color:#045fb4">
                                            <i class="fa-regular fa-arrow-left mr-1"></i>
                                            Navigate to source</a>
                                    </div>


                                    <div class="text-sm p-3 bg-gray-50 mt-2 rounded" [innerHTML]="source.source_text">
                                    </div>



                                </div>

                            </div>

                            <div class="h-40 w-full">

                            </div>

                        </div>





                        <div class="flex flex-col space-y-2 mx-3 my-1 overflow-y-scroll" id="messages"
                            *ngIf="current_message_tab === 'chat'">

                            <div *ngFor="let message of chat_messages" class="p-0 border-0 flex flex-col mt-3">

                                <div class="p-4 rounded bg-gray-100 border-0" *ngIf="message.actor === 'user'">
                                    <div class="flex justify-start items-start">

                                        <img src="assets/user.svg" class="w-8 h-8  mr-4" />

                                        <!-- <div>

                                            {{message.text}}

                                        </div> -->
                                        <div class="custom-link"
                                            [innerHTML]="filterAllTagsOtherThanLinks(message.text)">
                                        </div>

                                    </div>
                                </div>

                                <div class="p-4 rounded border flex flex-col" *ngIf="message.actor === 'bot'">

                                    <div class="flex justify-start items-start">

                                        <img src="assets/bot-1.svg" class="w-8 h-8  mr-4" />

                                        <div class="flex flex-col">

                                            <div class="custom-link"
                                                [innerHTML]="filterAllTagsOtherThanLinks(message.text)"></div>

                                            <a (click)="sources(message.sources)" *ngIf="message.actor === 'bot'"
                                                style="color:#045fb4"
                                                class="cursor-pointer text-blue-500 mt-2 text-xs">sources
                                                ({{message.sources.length}})</a>

                                        </div>

                                    </div>

                                </div>





                            </div>
                            <div class="w-full p-8 mx-auto flex justify-center items-center" *ngIf="typing">

                                <img src="assets/progress.gif" class="w-12 h-12 mx-auto" />
                            </div>


                            <div class="w-full h-40" id="">

                            </div>
                        </div>

                        <div class="w-full h-28" id="end_of_chat">

                        </div>







                    </div>

                    <div class="border-t-2 border-gray-200 px-4 pt-3 pb-3 bg-white mb-0 sm:mb-0 absolute bottom-0 w-full right-0"
                        *ngIf="!is_shared">
                        <div class="relative flex">

                            <input type="text" placeholder="Ask your question ..." [(ngModel)]="current_input_message"
                                (keyup.enter)="getAnswer()"
                                class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 bg-white border border-gray-900 rounded-md py-3 mr-1">
                            <div class=" right-0 items-center inset-y-0 hidden sm:flex">

                                <button type="button" (click)="getAnswer()"
                                    *ngIf="!limits?.is_own_key || (limits?.is_own_key && limits?.is_key_generated)"
                                    class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                                    style="background: var(--app-theme-color);">
                                    <span class="font-normal">Send</span>
                                    <i class="fa-regular fa-paper-plane ml-2"></i>
                                </button>

                                <button type="button" *ngIf="(limits?.is_own_key && !limits?.is_key_generated)"
                                    class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                                    style="background: var(--app-theme-color-light); color:#a4a4a4">
                                    <span class="font-normal">Send</span>
                                    <i class="fa-regular fa-paper-plane ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <style>
                        .scrollbar-w-2::-webkit-scrollbar {
                            width: 0.25rem;
                            height: 0.25rem;
                        }

                        .scrollbar-track-blue-lighter::-webkit-scrollbar-track {
                            --bg-opacity: 1;
                            background-color: #f7fafc;
                            background-color: rgba(247, 250, 252, var(--bg-opacity));
                        }

                        .scrollbar-thumb-blue::-webkit-scrollbar-thumb {
                            --bg-opacity: 1;
                            background-color: #edf2f7;
                            background-color: rgba(237, 242, 247, var(--bg-opacity));
                        }

                        .scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
                            border-radius: 0.25rem;
                        }
                    </style>

                    <script>
                        const el = document.getElementById('messages')
                        el.scrollTop = el.scrollHeight
                    </script>

                </div>





            </div>

            <div class="right_div w-1/2 h-full  flex flex-col" *ngIf="is_shared">



                <div class="w-full px-0 pt-0 bg-white border-b  gap-x-4 justify-between" style="padding-top: 2px !important;
                ">

                    <div class="py-0 pl-4 pr-1 text-base w-full flex justify-between items-center">

                        <div class="" *ngIf="shared_mode_tab!=='saved_chat_view'">
                            <nav class="flex space-x-4" aria-label="Tabs">
                                <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

                                <a (click)="setSharedModeTab('interact')"
                                    class="bg-white text-gray-800 rounded-none px-2 py-3 text-ls font-medium"
                                    [ngClass]="{ 'subtab': shared_mode_tab === 'interact' }" aria-current="page">
                                    <i class="fa-regular fa-message mr-1"></i>
                                    Chat</a>

                                <a (click)="setSharedModeTab('saved_chats')" *ngIf="permissions.view_chats"
                                    class="bg-white text-gray-800 rounded-none px-2 py-3 text-ls font-medium"
                                    [ngClass]="{ 'subtab': shared_mode_tab === 'saved_chats' }" aria-current="page">
                                    <i class="fa-regular fa-message-check mr-1"></i>
                                    Saved Chats</a>

                            </nav>
                        </div>


                        <div class="" *ngIf="shared_mode_tab==='saved_chat_view'" class="py-3">
                            <nav class="flex space-x-4" aria-label="Tabs">
                                <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

                                <a href="#" class="bg-white text-gray-800 rounded-none px-0 py-0 text-lg font-medium"
                                    style="border-bottom: solid 0px var(--app-theme-color);" aria-current="page">
                                    <i class="fa-regular fa-message mr-1"></i>
                                    {{current_interaction['interaction_name']}}
                                </a>
                                <!-- <a href="#"
                                    class="text-gray-800 hover:text-gray-700 rounded-md px-3 py-3 text-sm font-medium">
                                    <i class="fa-regular fa-file mr-1"></i>
                                    Document
                                    Mode</a> -->
                            </nav>
                        </div>

                        <button class="bg-none text-gray-900 px-4 cursor-hand py-3 text-sm"
                            *ngIf="shared_mode_tab==='saved_chat_view'" (click)="shared_mode_tab = 'saved_chats'">
                            <i class="fa-regular fa-arrow-left mr-1 "></i>
                            Back
                        </button>






                    </div>

                </div>



                <div #chatScroll id="interact" class="flex flex-col flex-1 flex-grow overflow-y-scroll"
                    *ngIf="shared_mode_tab === 'interact'">






                    <div *ngIf="current_message_tab === 'sources'">

                        <div class="flex justify-end items-end px-4">
                            <a (click)="closeSources()" style="color:#045fb4"
                                class="text-sm p-2 w-8 h-8 rounded-full flex justify-center items-center border">
                                <i class="fa-regular fa-times text-lg"></i>
                            </a>

                        </div>

                        <div *ngFor="let source of current_sources" class="p-8 border-b">

                            <div class="flex flex-col">

                                <div class="flex justify-between items-center">
                                    <div class="text-base font-bold">File: {{source.fileobj['file_name']}}</div>

                                    <a (click)="goToSource(source.page, source.fileobj, source.source_text)"
                                        style="color:#045fb4">
                                        <i class="fa-regular fa-arrow-left mr-1"></i>
                                        See in file</a>
                                </div>


                                <div class="text-sm p-3 bg-gray-50 mt-2 rounded" [innerHTML]="source.source_text">
                                </div>



                            </div>

                        </div>

                        <div class="h-40 w-full">

                        </div>

                    </div>





                    <div class="flex flex-col space-y-2 mx-3 my-1 " id="messages" style="bottom:100px" #chatScroll
                        *ngIf="current_message_tab === 'chat'" id="message_window">

                        <div *ngFor="let message of chat_messages" class="p-0 border-0 flex flex-col mt-3">

                            <div class="p-4 rounded  border-0" *ngIf="message.actor === 'user'">
                                <div class="flex justify-start items-start">

                                    <img src="assets/user.svg" class="w-8 h-8  mr-4" />

                                    <div>

                                        {{message.text}}

                                    </div>
                                    <!-- <div class="custom-link" [innerHTML]="filterAllTagsOtherThanLinks(message.text)"></div> -->

                                </div>
                            </div>

                            <div class="p-4 rounded border flex flex-col" *ngIf="message.actor === 'bot'">

                                <div class="flex justify-start items-start">

                                    <img src="assets/bot-1.svg" class="w-8 h-8  mr-4" />

                                    <div class="flex flex-col">

                                        <div class="custom-link"
                                            [innerHTML]="filterAllTagsOtherThanLinks(message.text)">
                                        </div>

                                        <a (click)="sources(message.sources)" *ngIf="message.actor === 'bot'"
                                            style="color:#045fb4"
                                            class="cursor-pointer text-blue-500 mt-2 text-xs">sources
                                            ({{message.sources.length}})</a>

                                    </div>

                                </div>

                            </div>


                            <!-- <div class="w-full h-28" id="end_of_chat2">

                            </div>
                            <div class="w-full h-40" id="">

                            </div> -->





                        </div>

                        <div class="w-full p-8 mx-auto flex justify-center items-center" *ngIf="typing">

                            <img src="assets/progress.gif" class="w-12 h-12 mx-auto" />
                        </div>

                        <div id="end_of_chat">

                        </div>


                    </div>




                </div>

                <div class="border-t-2 border-gray-200 px-4 pt-3 pb-3 bg-none mb-0"
                    *ngIf="shared_mode_tab === 'interact'">
                    <div class="relative flex">

                        <input type="text" placeholder="Write your message!" [(ngModel)]="current_input_message"
                            (keyup.enter)="getAnswer()"
                            class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 bg-white border border-gray-900 rounded-md py-3 mr-1">
                        <div class=" right-0 items-center inset-y-0 hidden sm:flex">

                            <button type="button" (click)="getAnswer()"
                                class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                                style="background: var(--app-theme-color);">
                                <span class="font-normal">Send</span>
                                <i class="fa-regular fa-paper-plane ml-2"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <style>
                    .scrollbar-w-2::-webkit-scrollbar {
                        width: 0.25rem;
                        height: 0.25rem;
                    }

                    .scrollbar-track-blue-lighter::-webkit-scrollbar-track {
                        --bg-opacity: 1;
                        background-color: #f7fafc;
                        background-color: rgba(247, 250, 252, var(--bg-opacity));
                    }

                    .scrollbar-thumb-blue::-webkit-scrollbar-thumb {
                        --bg-opacity: 1;
                        background-color: #edf2f7;
                        background-color: rgba(237, 242, 247, var(--bg-opacity));
                    }

                    .scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
                        border-radius: 0.25rem;
                    }
                </style>

                <div class="flex flex-col" *ngIf="shared_mode_tab === 'saved_chats' && permissions.view_chats">

                    <div class="w-full h-full overflow-y-scroll p-4">


                        <div class="grid grid-cols-4 gap-x-4 gap-y-4">

                            <a *ngFor="let i of user_interactions" (click)="goToSavedChat(i)"
                                class="p-2 py-4 border rounded flex flex-col justify-center items-center cursor-pointer">



                                <!-- <img src="assets/chat.svg" class="w-8 h-8 rounded-full mb-3"> -->
                                <i class="fa-light fa-messages mb-3 text-xl" style="color: var(--app-theme-color);"></i>

                                {{i['interaction_name']}}
                            </a>

                        </div>




                    </div>

                </div>

                <div class="flex flex-col w-full h-full overflow-y-scroll p-4"
                    *ngIf="shared_mode_tab === 'saved_chat_view'">



                    <div *ngFor="let message of chat_messages" class="p-0 border-0 flex flex-col mt-3">

                        <div class="p-4 rounded bg-gray-100 border-0" *ngIf="message.actor === 'user'">
                            <div class="flex justify-start items-start">

                                <img src="assets/user.svg" class="w-8 h-8  mr-4" />

                                <div>

                                    {{message.text}}

                                </div>
                                <!-- <div class="custom-link" [innerHTML]="filterAllTagsOtherThanLinks(message.text)"></div> -->

                            </div>
                        </div>

                        <div class="p-4 rounded border flex flex-col" *ngIf="message.actor === 'bot'">

                            <div class="flex justify-start items-start">

                                <img src="assets/bot-1.svg" class="w-8 h-8  mr-4" />

                                <div class="flex flex-col">

                                    <div class="custom-link" [innerHTML]="filterAllTagsOtherThanLinks(message.text)">
                                    </div>


                                </div>

                            </div>

                        </div>





                    </div>


                    <div class="w-full h-28" id="end_of_chat">

                    </div>
                    <div class="w-full h-40" id="">

                    </div>



                </div>







            </div>

        </div>

    </div>



    <div class="flex flex-col w-full h-screen bg-white justify-center items-center text-gray-800"
        *ngIf="no_access && !loading_project">

        <!-- logg {{user_logged_in.toString()}} -->

        <!-- <img src="assets/404.gif" class="w-64 mb-10" /> -->
        <!-- hello word-wrap -->


        <div [hidden]="user_logged_in === true">

            <img src="assets/404.gif" class="w-64 mb-10" />


            <a href="" class="text-blue-500">Login</a> or <a href="" class="text-blue-500">Signup</a> to access this
            project


        </div>


        <div [hidden]="user_logged_in === false" class="w-full flex flex-col justify-center items-center">

            <img src="assets/404.gif" class="w-64 mb-10" />


            Project not found. You may not have access to this project or the project may have been deleted.

        </div>






    </div>

    <div *ngIf="loading_project" class="w-full h-screen bg-white flex justify-center items-center">

        <img src="assets/progress.gif" class="w-12 h-12 mx-auto" />

    </div>

    <div class="modal {{modal_class_nomore_files}} rounded-sm" style=" z-index: 999999;">
        <div class="modal-box w-6/12 max-w-5xl p-0 ng-white flex flex-col justify-center items-center"
            style="background:#fff !important">

            <div class="p-4 mt-4 flex justify-center items-center">

                <img src="assets/smiley-sad.svg" class="w-20" />

            </div>
            <div class="p-10" style="text-align: center;">

                Oops. Limit reached. You can upgrade your plan to add more files, urls or text blocks. Please check the
                <a href="/main" class="text-blue-500">dashboard</a> for your current plan limits.

            </div>

            <div class="p-4 mb-4 flex justify-center items-center gap-x-4">
                <button class="text-base text-white px-4 py-2 rounded" style="background: var(--app-theme-color);"
                    (click)="redirectToPricingPage()">Upgrade</button>
                <button class="text-base text-white px-4 py-2 rounded" style="background: var(--app-theme-color);"
                    (click)="modal_class_nomore_files = 'modal-close'">Close</button>
            </div>

        </div>

    </div>


    <div class="modal {{modal_class_declaration}} rounded-sm" style=" z-index: 999999;" *ngIf="upload">
        <div class="modal-box w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important">
            <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
                <div class="flex p-0 justify-start items-center">

                    Upload File

                </div>
                <div class="flex p-0 justify-end items-center" style="cursor: pointer;" (click)="closeModalEve($event)">
                    X
                </div>


            </div>

            <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">

                <!-- <div class=" w-full block text-gray-700 font-normal text-base mb-2 p-4 bg-gray-100 rounded" for="name">
                Please upload your file.
            </div> -->


                <!-- <input type="file" (change)="handleFileInput($event)"> -->


                <div class="flex items-center justify-center w-full" *ngIf="pdfUrl ==='' || pdfUrl === null">
                    <label for="dropzone-file"
                        class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <!-- <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="!fileToUpload">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none"
                                stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                </path>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                    to
                                    upload</span></p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">PDF or Text files</p>

                            <input id="dropzone-file" type="file" class="hidden" (change)="handleFileInput($event)" />
                        </div> -->

                        <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="!fileToUpload"
                            (dragover)="handleDragOver($event)" (dragenter)="handleDragEnter($event)"
                            (dragleave)="handleDragLeave($event)" (drop)="handleDrop($event)">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none"
                                stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                </path>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                    to upload</span></p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">PDF or Text files</p>

                            <input id="dropzone-file" type="file" class="hidden" (change)="handleFileInput($event)" />
                        </div>


                        <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="fileToUpload">
                            <div class="w-full flex flex-col justify-center items-center">

                                <img src="assets/pdf-file-3.svg" class="w-12 h-12 mb-3"
                                    *ngIf="uploading_file_extension ==='pdf'" />
                                <img src="assets/txt.svg" class="w-12 h-12 mb-3"
                                    *ngIf="uploading_file_extension === 'txt'" />

                                <span class="font-semibold text-base mt-3">{{fileToUpload.name}}</span>

                                <span class="text-xs text-gray-500 dark:text-gray-400 mt-1">{{fileToUpload.size}}
                                    bytes</span>


                                <!-- remove -->
                                <div class="flex justify-center items-center mt-3">
                                    <button class="text-blue-500 font-normal text-sm p-0 rounded " type="button"
                                        *ngIf="!upload_index_progress" (click)="removeUpload($event)">Remove</button>
                                </div>

                                <div class="p-2 flex justify-center items-center text-sm text-red-500"
                                    [hidden]="limits.max_file_size_mb > fileToUpload.size/1024/1024">
                                    Max file size limit for your plan is {{limits.max_file_size_mb}} MB.
                                    Please upgrade your plan to upload larger files.
                                </div>

                                <button
                                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded "
                                    *ngIf="!upload_index_progress"
                                    style="background: var(--app-theme-color); color:#fff !important;" type="button"
                                    (click)="uploadPdf()"
                                    [hidden]="limits.max_file_size_mb <= fileToUpload.size/1024/1024">
                                    <i class="fa fa-upload mr-1"></i>
                                    Upload</button>
                                <button
                                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded "
                                    *ngIf="!upload_index_progress"
                                    style="background: var(--app-theme-color); color:#fff !important;" type="button"
                                    (click)="redirectToPricingPage()"
                                    [hidden]="limits.max_file_size_mb > fileToUpload.size/1024/1024">
                                    <i class="fa fa-rocket mr-1"></i>
                                    Upgrade</button>

                                <button
                                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded flex"
                                    *ngIf="upload_index_progress"
                                    style="background: var(--app-theme-color-light); color:#000 !important;"
                                    type="button">
                                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                        </path>
                                    </svg>
                                    {{upload_progress_text}}</button>
                            </div>
                        </div>

                    </label>
                </div>

                <br>
                <div class="flex items justify-center w-full" style="text-align: center;"
                    *ngIf="!fileToUpload && (pdfUrl ==='' || pdfUrl === null)">
                    OR
                </div>
                <br>
                <label class="block text-gray-700 font-semibold text-base mb-2" for="name" *ngIf="!fileToUpload">
                    Add file Url
                </label>

                <input [(ngModel)]="pdfUrl"
                    class="w-full border text-sm font-normal border-gray-400 p-3 mb-4 mt-1 rounded-lg"
                    *ngIf="!fileToUpload" type="text" name="name" id="name"
                    placeholder="https://example.com/example.pdf" required>

                <button
                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 mx-0 text-sm py-2 px-4 mb-4 rounded "
                    *ngIf="!upload_index_progress && !fileToUpload && pdfUrl !=='' && pdfUrl !== null"
                    style="background: var(--app-theme-color); color:#fff !important;" type="button"
                    (click)="uploadPdf()">Add File</button>

                <button
                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded flex"
                    *ngIf="upload_index_progress && !fileToUpload"
                    style="background: var(--app-theme-color-light); color:#000 !important;" type="button">
                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                    {{upload_progress_text}}</button>



            </div>



        </div>

    </div>

    <div class="modal {{modal_class_add_url}} " style="z-index: 999999;">
        <div class="w-3/4 h-full m-4 p-0 ng-white overflow-y-scroll" style="background:#fff !important">
            <div class="w-full py-2 px-4 bg-yellow-100" *ngIf="crawl_status === 'Indexing'">
                <i class="fa-light fa-spinner fa-spin mr-2"></i>
                Adding webpage. Please do not close this window.
            </div>

            <div class="w-full py-2 px-4 bg-green-100 flex justify-between items-center"
                *ngIf="crawl_status === 'Indexed'">
                <div>
                    <i class="fa-light fa-circle-check  mr-2 text-green-500"></i>
                    Successfully added {{success_url_count}} webpages.
                </div>

                <div class="flex gap-x-2">

                    <button class="text-sm text-white bg-green-700 p-2"
                        (click)="modal_class_add_url = 'modal-close'; resetAddUrlPopUp(); this.show_abort_banner=false">Close</button>
                    <button class="text-sm text-gray-800 bg-green-200 p-2" (click)="startOver()">Start over</button>

                </div>



            </div>
            <div
                class="py-2 px-8 border-b text-gray-800 font-normal text-xl flex justify-between items-center sticky top-0 bg-white">
                <div class="flex p-0 justify-start items-center" *ngIf="crawl_status === 'Not Started'">

                    <i class="fa-light fa-globe mr-2 text-blue-600"></i>
                    Add Webpages

                </div>

                <div class="flex p-0 justify-start items-center truncate text-base font-semibold mr-3"
                    contenteditable="true"
                    *ngIf="crawl_status === 'Scraped' || crawl_status === 'Indexing' || crawl_status === 'Indexed'">

                    {{add_url_current}}

                </div>

                <div class="flex gap-x-4">

                    <button class="bg-red-50 rounded text-gray-800 px-3 py-2 border-0 text-sm font-normal"
                        *ngIf="crawl_status === 'Scraped'" (click)="startOver()">
                        Cancel
                    </button>

                    <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                        *ngIf="crawl_status !== 'Indexing' && crawl_status !== 'Scraping'"
                        (click)="modal_class_add_url = 'modal-close'; resetAddUrlPopUp(); this.show_abort_banner=false"
                        style="
                        background: #fff !important;
                    ">
                        <i class="fa-regular fa-times"></i>
                    </button>

                </div>


            </div>

            <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start"
                *ngIf="!upload_index_progress && crawl_status !== 'Scraped' && crawl_status !== 'Indexed' && crawl_status !== 'Indexing'">

                <div class="w-full flex justify-between items-center mb-2 bg-yellow-50" *ngIf="show_abort_banner">
                    <div class="px-4 py-2 text-sm text-gray-700">
                        Please Use 'upload file' option to upload text and pdf urls.
                    </div>
                    <button class="m-0 btn bg-yellow-50 text-gray-800 px-2 border-0"
                        style="background: rgb(254, 252, 232) !important;" (click)="this.show_abort_banner=false">
                        <i class="fa-regular fa-times"></i>
                    </button>
                </div>
                <div class="w-full flex justify-start items-center" *ngIf="crawl_status === 'Not Started'">
                    <input [(ngModel)]="add_url_current"
                        class="w-full border-b border-dashed  focus:outline-none text-sm font-normal border-gray-400 py-3 px-0 rounded-none"
                        type="text" name="name" id="starting_url"
                        placeholder="Starting webpage. Example: https://example.com/docs" required>


                    <button
                        class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 text-sm py-2 px-4  rounded w-40 "
                        style="background: var(--app-theme-color); color:#fff !important;" type="button"
                        (click)="getSubUrls(); ">Fetch Links</button>


                </div>

                <div class="w-full flex justify-start items-center" *ngIf="crawl_status === 'Scraping'">
                    <i class="fa-light fa-spinner fa-spin mr-2"></i>
                    Fetching links... Please do not close this window.
                </div>



            </div>

            <div *ngIf="!upload_index_progress && crawl_status !== 'Scraped' && crawl_status !== 'Indexed' && crawl_status !== 'Indexing'"
                class="px-4 py-2 text-sm text-gray-500">
                This will fetch all the links found on the webpage given above. Any external links not belonging to the
                same domain will be ignored. Files will also be ignored.
            </div>

            <div class="px-6">

                <div class="w-full flex justify-between items-center gap-x-4 px-2 py-4 sticky top-16 bg-white"
                    *ngIf="crawl_status === 'Scraped'">
                    <div class="text-lg font-semibold">
                        Selected {{selected_url_count}} of {{sub_urls.length}} urls
                    </div>

                    <div class="flex justify-end items-center gap-x-4">

                        <div class="flex gap-x-2" *ngIf="crawl_status === 'Scraped'">
                            <a (click)="selectAllSubUrls()" class="text-sm text-blue-500 mx-1 cursor-pointer">Select
                                All</a>
                            <i class="fa-light fa-ellipsis-vertical text-gray-300"></i>
                            <a (click)="unselectAllSubUrls()" class="text-sm text-blue-500 mx-1 cursor-pointer">Unselect
                                All</a>
                        </div>

                        <button *ngIf="show_add_url_loop"
                            class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 text-sm py-2 px-4 rounded"
                            style="background: var(--app-theme-color); color:#fff !important;" type="button"
                            (click)="addUrlLoop(); ">+
                            Add Selected Urls</button>

                    </div>


                </div>


                <div class="flex flex-col justify-start items-start w-full gap-y-1 py-4"
                    *ngIf="crawl_status === 'Indexing'">
                    Added {{success_url_count}} of {{selected_url_count}} pages
                    <progress class="progress progress-error w-full " value="{{success_url_count}}" style="height: 4px;"
                        max="{{selected_url_count}}"></progress>

                </div>



                <table class="table-auto w-full">
                    <tr *ngFor="let url of sub_urls" class="border-b w-full">
                        <td class="p-2" *ngIf="crawl_status !== 'Indexing' && crawl_status !=='Indexed'">
                            <input type="checkbox" [(ngModel)]="url.checked" (change)="updateSelectedUrlCount()"
                                class="p-3">
                        </td>
                        <td class="p-2">
                            <i class="fa-light fa-circle-check text-green-500" *ngIf="url.status === 'success'"></i>
                            <i class="fa-light fa-times text-red-500" *ngIf="url.status === 'failed'"></i>
                            <i class="fa-light fa-spinner fa-spin text-blue-500" *ngIf="url.status === 'pending'"></i>
                            <span class="text-sm text-gray-500" *ngIf="!url.checked">Excluded</span>
                        </td>
                        <td class="p-2 text-base" style="font-family: proxima-nova;">
                            <a href="{{url.url}}" class="text-blue-500" target="_blank">{{url.url | urlTrunc}}</a>
                        </td>

                        <td class="p-2">

                        </td>

                    </tr>
                </table>

                <div class="w-full h-40">

                </div>

            </div>




            <div *ngIf="upload_index_progress" class="flex justify-center items-center w-full h-60">

                <!-- <img src="assets/progress.gif" alt="loader" class="mx-auto w-24"> -->

                <div class="flex">

                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>

                    <div>
                        Adding Url. Please wait...
                    </div>

                </div>



            </div>

        </div>

    </div>


    <div class="modal {{modal_class_add_text}} " style="z-index: 999999;">
        <div class="modal-box w-6/12 max-w-5xl p-0 ng-white h-3/4" style="background:#fff !important; ">
            <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
                <div class="flex p-0 justify-start items-center">

                    Add Text

                </div>

                <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                    (click)="modal_class_add_text = 'modal-close'; resetAddText()" style="
            background: #fff !important;
        ">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>

            <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start " *ngIf="!upload_index_progress"
                style="height: 85%;">

                <label class="block text-gray-700 font-semibold text-base mb-2" for="name">
                    Title
                </label>
                <input [(ngModel)]="add_text_name"
                    class="w-full border text-sm font-normal border-gray-400 p-3 mb-4 rounded-lg" type="text"
                    name="name" id="name" placeholder="ML Handbook" required>
                <label class="block text-gray-700 font-semibold text-base mb-2" for="name">
                    Content
                </label>
                <textarea [(ngModel)]="add_text_current"
                    class="w-full h-full border text-sm font-normal border-gray-400 p-3 mb-4 rounded-lg" type="text"
                    name="name" style="resize: none;" id="name" placeholder="Add your text here..." required></textarea>

                <!-- <select [(ngModel)]="current_collection_for_add_url" class="select select-bordered w-full max-w-xs py-4">

                <option *ngFor="let coll of user_collections; let i = index" value="{{coll.coll_uuid}}">
                    {{coll.collection_name}}{{coll.coll_uuid}}</option>

            </select> -->



                <button
                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 text-sm py-2 px-4 mb-4 rounded "
                    style="background: var(--app-theme-color); color:#fff !important;" type="button"
                    (click)="addText();">+
                    Add Text</button>

            </div>
            <div *ngIf="upload_index_progress" class="flex justify-center items-center w-full h-60">

                <!-- <img src="assets/progress.gif" alt="loader" class="mx-auto w-24"> -->

                <div class="flex">

                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>

                    <div>
                        Adding Text. Please wait...
                    </div>

                </div>



            </div>

        </div>

    </div>

    <div class="modal {{modal_class_delete_interaction}} " style="opacity:100% !important; z-index: 999999;">
        <div class="modal-box w-6/12 max-w-5xl p-0 bg-white  rounded"
            style="background:#fff !important; opacity:100% !important; width:550px">
            <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
                <div class="flex p-0 justify-start items-center">
                    Confirm
                </div>
                <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                    (click)="modal_class_delete_interaction = 'modal-close'" style="background: #fff !important;">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>

            <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start">

                <div class="flex justify-between w-full px-2 py-2 mb-2">
                    <div class="p-2 w-full bg-white flex justify-start items-center">
                        Are you sure you want to delete this chat?
                    </div>

                </div>
                <div class="flex justify-center items-center w-full px-2 py-2 mb-2">
                    <button class=" text-white font-repl mr-0 ml-2 py-2 px-4 rounded "
                        style="background: var(--app-theme-color);" type="button"
                        (click)="deleteInteraction()">confirm</button>
                </div>

            </div>

        </div>

    </div>

    <div class="modal {{modal_class_delete_project}} " style="opacity:100% !important; z-index: 999999;">
        <div class="modal-box w-6/12 max-w-5xl p-0 bg-white  rounded"
            style="background:#fff !important; opacity:100% !important; width:550px">
            <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
                <div class="flex p-0 justify-start items-center">
                    Are you Sure ?
                </div>
                <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                    (click)="modal_class_delete_project = 'modal-close'" style="background: #fff !important;">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>

            <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start">

                <div class="flex justify-between w-full px-2 py-2 mb-2">
                    <div class="p-2 w-full bg-white flex justify-start items-center">
                        Deleting the Project, deletes all the resources associated with it.
                    </div>

                </div>
                <div class="flex justify-center items-center w-full px-2 py-2 mb-2">
                    <button class=" text-white font-repl mr-0 ml-2 py-2 px-4 rounded "
                        style="background: var(--app-theme-color);" type="button"
                        (click)="confirmDeleteProject()">confirm</button>
                </div>

            </div>

        </div>

    </div>


    <div class="modal {{modal_class_share_project}} rounded-sm" style="z-index: 999999;">
        <div class="modal-box w-6/12 max-w-5xl p-0 ng-white h-3/4" style="background:#fff !important; ">
            <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
                <div class="flex p-0 justify-start items-center">

                    Share

                </div>

                <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                    (click)="modal_class_share_project = 'modal-close'; resetAddText()" style="
            background: #fff !important;
        ">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>

            <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start " *ngIf="!upload_index_progress"
                style="height: 85%;">

                <div class="flex mt-3 mb-6">
                    <input type="checkbox" class="toggle toggle-success" [(ngModel)]="permissions.enable_sharing"
                        (change)="onSharingToggle($event)" />
                    &nbsp;&nbsp;Enable Sharing
                </div>


                Shareable Link:<br>

                <div class="py-3 flex justify-center items-center w-full" *ngIf="permissions.enable_sharing">
                    <div class="w-full p-4 border rounded text-sm mr-2">
                        <input #shareLinkInput type="text" class="share-link-input" readonly
                            class="w-full focus:outline-none"
                            [value]="'https://knibble.ai/project/shared/' + (share_info?.share_code || '')">
                    </div>
                    <a class="ml-4 mr-2" (click)="copyTexttoClipboard()">
                        <i class="fa-regular fa-copy text-xl text-blue-700" *ngIf="!copyied_text"
                            style="color:var(--app-theme-color)"></i>
                        <div class="flex flex-col justify-center items-center text-xs" *ngIf="copyied_text">

                            <i class="fa-regular fa-circle-check text-xl text-green-700"></i>
                            Copied

                        </div>

                    </a>
                </div>



                <div class="flex flex-col" *ngIf="permissions.enable_sharing">


                    <div class="font-semibold py-3">
                        Permissions
                    </div>

                    <div class=" pb-3 pt-1 text-sm">
                        Anyone with the link:
                    </div>

                    <div class="flex my-3">
                        <input type="checkbox" class="toggle toggle-success" [(ngModel)]="permissions.view_files"
                            (change)="onPermissionChange($event)" />
                        &nbsp;&nbsp;Can view files
                    </div>

                    <div class="flex my-3">
                        <input type="checkbox" class="toggle toggle-success" [(ngModel)]="permissions.download_files"
                            (change)="onPermissionChange($event)" />
                        &nbsp;&nbsp;Can download files
                    </div>

                    <div class="flex my-3">
                        <input type="checkbox" class="toggle toggle-success" [(ngModel)]="permissions.view_chats"
                            (change)="onPermissionChange($event)" />
                        &nbsp;&nbsp;Can view existing chats
                    </div>


                </div>







            </div>


        </div>

    </div>

    <div class="modal {{modal_class_embed}} rounded-sm" style="z-index: 999999;top:0;" *ngIf="!is_shared">
        <div class="modal-box w-full h-screen max-w-5xl p-0 bg-white rounded-sm"
            style="background:#fff !important; position:absolute;top:10px; bottom:10px; max-width: 1600px;">



            <div>
                <div class="py-0 pl-4  pr-1 text-base w-full flex justify-between items-center border-b">


                    <div class="flex p-0 justify-start items-center font-bold text-lg">

                        Chatbot

                    </div>

                    <div class="flex justify-center items-center gap-x-6">

                        <a class="bg-white text-gray-800 rounded-none px-2 pt-3 pb-2 text-sm font-medium"
                            (click)="setEmbedScreen('customize')" [ngClass]="{ 'subtab': embed_tab === 'customize' }"
                            style=" padding-bottom: 16px !important;" aria-current="page">
                            <i class="fa-regular fa-message mr-1"></i>
                            Customize</a>


                        <a class="bg-white text-gray-800 rounded-none px-2 pt-3 pb-2 text-sm font-medium"
                            (click)="setEmbedScreen('embed')" [ngClass]="{ 'subtab': embed_tab === 'embed' }"
                            style=" padding-bottom: 16px !important;" aria-current="page">
                            <i class="fa-regular fa-code mr-1"></i>
                            Embed Code</a>


                        <a class="bg-white text-gray-800 rounded-none px-2 pt-3 pb-2 text-sm font-medium"
                            (click)="setEmbedScreen('widget')" [ngClass]="{ 'subtab': embed_tab === 'widget' }"
                            style="padding-bottom: 16px !important;" aria-current="page">
                            <i class="fa-regular fa-widget mr-1"></i>
                            Notion Widget</a>

                    </div>




                    <button _ngcontent-xis-c92="" for="my-modal-5"
                        class="m-0 mr-3 px-2 btn bg-none text-gray-800 p-0 border-0"
                        (click)="modal_class_embed = 'modal-close';" style="
                background: #fff !important;
            ">
                        <i class="fa-regular fa-times"></i>
                    </button>






                </div>

            </div>

            <div class="py-0 px-2 mt-2 flex justify-start items-start h-screen"
                *ngIf="!upload_index_progress && embed_tab === 'customize'" style="height: 99%; max-width:1500px;">


                <div class="w-7/12 border-r p-0 py-0 h-full flex flex-col  justify-center items-center">

                    <!-- <span class="text-xs text-gray-400">
                        {{embed?.embed_uuid}}
                    </span> -->


                    <div class="w-full h-auto shadow flex flex-col p-0  overflow-hidden {{embed?.rounded}}"
                        style="max-width: 400px;"
                        style="background:{{embed?.bg_color}}; border:solid 1px {{embed?.border_color}}; border-radius:{{embed?.rounded}}%">

                        <div class="p-3 flex justify-between items-center border-b"
                            style="background: {{embed?.header_color}};">

                            <div class="flex justify-start items-center">

                                <div>
                                    <img src="assets/bot-1.svg" class="w-10"
                                        *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined" />
                                    <img src="{{embed?.logo}}" class="w-10"
                                        *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined"
                                        [ngClass]="{'rounded-full':embed?.logo_rounded}" />
                                </div>
                                <div class="ml-2 text-lg font-semibold" style="color:{{embed?.header_text_color}}">
                                    {{embed?.title}}
                                </div>
                            </div>

                            <div>

                            </div>


                        </div>

                        <div class="p-0">

                            <div class="p-3   border-0 w-full flex ">
                                <div class="p-3 my-2 w-fit  text-white rounded-lg"
                                    style="background: {{embed?.bot_msg_bg_color}};">

                                    <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                                    <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                                        {{embed?.default_message}}

                                    </div>

                                </div>
                            </div>

                            <div *ngFor="let message of sample_chat_messages" class="px-3 border-0">

                                <div class="p-0 rounded-lg  border-0 w-full flex justify-end items-end"
                                    *ngIf="message.actor === 'user'">
                                    <div class="p-3 my-2 w-fit rounded-lg"
                                        style="background: {{embed?.user_msg_bg_color}};">

                                        <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                                        <div class="text-sm" style="color:{{embed?.user_msg_text_color}}">

                                            {{message.text}}

                                        </div>

                                    </div>
                                </div>

                                <div class="p-0   border-0 w-full flex " *ngIf="message.actor === 'bot'">
                                    <div class="p-3 my-2 w-fit  text-white rounded-lg"
                                        style="background: {{embed?.bot_msg_bg_color}};">

                                        <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                                        <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                                            <div class="custom-link" [innerHTML]="message.text"></div>

                                        </div>

                                    </div>
                                </div>







                            </div>
                            <div class="h-40 w-full">

                            </div>

                            <div class="relative flex border-t py-2"
                                style="background: {{embed?.send_bar_background}};">

                                <input type="text" placeholder="{{embed?.placeholder}}"
                                    style="color:{{embed?.send_bar_text_color}}; ::placeholder{color:red}"
                                    value="Hello!"
                                    class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 border-0 bg-inherit  border-gray-900 rounded-md py-3">
                                <div class="absolute right-0 items-center inset-y-0 hidden sm:flex">

                                    <button type="button"
                                        class="inline-flex items-center justify-center rounded-lg px-4 py-3 m-3 transition duration-500 ease-in-out  bg-blue-500 hover:bg-blue-400 focus:outline-none"
                                        style="background: {{embed?.send_icon_bg_color}}; color:{{embed?.send_icon_color}}">

                                        <i class=" {{embed?.send_icon}}"></i>
                                    </button>
                                </div>
                            </div>

                        </div>





                    </div>

                    <div class="flex justify-end items-end mt-3 h-auto px-4 w-full">

                        <button class=" w-16 h-16 rounded-full bg-gray-800 text-2xl flex justify-center items-center"
                            style="z-index:999999; background: {{embed?.bubble_bg_color}};">
                            <i class="{{embed?.bubble_icon}}" style="color:{{embed?.bubble_text_color}}"></i>
                        </button>

                    </div>





                </div>

                <div class="w-5/12 h-full overflow-y-scroll px-16 py-4 flex flex-col justify-start items-start">

                    <label class="edit_bot_label text-lg font-semibold mb-3">Customize</label>
                    <div class="w-full my-2">
                        <label class="edit_bot_label text-base font-normal mb-1">Logo</label>
                        <div class="flex flex-col justify-start items-start ">
                            <a (click)="onImageClick()" class="p-0 m-0">
                                <div class="avatar mr-0">
                                    <div class=""
                                        *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined">
                                        <img src="{{embed?.logo}}" cors="anonymous"
                                            style="min-width: 50px; max-width: 50px;" />
                                    </div>
                                    <div class="w-12  rinng ring-primary ring-offset-base-800 ring-offset-0"
                                        *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined">
                                        <div class="flex justify-center items-center h-full">
                                            <img src="assets/bot-1.svg" cors="anonymous"
                                                style="min-width: 50px; max-width: 50px;" />
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <input type="file" accept="image/*" (change)="onFileSelected($event)"
                                style="display: none;">

                            <a (click)="onImageClick()" class="text-xs cursor-pointer">Edit Picture</a>



                        </div>
                    </div>

                    <div class="w-full flex justify-start items-center gap-x-3 my-3">

                        <label class="edit_bot_label text-base font-normal mb-1">Logo Rounded</label>
                        <input type="checkbox" class="toggle toggle-success" [(ngModel)]="embed.logo_rounded"
                            (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full my-2">

                        <label class="edit_bot_label text-base font-normal mb-1">Bot Title</label>
                        <input type="text" class="edit_bot_input w-full p-3 mt-1 border rounded"
                            [(ngModel)]="embed.title" (keyup)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3">

                        <label class="edit_bot_label text-base font-normal mb-1">Bot Default Message</label>
                        <input type="text" class="edit_bot_input w-full p-3 mt-1 border rounded"
                            [(ngModel)]="embed.default_message" (keyup)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3">

                        <label class="edit_bot_label text-base font-normal mb-1">Message Placeholder Text</label>
                        <input type="text" class="edit_bot_input w-full p-3 mt-1 border rounded"
                            [(ngModel)]="embed.placeholder" (keyup)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Header Background Color</label>
                        <input type="color" class=" mt-1" value="embed?.header_color" [(ngModel)]="embed.header_color"
                            (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Header Text Color</label>
                        <input type="color" class=" mt-1" value="embed?.header_text_color"
                            [(ngModel)]="embed.header_text_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Chat Window Background Color</label>
                        <input type="color" class=" mt-1" value="embed.bg_color" [(ngModel)]="embed.bg_color"
                            (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Chat Window Border Color</label>
                        <input type="color" class=" mt-1" value="embed.border_color" [(ngModel)]="embed.border_color"
                            (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">


                        <label class="edit_bot_label text-base font-normal mb-1">Chat Window Roundedness</label>
                        <input type="range" min="0" max="10" value="5" class="range" step="1"
                            (change)="saveBotSettings()" [(ngModel)]="embed.rounded" />

                    </div>


                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Send Bar Backgrond Color</label>
                        <input type="color" class=" mt-1" value="embed.send_bar_background"
                            [(ngModel)]="embed.send_bar_background" (change)="saveBotSettings()" />

                    </div>


                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Send Bar Text Color</label>
                        <input type="color" class=" mt-1" value="embed.send_bar_text_color"
                            [(ngModel)]="embed.send_bar_text_color" (change)="saveBotSettings()" />

                    </div>

                    <hr class="w-full h-1 bg-gray-100 mt-4">

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Bot Message Background Color</label>
                        <input type="color" class=" mt-1" value="embed.bot_msg_bg_color"
                            [(ngModel)]="embed.bot_msg_bg_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Bot Message Text Color</label>
                        <input type="color" class=" mt-1" value="embed.bot_msg_text_color"
                            [(ngModel)]="embed.bot_msg_text_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">User Message Background Color</label>
                        <input type="color" class=" mt-1" value="embed.user_msg_bg_color"
                            [(ngModel)]="embed.user_msg_bg_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">User Message Text Color</label>
                        <input type="color" class=" mt-1" value="embed.user_msg_text_color"
                            [(ngModel)]="embed.user_msg_text_color" (change)="saveBotSettings()" />

                    </div>

                    <hr class="w-full h-1 bg-gray-100 mt-4">

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Send Button Background Color</label>
                        <input type="color" class=" mt-1" value="embed.send_icon_bg_color"
                            [(ngModel)]="embed.send_icon_bg_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Send Icon Color</label>
                        <input type="color" class=" mt-1" value="embed.send_icon_color"
                            [(ngModel)]="embed.send_icon_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Send Button Icon </label>

                        <div class="flex gap-x-4">

                            <i class="fa-solid fa-paper-plane text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-paper-plane', 'text-primary': embed?.send_icon === 'fa-solid fa-paper-plane'}"
                                (click)="embed.send_icon = 'fa-solid fa-paper-plane'; saveBotSettings()"></i>

                            <!-- Few other Icon Options -->

                            <i class="fa-light fa-paper-plane text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-paper-plane', 'text-primary': embed?.send_icon === 'fa-light fa-paper-plane'}"
                                (click)="embed.send_icon = 'fa-light fa-paper-plane'; saveBotSettings()"></i>

                            <i class="fa-solid fa-paper-plane-top text-lg cursor-pointer ml-3"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-paper-plane-top', 'text-primary': embed?.send_icon === 'fa-solid fa-paper-plane-top'}"
                                (click)="embed.send_icon = 'fa-solid fa-paper-plane-top'; saveBotSettings()"></i>


                            <i class="fa-light fa-paper-plane-top text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-paper-plane-top', 'text-primary': embed?.send_icon === 'fa-light fa-paper-plane-top'}"
                                (click)="embed.send_icon = 'fa-light fa-paper-plane-top'; saveBotSettings()"></i>

                            <i class="fa-solid fa-message-arrow-up text-lg ml-3 cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message-arrow-up', 'text-primary': embed?.send_icon === 'fa-solid fa-message-arrow-up'}"
                                (click)="embed.send_icon = 'fa-solid fa-message-arrow-up'; saveBotSettings()"></i>


                            <i class="fa-light fa-message-arrow-up text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message-arrow-up', 'text-primary': embed?.send_icon === 'fa-light fa-message-arrow-up'}"
                                (click)="embed.send_icon = 'fa-light fa-message-arrow-up'; saveBotSettings()"></i>


                        </div>

                    </div>



                    <hr class="w-full h-1 bg-gray-100 mt-4">

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Background Color</label>
                        <input type="color" class=" mt-1" value="embed.bubble_bg_color"
                            [(ngModel)]="embed.bubble_bg_color" (change)="saveBotSettings()" />

                    </div>

                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Icon Color</label>
                        <input type="color" class=" mt-1" value="embed.bubble_text_color"
                            [(ngModel)]="embed.bubble_text_color" (change)="saveBotSettings()" />

                    </div>


                    <div class="w-full mt-3 flex flex-col">

                        <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Icon </label>

                        <div class="flex gap-x-4">

                            <i class="fa-light fa-message text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message', 'text-primary': embed?.send_icon === 'fa-light fa-message'}"
                                (click)="embed.bubble_icon = 'fa-light fa-message'; saveBotSettings()"></i>

                            <i class="fa-solid fa-message text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message', 'text-primary': embed?.send_icon === 'fa-solid fa-message'}"
                                (click)="embed.bubble_icon = 'fa-solid fa-message'; saveBotSettings()"></i>

                            <i class="fa-solid fa-messages text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-messages', 'text-primary': embed?.send_icon === 'fa-solid fa-messages'}"
                                (click)="embed.bubble_icon = 'fa-solid fa-messages'; saveBotSettings()"></i>

                            <i class="fa-light fa-messages text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-messages', 'text-primary': embed?.send_icon === 'fa-light fa-messages'}"
                                (click)="embed.bubble_icon = 'fa-light fa-messages'; saveBotSettings()"></i>

                            <i class="fa-light fa-message-quote text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message-quote', 'text-primary': embed?.send_icon === 'fa-light fa-message-quote'}"
                                (click)="embed.bubble_icon = 'fa-light fa-message-quote'; saveBotSettings()"></i>

                            <i class="fa-solid fa-message-quote text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message-quote', 'text-primary': embed?.send_icon === 'fa-solid fa-message-quote'}"
                                (click)="embed.bubble_icon = 'fa-solid fa-message-quote'; saveBotSettings()"></i>

                            <i class="fa-light fa-circle-info text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-circle-info', 'text-primary': embed?.send_icon === 'fa-light fa-circle-info'}"
                                (click)="embed.bubble_icon = 'fa-light fa-circle-info'; saveBotSettings()"></i>

                            <i class="fa-solid fa-circle-info text-lg cursor-pointer"
                                [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-circle-info', 'text-primary': embed?.send_icon === 'fa-solid fa-circle-info'}"
                                (click)="embed.bubble_icon = 'fa-solid fa-circle-info'; saveBotSettings()"></i>







                        </div>

                        <div class="w-full h-40">

                        </div>


                    </div>






                </div>







            </div>

            <div class="p-4 mt-2 flex flex-col justify-center items-center w-full"
                *ngIf="!upload_index_progress && embed_tab === 'embed'">

                <img src="assets/embed.svg" class="w-40 mb-3">

                <div class="text-base text-gray-800 mt-3 px-4 ml-4">Copy and paste the code below into your website
                </div>

                <code class="mb-4 p-4 rounded text-sm border mt-3">

                    &lt;knibble-bot [chatbot_id]="{{project?.embed_uuid}}" #chatbot&gt;&lt;/knibble-bot&gt;
                    <br>
                    &lt;script src="https://s3.amazonaws.com/knibble.ai.assets/package.js" type="module"&gt;&lt;/script&gt;

                </code>

                <button (click)="copyTextToClipboard()" class="py-2 px-4 text-xs text-white rounded"
                    style="background: var(--app-theme-color);">
                    <i class="fa-regular mr-1 fa-copy"></i>
                    <span *ngIf="isCopied">Copied!</span>
                    <span *ngIf="!isCopied">Copy</span>

                </button>





                <!-- <div class="w-1/2 mx-auto flex flex-col">

                    <div class="text-base text-gray-800 mt-3 px-4 ml-4">Copy and paste the code below into your website
                    </div>

                    <div class="flex flex-col justify-start items-start pl-10">

                        <code class="mb-4 p-4 rounded text-xs border mt-3">

                            &lt;knibble-bot [chatbot_id]="{{project?.embed_uuid}}" #chatbot&gt;&lt;/knibble-bot&gt;
                            <br>
                            &lt;script src="https://knibble.ai/assets/embed/package.js" type="module"&gt;&lt;/script&gt;
    
                        </code>


                        <button (click)="copyTextToClipboard()" class="py-2 px-4 text-xs text-white rounded"
                            style="background: var(--app-theme-color);">
                            <i class="fa-regular mr-1 fa-copy"></i>
                            <span *ngIf="isCopied">Copied!</span>
                            <span *ngIf="!isCopied">Copy</span>

                        </button>

                    </div>

                </div> -->





            </div>

            <div class="p-4 mt-10 flex justify-center items-center  flex-col "
                *ngIf="!upload_index_progress && embed_tab === 'widget'">




                <img src="assets/notion.svg" class="w-20 mb-3">

                <div class="text-base text-gray-800 mt-3 px-4 ml-4">Use the below link to embed the chatbot in your
                    Notion page.
                </div>

                <code class="mb-4 p-4 rounded text-sm border mt-3">
                    https://knibble.ai/chat/{{project?.embed_uuid}}

                </code>

                <button (click)="copyNotionLink()" class="py-2 px-4 ml-10 text-xs text-white rounded"
                    style="background: var(--app-theme-color);">
                    <i class="fa-regular mr-1 fa-copy"></i>
                    <span *ngIf="isCopied">Copied!</span>
                    <span *ngIf="!isCopied">Copy</span>

                </button>



            </div>



        </div>

    </div>

</div>

<!-- Embed Chatbot -->

<div class="w-full  rounded-t-full rounded-b-full overflow-visible"
    [hidden]="(embed_show === false || embed_show === null)">

    <div class="w-full h-screen overflow-hidden flex flex-col p-0 items-stretch {{embed?.rounded}}"
        style="background:{{embed?.bg_color}}; border:solid 1px {{embed?.border_color}}; border-radius:{{embed?.rounded}}%">

        <div class="p-3 flex justify-between items-center border-b" style="background:{{embed?.header_color}}">

            <div class="flex justify-start items-center">

                <div>
                    <img src="assets/bot-1.svg" class="w-10"
                        *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined" />
                    <img src="{{embed?.logo}}" class="w-10"
                        *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined"
                        [ngClass]="{'rounded-full':embed?.logo_rounded}" />
                </div>
                <div class="ml-2 text-lg font-semibold" style="color: {{embed?.header_text_color}};">
                    {{embed?.title}}
                </div>
            </div>

            <div>

            </div>


        </div>

        <div class="p-3  h-auto flex-1 flex-grow overflow-y-scroll">

            <div class="p-0   border-0 w-full  flex ">
                <div class="p-3 my-2 w-fit  text-white rounded-lg" style="background: {{embed?.bot_msg_bg_color}};">

                    <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                    <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                        {{embed?.default_message}}

                    </div>

                </div>
            </div>




            <div *ngFor="let message of chat_messages" class="p-0 border-0">

                <div class="p-0 rounded-lg  border-0 w-full flex justify-end items-end"
                    *ngIf="message.actor === 'user'">
                    <div class="p-3 my-2 w-fit rounded-lg" style="background: {{embed?.user_msg_bg_color}};">



                        <div class="text-sm" style="color:{{embed?.user_msg_text_color}}">

                            {{message?.text}}

                        </div>

                    </div>
                </div>

                <div class="p-0   border-0 w-full flex " *ngIf="message?.actor === 'bot'">
                    <div class="p-3 my-2 w-fit  text-white rounded-lg" style="background: {{embed?.bot_msg_bg_color}};">



                        <div [innerHTML]="filterAllTagsOtherThanLinks(message?.text)" class="text-sm"
                            style="color:{{embed?.bot_msg_text_color}}">



                        </div>

                    </div>
                </div>










            </div>

            <div class="w-full p-8 mx-auto flex justify-center items-center" *ngIf="typing">

                <img src="assets/progress.gif" class="w-12 h-12 mx-auto" />
            </div>

            <div class="w-full h-40" id="end_of_chat">

            </div>






        </div>

        <div class="relative flex justify-between items-center border-t px-2 py-2"
            style="background: {{embed?.send_bar_background}};">

            <input type="text" placeholder="{{embed?.placeholder}}" [(ngModel)]="current_input_message"
                style="width:90%;color:{{embed?.send_bar_text_color}}" (keyup.enter)="getAnswer()"
                class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 border-0 rounded-none py-3 bg-inherit">
            <div class=" right-0 items-center">

                <button type="button" (click)="getAnswer()"
                    class="inline-flex items-center justify-center rounded-lg px-4 py-3 mx-2 transition duration-500 ease-in-out  bg-blue-500 hover:bg-blue-400 focus:outline-none"
                    style="background: {{embed?.send_icon_bg_color}}; color:{{embed?.send_icon_color}}">

                    <i class=" {{embed?.send_icon}}"></i>
                </button>
            </div>
        </div>





    </div>


</div>
<div class="modal {{modal_state}} " style="z-index: 999999;">
    <div class="modal-box w-full md:w-6/12 max-w-5xl p-0 ng-white h-auto rounded-sm"
        style="background:#fff !important; ">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Create Project

            </div>

            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_state = 'modal-close'; resetText()" style="
            background: #fff !important;
        ">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start" style="height: 85%;">

            <label class="mb-2 text-base">
                Project Name
            </label>


            <div class="p-0 w-full border rounded-lg bg-white flex justify-start items-center"
                style="border:solid 1px #bab8b8 !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">

                <input type="text" [(ngModel)]="project_name"
                    class="bg-white text-gray-800 rounded-lg px-3 py-3 text-sm mr-0 w-full h-full focus:outline-none"
                    style="padding-top:6px;padding-bottom:6px" placeholder="Ex: Product Documentation" />

            </div>

            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="project_name !== ''" style="background: var(--app-theme-color) !important;"
                (click)="createProject($event)">
                Create</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="project_name === ''" style="background: #c9c8c8 !important;" disabled>
                Create</button>




        </div>


    </div>

</div>
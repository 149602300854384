<app-add-new-project></app-add-new-project>
<app-delete-project (notifyDeletSuccess)="projectDeletionHandler($event)"></app-delete-project>
<app-api-key [limits]="limits" [tracker]="tracker" (notifySuccess)="keyAdded()"></app-api-key>
<div class="h-full flex justify-center items-center shadow-sm flex-col px-0 overflow-visible" [hidden]="!showSidebar"
    style=" width: 18rem;background: #000;">

    <div class="flex justify-between items-center px-4 py-8 mt-1" style="background: #000000; width:18rem">

        <div class="flex justify-start items-center">

            <a href="#" title="" class="mr-2">

                <span class="text-white">

                    <img src="assets/logo-re2.svg" alt="" style="width:25px">
                    <!-- <i class="fa-brands fa-codepen" style="font-size: 29px;"></i> -->
                </span>


            </a>

            <!-- <span class="text-white font-semibold" style="font-size: 20px;">
                Knibble
            </span> -->

        </div>


        <a (click)="showSidebar = !showSidebar"><i class="fa-regular fa-sidebar text-gray-400 text-xl"></i></a>



    </div>

    <div class="divider">

    </div>

    <div class="px-4 h-20 mt-1 flex font- justify-start text-white items-center w-full"
        style="font-size: 16px;z-index: 99;">
        <a class="py-0 px-2 w-full bg-inherit text-white text-base rounded" href="/project-new">
            <i class="fa-duotone fa-grid-2  mr-2 -ml-2 fa fa-fw"></i> Dashboard
        </a>
    </div>

    <div class="px-4 h-20  flex font- justify-between text-white items-center w-full" style="font-size: 16px;"
        [hidden]="plan === 'Basic'">
        <button (click)="addAPIKey()" class="py-0 px-2 bg-inherit text-white text-base rounded" style="background-image: linear-gradient(60deg, #fe72ff, #FFB03A);
            -webkit-background-clip: text; background-clip: text;
            color: transparent !important;">
            <i class="fa-regular fa-key  mr-2 -ml-2 fa fa-fw"></i> API Key
        </button>
        <i class="fa-regular fa-warning  text-yellow-600 mr-1" data-toggle="tooltip" data-placement="top"
            title="Tooltip on top" *ngIf="limits?.is_own_key && !limits?.is_key_generated"></i>
    </div>

    <div class="px-4 h-20  flex font- justify-start text-white items-center w-full" style="font-size: 16px;"
        [hidden]="plan !== 'Basic'">
        <a href="/pricing" class="py-0 px-2 bg-inherit text-white text-base rounded" style="background-image: linear-gradient(60deg, #fe72ff, #FFB03A);
                -webkit-background-clip: text; background-clip: text;
                color: transparent !important;">
            <i class="fa-regular fa-bolt mr-2 fa fa-fw -ml-2"></i>
            Upgrade
        </a>
    </div>

    <div class="divider mt-3">

    </div>

    <div class=" px-4 py-2 mt-2 rounded  text-lg flex justify-between text-white items-center"
        style="background: inherit; width:18rem">

        <div style="font-size: 15px;" class="ml-1 font-semibold text-gray-400">

            PROJECTS
        </div>

        <button (click)="addProject()">

            <i class="fa-regular fa-plus text-lg text-white mr-1"></i>

        </button>


    </div>

    <div class=" px-2 py-0 rounded mt-0 mb-4  text-lg flex flex-col justify-start text-white items-center h-full overflow-y-scroll"
        style="background: inherit; width:18rem">




        <ul class="px-1  w-full" style="border-left:solid 0px #E9ECEE;">
            <li *ngFor="let p of user_projects" class="flex justify-between items-center my-1 px-3 py-2 w-full "
                [ngClass]="{'bg-chatbot-selected': p.project_uuid === pid}" style="font-size: 15px;">

                <button class="w-full flex justify-between items-center project-li"
                    (click)="goToProject(p.project_uuid)">

                    <div class="flex justify-start items-center ">
                        <i class="fa-solid fa-circle text-xs mr-3 {{p.color}} " style=" font-size: 8px;"></i>
                        <div class="">{{p.project_name | truncatePipe:0:18}}</div>
                    </div>


                    <button (click)="delProject(p, $event)" data-toggle="tooltip" data-placement="right"
                        title="Delete Project" class=" text-white hover:text-red-500 project-item-delete hidden"><i
                            class="fa-solid fa-times-circle" style="font-size: 14px;"></i></button>
                    <i class="fa-light fa-chevron-right text-xs text-gray-600 mr-1 project-item-cirlce"></i>


                </button>
            </li>

            <div class="w-full px-0">

                <li class="flex justify-center items-center my-1 px-3 py-2 w-full bg-inherit mt-3 rounded-xl cursor-pointer hover:bg-gray-900"
                    (click)="addProject()" style="border:dashed 1px #3d4450;font-size: 15px;">
                    + New Project
                </li>

            </div>



        </ul>


        <!-- <div class="px-2 mt-2 w-full">

            <button class="w-full py-2 px-3 rounded  mx-2 text-white text-base bg-inherit"
                style="border:dashed 1px #3d4450">
                + New Project
            </button>

        </div> -->



    </div>

    <div class="divider my-0">

    </div>

    <div class="flex flex-col bottom-0  items-center inset-y-0 sm:flex text-white p-3 w-full gap-y-4"
        style="background:#000000">

        <div class="flex justify-start items-center w-full px-2">

            <div class="dropdown dropup px-0" *ngIf="user_logged_in" style="z-index:999999">
                <button
                    class="btn bg-inherit focus:outline-none dropdown-toggle px-0 w-full flex justify-start items-start"
                    type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">

                    <div class="flex justify-start items-start">
                        <div class="rounded-full">

                            <div class="w-8 h-8 rounded-xl bg-inherit"
                                style="border:solid 0px var(--app-theme-color) !important; background:var(--app-theme-color);">
                                <div class="flex justify-center items-center h-full">
                                    <div class="text-base text-white capitalize font-semibold"
                                        style="color:#fff !important">
                                        {{user?.user_uuid[0]}}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div class="flex flex-col justify-start items-start">
                        <div class="ml-3 text-sm text-white">
                            {{user?.user_uuid | truncatePipe:0:13}}
                        </div>
                        <div class="mt-1 ml-3">

                            <div *ngIf="user_logged_in">

                                <div *ngIf="user_upgrade"
                                    class="text-sm text-gray-400 flex justify-center items-center">
                                    <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;"
                                        class="mr-1  -ml-1">
                                    {{plan}} plan
                                </div>

                                <div *ngIf="!user_upgrade"
                                    class="text-sm text-gray-400 flex justify-center items-center">
                                    <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                                    Basic plan
                                </div>


                            </div>

                        </div>

                    </div>



                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="left: 53px;">
                    <a class="dropdown-item font-semibold py-1 border-b text-xs">{{user?.user_uuid}}</a>
                    <a class="dropdown-item" (click)="showAccount()">Account</a>

                    <a class="dropdown-item" href="https://tally.so/r/mRd6jJ">Contact Us</a>
                    <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                    <!-- <a class="dropdown-item text-gray-500" href="#">Delete Account</a> -->
                </div>
            </div>



        </div>


        <!-- <button class="flex justify-center items-center my-1 px-3 py-2 w-full bg-inherit mt-3 rounded-lg "
            (click)="addProject()" style="border:dashed 1px #3d4450;font-size: 16px;">
            + New Project
        </button> -->

        <!-- <div class="text-sm px-8 flex justify-start items-center w-full">
            <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
            {{plan}} plan
        </div> -->


        <!-- <div class="px-4 mb-3 w-full">

            

        </div> -->





        <!-- <button class="px-3  py-2 bg-inherit text-white rounded w-full" style="background: var(--app-theme-color);">
            <i class="fa-light fa-bolt mr-2"></i>
            Upgrade
        </button> -->

        <!-- <div class="px-4 py-3 flex flex-col justify-center items-center text-white rounded"
            style="border:dotted 0px #f7f7f7; background: #1a1a1a;">
            <i class="fa-light fa-key text-2xl"></i>
            <span class="text-sm w-full mt-2 text-center align-middle">
                Add your own API key and get unlimited messages
            </span>
            <span class="text-sm text-yellow-300">
                Learn more
            </span>
        </div> -->




    </div>

</div>


<div class="h-full flex justify-between items-center flex-col px-0 overflow-visible" [hidden]="showSidebar"
    style=" width: 5rem;background: #000000;">

    <div class="flex flex-col justify-center items-centers">

        <div class="w-full flex justify-center items-center">

            <a (click)="showSidebar = !showSidebar" class="py-4"><i
                    class="fa-regular fa-sidebar text-gray-400 text-xl"></i></a>

        </div>



        <div class="flex justify-center items-center px-4 py-8 mt-1" style="background: #000000; width:5rem">

            <div class="flex justify-start items-center">

                <a href="#" title="">

                    <span class="text-white">

                        <img src="assets/logo-re2.svg" alt="" style="width:25px">

                    </span>


                </a>

            </div>

        </div>


        <div class="divider">

        </div>



        <div class=" h-20 mt-1 flex font- justify-center text-white items-center w-full" style="font-size: 16px;">
            <a class="py-0 w-full flex justify-center items-center bg-inherit text-white text-base" href="/project-new">
                <i class="fa-duotone fa-grid-2  fa fa-fw text-xl"></i>
            </a>
        </div>


        <div class=" h-20  flex font- justify-center text-white items-center w-full" style="font-size: 16px;"
            [hidden]="plan === 'Basic'">
            <button (click)="addAPIKey()" class="py-0 px-2 bg-inherit text-white text-base rounded" style="background-image: linear-gradient(60deg, #fe72ff, #FFB03A);
                -webkit-background-clip: text;
                color: transparent !important;">
                <i class="fa-regular fa-key  fa fa-fw text-xl"></i>
            </button>
        </div>

        <div class=" h-20  flex font- justify-center text-white items-center w-full" style="font-size: 16px;"
            [hidden]="plan !== 'Basic'">
            <a class="py-0 px-2 bg-inherit text-white text-base rounded  justify-center items-center" style="background-image: linear-gradient(60deg, #fe72ff, #FFB03A);
                    -webkit-background-clip: text;
                    color: transparent !important;">
                <i class="fa-regular fa-bolt  fa fa-fw text-xl"></i>

            </a>
        </div>



        <div class="divider mt-3">

        </div>


        <div class=" px-4 py-2 mt-2 rounded  text-lg flex justify-center text-white items-center"
            style="background: inherit; width:5rem">


            <button (click)="addProject()">

                <i class="fa-regular fa-plus text-xl text-white mr-1"></i>

            </button>


        </div>




    </div>



    <div
        class="flex flex-col bottom-0 h-24 justify-center items-center inset-y-0 sm:flex text-white p-3 w-full gap-y-4">

        <div class="flex justify-center items-center w-full px-2">

            <div class="dropdown dropup px-0" *ngIf="user_logged_in" style="z-index:999999">
                <button
                    class="btn bg-inherit focus:outline-none dropdown-toggle px-0 w-full flex justify-start items-start"
                    type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">

                    <div class="flex justify-start items-start">
                        <div class="rounded-full">

                            <div class="w-8 h-8 rounded-xl bg-inherit"
                                style="border:solid 0px var(--app-theme-color) !important; background:var(--app-theme-color);">
                                <div class="flex justify-center items-center h-full">
                                    <div class="text-base text-white capitalize font-semibold"
                                        style="color:#fff !important">
                                        {{user?.user_uuid[0]}}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="left: 53px;">
                    <a class="dropdown-item font-semibold py-1 border-b text-xs">{{user?.user_uuid}}</a>
                    <a class="dropdown-item" (click)="showAccount()">Account</a>

                    <a class="dropdown-item" href="https://tally.so/r/mRd6jJ">Contact Us</a>
                    <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                    <!-- <a class="dropdown-item text-gray-500" href="#">Delete Account</a> -->
                </div>
            </div>



        </div>

    </div>

</div>
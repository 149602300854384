import { Component, OnInit } from '@angular/core';
// import { AuthService } from '@auth0/auth0-angular';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  sub_data: any;
  upgraded = false;
  customerportal = "";
  profile: any;
  isLoading = true;
  i = 0;
  error_state = false;


  constructor(public dataservice: DataService, private route: Router, private authenticatorService: AuthenticatorService) { }

  ngOnInit(): void {



    this.authenticatorService.subscribe(() => {
      const { route } = this.authenticatorService;

      // this.auth.user$.subscribe(
      //   (profile) => {

      this.profile = this.authenticatorService.user.attributes;


      if (route === 'authenticated') {
        this.verifySubscription();

      } else {
        this.route.navigate(["/"]);
      }
    });


  }


  verifySubscription() {

    this.dataservice.isUpgraded().subscribe((data) => {
      // //console.log(data);
      this.sub_data = data;
      if (data['isUpgraded']) {
        this.upgraded = true;
      } else {
        this.upgraded = false;
      }

      this.isLoading = false;
      if (this.upgraded) {

        this.dataservice.getCustomerPortal().subscribe((data) => {
          // //console.log(data);
          this.customerportal = data['session_url']
          this.isLoading = false;
        });

      } else {
        if (this.i < 5) {
          this.i++;
          setTimeout(() => {
            this.verifySubscription();
          }, 2000);

        } else {
          this.error_state = true;
          this.isLoading = false;
        }
      }

    });

  }


}

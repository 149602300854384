import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@aws-amplify/storage';
import { Observable, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { data } from 'jquery';
import { environment } from '../../environments/environment';



export interface widgetsquare {
  bgcolor: any,
  textcolor: any,
  iconcolor: any,
  bordercolor: any,
  border: any,
  size: any,
  rounded: any,
  place: any
}

@Injectable({
  providedIn: 'root'
})


export class DataService {




  BASE_URL: string;
  // BASE_URL = "https://api.leapp.ai/";

  // private _shouldPollLpStatus = true;
  private _stopPollingLpStatus$ = new Subject<void>();
  private _pollingInterval = 5000;
  private _stopPollingTopicCreationStatus$ = new Subject<void>();
  private _stopPollingSectionCreationStatus$ = new Subject<void>();
  private _pollingTopicInterval = 5000;
  private _pollingSectionInterval = 5000;





  constructor(private http: HttpClient) {

    if (environment.production) {
      this.BASE_URL = "https://apiv2.knibble.ai/";
    } else {
      this.BASE_URL = "https://apiv2.knibble.ai/";
      // this.BASE_URL = "http://localhost:5002/";
    }
  }

  // Chatdoc Methods Start
  // api/track POST
  cd_trackUsage() {
    return this.http.post(this.BASE_URL + "/api/track", {}, {
      withCredentials: true
    })
  }

  // api/user/get/allowed POST
  cd_getAllowedFunctions(plan) {
    return this.http.post(this.BASE_URL + "/api/user/get/allowed", { "plan": plan }, {
      withCredentials: true
    })
  }

  // api/user/get/limit POST "plan"
  cd_getLimit(plan) {
    return this.http.post(this.BASE_URL + "api/user/get/limits", { "plan": plan }, {
      withCredentials: true
    })
  }

  // api/delete_object POST cd_deleteObject
  cd_deleteObject(object_uuid, project_uuid, file_size) {
    return this.http.post(this.BASE_URL + "api/delete_object", {
      "object_uuid": object_uuid,
      "project_uuid": project_uuid,
      "file_size": file_size
    }, {
      withCredentials: true
    });
  }


  // api/project/create POST cd_createProject
  cd_createProject(name) {
    return this.http.post(this.BASE_URL + "api/project/create", { "name": name }, {
      withCredentials: true
    });
  }

  cd_deleteProject(project_uuid) {
    return this.http.post(this.BASE_URL + "api/user/project/delete", { "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  // api/user/projects/get POST cd_getUserProjects
  cd_getUserProjects() {
    return this.http.post(this.BASE_URL + "api/user/projects/get", {}, {
      withCredentials: true
    });
  }

  // api/plans/get POST cd_getPlans
  cd_getPlans() {
    return this.http.post(this.BASE_URL + "api/plans/get", {}, {
      withCredentials: true
    });
  }

  // api/user/project/get POST
  cd_getUserProject(project_uuid: string, share_code: string, chat_id: string) {
    let data = {
      "project_uuid": project_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    if (chat_id != "") {
      data["chat_id"] = chat_id;
    }
    return this.http.post(this.BASE_URL + "api/user/project/get", data, {
      withCredentials: true
    });
  }

  // /api/project/chatbot-config/get
  cd_getChatbotConfig(project_uuid: string) {
    let data = {
      "project_uuid": project_uuid
    }
    return this.http.post(this.BASE_URL + "api/project/chatbot-config/get", data, {
      withCredentials: true
    });
  }
  // /api/project/chatbot-config/update
  cd_updateChatbotConfig(project_uuid: string, persona: string, temperature: string) {
    let data = {
      "project_uuid": project_uuid,
      "temperature": temperature,
      "persona": persona
    }
    return this.http.post(this.BASE_URL + "api/project/chatbot-config/update", data, {
      withCredentials: true
    });
  }


  cd_getUserProjectForEmbed(project_uuid: string, share_code: string, chat_id: string) {
    let data = {
      "project_uuid": project_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    if (chat_id != "") {
      data["chat_id"] = chat_id;
    }
    return this.http.post(this.BASE_URL + "api/user/projectforembed/get", data, {
      withCredentials: true
    });
  }

  // api/user/project/update POST 
  cd_updateUserProject(project: any) {
    return this.http.post(this.BASE_URL + "api/user/project/update", project, {
      withCredentials: true
    });
  }

  cd_addUrlAndIndex(url, project_uuid) {
    return this.http.post(this.BASE_URL + "api/add_url_start_indexing", { "url": url, "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  cd_addFileUrlAndIndex(url, project_uuid, retry, object_id) {
    return this.http.post(this.BASE_URL + "api/add_file_url_start_indexing", { "url": url, "project_uuid": project_uuid, "retry": retry, "object_id": object_id }, {
      withCredentials: true
    });
  }

  cd_getFileUploadPath(filename, project_uuid, file_size) {
    return this.http.post(this.BASE_URL + "api/upload_path", { "filename": filename, "project_uuid": project_uuid, "file_size": file_size }, {
      withCredentials: true
    })

    // Return a dummy observable for now
    return new Observable((observer) => {
      observer.next({
        status: "success",
        file_path: "uploads/user1/project1/" + filename
      });
    }
    );

  }

  //api/get_file_index_status POST cd_getFileIndexStatus file_uuid
  cd_getFileIndexStatus(file_uuid) {
    return this.http.post(this.BASE_URL + "api/get_file_index_status", { "file_uuid": file_uuid }, {
      withCredentials: true
    });
  }


  // api/start_indexing POST cd_startIndexing(file_path, project_uuid)
  cd_startIndexing(object_uuid, project_uuid) {
    return this.http.post(this.BASE_URL + "api/start_indexing", { "object_uuid": object_uuid, "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  cd_getObjectsForProject(project_id, share) {
    var data = {
      "project_uuid": project_id
    }
    if (share != "") {
      data["share_code"] = share;
    }
    return this.http.post(this.BASE_URL + "api/get_objects", data, {
      withCredentials: true
    });

    // Return a dummy observable for now, list of objects, with each object containing file_name, number of pages, file type, file size
    return new Observable((observer) => {
      observer.next({
        status: "success",
        objects: [
          {
            "file_name": "file1.pdf",
            "num_pages": 10,
            "file_type": "pdf",
            "file_size": "1.2MB",
            "object_id": "object1"
          },
          {
            "file_name": "file2.docx",
            "num_pages": 20,
            "file_type": "docx",
            "file_size": "2.2MB",
            "object_id": "object2"
          },
          {
            "file_name": "file3.xls",
            "num_pages": 30,
            "file_type": "xls",
            "file_size": "3.2MB",
            "object_id": "object3"
          },
          {
            "file_name": "file4.txt",
            "num_pages": 40,
            "file_type": "txt",
            "file_size": "4.2MB",
            "object_id": "object4"
          }
        ]
      });
    }
    );
  }

  cd_startFileIndexing(project_id, object_id) {
    return this.http.post(this.BASE_URL + "api/start_indexing", { "project_uuid": project_id, "object_uuid": object_id }, {
      withCredentials: true
    });
  }

  // api/get_answer POST project_uuid question
  cd_getAnswer(project_uuid, question, chathistory) {
    return this.http.post(this.BASE_URL + "api/get_answer", { "project_uuid": project_uuid, "query": question, "chathistory": chathistory }, {
      withCredentials: true
    });
  }


  // /api/add_interaction POST project_uuid  interaction_type  interaction_name
  cd_addInteraction(project_uuid, interaction_type, interaction_name) {
    return this.http.post(this.BASE_URL + "api/add_interaction", { "project_uuid": project_uuid, "interaction_type": interaction_type, "interaction_name": interaction_name }, {
      withCredentials: true
    });
  }

  // /api/get_interactions POST project_uuid
  cd_getInteractions(project_uuid, share_code) {
    var data = {
      "project_uuid": project_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    return this.http.post(this.BASE_URL + "api/get_interactions", data, {
      withCredentials: true
    });
  }

  // /api/user/interaction/update
  cd_updateInteraction(interaction) {
    return this.http.post(this.BASE_URL + "api/user/interaction/update", interaction, {
      withCredentials: true
    });
  }

  // /api/add_interaction_message POST project_uuid interaction_uuid message actor
  cd_addInteractionMessage(project_uuid, interaction_uuid, message, actor) {
    return this.http.post(this.BASE_URL + "api/add_interaction_message", { "project_uuid": project_uuid, "interaction_uuid": interaction_uuid, "message": message, "actor": actor }, {
      withCredentials: true
    });
  }

  // /api/increment_n_questions POST project_uuid
  cd_incrementNQuestions(project_uuid) {
    return this.http.post(this.BASE_URL + "api/increment_n_question", { "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  // /api/create_share POST project_uuid
  cd_createShare(project_uuid, permissions) {
    return this.http.post(this.BASE_URL + "api/create_share", { "project_uuid": project_uuid, "permissions": permissions }, {
      withCredentials: true
    });
  }

  // /api/get_share POST project_uuid
  cd_getShare(project_uuid) {
    return this.http.post(this.BASE_URL + "api/get_share", { "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  // /api/get_share_by_code POST share_code
  cd_getShareByCode(share_code) {
    return this.http.post(this.BASE_URL + "api/get_share_by_code", { "share_code": share_code }, {
      withCredentials: true
    });
  }

  // /api/update_share_permissions POST project_uuid permissions
  cd_updateSharePermissions(project_uuid, permissions) {
    return this.http.post(this.BASE_URL + "api/update_share_permissions", { "project_uuid": project_uuid, "permissions": permissions }, {
      withCredentials: true
    });
  }

  // /api/get_interaction_messages POST project_uuid interaction_uuid
  cd_getInteractionMessages(project_uuid, interaction_uuid, share_code) {
    var data = {
      "project_uuid": project_uuid,
      "interaction_uuid": interaction_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    return this.http.post(this.BASE_URL + "api/get_interaction_messages", data, {
      withCredentials: true
    });
  }

  // /api/delete_interaction POST interaction_uuid
  cd_deleteInteraction(interaction_uuid) {
    return this.http.post(this.BASE_URL + "api/delete_interaction", { "interaction_uuid": interaction_uuid }, {
      withCredentials: true
    });
  }


  // /api/project/embed/get POST embed_uuid
  cd_getEmbed(embed_uuid) {
    return this.http.post(this.BASE_URL + "api/project/embed/get", { "embed_uuid": embed_uuid }, {
      withCredentials: true
    });
  }

  // /api/project/embed/update POST embed_uuid embed
  cd_updateEmbed(embed_uuid, embed) {
    return this.http.post(this.BASE_URL + "api/project/embed/update", { "embed_uuid": embed_uuid, "embed": embed }, {
      withCredentials: true
    });
  }


  // /api/user/add/key POST key
  cd_addKey(key) {
    return this.http.post(this.BASE_URL + "api/user/add/key", { "key": key }, {
      withCredentials: true
    });
  }

  // c/api/user/delete/key POST key
  cd_deleteKeyForAccount() {
    return this.http.post(this.BASE_URL + "api/user/delete/key", {}, {
      withCredentials: true
    });
  }

  // /api/add_url_start_crawler POST url
  cd_addUrlStartCrawler(url) {
    return this.http.post(this.BASE_URL + "api/add_url_start_crawler", { "url": url }, {
      withCredentials: true
    });
  }


  async crawlandprint(url) {

  }



  // Chatdoc Methods End


  viewincrement(lp_id) {
    return this.http.post(this.BASE_URL + "api/lp/viewincrement", { "lp_id": lp_id }, {
      withCredentials: true
    });
  }


  formatEditorjs(md, topic_id) {
    return this.http.post(this.BASE_URL + "api/lp/topic/format", { 'markdown': md, 'topic_id': topic_id }, {
      withCredentials: true
    })
  }


  getEventSource(path) {
    path = path.replaceAll(' ', "-");
    path = path.replaceAll('/', '*r:leapp:slash*').replaceAll('.', '*r:leapp:dot*').replaceAll('?', '*r:leapp:question*');


    //console.log(path);


    return new EventSource(this.BASE_URL + "api/lp/topic/create/stream/" + path, {
      withCredentials: true
    });
  }


  getServerSentEvent(path) {

    return Observable.create(observer => {
      const eventSource = this.getEventSource(path);
      eventSource.onmessage = event => {
        observer.next(event);
      };
      eventSource.onerror = error => {
        observer.error(error);
      };
      eventSource.close = () => {
        observer.complete();
      }

    });
  }


  copyPlan(lpId) {
    var data = { "lp_id": lpId }
    return this.http.post(this.BASE_URL + "api/copy", data, {
      withCredentials: true
    })
  }

  askAI(sectionId, message) {
    var data = { "section_id": sectionId, "message": message }
    return this.http.post(this.BASE_URL + "api/ask_ai", data, {
      withCredentials: true
    })
  }

  updateLearningPlanImage(id, key) {

    var data = { "id": id, "key": key };
    return this.http.post(this.BASE_URL + "api/update_lp_image", data, {
      withCredentials: true
    })

  }

  update_user_image(key) {

    var data = { "key": key };
    return this.http.post(this.BASE_URL + "api/update_user_image", data, {
      withCredentials: true
    });

  }

  updateLearningPlanStatus(id, status) {

    var data = { "id": id, "status": status };
    return this.http.post(this.BASE_URL + "api/update_lp_status", data, {
      withCredentials: true
    })

  }

  // TODO: To be removed
  createLearningPlan(topic, goal, skill, job, time, focus, selected_topics) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    var data = { "topic": topic, "goal": goal, "level": skill, "job": job, "time": time, "focus": focus, "topics_to_include": selected_topics };
    return this.http.post(this.BASE_URL + "api/outline3", data, {
      withCredentials: true
    })
  }

  // 2 split api thing for the create lp
  createLearningPlanStart(topic, goal, skill, job, time, focus, selected_topics, desc, tags) {
    var data = { "topic": topic, "goal": goal, "level": skill, "job": job, "time": time, "focus": focus, "topics_to_include": selected_topics, "desc": desc, "tags": tags };
    return this.http.post(this.BASE_URL + "api/lp/create/start", data, {
      withCredentials: true
    })
  }

  createLearningPlanReStart(lp_id) {
    var data = { "lp_id": lp_id };
    return this.http.post(this.BASE_URL + "api/lp/create/restart", data, {
      withCredentials: true
    })
  }

  // api to check the lp creation status
  checkLpStatus(lp_id: string): Observable<any> {
    let data = {
      "lp_id": lp_id
    }
    return timer(0, this._pollingInterval)
      .pipe(
        takeUntil(this._stopPollingLpStatus$),
        switchMap(() => this.http.post(this.BASE_URL + "api/lp/create/status", data, {
          withCredentials: true
        }))
      )
  }


  checkLPStatus2(lp_id: string) {
    let data = {
      "lp_id": lp_id
    }
    return this.http.post(this.BASE_URL + "api/lp/create/status", data, {
      withCredentials: true
    });
  }

  createSectionUsingAIStart(section_id, section_name, section_desc, lp_name, lp_id) {
    var data = { "section_id": section_id, "section_name": section_name, "section_desc": section_desc, "lp_name": lp_name, "lp_id": lp_id };
    return this.http.post(this.BASE_URL + "api/section/generate", data, {
      withCredentials: true
    })
  }


  checkSectionCreationStatus(section_id: string): Observable<any> {
    let data = {
      "section_id": section_id
    }
    return timer(0, this._pollingSectionInterval)
      .pipe(
        takeUntil(this._stopPollingSectionCreationStatus$),
        switchMap(() => this.http.post(this.BASE_URL + "api/section/create/status", data, {
          withCredentials: true
        }))
      );
  }

  stopPollingSectionCreationStatus(): void {
    this._stopPollingSectionCreationStatus$.next();
  }

  stopPollingLpStatus(): void {
    // this._shouldPollLpStatus = false;
    this._stopPollingLpStatus$.next();
  }

  // delete lp
  deleteLp(lp_id: string) {
    let data = {
      "lp_id": lp_id
    };
    return this.http.post(this.BASE_URL + "api/lp/delete", data, {
      withCredentials: true
    });
  }


  getLearningPlans() {
    return this.http.get(this.BASE_URL + "api/get_user_lps", {
      withCredentials: true
    });
  }

  //api/lp/resources post
  getLearningPlanResources(id) {
    var data = { "id": id };
    return this.http.post(this.BASE_URL + "api/lp/resources", data, {
      withCredentials: true
    });
  }

  getLearningPlanResourcesPublic(id) {
    var data = { "id": id };
    return this.http.post(this.BASE_URL + "api/lp/resources/public", data, {
      withCredentials: true
    });
  }

  fetchLpOutline(lp_id: string) {
    var data = { "lp_id": lp_id }
    return this.http.post(`${this.BASE_URL}api/fetchlp`, data, {
      withCredentials: true
    });
  }

  fetchLpOutlinePublic(lp_id: string) {
    var data = { "lp_id": lp_id }
    return this.http.post(`${this.BASE_URL}api/fetchlppublic`, data, {
      withCredentials: true
    });
  }

  fetchSectionOutline(section_id: string) {
    var data = { "section_id": section_id }
    return this.http.post(`${this.BASE_URL}api/getsectiontree`, data, {
      withCredentials: true
    });
  }


  fetchSectionOutlinePublic(section_id: string) {
    var data = { "section_id": section_id }
    return this.http.post(`${this.BASE_URL}api/getsectiontreepublic`, data, {
      withCredentials: true
    });
  }


  // module edit/create/delete apis --- start
  createModuleForLp(lp_id: string, section_title: string, section_description: string, section_status: string, subsections) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    var data = {
      "lp_id": lp_id,
      "section_name": section_title,
      "section_desc": section_description,
      "section_status": section_status,
      "subsection_ids": subsections
    };
    return this.http.post(`${this.BASE_URL}api/section/put`, data, {
      withCredentials: true
    });
  }

  editModuleForLp(lp_id: string, section_id: string, section_title: string, section_description: string, section_status: string, subsection_ids: string[]) {
    var data = {
      "lp_id": lp_id,
      "section_id": section_id,
      "section_name": section_title,
      "section_desc": section_description,
      "section_status": section_status,
      "subsection_ids": subsection_ids
    };
    return this.http.post(`${this.BASE_URL}api/section/update`, data, {
      withCredentials: true
    });
  }
  /**
   * To reorder section ids in lp
   * @param lp_id : Learning plan id
   * @param reOrderedSectionIds 
   * @returns 
   */
  reOrderModulesForLp(lp_id: string, reOrderedSectionIds: string[]) {
    var data = {
      "lp_id": lp_id,
      "section_ids": reOrderedSectionIds
    };
    return this.http.post(`${this.BASE_URL}api/lp/reorder`, data, {
      withCredentials: true
    });
  }

  deleteModuleForLp(lp_id: string, section_id: string) {
    var data = {
      "lp_id": lp_id,
      "section_id": section_id
    };
    return this.http.post(`${this.BASE_URL}api/section/delete`, data, {
      withCredentials: true
    });
  }

  // same as above but with other params
  editModule(section: any) {
    return this.http.post(`${this.BASE_URL}api/section/update`, section, {
      withCredentials: true
    });
  }

  // subsection edit/ create / delete apis -- start

  createSubsectionForModule(section_id: string, subsection_name: string) {
    let data = {
      "section_id": section_id,
      "subsection_name": subsection_name
    }
    return this.http.post(`${this.BASE_URL}api/subsection/put`, data, {
      withCredentials: true
    })
  }

  /**
   * 1. called when creating new subsection,
   * 2. called when reordering topics in subsection
   * 3. called when changing the subsection name
   * @param subsection 
   */
  updateSubsection(subsection) {
    return this.http.post(`${this.BASE_URL}api/subsection/edit`, subsection, {
      withCredentials: true
    });
  }

  deleteSubsection(subsection) {
    return this.http.post(`${this.BASE_URL}api/subsection/delete`, subsection, { withCredentials: true });
  }

  // module edit/create/delete apis --- end

  // topic create or edit -- start

  createTopicForSubsection(subsection_id: string, topic_name: string) {
    let data = {
      "subsection_id": subsection_id,
      "topic_name": topic_name,
    }
    return this.http.post(`${this.BASE_URL}api/topic/put`, data, {
      withCredentials: true
    });
  }

  editTopic(topic) {
    return this.http.post(`${this.BASE_URL}api/topic/edit`, topic, {
      withCredentials: true
    })
  }

  // topic create or edit -- end

  // topics delete -- start

  deleteTopicForSubSection(topic_id: string, subsection_id: string) {
    var data = {
      "topic_id": topic_id,
      "subsection_id": subsection_id
    };
    return this.http.post(`${this.BASE_URL}api/topic/delete`, data, {
      withCredentials: true
    });
  }


  // topics delete -- end

  chatGPT() {
    return this.http.get(this.BASE_URL + "healthcheck", {
    });
  }


  getFile(key) {

    return Storage.get(key);

  }

  putFile(file, name) {

    return Storage.put(name, file);

  }

  getWidgetData(uuid) {
    return this.http.get<widgetsquare>(this.BASE_URL + "api/widget/data/" + uuid, {
      withCredentials: true
    });
  }

  getNotionChartData(db_id) {
    return this.http.get(this.BASE_URL + "live/notion/chart/" + db_id, {
      withCredentials: true
    });
  }

  getNotionDatabases() {
    return this.http.get(this.BASE_URL + "live/notion/databases", {
      withCredentials: true
    });
  }

  getTimeZone(lat, lng) {
    return this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + lng + "&timestamp=1331161200&key=AIzaSyCZbS6lRFbF9TMyvi_kVe4q4MNOojHL1oc");
  }


  getWidgetDataPublic(uuid, wuuid) {
    return this.http.get<widgetsquare>(this.BASE_URL + "live/widget/public/data/" + uuid + "/" + wuuid, {
      withCredentials: true
    });
  }

  getDummyWidget() {
    return this.http.get(this.BASE_URL + "live/widget/public/dummy/", {
      withCredentials: true,
    });
  }

  getDummyWidget2() {
    return this.http.get(this.BASE_URL + "live/widget/public/dummy/", {
      withCredentials: false,
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      })
    });
  }


  getRandomQuotePublic(uuid, wuuid) {
    return this.http.get(this.BASE_URL + "live/quote/public/random/" + uuid + "/" + wuuid, {
      withCredentials: true
    });
  }

  setSquareWidgetData(data, uuid) {
    return this.http.post(this.BASE_URL + "api/widget/save", { "data": data, "uuid": uuid }, {
      withCredentials: true
    });
  }


  getWeatherCityCountry(city, state, country, metrics) {
    return this.http.get(this.BASE_URL + "api/weather/" + city + "/" + state + "/" + country + "/" + metrics, {
      withCredentials: true
    });
  }

  getWeatherCityCountryForecastPublic(city, state, country, metrics, uuid, wuuid) {
    return this.http.get(this.BASE_URL + "live/weather/public/forecast/" + city + "/" + state + "/" + country + "/" + metrics + "/" + uuid + "/" + wuuid, {
      withCredentials: true
    });
  }

  getWeatherCityCountryPublic(city, state, country, metrics, uuid, wuuid) {
    return this.http.get(this.BASE_URL + "live/weather/public/" + city + "/" + state + "/" + country + "/" + metrics + "/" + uuid + "/" + wuuid, {
      withCredentials: true
    });
  }

  getWeatherCityCountryPublicLocal(city, state, country, metrics, uuid, wuuid) {
    return this.http.get(this.BASE_URL + "live/weather/public/" + city + "/" + state + "/" + country + "/" + metrics + "/" + uuid + "/" + wuuid, {
      withCredentials: true
    });
  }


  createWidget(name) {

    return this.http.post(this.BASE_URL + "api/widget/create", { "widget_name": name }, {
      withCredentials: true
    });

  }

  deleteWidget(wuuid) {

    return this.http.post(this.BASE_URL + "api/widget/delete", { "widget_uuid": wuuid }, {
      withCredentials: true
    });

  }

  getWidgetList() {
    return this.http.get(this.BASE_URL + "api/widget/list", {
      withCredentials: true
    });

  }

  checkout(pid, referral_id) {
    return this.http.post(this.BASE_URL + "api/create-checkout-session", { 'priceId': pid, 'ref_id': referral_id }, {
      withCredentials: true
    });
    // price_1JoTdOAPQVhXkJt31RH7gRNp
  }

  isUpgraded() {
    return this.http.get(this.BASE_URL + "api/isUpgraded", {
      withCredentials: true
    });
  }

  getCustomerPortal() {
    return this.http.get(this.BASE_URL + "api/customerportal", {
      withCredentials: true
    });
  }

  deleteAccount(feedback_form) {
    return this.http.post(this.BASE_URL + "api/deleteaccount", { feedback_form }, { withCredentials: true });
  }


  // create a collection. Input: Collection Name, Optional: learning plan uuid
  createCollection(name) {
    return this.http.post(this.BASE_URL + "api/collection/create", { "collection_name": name }, {
      withCredentials: true
    });
  }

  // get all collections
  getCollections() {
    return this.http.get(this.BASE_URL + "api/collection/list", {
      withCredentials: true
    });
  }

  // get collection by uuid
  getCollectionById(uuid) {
    return this.http.post(this.BASE_URL + "api/collection", { "collection_id": uuid }, {
      withCredentials: true
    });
  }

  // update collection name
  updateCollectionName(uuid, name) {
    return this.http.post(this.BASE_URL + "api/collection/update", { "collection_id": uuid, "collection_name": name }, {
      withCredentials: true
    });
  }

  // delete collection
  deleteCollection(uuid) {
    return this.http.post(this.BASE_URL + "api/collection/delete", { "collection_id": uuid }, {
      withCredentials: true
    });
  }

  // add url to collection
  addUrlToCollection(uuid, url) {
    return this.http.post(this.BASE_URL + "api/collection/add/url", { "collection_id": uuid, "url": url }, {
      withCredentials: true
    });
  }

  // get all user resources
  getUserResources() {
    return this.http.get(this.BASE_URL + "api/user/resources", {
      withCredentials: true
    });
  }

  // get resource by resource id
  getResourceById(uuid, type) {
    return this.http.post(this.BASE_URL + "api/resource", { "resource_id": uuid, 'type': type }, {
      withCredentials: true
    });
  }

  // get resources by collection id

  getResourcesByCollectionId(uuid) {
    return this.http.post(this.BASE_URL + "api/collection/resources", { "collection_id": uuid }, {
      withCredentials: true
    });
  }

  // delete resource
  deleteResource(uuid, type) {
    // alert(uuid + type);
    return this.http.post(this.BASE_URL + "api/resource/delete", { "resource_id": uuid, "type": type }, {
      withCredentials: true
    });
  }

  // add note to collection
  addNoteToCollection(uuid) {
    return this.http.post(this.BASE_URL + "api/collection/add/note", { "collection_id": uuid }, {
      withCredentials: true
    });
  }

  // update note title resource/update/title
  updateNoteTitle(uuid, title) {
    return this.http.post(this.BASE_URL + "api/resource/update/title", { "resource_id": uuid, "title": title }, {
      withCredentials: true
    });
  }

  // update collection title
  updateCollectionTitle(uuid, title) {
    return this.http.post(this.BASE_URL + "api/collection/update/title", { "collection_id": uuid, "title": title }, {
      withCredentials: true
    });
  }

  getTopicsForLP(uuid, goal, skill) {
    return this.http.post(this.BASE_URL + "api/topicstoinclude", { "topic": uuid, "goal": goal, "skill": skill }, {
      withCredentials: true
    });
  }


  // topic creation using ai

  getTopicContentStart(topic_id, tstring, use_ai) {
    return this.http.post(this.BASE_URL + "api/lp/topic/create/start", { "topic_id": topic_id, "topic": tstring, "use_ai": use_ai }, {
      withCredentials: true
    });
  }

  // api to poll and check if the topic got created or not
  checkTopicCreationStatus(topic_id: string): Observable<any> {
    let data = {
      "topic_id": topic_id
    }
    return timer(0, this._pollingTopicInterval)
      .pipe(
        takeUntil(this._stopPollingTopicCreationStatus$),
        switchMap(() => this.http.post(this.BASE_URL + "api/lp/topic/create/status", data, {
          withCredentials: true
        }))
      );
  }

  stopPollingTopicCreationStatus(): void {
    this._stopPollingTopicCreationStatus$.next();
  }


  //api/user/get POST
  getUser() {
    return this.http.get(this.BASE_URL + "api/user/get", {
      withCredentials: true
    });
  }

  //api/user/update POST
  updateUserAliasandName(alias, name) {
    return this.http.post(this.BASE_URL + "api/user/update", { "name": name, "alias": alias }, {
      withCredentials: true
    });
  }

  logout() {
    return this.http.get(this.BASE_URL + "api/logout", {
      withCredentials: true
    });
  }

  //api/topic/completed POST
  updateTopicStatus(uuid, status) {
    return this.http.post(this.BASE_URL + "api/topic/status", { "topic_id": uuid, 'status': status }, {
      withCredentials: true
    });
  }

  // update section status
  updateSectionStatus(uuid, status) {
    return this.http.post(this.BASE_URL + "api/section/status", { "section_id": uuid, 'status': status }, {
      withCredentials: true
    });
  }

  //update lp status
  updateLPStatus(uuid, status) {
    return this.http.post(this.BASE_URL + "api/lp/status", { "lp_id": uuid, 'status': status }, {
      withCredentials: true
    });
  }

  // api/lp/share POST
  shareLP(lp_id, to_id, permissions) {
    return this.http.post(this.BASE_URL + "api/lp/share", { "lp_id": lp_id, "to_id": to_id, "permissions": permissions }, {
      withCredentials: true
    });
  }

  // api/lp/share/list POST input: lp_id
  getShares(lp_id) {
    return this.http.post(this.BASE_URL + "api/lp/share/list", { "lp_id": lp_id }, {
      withCredentials: true
    });
  }

  // lp/share/delete POST input: share_id
  deleteShare(share_id) {
    return this.http.post(this.BASE_URL + "api/lp/share/delete", { "share_id": share_id }, {
      withCredentials: true
    });
  }


  //api/lp/share/update_permission POST, input share_id, permission
  updateSharePermission(share_id, permission) {
    return this.http.post(this.BASE_URL + "api/lp/share/update_permission", { "share_id": share_id, "permission": permission }, {
      withCredentials: true
    });
  }

  // api/user/get_shared_lps POST, input user_id
  getSharedLPs() {
    return this.http.post(this.BASE_URL + "api/user/get_shared_lps", {}, {
      withCredentials: true
    });
  }

  //lp/make_public POST, input lp_id
  makeLPPublic(lp_id) {
    return this.http.post(this.BASE_URL + "api/lp/make_public", { "lp_id": lp_id }, {
      withCredentials: true
    });
  }

  //lp/make_public POST, input lp_id
  makeLPPrivate(lp_id) {
    return this.http.post(this.BASE_URL + "api/lp/make_private", { "lp_id": lp_id }, {
      withCredentials: true
    });
  }


  getPublicLp(search) {
    return this.http.post(this.BASE_URL + "api/lp/get_public", { "search_string": search }, {
      withCredentials: true
    });
  }


  // lp/get_tags POST, input none
  getLPTags(search_string) {
    // alert(search_string);
    if (search_string != null) {
      return this.http.post(this.BASE_URL + "api/lp/get_tags", { "search_string": search_string }, {
        withCredentials: true
      });
    }
    return this.http.post(this.BASE_URL + "api/lp/get_tags", {}, {
      withCredentials: true
    });
  }

  //lp/get_lps_by_tag POST, input tags list
  getLPsByTag(tags, search) {
    return this.http.post(this.BASE_URL + "api/lp/get_lps_by_tag", { "tags_list": tags, 'search_string': search }, {
      withCredentials: true
    });
  }

  _pollingNotificationInterval = 15000;
  _stopPollingNotification$ = new Subject<void>();

  // user/notification/get POST
  getNotifications() {
    return timer(0, this._pollingNotificationInterval)
      .pipe(
        takeUntil(this._stopPollingNotification$),
        switchMap(() => this.http.post(this.BASE_URL + "api/user/notification/get", {}, {
          withCredentials: true
        }))
      );
  }

  updateNotification(notification: any) {
    return this.http.post(this.BASE_URL + "api/notification/update", notification, {
      withCredentials: true
    });
  }

  stopPollingNotification(): void {
    this._stopPollingNotification$.next();
  }

  updateLPColor(lp_id, color) {
    return this.http.post(this.BASE_URL + "api/lp/update_color", { "lp_id": lp_id, "color": color }, {
      withCredentials: true
    });
  }

  editLPName(lp_id, lpname) {
    return this.http.post(this.BASE_URL + "api/edit/lpname", { "lp_id": lp_id, "lp_name": lpname }, {
      withCredentials: true
    });
  }

  isAllowedCreate() {
    return this.http.post(this.BASE_URL + "api/isAllowedCreate", {}, {
      withCredentials: true
    });
  }

  hasDeclared() {
    return this.http.post(this.BASE_URL + "api/user/hasdeclared", {}, {
      withCredentials: true
    });
  }

  declare() {
    return this.http.post(this.BASE_URL + "api/user/declare", {}, {
      withCredentials: true
    });
  }


  createLPUser(lp_name, lp_desc) {
    return this.http.post(this.BASE_URL + "api/lp/create/user", { "lp_name": lp_name, "lp_desc": lp_desc }, {
      withCredentials: true
    });
  }
}

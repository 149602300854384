<div id="main-wrapper" class="w-full h-full overflow-scroll" style="background: #fff !important ;" *ngIf="user_loaded">

    <div class="content-wrapper w-full bg-white">

        <div id="content-container"
            class="flex flex-col justify-start bg-white items-start bg-whdite  px-8 pb-3 pt-10 mx-auto"
            style="max-width: 1300px;">

            <div class="w-full flex justify-start items-start mx-auto py-6 bg-white">

                <!-- <div class="w-2/12 px-16 py-0 pr-3 flex justify-end  flex-col items-end">



                   

                </div> -->
                <div class="w-5/12 pl-8 flex flex-col">

                    <div class="flex flex-col justify-start items-start mb-4 w-full">
                        <!-- <input type="file" accept="image/*" (change)="onFileSelectedImage($event)"> -->
                        <a (click)="onProfileImageClick()" class="p-0 m-0">
                            <div class="avatar mr-0">
                                <div class="w-24 rounded-full rihng ring-primary ring-offset-base-100 ring-offset-2 mask mask-hexagon"
                                    *ngIf="user_pic_url !== ''">
                                    <img src="{{user_pic_url}}" cors="anonymous" />
                                </div>
                                <div *ngIf="user_pic_url === ''"
                                    class="w-24 h-24 p-2 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-gray-200 mask mask-hexagon">
                                    <div class="flex justify-center items-center h-full">
                                        <img class=" bg-none" src="assets/default_user.svg" cors="anonymous" />
                                    </div>
                                </div>
                            </div>
                        </a>

                        <input type="file" accept="image/*" (change)="onFileSelectedImage($event)"
                            id="image-dropzone-file" class="hidden">

                        <a (click)="onProfileImageClick()" class="text-xs cursor-pointer">click to edit</a>



                    </div>

                    <!-- <form class="bg-white p-8 w-full"> -->
                    <!-- <h2 class="text-2xl font-bold mb-6">Profile</h2> -->

                    <!-- Full Name -->
                    <div class="mb-4 w-full">
                        <label class="block text-gray-700 font-semibold mb-2" for="full-name">
                            Handle </label>
                        <!-- <input class=" border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="full-name" type="text" [(ngModel)]="user.alias"> -->

                        <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
                            style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
                            <i class="fa-light fa-at text-gray-500 ml-3"></i>
                            <input type="text" [(ngModel)]="user.alias"
                                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full border-0 focus:outline-none"
                                style="padding-top:6px;padding-bottom:6px" placeholder="handle" />
                        </div>
                    </div>

                    <div class="mb-4 w-full">
                        <label class="block text-gray-700 font-semibold mb-2" for="full-name">
                            Name </label>

                        <!-- <input
                class="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="full-name" type="text" [(ngModel)]="user.given_name" placeholder="Your unique handle"> -->

                        <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
                            style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
                            <i class="fa-light fa-user text-gray-500 ml-3"></i>
                            <input type="text" [(ngModel)]="user.given_name"
                                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full focus:outline-none border-0"
                                style="padding-top:6px;padding-bottom:6px" placeholder="name" />
                        </div>
                    </div>

                    <!-- Email Address -->
                    <div class="mb-4 w-full">
                        <label class="block text-gray-700 font-semibold mb-2" for="email">
                            Email Address
                        </label>

                        <div class="p-0 w-80 border rounded-lg bg-white flex justify-start items-center"
                            style="border:solid 1px #b6b5ba !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">
                            <i class="fa-light fa-envelope text-gray-500 ml-3"></i>
                            <input type="text" [(ngModel)]="user.email" disabled
                                class="bg-white text-gray-800 rounded-lg px-2 py-2 text-sm mr-0 w-full h-full focus:outline-none border-0"
                                style="padding-top:6px;padding-bottom:6px" placeholder="email" />
                        </div>
                    </div>


                    <div class="flex items-center justify-between">
                        <button
                            class=" text-white font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            (click)="updateUserNameAlias()" *ngIf="!updatingprofile"
                            style="background: var(--app-theme-color);" type="submit">
                            Save
                        </button>
                        <button
                            class=" text-white font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            *ngIf="updatingprofile" style="background: #868687;" type="submit">
                            <i class="fa-light fa-spinner-third fa-spin mr-2"></i>
                            Saving
                        </button>
                    </div>
                    <!-- </form> -->


                </div>

                <div class="w-5/12 pl-4 flex flex-col h-full justify-start items-start">


                    <div class="bg-white p-0 w-full mt-0 py-8">
                        <h2 class="text-2xl font-bold mb-6">Billing</h2>


                    </div>

                    <div class="flex flex-col w-96 h-auto border rounded justify-center items-center pt-4 pb-8"
                        *ngIf="upgraded?.isUpgraded">

                        <img src="assets/{{upgraded?.plan}}.svg" style="width: 63px;" class="mb-2">

                        <div class="flex justify-start items-center text-xl" *ngIf="upgraded?.isUpgraded">


                            {{upgraded?.plan}} plan

                        </div>

                        <div class="mt-2">

                            <span class="font-normal text-gray-600">Renews on</span><span class="font-bold">
                                {{upgraded?.expiration |
                                date: 'mediumDate'}}</span>

                        </div>

                        <div class="mt-4 mb-3">

                            <a class="text-white p-2 rounded text-sm cursor-pointer"
                                style="background: var(--app-theme-color);" (click)="goToCustomerPortal()"
                                target="_blank">Manage
                                subscription</a>

                        </div>

                    </div>



                    <div class="flex justify-start items-center text-lg" *ngIf="!upgraded?.isUpgraded">

                        <div class="flex flex-col w-96 h-auto border rounded justify-center items-center pt-4 pb-8">

                            <img src="assets/Free.svg" style="width: 63px;" class="">
                            Basic plan

                            <div class="mt-4 mb-3">

                                <a class="text-white p-2 rounded text-sm " style="background: var(--app-theme-color);"
                                    href="/pricing">Upgrade</a>

                            </div>

                        </div>



                    </div>

                </div>

            </div>


        </div>

    </div>
</div>
<div class="px-6 py-10  w-full h-full flex flex-col bg-white justify-center items-center" style="width:100%"
    *ngIf="isLoading === true">
    <div><img src="assets/progress.gif" style="width: 100px;" /></div>
</div>
<div class="flex w-full h-screen bg-white  mt-0 p-4">
    <div class="flex w-full  mt-10 p-4 container relative mx-auto flex justify-center items-center w-full px-10"
        *ngIf="isLoading === false && upgraded">

        <!-- component -->
        <div class="w-2/4">
            <div class="bg-white p-6  md:mx-auto">
                <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
                    <path fill="currentColor"
                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                    </path>
                </svg>
                <div class="text-center">
                    <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
                    <p class="text-gray-600 my-2">Thank you for completing your secure online payment. If you have any
                        questions about your subscription, please contact us at support@knibble.ai</p>
                    <p> Happy Learning! </p>
                    <div class="py-10 text-center">
                        <a href="/main" class="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-normal py-3">
                            Go to Dashboard
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <app-footer></app-footer> -->
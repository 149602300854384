import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-tree-component',
  templateUrl: './main-tree-component.component.html',
  styleUrls: ['./main-tree-component.component.css']
})
export class MainTreeComponentComponent implements OnInit {

  selectedItem: any = null;

  constructor() { }

  ngOnInit(): void {
    this.selectedItem = this.treeData[0].children[0];
    this.treeData[0].isExpanded = true;
  }

  treeData = [
    {
      label: 'Introduction to Machine Learning',
      isExpanded: false,
      children: [
        { label: 'Machine Learning Basics' },
        { label: 'Supervised vs Unsupervised Learning' },
        { label: 'Use cases for Machine Learning' },
        { label: 'Regression vs Classification' }
      ]
    },
    {
      label: 'Supervised vs Unsupervised',
      isExpanded: false,
      children: [
        { label: 'Machine Learning Basics' },
        { label: 'Supervised vs Unsupervised Learning' },
        { label: 'Use cases for Machine Learning' },
        { label: 'Regression vs Classification' }
      ]
    },
    {
      label: 'Regression vs Classification',
      isExpanded: false,
      children: [
        { label: 'Machine Learning Basics' },
        { label: 'Supervised vs Unsupervised Learning' },
        { label: 'Use cases for Machine Learning' },
        { label: 'Regression vs Classification' }
      ]
    },
    {
      label: 'Framework for Machine Learning',
      isExpanded: false,
      children: [
        { label: 'Machine Learning Basics' },
        { label: 'Supervised vs Unsupervised Learning' },
        { label: 'Use cases for Machine Learning' },
        { label: 'Regression vs Classification' }
      ]
    }
  ];

  // Toggle the isExpanded property of the folder
  toggleFolder(folder: any) {
    folder.isExpanded = !folder.isExpanded;
  }

  // Select an item and update the selectedItem property
  selectItem(item: any) {
    this.selectedItem = item;
  }

  // Handle edit action
  editItem(item: any) {
    // //console.log('Edit item:', item);
    // Implement edit logic here
  }

  // Handle delete action
  deleteItem(item: any) {
    // //console.log('Delete item:', item);
    // Implement delete logic here
  }



}

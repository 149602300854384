<div class="modal {{modal_class_declaration}} " style=" z-index: 999999;" *ngIf="!declaration?.hasdeclared">
    <div class="modal-box w-full md:w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Consent

            </div>


        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">

            <div class="block text-gray-700 font-normal text-base mb-2 p-4 bg-gray-100 rounded" for="name">
                Please note that AI generated may not be accurate, may be biased and may not be factually correct.
            </div>

            <div class="block text-gray-700 font-normal text-base mb-4 p-4 bg-gray-100 rounded mt-2" for="name">
                Knibble.ai relies on third party APIs and services for generating content. Knibble.ai does not
                guarantee
                the
                accuracy of the AI generated content.
            </div>
            <div class="flex justify-start items-center p-0">
                <input type="checkbox" [(ngModel)]="i_agree" /><span class="ml-2">I understand and agree to <a
                        href="/termsandconditions" target="_blank" class="text-blue-700">Terms &
                        Conditions</a></span>
            </div>



            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color) !important;" *ngIf="i_agree && !click_declared" type="button"
                (click)="declare()">Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="opacity: 40%; background:var(--app-theme-color) !important;" *ngIf="i_agree && click_declared"
                type="button" disabled>
                <i class="fa-solid fa-spinner fa-spin "></i>
                Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color-light) !important;" *ngIf="!i_agree " disabled>Continue</button>

        </div>

    </div>

</div>


<div class="modal {{modal_alias}} " style=" z-index: 999999;" [hidden]="loading || (page !== 'dashboard')">
    <div class="modal-box w-full md:w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Username / Alias

            </div>


        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">

            <input type="text" class="block border border-grey-light w-full p-3 rounded mb-4"
                [(ngModel)]="upgraded?.user.alias" placeholder="Username / Alias" />
            <div class="flex p-2 text-red-500 text-sm" *ngIf="alias_exists">This username already exists. Please choose
                another.</div>
            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color) !important;"
                *ngIf="(upgraded?.user?.alias !==null && upgraded?.user?.alias !== '' && upgraded?.user?.alias !== undefined) && !updatingprofile"
                type="button" (click)="updateUserNameAlias()">Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="opacity: 40%; background:var(--app-theme-color) !important;" *ngIf="updatingprofile"
                type="button" disabled>
                <i class="fa-solid fa-spinner fa-spin "></i>
                Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color-light) !important;"
                *ngIf="(!upgraded?.user?.alias || upgraded?.user?.alias === '') && !updatingprofile"
                disabled>Continue</button>

        </div>

    </div>

</div>



<div class="w-full h-full bg-white flex flex-col overflow-y-scroll justify-center items-center"
    style="background: #ffffff !important;" [hidden]="loading">

    <div class="flex w-full justify-between items-center border-b h-28" id="chatbox-header">
        <div class="flex justify-start items-center pr-4 py-1">
            <div class="flex justify-start items-center px-8 gap-x-2   font-semibold outline:focus-none border-0" style="background: #fff;font-size: 26px;
                font-family: 'Roboto' !important;
                color: #2e2e2e;">
                Dashboard
            </div>
        </div>
    </div>

    <!-- <div class="w-full px-8 py-2 bg-yellow-100 text-sm" *ngIf="limits?.is_own_key && !limits?.is_key_generated">
        {{byok_error}} <a
            href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
            class="text-blue-700">Learn more</a>
    </div> -->

    <div class="bg-inherit h-full w-full overflow-y-scroll  mx-auto flex flex-col justify-start items-center"
        style="max-width: 900px;">

        <div class="py-4 mt-4 w-full flex justify-start items-start">

            <div class="text-4xl font-semibold flex justify-start items-center" *ngIf="upgraded !== undefined">
                <!-- hi emoticon -->
                <span class="text-4xl mr-2">👋</span>Hello, {{upgraded.user?.alias}}!
            </div>

        </div>

        <!-- [hidden]="user_projects.length !== 0 || loading" -->
        <!-- (click)="openModal()" -->

        <div class="w-auto flex flex-col justify-start p-8 bg-gray-100 mt-4 text-gray-700 rounded-xl"
            [hidden]="projects !== 0" style="max-width: 900px;">

            Start by creating a project. Projects are a way to organize your Resources (documents, urls, text) and
            interact over them using AI.

            <div class="w-full flex justify-center items-center mt-3">

                <button class="bg-none text-white py-2 px-3 my-2 rounded text-base" type="button"
                    (click)="createProject()" style="background: var(--app-theme-color) !important;">
                    + Create your first project</button>

            </div>

        </div>

        <!-- <div class="py-4 w-full">

            <div class="rounded-lg  justify-center gap-x-4 gap-y-4 items-center w-full py-3 mt-2 grid grid-cols-2">

                <button
                    class="flex w-full h-24 text-xl justify-center items-center rounded-xl border hover:bg-gray-900 hover:text-white">
                    <i class="fa-solid fa-plus-circle mr-2 text-blue-500"></i>
                    New Project
                </button>


            </div>

        </div> -->

        <div class="w-full py-4 px text-lg font-semibold text-gray-700 border-b mb-2 mt-4">
            Your Usage Stats
        </div>

        <div class=" justify-center gap-x-4 gap-y-4 items-center w-full py-3 mt-0 grid grid-cols-2">
            <div class="p-4 flex flex-col justify-start items-start  rounded-xl  border" style="background: #fff;">
                <div class="flex justify-between w-full items-center mb-3">
                    <div class="w-14 h-14 rounded-full bg-yellow-200 flex justify-center items-center">
                        <i class="fa-light fa-server text-lg text-gray-900"></i>
                    </div>
                    <div class="font-semibold text-gray-400">
                        {{a_used_storage}} / {{a_tot_storage}} MB

                    </div>

                </div>

                <div class="flex justify-between w-full items-center mb-4 font-semibold text-lg">
                    Storage
                </div>


                <progress class="progress progress-error w-full" value="{{a_used_storage}}" style="height: 1px;"
                    max="{{a_tot_storage}}"></progress>


            </div>





            <div class="p-4 flex flex-col justify-start items-start  rounded-xl  border" style="background: #fff;">

                <div class="flex justify-between w-full items-center mb-3">

                    <div class="w-14 h-14 rounded-full bg-blue-200 flex justify-center items-center">

                        <i class="fa-light fa-files text-lg text-gray-900"></i>

                    </div>

                    <div class="font-semibold text-gray-400" *ngIf="!limits?.is_own_key">

                        {{a_tot_used_files}} / {{a_tot_files}}

                    </div>

                    <div class="font-semibold text-gray-400" *ngIf="limits?.is_own_key">

                        {{a_tot_used_files}} / Unlimited

                    </div>

                </div>

                <div class="flex justify-between w-full items-center mb-4 font-semibold text-lg">
                    Files
                </div>


                <progress class="progress progress-error w-full" value="{{a_tot_used_files}}" style="height: 1px;"
                    max="{{a_tot_files}}"></progress>


            </div>


            <div class="p-4 flex flex-col justify-start items-start  rounded-xl  border" style="background: #fff;">

                <div class="flex justify-between w-full items-center mb-3">

                    <div class="w-14 h-14 rounded-full bg-green-200 flex justify-center items-center">

                        <i class="fa-light fa-messages text-lg text-gray-900"></i>

                    </div>

                    <div>

                        <div *ngIf="!limits?.is_own_key && !limits?.is_key_generated"
                            class="font-semibold text-gray-400">

                            {{a_tot_used_questions}} / {{a_tot_questions}} <span class="text-xs text-gray-500">
                                per month</span>

                        </div>

                        <div *ngIf="limits?.is_own_key || (!limits?.is_own_key && limits?.is_key_generated)"
                            class="font-semibold text-gray-400">

                            {{a_tot_used_questions}} / Unlimited <span class="text-xs text-gray-500">
                            </span>

                        </div>

                    </div>

                </div>

                <div class="flex justify-between w-full items-center mb-4 font-semibold text-lg">
                    Messages
                </div>


                <progress *ngIf="!limits?.is_own_key && !limits?.is_key_generated"
                    class="progress progress-error w-full" value="{{a_tot_used_questions}}" style="height: 1px;"
                    max="{{a_tot_questions}}"></progress>


            </div>


            <div class="p-4 flex flex-col justify-start items-start  rounded-xl  border" style="background: #fff;">

                <div class="flex justify-between w-full items-center mb-3">

                    <div class="w-14 h-14 rounded-full bg-pink-200 flex justify-center items-center">

                        <i class="fa-light fa-link-simple text-lg text-gray-900"></i>

                    </div>

                    <div>

                        <div *ngIf="!limits?.is_own_key" class="font-semibold text-gray-400">

                            {{a_tot_used_urls}} / {{a_tot_urls}} <span class="text-xs text-gray-500"></span>

                        </div>

                        <div *ngIf="limits?.is_own_key " class="font-semibold text-gray-400">

                            {{a_tot_used_urls}} / Unlimited

                        </div>

                    </div>

                </div>

                <div class="flex justify-between w-full items-center mb-4 font-semibold text-lg">
                    Urls
                </div>


                <progress *ngIf="!limits?.is_own_key" class="progress progress-error w-full" value="{{a_tot_used_urls}}"
                    style="height: 1px;" max="{{a_tot_urls}}"></progress>


            </div>

        </div>

        <div class="h-96 w-full"></div>

    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { exit } from 'process';


@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {
  sub_data: any;
  upgraded = false;
  customerportal = "";
  profile = null;
  isLoading = true;
  picture = "";
  otherreason = 'false';
  feedback_form = {};
  star_val = 0;
  recommend = 0;
  reason = "";
  otherreasontext = "";
  favorite_widget = ""
  feedback = "";
  mandatory_rating = "false"; //for the mandatory fields 
  mandatory_feedback = "false"; //for the mandatory fields 



  constructor(public dataservice: DataService, private route: Router, private authenticatorService: AuthenticatorService) { }

  ngOnInit(): void {

    this.authenticatorService.subscribe(() => {
      const { route } = this.authenticatorService;

      // this.auth.user$.subscribe(
      //   (profile) => {


      if (route === 'authenticated') {

        this.profile = this.authenticatorService.user.attributes;

        // // //console.log(this.profile);
        this.picture = this.profile.picture;
        // // //console.log(this.profile.email);
        // // //console.log(this.feedback_form);

        this.feedback_form['email'] = this.profile.email

        // // //console.log("Feedback form data:", this.feedback_form);
        this.dataservice.isUpgraded().subscribe((data) => {
          // // //console.log(data);
          this.sub_data = data;
          if (data['msg'] == 'Upgraded') {
            this.upgraded = true;
          } else {
            this.upgraded = false;
          }
          if (this.upgraded) {

            this.dataservice.getCustomerPortal().subscribe((data) => {
              // // //console.log(data);
              this.customerportal = data['session_url']
              this.isLoading = false;
            });

          } else {
            this.isLoading = false;
          }

        });



      } else {
        // this.auth.loginWithRedirect();
        this.route.navigate(["/"]);
      }

    },
    );

  }


  checkOut() {

    // this.dataservice.checkout().subscribe((data) => {
    //   // // //console.log(data);
    //   window.location = data['cc_url'];
    // })

  }

  deleteAccount() {

    // if ((this.star_val == 0) && (this.feedback == "")){
    //   this.mandatory_rating = "true";
    //   this.mandatory_feedback = "true";
    //   return;
    // }
    // if (this.star_val == 0) {
    //   this.mandatory_rating = "true";
    //   this.mandatory_feedback = "false";

    //   return;
    // }
    // if (this.feedback == "") {
    //   this.mandatory_feedback = "true";
    //   this.mandatory_rating = "false";

    //   return;
    // }

    // this.mandatory_feedback = "false";
    // this.mandatory_rating = "false";
    var x = confirm("\nAre you sure you want to delete your account? \nAll your widgets and related data will be deleted.");
    // // //console.log(x);
    if (x) {

      this.dataservice.deleteAccount(this.feedback_form).subscribe((data) => {
        // //console.log(data);
        if (data['status'] == 'deleted') {
          alert("Your account has been deleted successfully! \n\n You will be loggedout and re-directed to home page");
          this.signOut()
          // this.auth.logout();
        }
        else (data['status'] == 'cannot_delete')
        {
          if (data['reason'] == 'still_active') {
            alert("Sorry, We can't  delete your account as you still have an Active Subscription.\n\nPlease cancel your subscription and then request for account deletion after your current subscription expires.");
            this.route.navigate(["/account"])
          }
          else if (data['reason'] == 'cancelled_but_wait') {
            alert("Sorry, we can't delete your account yet as your subscription hasn't expired.\nPlease request deletion after your subscription expires on:\n" + data['period_end_date']);
            this.route.navigate(["/account"])
          }
        }

      });

    }

  }


  signOut() {
    try {
      Auth.signOut().then(() => {
        this.route.navigate(["/"]);
      });
    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  SetOtherReasonFlag() {
    if (this.otherreason == 'false') {
      this.otherreason = 'true'
    }
    else {
      this.otherreason = 'false'
      if (this.reason.includes(this.otherreasontext) && this.otherreasontext != "") {
        this.otherreasontext = this.otherreasontext + ";";
        this.reason = this.reason.replace(this.otherreasontext, "");
        this.feedback_form['delete_reason'] = this.reason;
        this.otherreasontext = ""
        // // //console.log(this.feedback_form)
      }
    }
  }

  SaveOtherReasonText() {
    this.otherreasontext = "Other reason:" + this.otherreasontext;
    this.reason = this.otherreasontext + ';' + this.reason;
    this.feedback_form['delete_reason'] = this.reason;
    // // //console.log(this.feedback_form)
  }



  SaveReasonForDeletion(value) {
    if (this.reason.includes(value)) {
      value = value + ";";
      this.reason = this.reason.replace(value, "");
    }
    else {
      this.reason = value + ';' + this.reason;
    }
    // // //console.log(this.reason)
    this.feedback_form['delete_reason'] = this.reason;
  }

  SetStar(num) {
    this.star_val = num;
    this.feedback_form['star_rating'] = this.star_val;
    // this.mandatory_rating='false' 
  }

  SaveFavoriteWidget() {
    this.feedback_form['favorite_widget'] = this.favorite_widget;
    // // //console.log(this.feedback_form)    

  }

  SaveAdditionalFeedback() {
    this.feedback_form['feedback'] = this.feedback;
    // this.mandatory_feedback='false'
    // if (this.feedback == "") {
    //   this.mandatory_feedback='true'
    // }
    // // //console.log(this.feedback_form)    

  }

  SetRecommendation(num) {
    this.recommend = num;
    this.feedback_form['recommendation'] = this.recommend
    // // //console.log(this.feedback_form)        
  }

}


<div class="flex w-full bg-gray-800 tex-white">
  <div
    class="flex relative container mx-auto justify-between items-center py-0"
  >
    <div class="px-4 py-3 text-xl font-extrabold text-white">
      <div class="flex justify-start w-full items-center">
        <a
          href="/dashboard"
          class="no-underline focus:text-white hover:text-white hover:no-underline"
        >
          <img
            src="assets/logo_light.png"
            style="width: 30px; display: inline"
            class="mr-0 -mt-1"
          />&nbsp;
          <div
            class="text-normal font-normal inline ml-4"
            style="font-size: 16px"
          >
            <i class="fa-regular fa-chevron-circle-left"></i>&nbsp;Back to
            Dashboard
          </div></a
        >
      </div>
    </div>

    <div class="flex justify-end px-4 items-end">
      <!-- <div class=" rounded-2xl w-8 h-8 border border-white  flex mr-4" *ngIf="!auth.user$ | async as user">
                <img [src]="user.picture" alt="User's profile picture" class="rounded-full inline" />
            </div> -->

      <div
        class="flex items-center border-none hover:bg-gray-700 profile-button"
        style="background: none !important"
      >
        <button
          class="btn btn-secondary flex border-none justify-start items-center bg-none hover:bg-gray-700"
          type="button"
          style="background: none !important"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="rounded-2xl w-8 h-8 flex">
            <img
              src="{{ picture }}"
              alt="User's profile picture"
              class="rounded-full inline"
              referrerpolicy="no-referrer"
              *ngIf="profile != null"
            />
          </div>
        </button>
        <div
          class="flex items-center text-white"
          aria-labelledby="dropdownMenuButton"
        >
          <a
            href="/account"
            class="w-full px-2 py-2 hover:bg-gray-700 text-white rounded"
            style="text-decoration: none !important"
            >Account</a
          >
          <button
            (click)="signOut()"
            class="px-4 py-2 hover:bg-gray-700 rounded w-full"
          >
            <i class="fa-regular fa-right-from-bracket"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="px-6 py-10 w-full h-full flex flex-col justify-center items-center"
  style="width: 100%"
  *ngIf="isLoading == true"
>
  <div><img src="assets/loadingnew.gif" /></div>
</div>
<div class="flex w-full mt-10 p-4" *ngIf="isLoading == false">
  <div
    class="container relative mx-auto flex justify-center items-center w-full px-10"
  >
    <div class="w-full flex flex-col rounded shadow">
      <div class="w-full p-4 flex flex-col justify-center items-center">
        <h1 class="text-2xl font-bold">Delete Account Request Form</h1>
        <p class="text-justify text-base font-normal py-6 px-16 m-8">
          Thanks for using widgetbox.app. We are really sorry to see you go and
          we hope you enjoyed your experience with widgetbox.app. If there's any
          thing we can do improve your experience please let us know using the
          form below.
        </p>

        <div class="w-full px-24">
          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block tracking-wide text-gray-700 text-lg font-bold mb-3"
                for="grid-password"
              >
                EMAIL:
              </label>
              <input
                class="appearance-none cursor-not-allowed block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                [(ngModel)]="feedback_form['email']"
                disabled
              />
              <p class="text-gray-600 text-xs italic">
                This is the email adress you registered with.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-lg font-bold mb-4"
                for="grid-password"
              >
                Why do you want to delete your account?
              </label>
              <div class="form-check my-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="SaveReasonForDeletion('expensive')"
                  value="It's expensive"
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  It's expensive
                </label>
              </div>
              <div class="form-check my-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="SaveReasonForDeletion('doesnt like product')"
                  value="I don't like the product/functionality"
                  id="defaultCheck2"
                />
                <label class="form-check-label" for="defaultCheck2">
                  I don't like the product/functionality
                </label>
              </div>
              <div class="form-check my-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="SaveReasonForDeletion('Dont use much')"
                  value=""
                  id="defaultCheck3"
                />
                <label class="form-check-label" for="defaultCheck3">
                  I don't use it much
                </label>
              </div>
              <div class="form-check my-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="SaveReasonForDeletion('Signed up just to checkOut')"
                  value=""
                  id="defaultCheck4"
                />
                <label class="form-check-label" for="defaultCheck4">
                  Signed up just to check it out
                </label>
              </div>
              <div class="form-check mt-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="SetOtherReasonFlag()"
                  value=""
                  id="defaultCheck5"
                />
                <label class="form-check-label" for="defaultCheck5">
                  Some other reason
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-8" *ngIf="otherreason == 'true'">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-xl font-bold mb-2"
                for="grid-password"
              >
                Other reasons:
              </label>
              <textarea
                (change)="SaveOtherReasonText()"
                [(ngModel)]="otherreasontext"
                class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
              ></textarea>
            </div>
          </div>
          <div class="flex flex-col -mx-3 mb-8">
            <div class="w-full px-3">
              <label
                class="block required tracking-wide text-gray-700 text-lg font-bold mb-4"
                for="grid-password"
                [ngClass]="{
                  'border border-danger': mandatory_rating == 'true'
                }"
              >
                How satisfied are you with the overall quality of WidgetBox?
              </label>
            </div>
            <div class="flex items-center px-3">
              <svg
                aria-hidden="true"
                class="w-10 h-10 text-gray-300"
                (click)="SetStar(1)"
                [ngClass]="{ 'text-yellow-400': star_val >= 1 }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                class="w-10 h-10 text-gray-300"
                (click)="SetStar(2)"
                [ngClass]="{ 'text-yellow-400': star_val >= 2 }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                class="w-10 h-10 text-gray-300"
                (click)="SetStar(3)"
                [ngClass]="{ 'text-yellow-400': star_val >= 3 }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                class="w-10 h-10 text-gray-300"
                (click)="SetStar(4)"
                [ngClass]="{ 'text-yellow-400': star_val >= 4 }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                class="w-10 h-10 text-gray-300"
                (click)="SetStar(5)"
                [ngClass]="{ 'text-yellow-400': star_val >= 5 }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div
              class="flex text-sm px-3"
              style="color: red"
              *ngIf="mandatory_rating == 'true'"
            >
              *This is a required field.
            </div>
          </div>
          <div class="flex flex-wrap hidden -mx-3 mb-8">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-xl font-bold mb-4"
                for="grid-password"
              >
                What is your favorite widget ?
              </label>
              <textarea
                class="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-24 resize-none"
                (change)="SaveFavoriteWidget()"
                [(ngModel)]="favorite_widget"
                id="message"
              ></textarea>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-lg font-bold mb-4"
                for="grid-password"
                [ngClass]="{
                  'border border-danger': mandatory_feedback == 'true'
                }"
              >
                Anything else you would like to share ?
              </label>
              <textarea
                class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-24 resize-none"
                id="message"
                (change)="SaveAdditionalFeedback()"
                (keyup)="SaveAdditionalFeedback()"
                [(ngModel)]="feedback"
              ></textarea>
            </div>
            <div
              class="flex text-sm px-3"
              style="color: red"
              *ngIf="mandatory_feedback == 'true'"
            >
              *This is a required field.
            </div>
          </div>
          <div class="flex flex-wrap hidden -mx-3 mb-8">
            <div class="flex flex-col w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-xl font-bold mb-4"
                for="grid-password"
              >
                Would you recommend Widgetbox to your friends?
              </label>
              <div class="flex flex-wrap">
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 1 }"
                    (click)="SetRecommendation(1)"
                  >
                    1
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 2 }"
                    (click)="SetRecommendation(2)"
                  >
                    2
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 3 }"
                    (click)="SetRecommendation(3)"
                  >
                    3
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 4 }"
                    (click)="SetRecommendation(4)"
                  >
                    4
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 5 }"
                    (click)="SetRecommendation(5)"
                  >
                    5
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 6 }"
                    (click)="SetRecommendation(6)"
                  >
                    6
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 7 }"
                    (click)="SetRecommendation(7)"
                  >
                    7
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 8 }"
                    (click)="SetRecommendation(8)"
                  >
                    8
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 9 }"
                    (click)="SetRecommendation(9)"
                  >
                    9
                  </button>
                </div>
                <div class="flex justify-start items-center pr-2">
                  <button
                    class="btn btn-outline-primary w-12 h-12 items-center hover:bg-blue-800 text-2xl"
                    [ngClass]="{ 'bg-blue-800 text-white': recommend == 10 }"
                    (click)="SetRecommendation(10)"
                  >
                    10
                  </button>
                </div>
              </div>
              <div
                class="flex justify-between py-4 text-sm"
                style="width: 600px"
              >
                <div class="flex">Less Likely</div>
                <div class="flex pr-8">Most Likely</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex text-base px-3 font-bold"
          style="color: red"
          *ngIf="mandatory_rating == 'true' || mandatory_feedback == 'true'"
        >
          **Please fill all the required fields.
        </div>
        <div class="flex justify-center py-2">
          <a
            style="text-decoration: none"
            class="btn btn-primary cursor-pointer text-white font-bold py-3 px-4 items-center justify-center flex bg-blue-800"
            (click)="deleteAccount()"
          >
            Submit
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="w-full h-full flex justify-center items-center">

    <div class="flex flex-col flex-grow justify-start items-center h-full border-r" style="max-width: 60%;">


        <div class="flex w-full justify-between items-center border-b h-24" id="chatbox-header" style="z-index:9999">

            <div class="flex justify-start items-center pr-4 py-2">
                <div class="flex justify-start items-center px-8 gap-x-2   font-semibold outline:focus-none border-0"
                    style="background: #fff;font-size:22px;">

                    Embed Chatbot

                </div>
                <!-- <i class="fa-solid fa-pen -ml-4 text-gray-400 text-xl cursor-pointer"></i> -->

            </div>





        </div>

        <div class="w-full h-full flex flex-col justify-center items-center">

            <div class="w-full h-auto shadow flex flex-col p-0  overflow-hidden {{embed?.rounded}}"
                style="max-width: 400px;"
                style="background:{{embed?.bg_color}}; border:solid 1px {{embed?.border_color}}; border-radius:{{embed?.rounded}}%">

                <div class="p-3 flex justify-between items-center" style="background: {{embed?.header_color}};">

                    <div class="flex justify-start items-start ml-1">

                        <div>
                            <img src="assets/default_logo.png" class="w-8 mt-1"
                                *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined" />
                            <img src="{{embed?.logo}}" class="w-8 mt-1"
                                *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined"
                                [ngClass]="{'rounded-full':embed?.logo_rounded}" />
                        </div>
                        <div class="flex flex-col justify-start">
                            <div class="ml-3 text-lg font-semibold" style="color: {{embed?.header_text_color}};">
                                {{embed?.title}}
                            </div>

                            <div class="font-normal ml-3 mt-1 text-sm" *ngIf="embed?.subtitle !== ''"
                                style="color: {{embed?.header_text_color}}; opacity: 70%;">
                                {{embed?.subtitle}}
                            </div>

                        </div>
                    </div>

                    <div class="px-2" style="color: {{embed?.header_text_color}};">

                        <i class="fa-light fa-eraser text-xl cursor-pointer"></i>

                    </div>


                </div>

                <div class="w-full px-4 py-2 bg-gray-100 text-sm"
                    style="color: {{embed?.sub_header_text_color}}; background: {{embed?.sub_header_bg_color}}"
                    *ngIf="embed?.sub_header_text !== '' && embed?.sub_header_text !== null && embed?.sub_header_text !== undefined">
                    {{embed?.sub_header_text}}
                </div>


                <div class="p-0">

                    <div class="p-3   border-0 w-full flex ">
                        <div class="p-3 my-2 w-fit  text-white rounded-lg"
                            style="background: {{embed?.bot_msg_bg_color}};">

                            <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                            <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                                {{embed?.default_message}}

                            </div>

                        </div>
                    </div>

                    <div *ngFor="let message of sample_chat_messages" class="px-3 border-0">

                        <div class="p-0 rounded-lg  border-0 w-full flex justify-end items-end"
                            *ngIf="message.actor === 'user'">
                            <div class="p-3 my-2 w-fit rounded-lg" style="background: {{embed?.user_msg_bg_color}};">

                                <!-- <img src="assets/user.svg" class="w-6 h-6  mr-4" /> -->

                                <div class="text-sm" style="color:{{embed?.user_msg_text_color}}">

                                    {{message.text}}

                                </div>

                            </div>
                        </div>

                        <div class="p-0   border-0 w-full flex " *ngIf="message.actor === 'bot'">
                            <div class="p-3 my-2 w-fit  text-white rounded-lg"
                                style="background: {{embed?.bot_msg_bg_color}};">



                                <div class="text-sm" style="color:{{embed?.bot_msg_text_color}}">

                                    <div class="custom-link" [innerHTML]="message.text"></div>

                                </div>

                            </div>
                        </div>







                    </div>
                    <div class="h-24 w-full">

                    </div>



                    <div class="relative flex border-0 py-0 rounded-lg">

                        <div class="flex flex-col w-full justify-center items-center  border-t shadow-sm rounded-lg"
                            style="background: {{embed?.send_bar_background}};">

                            <div class="flex w-full justify-center items-center ">

                                <div class="textarea w-full py-3 px-4 border-0 rounded shadow-none bg-inherit focus:outline-none input-box"
                                    style="color:{{embed?.send_bar_text_color}}" contenteditable="true">
                                    {{embed?.placeholder}}
                                </div>

                                <button class="ml-4 mr-4">
                                    <i class=" {{embed?.send_icon}}" style="color:{{embed?.send_icon_bg_color}}"></i>
                                </button>

                            </div>

                            <div class="w-full text-sm text-gray-300 font-thin py-2 flex justify-center items-center">
                                Powered by&nbsp;<a href="https://knibble.ai" target="_blank"
                                    class="custom-link">Knibble.ai</a>
                            </div>



                        </div>

                    </div>




                </div>

            </div>

            <div class="flex justify-end items-end mt-3 h-auto pr-8 w-full">

                <button class=" w-16 h-16 rounded-full bg-gray-800 text-2xl flex justify-center items-center"
                    style="z-index:999999; background: {{embed?.bubble_bg_color}}; margin-right:40px">
                    <i class="{{embed?.bubble_icon}}" style="color:{{embed?.bubble_text_color}}"></i>
                </button>

            </div>


        </div>

    </div>

    <div class="h-full flex flex-col inset_shadow relative" style="width:40%; max-width: 40%;">



        <div class="flex gap-x-2 border-b relative h-24">




            <button class="px-4 font- w-1/3 py-1 flex justify-center items-center" (click)="resources_tab = 'settings'"
                [ngClass]="{'font-semibold border-b-2 border-black':resources_tab === 'settings'}">
                <i class="fa-light fa-gear mr-1" style="color: #000"></i>
                <!-- <img src="assets/copy-file.svg" alt="" class="w-6 mr-1"> -->
                Settings
            </button>

            <button class="px-4 font- w-1/3 py-1 flex justify-center items-center" (click)="resources_tab = 'code'"
                [ngClass]="{'font-semibold border-b-2 border-black':resources_tab === 'code'}">
                <i class="fa-light fa-code mr-1" style="color: #000"></i>
                <!-- <img src="assets/global.svg" alt="" class="w-6 mr-1"> -->
                Code
            </button>


            <button class="px-4 font- w-1/3 py-1 flex justify-end items-center" (click)="close()">
                <i class="fa-solid fa-times-circle mr-1 text-xl" style="color: #cd4141;z-index: 99999;"></i>
            </button>





        </div>

        <div class="w-full h-full overflow-y-scroll px-2" *ngIf="resources_tab === 'settings'">

            <div class="w-full mx-auto">


                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('p-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Persona
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="p-settings">

                            <div class="px-8 py-2 leading-normal text-sm opacity-80 " *ngIf="!limits?.is_key_generated">
                                <div class="bg-yellow-100 border px-3 py-2">
                                    Persona is available when you add your own API key. <a
                                        href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                                        class="text-blue-700">Learn more</a>
                                </div>

                            </div>


                            <div class="px-8 py-2 leading-normal text-sm opacity-80 " *ngIf="limits?.is_key_generated">

                                <div class=" pt-0 pb-4">
                                    <!-- <label class="edit_bot_label text-lg font-normal mb-1">Persona</label> -->
                                    <p class="py-2 text-sm mb-2">Persona lets you define the role of the chatbot. For
                                        example,
                                        you
                                        can
                                        ask the chatbot to be "A helpful assistant" or "An expert at answering math
                                        questions".
                                        This
                                        text will be included in the prompts to the AI. Please note that this will
                                        increase your
                                        token usage.</p>
                                    <textarea class="textarea textarea-bordered w-full h-40" maxlength="500"
                                        [(ngModel)]="chatBotConfig.persona" name="persona"
                                        placeholder="Max 500 words"></textarea>

                                    <button (click)="updateChatbotConfig()" *ngIf="!persona_saved"
                                        class="px-3 py-2 bg-gray-100 rounded text-sm">
                                        Save
                                    </button>

                                    <button (click)="updateChatbotConfig()" *ngIf="persona_saved"
                                        class="px-3 py-2 bg-green-50 rounded">
                                        <i class="fas fa-check mr-1"></i>
                                        Saved
                                    </button>

                                </div>



                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <div class="w-full mx-auto">


                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('logo-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Logo Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="logo-settings">

                            <div class="px-8 py-4 leading-normal text-sm opacity-80 ">


                                <div class="w-full my-2">

                                    <div class="flex flex-col justify-start items-start ">

                                        <div class="p-2  flex justify-center items-center">

                                            <a (click)="onLogoClick()" class="p-0 m-0">
                                                <div class="avatar mr-0">
                                                    <div class=""
                                                        *ngIf="embed?.logo !== 'default' && embed?.logo_path !== '' && embed?.logo_path !== undefined">
                                                        <img src="{{embed?.logo}}" cors="anonymous"
                                                            style="min-width: 50px; max-width: 50px;" />
                                                    </div>
                                                    <div class="w-12  rinng ring-primary ring-offset-base-800 ring-offset-0"
                                                        *ngIf="embed?.logo === 'default' || embed?.logo_path === '' || embed?.logo_path === undefined">
                                                        <div class="flex justify-center items-center h-full">
                                                            <img src="assets/bot-1.svg" cors="anonymous"
                                                                style="min-width: 50px; max-width: 50px;" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>

                                            <input type="file" accept="image/*" (change)="onLogoFileSelected($event)"
                                                id="bot-logo-zone" style="display: none;">



                                        </div>
                                        <a (click)="onLogoClick()" class="text-xs cursor-pointer">Click to edit</a>




                                    </div>
                                </div>

                                <div class="w-full flex justify-start items-center gap-x-3 my-3">

                                    <label class="edit_bot_label text-base font-normal mb-1">Logo Rounded</label>
                                    <input type="checkbox" class="toggle toggle-success"
                                        [(ngModel)]="embed.logo_rounded" (change)="saveBotSettings()" />

                                </div>


                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <div class="w-full mx-auto">

                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('text-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Text Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="text-settings">

                            <div class="px-8 py-4 leading-normal text-sm opacity-80 flex flex-col gap-y-4">

                                <div class="w-full my-2">

                                    <label class="edit_bot_label text-base font-normal text-gray-600 mb-1">Bot
                                        Title</label>
                                    <input type="text"
                                        class="edit_bot_input w-full p-3 mt-1 border-0  rounded-xl bg-gray-100"
                                        [(ngModel)]="embed.title" (keyup)="saveBotSettings()" />

                                </div>

                                <div class="w-full my-2">

                                    <label class="edit_bot_label text-base font-normal mb-1">Bot Subtitle</label>
                                    <input type="text"
                                        class="edit_bot_input w-full p-3 mt-1 border-0  rounded-xl bg-gray-100"
                                        [(ngModel)]="embed.subtitle" (keyup)="saveBotSettings()" />

                                </div>

                                <div class="w-full mt-3">

                                    <label class="edit_bot_label text-base font-normal mb-1">Bot Default Message</label>
                                    <input type="text"
                                        class="edit_bot_input w-full p-3 mt-1 border-0  rounded-xl bg-gray-100"
                                        [(ngModel)]="embed.default_message" (keyup)="saveBotSettings()" />

                                </div>

                                <div class="w-full mt-3">

                                    <label class="edit_bot_label text-base font-normal mb-1">Subheader Text</label>
                                    <input type="text"
                                        class="edit_bot_input w-full p-3 mt-1 border-0  rounded-xl bg-gray-100"
                                        [(ngModel)]="embed.sub_header_text" (keyup)="saveBotSettings()" />

                                </div>

                                <div class="w-full mt-3">

                                    <label class="edit_bot_label text-base font-normal mb-1">Message Placeholder
                                        Text</label>
                                    <input type="text"
                                        class="edit_bot_input w-full p-3 mt-1 border-0  rounded-xl bg-gray-100"
                                        [(ngModel)]="embed.placeholder" (keyup)="saveBotSettings()" />

                                </div>


                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div class="w-full mx-auto">

                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('header-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Header Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="header-settings">

                            <div class="px-8 py-2 leading-normal text-sm opacity-80 flex flex-col gap-y-3">

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Header Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.header_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            value="embed?.header_color" [(ngModel)]="embed.header_color"
                                            (change)="saveBotSettings()" />

                                    </div>


                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Header Text Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.header_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.header_text_color" [(ngModel)]="embed.header_text_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Subheader Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.sub_header_bg_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.sub_header_bg_color" [(ngModel)]="embed.sub_header_bg_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Subheader Text
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.sub_header_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.sub_header_text_color"
                                            [(ngModel)]="embed.sub_header_text_color" (change)="saveBotSettings()" />

                                    </div>

                                </div>



                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div class="w-full mx-auto">
                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('chat-window-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Chat Window Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="chat-window-settings">

                            <div class="px-8 py-2 leading-normal text-sm opacity-80 flex flex-col gap-x-2">


                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Window Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.bg_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.bg_color" [(ngModel)]="embed.bg_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Window Border
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.border_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.border_color" [(ngModel)]="embed.border_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>


                                <div class="w-full mt-4 flex flex-col">


                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Window
                                        Roundedness</label>
                                    <input type="range" min="0" max="10" value="5" class="range" step="1"
                                        (change)="saveBotSettings()" [(ngModel)]="embed.rounded" />

                                </div>


                            </div>

                        </div>
                    </div>

                </div>

            </div>



            <div class="w-full mx-auto">

                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('message-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Messages Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="message-settings">

                            <div class="px-8 py-2 leading-normal text-sm opacity-80 flex flex-col gap-x-2">

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Send Bar Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.send_bar_background" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.send_bar_background" [(ngModel)]="embed.send_bar_background"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Send Bar Text Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.send_bar_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.send_bar_text_color" [(ngModel)]="embed.send_bar_text_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Bot Message Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.bot_msg_bg_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.bot_msg_bg_color" [(ngModel)]="embed.bot_msg_bg_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Bot Message Text
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.bot_msg_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.bot_msg_text_color" [(ngModel)]="embed.bot_msg_text_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">User Message Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.user_msg_bg_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.user_msg_bg_color" [(ngModel)]="embed.user_msg_bg_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">User Message Text
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.user_msg_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.user_msg_text_color" [(ngModel)]="embed.user_msg_text_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Send Icon Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.send_icon_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.send_icon_color" [(ngModel)]="embed.send_icon_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>




                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Send Button Icon </label>

                                    <div class="flex gap-x-4">

                                        <i class="fa-solid fa-paper-plane text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-paper-plane', 'text-primary': embed?.send_icon === 'fa-solid fa-paper-plane'}"
                                            (click)="embed.send_icon = 'fa-solid fa-paper-plane'; saveBotSettings()"></i>

                                        <!-- Few other Icon Options -->

                                        <i class="fa-light fa-paper-plane text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-paper-plane', 'text-primary': embed?.send_icon === 'fa-light fa-paper-plane'}"
                                            (click)="embed.send_icon = 'fa-light fa-paper-plane'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-paper-plane-top text-lg cursor-pointer ml-3"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-paper-plane-top', 'text-primary': embed?.send_icon === 'fa-solid fa-paper-plane-top'}"
                                            (click)="embed.send_icon = 'fa-solid fa-paper-plane-top'; saveBotSettings()"></i>


                                        <i class="fa-light fa-paper-plane-top text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-paper-plane-top', 'text-primary': embed?.send_icon === 'fa-light fa-paper-plane-top'}"
                                            (click)="embed.send_icon = 'fa-light fa-paper-plane-top'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-message-arrow-up text-lg ml-3 cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message-arrow-up', 'text-primary': embed?.send_icon === 'fa-solid fa-message-arrow-up'}"
                                            (click)="embed.send_icon = 'fa-solid fa-message-arrow-up'; saveBotSettings()"></i>


                                        <i class="fa-light fa-message-arrow-up text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message-arrow-up', 'text-primary': embed?.send_icon === 'fa-light fa-message-arrow-up'}"
                                            (click)="embed.send_icon = 'fa-light fa-message-arrow-up'; saveBotSettings()"></i>


                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <div class="w-full mx-auto">
                <div accordion>
                    <div accordion-section class="mb-4 rounded-t-1">

                        <button section-trigger data-te-collapse-init data-te-target="#hello"
                            (click)="toggle('bubble-settings')"
                            class="relative flex items-center w-full p-4 font-semibold text-left transition-all border-b border-solid cursor-pointer border-slate-100 ease-soft-in text-slate-700 rounded-t-1"
                            aria-expanded="true">
                            Bubble Button Settings
                            <i section-open-icon
                                class="absolute right-0 hidden pt-1 mr-4 leading-tight fa fa-plus text-xs"></i>
                            <i section-close-icon
                                class="absolute right-0 pt-1 mr-4 leading-tight fa fa-minus text-xs"></i>
                        </button>

                        <div section-content class="overflow-hidden transition-all ease-soft-in-out duration-350 hidden"
                            data-te-collapse-item id="bubble-settings">

                            <div class="px-8 py-2 leading-normal text-sm opacity-80 flex flex-col gap-y-3">

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Background
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.bubble_bg_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.bubble_bg_color" [(ngModel)]="embed.bubble_bg_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>

                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Icon
                                        Color</label>

                                    <div class="flex justify-between items-center rounded-xl bg-gray-100 p-2">

                                        <input type="text" class=" mt-1 mr-2 border-0 bg-inherit focus:outline-none"
                                            [(ngModel)]="embed.bubble_text_color" (keyup)="saveBotSettings()" />

                                        <input type="color" class=" mt-1 w-8 h-8 rounded-full border-0 overflow-hidden"
                                            [value]="embed?.bubble_text_color" [(ngModel)]="embed.bubble_text_color"
                                            (change)="saveBotSettings()" />

                                    </div>

                                </div>


                                <!-- <div class="w-full mt-3 flex flex-col">
    
                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Background
                                        Color</label>
                                    <input type="color" class=" mt-1" value="embed.bubble_bg_color"
                                        [(ngModel)]="embed.bubble_bg_color" (change)="saveBotSettings()" />
    
                                </div>
    
                                <div class="w-full mt-3 flex flex-col">
    
                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Icon Color</label>
                                    <input type="color" class=" mt-1" value="embed.bubble_text_color"
                                        [(ngModel)]="embed.bubble_text_color" (change)="saveBotSettings()" />
    
                                </div> -->


                                <div class="w-full mt-3 flex flex-col">

                                    <label class="edit_bot_label text-base font-normal mb-1">Chat Bubble Icon </label>

                                    <div class="flex gap-x-4">

                                        <i class="fa-light fa-message text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message', 'text-primary': embed?.send_icon === 'fa-light fa-message'}"
                                            (click)="embed.bubble_icon = 'fa-light fa-message'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-message text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message', 'text-primary': embed?.send_icon === 'fa-solid fa-message'}"
                                            (click)="embed.bubble_icon = 'fa-solid fa-message'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-messages text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-messages', 'text-primary': embed?.send_icon === 'fa-solid fa-messages'}"
                                            (click)="embed.bubble_icon = 'fa-solid fa-messages'; saveBotSettings()"></i>

                                        <i class="fa-light fa-messages text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-messages', 'text-primary': embed?.send_icon === 'fa-light fa-messages'}"
                                            (click)="embed.bubble_icon = 'fa-light fa-messages'; saveBotSettings()"></i>

                                        <i class="fa-light fa-message-quote text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-message-quote', 'text-primary': embed?.send_icon === 'fa-light fa-message-quote'}"
                                            (click)="embed.bubble_icon = 'fa-light fa-message-quote'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-message-quote text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-message-quote', 'text-primary': embed?.send_icon === 'fa-solid fa-message-quote'}"
                                            (click)="embed.bubble_icon = 'fa-solid fa-message-quote'; saveBotSettings()"></i>

                                        <i class="fa-light fa-circle-info text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-light fa-circle-info', 'text-primary': embed?.send_icon === 'fa-light fa-circle-info'}"
                                            (click)="embed.bubble_icon = 'fa-light fa-circle-info'; saveBotSettings()"></i>

                                        <i class="fa-solid fa-circle-info text-lg cursor-pointer"
                                            [ngClass]="{'text-gray-400': embed?.send_icon !== 'fa-solid fa-circle-info', 'text-primary': embed?.send_icon === 'fa-solid fa-circle-info'}"
                                            (click)="embed.bubble_icon = 'fa-solid fa-circle-info'; saveBotSettings()"></i>

                                    </div>

                                    <div class="w-full h-40">

                                    </div>


                                </div>


                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>

        <div class="w-full h-full overflow-y-scroll px-2" *ngIf="resources_tab === 'code'">

            <div>

                <div class="p-4 mt-2 flex flex-col justify-start items-start w-full">


                    <label class="font-semibold">Embed as a popup</label>

                    <div class="text-sm text-gray-800 mt-3  w-full">Copy and paste the code below into your
                        website
                    </div>


                    <code
                        class="mb-4 p-4 rounded text-sm border mt-3 w-full flex max-w-min overflow-scroll whitespace-break-spaces">

                    &lt;knibble-bot [chatbot_id]="{{embed_uuid}}" #chatbot&gt;&lt;/knibble-bot&gt;
                    <br>
                    &lt;script src="https://s3.amazonaws.com/knibble.ai.assets/package.js" type="module"&gt;&lt;/script&gt;

                    </code>


                    <button (click)="copyTextToClipboard()" class="py-2 px-4 text-xs text-white rounded"
                        style="background: var(--app-theme-color);">
                        <i class="fa-regular mr-1 fa-copy"></i>
                        <span *ngIf="isCopied">Copied!</span>
                        <span *ngIf="!isCopied">Copy</span>

                    </button>

                </div>


                <div class="p-4 mt-2 flex flex-col justify-start items-start w-full">


                    <label class="font-semibold">Embed as a Iframe</label>

                    <div class="text-sm text-gray-800 mt-3  w-full">Copy and paste the code below into your
                        website
                    </div>


                    <code
                        class="mb-4 p-4 rounded text-sm border mt-3 w-full flex max-w-min overflow-scroll whitespace-break-spaces">



                    &lt;iframe src="https://knibble.ai/embed/bot/{{embed_uuid}}
                    " frameborder="0"
                    style="width:100%; height:100%" style="border:0 !important;" height="100%" #iframeRef&gt;
                
                &lt;/iframe&gt;

                    </code>


                    <button (click)="copyTextToClipboard()" class="py-2 px-4 text-xs text-white rounded"
                        style="background: var(--app-theme-color);">
                        <i class="fa-regular mr-1 fa-copy"></i>
                        <span *ngIf="isCopied">Copied!</span>
                        <span *ngIf="!isCopied">Copy</span>

                    </button>

                </div>
            </div>

        </div>

    </div>

</div>
<div id="main-wrapper" class="w-full h-screen" style="background: #f9fafb !important ;">

    <div class="info-wrapper w-full bg-gray">
        <!-- <div class="grid grid-cols-2 gap-8 w-full mt-3 h-screen">
            <div class="flex flex-col w-full h-screen bg-gray-100 rounded-xl px-8 py-4">first</div>
            <div class="flex flex-col w-full h-screen bg-gray-100 rounded-xl px-8 py-4">second</div>
        </div> -->
        <div class="flex justify-between items-center mt-0 w-full">
            
            <div style="width: 320px !important; background: #fff"
                class="ml-4 mr-2 px-4 py-4 rounded-lg shadow-md h-screen border-black">
                <div
                    class="text-md text-gray-500 font-semibold pt-3mt-2 mb-3 rounded-t-xl w-full flex justify-between items-center">
                    <div>My Projects</div>
                    <button class="bg-inherit border-none focus:outline-none" style="cursor: pointer;">
                        <i class="fa-regular fa-plus mr-1"></i>
                    </button>
                </div>
                <ul class="rounded-xl w-full">
                    <li class="flex py-2 justify-start item-start w-full border-0 pt-0"
                        *ngFor="let project of projects">
                        <div class="flex truncate justify-start items-start w-full">

                            <div class="pl-2  text-base flex font-semibold cursor-pointer w-full truncate justify-between items-center text-gray-800"
                                style="font-weight: 500;">
                                <div class="flex justify-start truncate">

                                    <div class="w-4 mr-2 flex justify-center items-center">

                                        <i class="fa-regular fa-grip-vertical text-gray-500 fa-fw text-xs"></i>

                                    </div>

                                    <div class="truncate pr-3 justify-start">
                                        <div class="truncate">{{project.name}}
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <button class="bg-none focus:outline-none hover:bg-gray-200 order-0 py-0 px-2"
                                        style="margin-top: -4px; margin-left:4px">
                                        <i class="fa-solid fa-times text-red-500 fa-fw text-xs"></i>
                                    </button>
                                </div>

                            </div>

                        </div>
                    </li>
                </ul>
            </div>
            

            <div class=" w-full flex justify-between items-center mt-1" id="button-bar">

                    <!-- <div></div> -->

                    <div class="flex  gap-x-10 justify-start items-center">

                        <button class=" bg-inherit py-3 font-semibold"
                            [ngClass]="{ ' border-b-4 border-blue-800 text-blue-800': current_tab === 'Chat', 'border-0 text-gray-500': current_tab !== 'Chat' }"
                            (click)="setCurrentTab('Chat')" style="font-size:17px; font-weight: 700px;">
                            <i class="fa-regular fa-list-timeline mr-1"></i> Chat
                        </button>

                        <button class="bg-inherit py-3 font-semibold "
                            [ngClass]="{ ' border-b-4 border-blue-800 text-blue-800': current_tab === 'Preview' , 'border-0 text-gray-500': current_tab !== 'Preview' }"
                            (click)="setCurrentTab('Preview')" style="font-size:17px; font-weight: 600px;">
                            <i class="fa-regular fa-link-simple mr-1"></i> Preview
                        </button>

                        
                    </div>



                    <div class="flex justify-center items-center">

                    </div>

                </div>


                <div class="content-wrapper w-full mt-4" *ngIf="current_tab === 'Chat' ">


                </div>

                <div class="content-wrapper w-full mt-4" *ngIf="current_tab === 'Preview'">
                    <div style="background: #fff;"
                        class="w-full h-screen px-4 py-4 mr-4 ml-2 rounded-lg shadow-md border-black">

                        <pdf-viewer [src]="pdfsrc" [render-text]="true" [original-size]="false"
                            style="width: 400px; height: 500px"></pdf-viewer>

                    </div>

                </div>

            
        </div>
    </div>

</div>

<app-footer></app-footer>
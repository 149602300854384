<div class="modal {{modal_state}}" style=" z-index: 999999;" id="upload_modal">


    <div class=" w-full md:w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important" [hidden]="!valid">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Upload File

            </div>
            <div class="flex p-0 justify-end items-center" style="cursor: pointer;" (click)="close_modal()">
                X
            </div>


        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">



            <div class="flex items-center justify-center w-full" *ngIf="pdfUrl ==='' || pdfUrl === null">
                <label for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

                    <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="!fileToUpload">
                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                            </path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                to upload</span></p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">PDF or Text files</p>

                        <input id="dropzone-file" type="file" class="hidden" (change)="handleFileInput($event)" />
                    </div>


                    <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="fileToUpload">
                        <div class="w-full flex flex-col justify-center items-center">

                            <img src="assets/pdf-file-3.svg" class="w-12 h-12 mb-3"
                                *ngIf="uploading_file_extension ==='pdf'" />
                            <img src="assets/txt.svg" class="w-12 h-12 mb-3"
                                *ngIf="uploading_file_extension === 'txt'" />

                            <span class="font-semibold text-base mt-3">{{fileToUpload.name}}</span>

                            <span class="text-xs text-gray-500 dark:text-gray-400 mt-1">{{fileToUpload.size}}
                                bytes</span>


                            <!-- remove -->
                            <div class="flex justify-center items-center mt-3">
                                <button class="text-blue-500 font-normal text-sm p-0 rounded " type="button"
                                    *ngIf="!upload_index_progress" (click)="removeUpload($event)">Remove</button>
                            </div>

                            <div class="p-2 flex justify-center items-center text-sm text-red-500"
                                [hidden]="limits.max_file_size_mb > fileToUpload.size/1024/1024">
                                Max file size limit for your plan is {{limits.max_file_size_mb}} MB.
                                Please upgrade your plan to upload larger files.
                            </div>

                            <button
                                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded "
                                *ngIf="!upload_index_progress"
                                style="background: var(--app-theme-color); color:#fff !important;" type="button"
                                (click)="uploadPdf()" [hidden]="limits.max_file_size_mb <= fileToUpload.size/1024/1024">
                                <i class="fa fa-upload mr-1"></i>
                                Upload</button>
                            <button
                                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded "
                                *ngIf="!upload_index_progress"
                                style="background: var(--app-theme-color); color:#fff !important;" type="button"
                                (click)="redirectToPricingPage()"
                                [hidden]="limits.max_file_size_mb > fileToUpload.size/1024/1024">
                                <i class="fa fa-rocket mr-1"></i>
                                Upgrade</button>

                            <button
                                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded flex"
                                *ngIf="upload_index_progress"
                                style="background: var(--app-theme-color-light); color:#000 !important;" type="button">
                                <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                    </path>
                                </svg>
                                {{upload_progress_text}}</button>
                        </div>
                    </div>

                </label>
            </div>

            <br>
            <div class="flex items justify-center w-full" style="text-align: center;"
                *ngIf="!fileToUpload && (pdfUrl ==='' || pdfUrl === null)">
                OR
            </div>
            <br>
            <label class="block text-gray-700 font-semibold text-base mb-2" for="name" *ngIf="!fileToUpload">
                Add file Url
            </label>

            <input [(ngModel)]="pdfUrl"
                class="w-full border text-sm font-normal border-gray-400 p-3 mb-4 mt-1 rounded-lg" *ngIf="!fileToUpload"
                type="text" name="name" id="name" placeholder="https://example.com/example.pdf" required>

            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 mx-0 text-sm py-2 px-4 mb-4 rounded "
                *ngIf="!upload_index_progress && !fileToUpload && pdfUrl !=='' && pdfUrl !== null"
                style="background: var(--app-theme-color); color:#fff !important;" type="button"
                (click)="uploadPdf()">Add File</button>

            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-2 mx-0 text-sm py-2 px-4 mb-4 rounded flex"
                *ngIf="upload_index_progress && !fileToUpload"
                style="background: var(--app-theme-color-light); color:#000 !important;" type="button">
                <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                    </circle>
                    <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                </svg>
                {{upload_progress_text}}</button>



        </div>



    </div>

    <div class=" w-full md:w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important" [hidden]="valid">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Upload File

            </div>
            <div class="flex p-0 justify-end items-center" style="cursor: pointer;" (click)="close_modal()">
                X
            </div>


        </div>

        <div class="p-4 px-8 mt-0 flex flex-col justify-center items-center">

            <div class="alert alert-danger rounded-sm" role="alert">
                {{error_message}}
            </div>

        </div>

    </div>

</div>
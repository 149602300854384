import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



//Pages
import { AuthComponent } from './components/pages/auth/auth.component';
import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './components/pages/main/main.component';

import { AuthcatcherComponent } from './components/plugins/authcatcher/authcatcher.component';
import { Authcatcher2Component } from './components/plugins/authcatcher2/authcatcher2.component';
import { IEditorComponent } from './components/plugins/ieditor/ieditor.component';
import { HttpClientModule } from '@angular/common/http';
import { SuccessComponent } from './components/pages/success/success.component';
import { AccountComponent } from './components/pages/account/account.component';
import { NewauthComponent } from './components/newauth/newauth.component';
import { GetStartedComponent } from './components/pages/get-started/get-started.component';
import { DeleteAccountComponent } from './components/pages/delete-account/delete-account.component';
// import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/pages/terms/privacy-policy/privacy-policy.component';
import { TermsComponent } from './components/pages/terms/terms/terms.component';
import { SpaComponent } from './components/try/spa/spa.component';
import { ProjectComponent } from './project/project.component';
import { ProjectMainComponent } from './components/pages/project-main/project-main.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { TryitoutComponent } from './tryitout/tryitout.component';
import { ChatembedComponent } from './components/app-ui/main/chatembed/chatembed.component';



const routes: Routes = [


  //Pages
  { path: 'login', component: AuthComponent },
  { path: 'auth', component: AuthcatcherComponent },
  { path: 'auth2', component: Authcatcher2Component },
  { path: '', component: HomeComponent },
  { path: 'subscription/success', component: SuccessComponent },
  { path: 'account', component: AccountComponent },
  { path: 'deleteaccount', component: DeleteAccountComponent },
  { path: 'newauth', component: NewauthComponent },
  { path: 'newauth/:state', component: NewauthComponent },
  { path: 'get-started', component: GetStartedComponent },
  { path: 'editor', component: IEditorComponent },
  // { path: 'dashboard', component: DashboardComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'termsandconditions', component: TermsComponent },
  { path: 'try', component: SpaComponent },
  { path: 'main', component: ProjectMainComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'project-new', component: ProjectMainComponent },
  { path: 'project-new/:ppid', component: ProjectMainComponent },
  { path: 'main/:ppid', component: ProjectMainComponent },
  { path: 'try-it-out', component: TryitoutComponent },
  { path: 'project/:ppid', component: ProjectComponent },
  { path: 'project/account', component: ProjectComponent },
  { path: 'project/shared/:share_code', component: ProjectComponent },
  { path: 'chat/:chat_id', component: ProjectComponent },
  { path: 'embed/bot/:embed_uuid', component: ChatembedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

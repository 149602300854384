import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Auth } from 'aws-amplify';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

declare var rewardful: any;
declare var Rewardful: any;

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  features = [
    "Questions per month",
    "Total Files",
    "Total Storage",
    "Total Urls",
    "Number of Chatbots",
    "Max file size",
    "All Chatbot customizations",
    "Remove Knibble.ai branding",
    "Embed on multiple websites"
  ];

  features_values = {
    "max_messages_per_month": "Questions per month",
    "max_files": "Total Files",
    "max_urls": "Total Urls",
    "max_total_storage_mb": "Total Storage",
    "max_file_size_mb": "Max file size",
    "max_projects": "Number of Chatbots",
    "price_monthly": "Price per month",
    "price_yealy": "Price per year"
  }

  feature_keys = [
    "max_messages_per_month",
    "max_files",
    "max_urls",
    "max_total_storage_mb",
    "max_file_size_mb",
    "max_projects",
    "price_monthly",
    "price_yealy"
  ]

  plans = []

  yearly = false;
  user: any;
  user_logged_in = false;
  user_upgrade = false;
  plan = "";
  all_plans: { [key: string]: any }[] = [];
  non_key_plans: { [key: string]: any }[] = [];
  key_plans: { [key: string]: any }[] = [];
  own_key = false;

  referral_id = "unknown-referral-id";


  constructor(private dataService: DataService, private route: Router) { }

  toggleOwnKey() {
    this.own_key = !this.own_key;
  }

  isUpgraded() {
    this.dataService.isUpgraded().subscribe((data) => {

      if (data['isUpgraded'] == true) {
        this.user_upgrade = true;
        this.plan = data['plan'];
      } else {
        this.user_upgrade = false;
      }
    }
    );
  }

  logout() {
    // alert("logout");
    try {

      this.dataService.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  plan_map = {};

  ngOnInit(): void {

    rewardful('ready', () => {
      if (Rewardful.referral) {
        console.log('Rewardful--Referral:', Rewardful.referral);
        this.referral_id = Rewardful.referral;
      }
      else {
        console.log('NONE:', Rewardful.referral);
      }
      console.log(Rewardful.referral)
    });

    this.dataService.cd_getPlans().subscribe((data) => {
      console.log(data);
      let k = [];
      k = data['plans'];
      this.all_plans = k.sort((a, b) => {
        if (parseFloat(a.price_monthly) < parseFloat(b.price_monthly)) {
          return -1;
        }
        else {
          return 1
        }
      });;

      this.all_plans.forEach(t => {
        console.log(t);
        this.plan_map[t.plan_uuid] = t;
        if (t.plan_uuid !== "BYOK") {
          this.plans.push(t.plan_uuid);
        }

      });
      console.log(this.plan_map);

      this.non_key_plans = this.all_plans.filter(t => (!t.is_own_key || t.is_own_key == null || t.is_own_key == undefined));

      this.key_plans = this.all_plans.filter(t => t.is_own_key);


    });



    this.dataService.getUser().subscribe((user) => {
      this.user_logged_in = true;
      this.user = user;
      this.isUpgraded();

    },
      (error) => {
        //console.log(error);
        this.user_logged_in = false;

      }
    );



  }

  createCheckoutSession(pid) {

    console.log("createCheckoutSession", pid, this.referral_id);

    this.dataService.checkout(pid, this.referral_id).subscribe((data: any[]) => {
      // //console.log(data);

      window.location = data['cc_url'];

    }
    );

  }

}

<!-- Ideally this should be in app-header -->
<!-- <div class="w-full h-screen" style="background: #ffffff !important; z-index:-999999"> -->

<app-toast></app-toast>
<input type="checkbox" id="my-modal-5" class="modal-toggle" />
<div class="modal {{modal_class_declaration}} " style=" z-index: 999999;" *ngIf="!declaration?.hasdeclared">
    <div class="modal-box w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Consent

            </div>


        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">

            <div class="block text-gray-700 font-normal text-base mb-2 p-4 bg-gray-100 rounded" for="name">
                Please note that AI generated may not be accurate, may be biased and may not be factually correct.
            </div>

            <div class="block text-gray-700 font-normal text-base mb-4 p-4 bg-gray-100 rounded mt-2" for="name">
                Knibble.ai relies on third party APIs and services for generating content. Knibble.ai does not
                guarantee
                the
                accuracy of the AI generated content.
            </div>
            <div class="flex justify-start items-center p-0">
                <input type="checkbox" [(ngModel)]="i_agree" /><span class="ml-2">I understand and agree to <a
                        href="/termsandconditions" target="_blank" class="text-blue-700">Terms &
                        Conditions</a></span>
            </div>



            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color) !important;" *ngIf="i_agree && !click_declared" type="button"
                (click)="declare()">Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="opacity: 40%; background:var(--app-theme-color) !important;" *ngIf="i_agree && click_declared"
                type="button" disabled>
                <i class="fa-solid fa-spinner fa-spin "></i>
                Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                style="background:var(--app-theme-color-light) !important;" *ngIf="!i_agree " disabled>Continue</button>

        </div>

    </div>

</div>

<div class="bg-gray-900 flex justify-between items-center">




    <div class="flex justify-start gap-x-4 text-white items-center w-1/3">

        <a href="/main" class="flex justify-center text-white items-center">

            <!-- <i class="fa-brands fa-dochub ml-4 text-xl mr-1" style="color:#ffe1d0"></i> -->
            <div style="font-size: 21px;
                        font-family: 'Poppins';
                        font-weight: bold;     
                        color: #e2e0e0;" class=" ml-4">Knibble<span class="text-gray-400"
                    style="color:var(--app-theme-color)">.</span>
                <!-- <span
                                class="text-sm ml-2 text-gray-300 -mt-3">Beta</span> -->
            </div>

        </a>

        <i class="fa-solid fa-ellipsis-vertical text-xs text-gray-700" *ngIf="user_logged_in"></i>

        <span class="text-white font-medium">
            Dashboard
        </span>

    </div>


    <div class="flex justify-end text-white items-center px-4">

        <a (click)="openKeyModal()"
            class="text-white bg-inherit hover:bg-gray-800 px-2 py-3 ml-4 text-sm cursor-pointer"
            *ngIf="!loading_limits && limits?.is_own_key && !limits?.is_key_generated"
            style=" font-weight: 600px;text-decoration: none; border:solid 0px var(--app-theme-color);background: #f35858;;">
            <i class="fa-regular fa-key mr-1" style="color: var(--app-theme-color-light)"></i>
            Add OpenAI API Key
        </a>

        <a (click)="openKeyModal()"
            class="text-white bg-inherit hover:bg-gray-800 px-2 py-3 ml-4 text-sm cursor-pointer"
            *ngIf="!loading_limits && limits?.is_own_key && limits?.is_key_generated"
            style=" font-weight: 600px;text-decoration: none; border:solid 0px var(--app-theme-color);background:inherit;;">
            <i class="fa-solid text-xs fa-circle mr-1 text-green-400"></i>
            API Key
        </a>



        <div *ngIf="user_logged_in" class="mx-8 flex">

            <div *ngIf="user_upgrade" class="text-sm flex justify-center items-center">
                <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                {{plan}} plan
            </div>

            <div *ngIf="!user_upgrade" class="text-sm flex justify-center items-center">
                <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                Basic plan
            </div>

            <!-- <div  class="ml-4"> -->
            <a href="/pricing" class="text-white bg-inherit hover:bg-gray-800 px-2 py-3 ml-4 text-sm"
                *ngIf="!user_upgrade"
                style=" font-weight: 600px;text-decoration: none; border:solid 0px var(--app-theme-color);background: #222c41;;">
                <i class="fa-regular fa-rocket mr-1" style="color: var(--app-theme-color-light)"></i>
                Upgrade
            </a>
            <!-- </div> -->

        </div>

        <div class="dropdown px-0 dropleft" *ngIf="user_logged_in">
            <button class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <div class="avatar mr-0">

                    <div class="w-8 h-8 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-inherit"
                        style="border:solid 0px var(--app-theme-color) !important; background:#233047;">
                        <div class="flex justify-center items-center h-full">
                            <div class="text-base text-white capitalize font-semibold">
                                {{user?.user_uuid[0]}}
                            </div>
                        </div>
                    </div>
                </div>



                <i _ngcontent-ilr-c103="" class="fa-solid text-base text-gray-100 fa-caret-down ml-1"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item font-semibold py-1 border-b">{{user?.user_uuid}}</a>
                <a class="dropdown-item" href="/account">Account</a>

                <a class="dropdown-item" href="https://tally.so/r/mRd6jJ">Contact Us</a>
                <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                <a class="dropdown-item text-gray-500" href="#">Delete Account</a>
            </div>
        </div>


    </div>
</div>

<div class="flex justify-start items-center px-4 py-2 text-sm bg-red-50"
    *ngIf="!loading_limits && limits?.is_own_key && !limits.is_key_generated">
    You are subscribed to {{limits?.plan_uuid}}. Please add your OpenAI API key to continue using Knibble. Your chatbots
    and knowledge assistant may not work without the API key.
</div>

<div class="w-full h-full bg-white pt-6" style="background: #ffffff !important;">

    <div class="bg-inherit h-full  mx-auto flex flex-col justify-start items-center" style="max-width: 1000px;">
        <div class=" flex justify-center gap-x-4 items-center w-full py-3 ">


            <div class="p-3 w-1/4 flex flex-col  rounded-lg" style="background: #f6f3ff;">

                <div class="text-base text-gray-700 py-2">
                    Storage
                </div>

                <progress class="progress progress-error w-48" value="{{a_used_storage}}" style="height: 4px;"
                    max="{{a_tot_storage}}"></progress>

                <div class="flex justify-between items-center">

                    <div class="mt-3 text-xs text-gray-500">

                        Used {{a_used_storage}} MB of {{a_tot_storage}} MB

                    </div>

                    <div>

                    </div>

                </div>

            </div>

            <div class="p-3 w-1/4  flex flex-col  rounded-lg" style="background: #e9ffed;">

                <div class="text-base text-gray-700 py-2">
                    Files
                </div>

                <progress class="progress progress-error w-48" value="{{a_tot_used_files}}" style="height: 4px;"
                    *ngIf="!limits?.is_own_key" max="{{a_tot_files}}"></progress>

                <div class="flex justify-between items-center">

                    <div class="mt-3 text-xs text-gray-500" *ngIf="!limits?.is_own_key">

                        {{a_tot_used_files}} of {{a_tot_files}}

                    </div>

                    <div class="mt-3 text-xs text-gray-500" *ngIf="limits?.is_own_key">

                        {{a_tot_used_files}} of unlimited

                    </div>

                    <div>

                    </div>

                </div>

            </div>

            <div class="p-3 w-1/4  flex flex-col   rounded-lg" style="background: #e9fdff;">

                <div class="text-base text-gray-700 py-2">
                    Chatbot Messages
                </div>

                <progress class="progress progress-error w-48" value="{{a_tot_used_questions}}" style="height: 4px;"
                    *ngIf="!limits?.is_own_key" max="{{a_tot_questions}}"></progress>

                <div class="flex justify-between items-center">

                    <div class="mt-3 text-xs text-gray-500" *ngIf="!limits?.is_own_key">

                        {{a_tot_used_questions}} of {{a_tot_questions}} <span class="text-xs text-gray-500">
                            per month</span>

                    </div>

                    <div class="mt-3 text-xs text-gray-500" *ngIf="limits?.is_own_key">

                        {{a_tot_used_questions}} of Unlimited <span class="text-xs text-gray-500">
                        </span>

                    </div>

                    <div>

                    </div>

                </div>

            </div>

            <div class="p-3 w-1/4  flex flex-col   rounded-lg" style="background: #fffcd8;">

                <div class="text-base text-gray-700 py-2">
                    Urls
                </div>

                <progress class="progress progress-error w-48" value="{{a_tot_used_urls}}" style="height: 4px;"
                    *ngIf="!limits?.is_own_key" max="{{a_tot_urls}}"></progress>

                <div class="flex justify-between items-center">

                    <div class="mt-3 text-xs text-gray-500" *ngIf="!limits?.is_own_key">

                        {{a_tot_used_urls}} of {{a_tot_urls}} <span class="text-xs text-gray-500"></span>

                    </div>

                    <div class="mt-3 text-xs text-gray-500" *ngIf="limits?.is_own_key">

                        {{a_tot_used_urls}} of unlimited

                    </div>

                    <div>

                    </div>

                </div>

            </div>



        </div>




    </div>

</div>

<div class="w-full h-screen bg-inherit pt-6" style="background: #ffffff !important;">

    <div class="bg-inherit h-full  mx-auto flex flex-col justify-start items-center" style="max-width: 1000px;">
        <div class=" flex justify-between items-center w-full py-3 border-b">

            <div class="text-2xl font-semibold
            ">Projects</div>
            <button class="bg-none text-white py-2 px-3 my-2 rounded text-base" type="button"
                style="background: var(--app-theme-color) !important;" (click)="openModal()"
                [hidden]="(user_projects.length >= limits['max_projects']) || loading_limits">
                + New Project</button>
            <button class="bg-none text-white py-2 px-3 my-2 rounded text-sm" type="button"
                style="background: var(--app-theme-color) !important;" (click)="this.redirectToPricingPage()"
                [hidden]="(user_projects.length < limits['max_projects']) || loading_limits">
                + Upgrade to create</button>

        </div>

        <div class="w-full flex flex-col justify-start p-8 bg-gray-100 mt-4 text-gray-700" style="width: 600px;"
            [hidden]="user_projects.length !== 0 || loading">

            Start by creating a project. Projects are a way to organize your Resources (documents, urls, text) and
            interact over them using AI. You will be able to share projects with others.

            <div class="w-full flex justify-center items-center mt-3">

                <button class="bg-none text-white py-2 px-3 my-2 rounded text-base" type="button"
                    style="background: var(--app-theme-color) !important;" (click)="openModal()">
                    + Create your first project</button>

            </div>

        </div>

        <div class="flex w-full justify-center items-center">

            <div class="grid grid-cols-3 gap-x-4 gap-y-4 mt-10 w-full">

                <a *ngFor="let p of user_projects" style="text-decoration: none; position: relative;" target="_blank"
                    class="flex flex-col justify-start items-start border p-8 hover:bg-gray-100 r_res bg-white rounded-lg cursor-pointer"
                    (click)="redirectToProject(p)">
                    <button class=" top-2 right-2 w-8 h-8 rounded-full bg-gray-100 r_del hidden"
                        (click)="openDeleteModal($event, p)" style="z-index:999999; position: absolute;">
                        <i class="fa-regular fa-times text-gray-500"></i>
                    </button>
                    <i class="fa-duotone fa-folder mb-3 text-4xl" style="color: #d4c6b5"></i>


                    <div class=" text-lg bg-inherit rounded mb-1  w-full">{{p.project_name}}</div>
                    <div class=" text-xs text-gray-500 bg-inherit rounded  w-full">{{getMoment(p.created_at)}}</div>

                </a>

            </div>

        </div>




    </div>

</div>


<div class="modal {{modal_class_create_project}} " style="z-index: 999999;">
    <div class="modal-box w-6/12 max-w-5xl p-0 ng-white h-auto rounded-sm" style="background:#fff !important; ">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Create Project

            </div>

            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_class_create_project = 'modal-close'; resetText()" style="
            background: #fff !important;
        ">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start" style="height: 85%;">

            <label class="mb-2 text-base">
                Project Name
            </label>


            <div class="p-0 w-full border rounded-lg bg-white flex justify-start items-center"
                style="border:solid 1px #bab8b8 !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">

                <input type="text" [(ngModel)]="project_name"
                    class="bg-white text-gray-800 rounded-lg px-3 py-3 text-sm mr-0 w-full h-full focus:outline-none"
                    style="padding-top:6px;padding-bottom:6px" placeholder="Ex: Product Documentation" />

            </div>

            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="project_name !== ''" style="background: var(--app-theme-color) !important;"
                (click)="createProject($event)">
                Create</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="project_name === ''" style="background: #c9c8c8 !important;" disabled>
                Create</button>




        </div>


    </div>

</div>



<div class="modal {{modal_class_delete_project}} " style="opacity:100% !important; z-index: 999999;">
    <div class="modal-box w-6/12 max-w-5xl p-0 bg-white  rounded"
        style="background:#fff !important; opacity:100% !important; width:550px">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">
                Are you Sure ?
            </div>
            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_class_delete_project = 'modal-close'" style="background: #fff !important;">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start">

            <div class="flex justify-between w-full px-2 py-2 mb-2">
                <div class="p-2 w-full bg-white flex justify-start items-center">
                    Deleting the Project, deletes all the resources associated with it.
                </div>

            </div>
            <div class="flex justify-center items-center w-full px-2 py-2 mb-2">
                <button class=" text-white font-repl mr-0 ml-2 py-2 px-4 rounded "
                    style="background: var(--app-theme-color);" type="button" (click)="deleteProject()">confirm</button>
            </div>

        </div>

    </div>

</div>



<div class="modal {{modal_add_key}} " style="z-index: 999999;">
    <div class="modal-box w-6/12 max-w-5xl p-0 ng-white h-auto rounded-sm" style="background:#fff !important; ">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Add OpenAI API Key
            </div>

            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_add_key = 'modal-close'; resetText()" style="
            background: #fff !important;
        ">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start" style="height: 85%;">

            <div class="p-0 px-8 mt-0 mb-8 flex flex-col justify-start items-start w-full"
                *ngIf="limits?.is_key_generated">

                <label class="mb-2 text-sm">
                    API Key added on : {{limits?.added_on | date:'shortDate' }}
                </label>

                <div
                    class="text-green-500 px-4 py-2 rounded border bg-gray-50 flex justify-between items-center w-full">

                    <span>
                        sk-********-********-********-********
                    </span>

                    <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-sm" type="button"
                        *ngIf="limits?.is_key_generated" style="background: #E93F36 !important;" (click)="deleteKey()">
                        Delete</button>


                </div>



            </div>

            <label class="mb-2 text-base" *ngIf="!limits?.is_key_generated">
                Please add your OpenAI API Key to continue
            </label>

            <label class="mb-2 text-base" *ngIf="limits?.is_key_generated">
                Update Key
            </label>


            <div class="p-0 w-full border rounded-lg bg-white flex justify-start items-center"
                style="border:solid 1px #bab8b8 !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">

                <input type="text" [(ngModel)]="key_string"
                    class="bg-white text-gray-800 rounded-lg px-3 py-3 text-sm mr-0 w-full h-full focus:outline-none"
                    style="padding-top:6px;padding-bottom:6px" placeholder="OpenAI API Key" />

            </div>

            <div class="my-2 text-sm text-red-500" *ngIf="add_key_error">
                Invalid Key. Please check again.
            </div>


            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string !== '' && !limits?.is_key_generated && !add_key_clicked"
                style="background: var(--app-theme-color) !important;" (click)="addKey($event)">
                Add</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string === '' && !limits?.is_key_generated && !add_key_clicked"
                style="background: #c9c8c8 !important;" disabled>
                Add</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="add_key_clicked" style="background: #c9c8c8 !important;" disabled>
                <i class="fa-regular fa-spinner-third fa-spin"></i>
                Adding</button>

            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string !== '' && limits?.is_key_generated && !add_key_clicked"
                style="background: var(--app-theme-color) !important;" (click)="addKey($event)">
                Update</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string === '' && limits?.is_key_generated && !add_key_clicked"
                style="background: #c9c8c8 !important;" disabled>
                Update</button>

            <a href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                target="_blank" class="text-blue-500 mb-2 mt-2">How to get OpenAI API Key?</a>



        </div>




    </div>

</div>

<!-- </div> -->
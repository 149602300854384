import { Component, ViewChild, ElementRef } from '@angular/core';
import { Storage } from 'aws-amplify';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { th, tr } from 'date-fns/locale';
import { set } from 'date-fns';
import { Auth } from 'aws-amplify';
import { SliceWordsPipePipe } from '../components/pipes/slice-words-pipe.pipe';
import { ToastComponent } from '../components/ui/repeatable/toast/toast.component';

declare var bootbox: any;
@Component({
  selector: 'app-tryitout',
  templateUrl: './tryitout.component.html',
  styleUrls: ['./tryitout.component.css']
})
export class TryitoutComponent {


  @ViewChild(ToastComponent) toast: ToastComponent;
  current_tab = "files";
  current_tab2 = "chat";
  current_subtab = "all";
  selectedProject = "";
  fileToUpload: File;
  upload = false;
  modal_class_declaration: string = "modal-close";
  modal_class_add_url: string = "modal-close";
  modal_class_share_project: string = "modal-close";
  modal_class_delete_project: string = "modal-close";
  project_objects = [
    {
      "file_name": "Solar_system.pdf",
      "file_path": "https://upload.wikimedia.org/wikipedia/commons/9/93/Solar_system_final.pdf",
      "file_size": "1769079",
      "file_type": "pdf",
      "is_external": false,
      "num_pages": "TBD",
      "object_id": "ee0f831e-80cf-4e4c-ba4d-57aef28c074e",
      "object_status": "done",
      "icon": "file-pdf",
      "iconcolor": "red"
    },
    {
      "domain": "en.wikipedia.org",
      "file_name": "https://en.wikipedia.org/wiki/Solar_System",
      "file_path": "0",
      "file_size": "0",
      "file_type": "url",
      "is_external": true,
      "num_pages": "0",
      "object_id": "b1258636-179a-41a0-be05-e2c34cea975a",
      "title": "Solar System - Wikipedia"
    }
  ];
  project_objects_display = [
    {
      "file_name": "Solar_system.pdf",
      "file_path": "https://upload.wikimedia.org/wikipedia/commons/9/93/Solar_system_final.pdf",
      "file_size": "1769079",
      "file_type": "pdf",
      "is_external": false,
      "num_pages": "TBD",
      "object_id": "ee0f831e-80cf-4e4c-ba4d-57aef28c074e",
      "object_status": "done",
      "icon": "file-pdf",
      "iconcolor": "red"
    },
    {
      "domain": "en.wikipedia.org",
      "file_name": "https://en.wikipedia.org/wiki/Solar_System",
      "file_path": "0",
      "file_size": "0",
      "file_type": "url",
      "is_external": true,
      "num_pages": "0",
      "object_id": "b1258636-179a-41a0-be05-e2c34cea975a",
      "title": "Solar System - Wikipedia"
    }
  ];;
  project_objects_display_search = this.project_objects;
  project_uuid = "d9aea68a-5544-4e81-a995-3811a2122fb9";
  project_name: string = "Sample project"
  project = {
    "created_at": "2023-06-27 02:47:00.827313",
    "embed_uuid": "1a5f8edd-d726-4f8f-9970-a939dd727614",
    "project_name": "Sample Project",
    "project_uuid": "d9aea68a-5544-4e81-a995-3811a2122fb9",
    "user_uuid": "sureddyakshay@gmail.com"
  };
  upload_index_progress = false
  pdfSrc = "";
  txtSrc = "";
  pageVariable = 0
  mode = 'base';
  timerId = null;
  add_menu_hidden = true;
  embed_chat_messages: any = [];
  chathistory: Array<[string, string]> = [];
  chat_id = "";

  public highlightAll = false;
  public matchCase = false;
  public wholeWord = true;
  public ignoreAccents = false;
  current_message_tab = "chat";
  current_sources: any = [];
  permissions = {
    'enable_sharing': false,
    'view_files': true,
    'download_files': true,
    'view_chats': true
  }
  share_code = "";
  is_shared = false;

  shared_mode_tab = "interact";

  temp_chat_messages: any;
  embed_show: any = null;
  projects_polling = null;
  scrollContainer: any;
  redirecturl = "https://knibble.ai/auth2/";


  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @ViewChild('chatScroll', { static: false }) private myScrollContainer: ElementRef;
  @ViewChild('chatScroll2') private myScrollContainer2: ElementRef;

  toggleAddMenu() {
    // this.add_menu_hidden = !this.add_menu_hidden;
    $('#' + 'button-drawer').toggle('fast', 'linear');
    $('#' + 'button-plus').toggle('fast', 'linear');
    $('#' + 'button-minus').toggle('fast', 'linear');

    // alert($('#' + 'button-drawer').is(":visible"));
  }


  projectNameEdited() {
    // clearTimeout(this.timerId);
    // this.timerId = setTimeout(() => {
    //   //console.log("project name edited....");
    //   // TODO: need to call the corresponding api to update the project name
    //   this.dataService.cd_updateUserProject(this.project).subscribe(data => {
    //     //console.log("data", data);
    //   }, err => console.log(err));
    // }, 2000);
  }

  setSharedModeTab(tab) {
    if (tab != "interact") {
      this.temp_chat_messages = this.chat_messages;
    } else {
      this.chat_messages = this.temp_chat_messages;
    }
    this.shared_mode_tab = tab;
  }


  share_info: any;
  getShare() {
    this.dataService.cd_getShare(this.project_uuid).subscribe((data: any) => {
      //console.log(data);
      if (data) {
        this.permissions = data['share']['permissions'];
        this.share_info = data['share'];
      }
    }
    );
  }

  onSharingToggle(event: any) {

    //console.log(this.permissions, event);
    if (this.permissions['enable_sharing']) {

    }
    this.dataService.cd_createShare(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
      this.share_info = data['share'];
    }
    );
  }

  onPermissionChange(event: any) {
    //console.log(this.permissions, event);
    this.dataService.cd_updateSharePermissions(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
    }
    );
  }

  scrollChatEnd(id) {

    // //console.log("scrolling to bottom", $('#' + 'message_window').html());
    setTimeout(() => {

      document.querySelector('#' + 'end_of_chat').scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });

    }, 300);

    // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 20;

  }
  scrollToBottom(): void {

    //console.log("scrolling to bottom");
    //console.log("scrolling to bottom", this.myScrollContainer.nativeElement);
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      // this.myScrollContainer2.nativeElement.scrollTop = this.myScrollContainer2.nativeElement.scrollHeight;
    } catch (err) {

      //console.log("error in scrolling to bottom", err);
    }
  }


  search(stringToSearch: string) {
    this.pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch, type: 'again', caseSensitive: false, findPrevious: true, highlightAll: true, phraseSearch: true
    });
  }



  search_display_objs = "";
  displayed_objects;
  searchObjects() {
    if (this.search_display_objs === "") {
      this.project_objects_display = this.project_objects_display_search;
    } else {
      this.project_objects_display = this.project_objects_display_search.filter(object => {
        if (object.file_type !== "url")
          return object.file_name.toLowerCase().indexOf(this.search_display_objs.toLowerCase()) !== -1;

        else
          return object.title.toLowerCase().indexOf(this.search_display_objs.toLowerCase()) !== -1;
      });
    }
    //console.log(this.project_objects_display);
  }


  constructor(public dataService: DataService, private aroute: ActivatedRoute, private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService, private route: Router) {

    pdfDefaultOptions.assetsFolder = 'assets';
  }


  no_access = false;

  loading_project = false;

  user: any = null;
  user_logged_in = true;

  show_login = false;
  take_last_n_messages = 3;

  logout() {
    // alert("logout");
    try {

      this.dataService.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  getUser() {
    this.dataService.getUser().subscribe((user) => {
      //console.log(user);
      this.user_logged_in = true;
      this.user = user;

    },
      (error) => {
        // //console.log(error);
      }
    );
  }

  embed_tab = "customize";

  setEmbedScreen(tab) {
    this.embed_tab = tab;
  }

  domain_url = "";

  ngOnInit(): void {

    this.domain_url = this.dataService.BASE_URL;




    this.goToChat(this.user_interactions[0]);

    this.dataService.getUser().subscribe((user) => {
      //console.log(user);
      this.user_logged_in = true;
      this.user = user;


    },
      (error) => {
        //console.log(error);
        this.user_logged_in = false;

      }
    );

  }



  embed = {
    "bg_color": "#ffffff",
    "border_color": "#a4a4a4",
    "bot_msg_bg_color": "#DD4C3F",
    "bot_msg_text_color": "#FFFFFF",
    "bot_picture": "default",
    "bubble_bg_color": "#DD4C3F",
    "bubble_icon": "fa-regular fa-message",
    "bubble_text_color": "#fff",
    "button_color": "#000000",
    "created_at": "2023-06-26 20:27:07.715236",
    "default_message": "👋 Hi there. How can I help you?",
    "embed_type": "web",
    "embed_uuid": "82eb1642-569d-4fa4-bd20-3f9bcfdf97ef",
    "header_color": "#ffffff",
    "header_text_color": "#000000",
    "logo": "default",
    "logo_path": "",
    "placeholder": "Type a message...",
    "project_uuid": "d9aea68a-5544-4e81-a995-3811a2122fb9",
    "rounded": "5",
    "send_icon": "fa-solid fa-paper-plane",
    "send_icon_bg_color": "#DD4C3F",
    "send_icon_color": "#fff",
    "title": "Sample Bot",
    "user_msg_bg_color": "#f7f7f7",
    "user_msg_text_color": "#000000",
    "user_picture": "default"
  };

  getEmbed(embed_uuid) {
    // this.dataService.cd_getEmbed(embed_uuid).subscribe((data: any) => {
    //   //console.log(data);
    //   if (data) {
    //     this.embed = data['embed'];
    //   }
    // }
    // );
  }

  // getProject() {
  //   this.dataService.cd_getUserProject(this.project_uuid, this.share_code, this.chat_id).subscribe((data: any) => {
  //     this.project = data?.project;
  //     //console.log("hhh", this.project);
  //     this.getEmbed(this.project['embed_uuid']);
  //   }, err => {
  //     //console.log("error: " + err);
  //     this.loading_project = false;
  //     this.no_access = true;
  //   }
  //   );
  // }

  getIconAndColor(element: any) {
    if (element['file_type'] == "pdf") {
      element['icon'] = "file-pdf";
      element['iconcolor'] = "red";
    }
    else if (element['file_type'] == "docx" || element['file_type'] == "doc") {
      element['icon'] = "file-word";
      element['iconcolor'] = "blue";
    }
    else if (element['file_type'] == "pptx" || element['file_type'] == "ppt") {
      element['icon'] = "file-powerpoint";
      element['iconcolor'] = "orange";
    }
    else if (element['file_type'] == "xlsx" || element['file_type'] == "xls") {
      element['icon'] = "file-excel";
      element['iconcolor'] = "green";
    }
    else if (element['file_type'] == "txt") {
      element['icon'] = "file-lines";
      element['iconcolor'] = "gray";
    }

    return element;
  }

  indexing = false;
  atleast_one_indexing = false;

  // getObjectsForProject() {



  //   this.dataService.cd_getObjectsForProject(this.project_uuid, this.share_code).subscribe((data: any) => {
  //     //console.log(data['objects']);

  //     this.atleast_one_indexing = false;

  //     this.project_objects = [];

  //     data['objects'].forEach((element: any) => {

  //       // add font awesome icon

  //       // alert(element.object_status)

  //       element = this.getIconAndColor(element);

  //       this.project_objects.push(element);
  //       this.setCurentSubTab(this.current_subtab);

  //       if (element['object_status'] == "indexing") {
  //         // alert("indexing");
  //         this.atleast_one_indexing = true;
  //       }


  //       //console.log(element);
  //     });

  //     this.indexing = this.atleast_one_indexing;

  //     if (!this.indexing) {
  //       clearInterval(this.projects_polling);
  //     }
  //     //console.log(this.project_objects);

  //     setTimeout(() => {

  //       if (this.project_objects.length == 0) {
  //         this.toggleAddMenu();
  //         // $('#button-drawer').addClass('animate-ping');
  //       } else {
  //         // $('#button-drawer').removeClass('animate-ping');
  //       }

  //     }, 200);


  //   }
  //   );

  // }

  setCurentSubTab(tab: string) {
    //console.log(tab);
    this.current_subtab = tab;
    // this.current_tab = tab;
    if (tab === 'all')
      this.project_objects_display = this.project_objects;
    else if (tab === 'links') {
      this.project_objects_display = [];
      this.project_objects.forEach(element => {
        if (element?.file_type === 'url')
          this.project_objects_display.push(element);
      });
    }
    else if (tab === 'files') {
      this.project_objects_display = [];
      this.project_objects.forEach(element => {
        if (element?.file_type !== 'url')
          this.project_objects_display.push(element);
      });
    }

    this.project_objects_display_search = this.project_objects_display;
    this.searchObjects();
    //console.log(this.project_objects);
    //console.log(this.project_objects_display);

  }

  setCurrentTab(tab: string) {
    this.current_tab = tab;

  }
  setCurrentTab2(tab: string) {
    this.current_tab2 = tab;

  }

  uploading_file_name = "";
  uploading_file_extension = "";
  handleFileInput(event: any) {
    this.fileToUpload = event.target.files[0];
    this.uploading_file_name = this.fileToUpload.name;
    this.uploading_file_extension = this.fileToUpload.name.split('.').pop();
  }

  openModal() {
    this.upload = true;
    this.modal_class_declaration = "modal-open";
  }

  openDeleteModal() {
    this.modal_class_delete_project = "modal-open";
  }



  closeModal() {
    this.upload = false;
    this.modal_class_declaration = "modal-close";
  }

  confirmDeleteProject() {
    // this.dataService.cd_deleteProject(this.project_uuid).subscribe(data => {
    //   this.redirectToMain();
    // }, err => console.log(err));
  }

  redirectToMain() {
    this.route.navigate(['/main']);
  }
  removeUpload(e) {
    e.preventDefault();
    this.fileToUpload = null;
    this.uploading_file_name = "";
    this.uploading_file_extension = "";
  }



  current_view_obj = {};
  textData = "";
  viewFile(obj) {

    //console.log(obj);

    if (obj.file_type == "url") {
      // open url in new tab
      window.open(obj['file_name'], '_blank');
      return;
    }
    else if (obj.is_external) {
      window.open(obj['file_path'], '_blank');
      return;
    }
    else if (obj.file_type == "txt") {
      this.current_tab = "view_txt";
      this.textData = "";

      this.current_view_obj = this.getIconAndColor(obj);


      try {

        fetch(obj['file_path']).then((response) => response.text()).then((data) => {

          this.textData = data;
          //console.log(data);

        }

        ).catch((err) => {
          //console.log(err);
        });;
      } catch (err) {
        //console.log(err);
      }

      return;

    }



    this.current_tab = "view_pdf";

    this.current_view_obj = this.getIconAndColor(obj);

    this.pdfSrc = obj['file_path'];



    // this.pdfSrc = obj['file_path'];
    // 
  }

  viewUrl(obj) {

    //console.log(obj);

    this.current_tab = "view_url";

    this.current_view_obj = this.getIconAndColor(obj);




    // this.pdfSrc = obj['file_path'];
    // 
  }

  // Deleting resources code
  deleteObject(e, obj) {
    e.stopPropagation();
    this.dataService.cd_deleteObject(obj?.object_id, this.project_uuid, obj?.file_size).subscribe(data => {
      //console.log("successfully deleted the object...", data);
      let doc_idx = this.project_objects.map(x => x?.object_id).indexOf(obj?.object_id);
      if (doc_idx != -1) {
        //console.log("index found...", doc_idx);
        this.project_objects.splice(doc_idx, 1);
        // To set the displaying resources also
        this.setCurentSubTab(this.current_subtab);
      }
    }, err => console.log(err));
  }


  chat_messages = [{
    "text": "👋 Hi there. How can I help you?",
    "actor": "bot",
    "sources": [
    ]
  }];
  current_input_message = "";
  typing = false;

  get_domain(data) {
    var a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }


  toggleSlideover() {
    document.getElementById('slideover-container').classList.toggle('invisible');
    // document.getElementById('slideover-bg').classList.toggle('opacity-0');
    // document.getElementById('slideover-bg').classList.toggle('opacity-50');
    document.getElementById('slideover').classList.toggle('translate-x-full');
  }


  getAnswer() {
    this.typing = true;
    var question = this.current_input_message;
    this.current_input_message = "";



    if (1 == 1) {

      this.saveChatMessage({ "text": question, "actor": "user" }, "user");
      this.scrollChatEnd('');

      //console.log("chathistory", this.chathistory);

      this.dataService.cd_getAnswer(this.project_uuid, question, this.chathistory.slice(-this.take_last_n_messages)).subscribe((data: any) => {
        //console.log(data);
        var sources = data['sources'];
        sources.forEach((element: any) => {
          this.project_objects.forEach((element2: any) => {
            if (element['object_id'] == element2['object_id']) {
              element['fileobj'] = element2;
            }
          });
        });
        //console.log({ "text": data['answer'], "actor": "bot", "sources": sources });
        this.saveChatMessage({ "text": data['answer'], "actor": "bot", "sources": sources }, "bot");
        this.scrollChatEnd('');
        this.typing = false;
        // Add to the chathistory
        this.chathistory.push([question, data['answer']])
      });

    } else {

    }

  }

  sources(sources) {
    this.current_message_tab = "sources";
    this.current_sources = sources;
  }

  closeSources() {
    this.current_message_tab = "chat";
    this.current_sources = [];
  }

  searchtext(text: string, page?: number) {
    var p_start = page - 1;
    var p_end = page + 1;
    var p_range = p_start + "," + page;
    //console.log(text, page, p_range);
    if (this.ngxExtendedPdfViewerService.find(
      text,
      {
        highlightAll: this.highlightAll,
        matchCase: this.matchCase,
        wholeWords: this.wholeWord,
        ignoreAccents: this.ignoreAccents,
        fuzzySearch: true,
        pageRange: p_range
      })) {
      // this._searchtext = text;
    }
  }

  // viewing = false;

  goToSource(page, obj, text) {

    //console.log(page, obj, text);



    this.viewFile(obj);




    setTimeout(() => {

      // alert(page);

      this.pageVariable = page;

      this.searchtext(text, page);
    }, 2000);


  }


  modal_class_add_text = "";
  add_text_current = "";
  add_text_name = "";
  openAddText() {
    this.modal_class_add_text = "modal-open";
  }
  closeAddText() {
    this.modal_class_add_text = "";

  }

  resetAddText() {
    this.add_text_current = '';
    this.add_text_name = "";
  }

  addText() {

    if (this.add_text_name.trim() === '' || this.add_text_name === null || this.add_text_current.trim() === '' || this.add_text_current === null) {
      console.error('Fill all the details.');
      return;
    }

    this.upload_index_progress = true;

    const filename = this.add_text_name + '.txt';




    //console.log(this.add_text_name, this.add_text_current);
    this.dataService.cd_getFileUploadPath(filename, this.project_uuid, "0").subscribe((data: any) => {
      //console.log(data);

      var upload_obj = data['obj'];

      //console.log(upload_obj);

      if (data.status == "success") {

        var file_extension = 'txt';
        var file_path = data.file_path;
        var content_type = "text/plain";

        try {
          Storage.put(file_path, this.add_text_current, {
            contentType: content_type, // Set the appropriate content type
            level: 'public' // Optional: Set the access level for the uploaded file
          }).then(() => {
            // Indexing Call
            //console.log("Indexing Call");
            //console.log(this.project_uuid, upload_obj['object_uuid']);
            this.dataService.cd_startFileIndexing(this.project_uuid, upload_obj['object_uuid']).subscribe((data: any) => {
              //console.log(data);
              this.upload_index_progress = false;
              this.closeAddText();
              this.resetAddText();
              this.projects_polling = setInterval(() => {
                // this.getObjectsForProject();
              }, 3000);
              //console.log("Indexing Call Successful");
              if (data.status === "success")
                this.toast.showToastNnotification("Successfully trained on the given text!!", "success");
              else
                this.toast.showToastNnotification("Failed to train on the given text!!", "failed");
            });

          });
          //console.log('File uploaded successfully.');
        } catch (error) {
          this.upload_index_progress = false;
          console.error('Error uploading file:', error);
          this.toast.showToastNnotification("Failed to train on the given text!!", "failed");
        }

      }
      else {
        this.upload_index_progress = false;
        this.toast.showToastNnotification("Uknown error occured. Please try again.", "failed");
        console.error('Uknown error occured. Please try again.');
        return [];
      }

    });
  }

  add_url_current: string = "";



  show_abort_banner = false;
  addUrl() {
    this.show_abort_banner = false;
    if (this.add_url_current.trim() === '' || this.add_url_current === null) {
      console.error('No Url provided.');
      // TODO: add toast here
      // this.toast.showToastNnotification("No Url provided...", "failed");
      return;
    }
    this.upload_index_progress = true;
    //console.log(this.add_url_current);

    this.dataService.cd_addUrlAndIndex(this.add_url_current, this.project_uuid).subscribe((data: any) => {
      //console.log(data);



      if (data.status == "success") {

        this.upload_index_progress = false;
        this.closeAddUrlToCollectionModal();
        this.add_url_current = '';
        // this.getObjectsForProject();

      }
      else if (data.status == "abort") {
        this.upload_index_progress = false;
        this.show_abort_banner = true;
        // this.toast.showToastNnotification(data.message, "success");
      } else {
        this.toast.showToastNnotification("Invalid URL, please try again.", 'failed');
        console.error('Uknown error occured. Please try again.');
        return [];
      }

    },
      (error) => {
        this.upload_index_progress = false;
        alert("Error. Please check the url and try again.")
        console.error('Uknown error occured. Please try again.');
        return [];
      });
  }

  openAddUrlToCollectionModal() {
    this.modal_class_add_url = "modal-open";
  }

  closeAddUrlToCollectionModal() {
    this.modal_class_add_url = "";
  }

  resetAddUrlPopUp() {
    this.add_url_current = '';
  }

  fileViewer() {
    this.current_tab = this.current_subtab;
    this.pdfSrc = "";
    this.textData = "";
    this.current_view_obj = {};
  }

  pdfUrl = "";
  upload_progress_text = "";
  async uploadPdf() {
    if ((this.pdfUrl.trim() === '' || this.pdfUrl === null) && !this.fileToUpload) {
      console.error('No file selected.');
      return;
    }



    this.upload_index_progress = true;
    this.upload_progress_text = "Uploading file..."

    if (this.fileToUpload) {
      const filename = this.fileToUpload.name;





      this.dataService.cd_getFileUploadPath(filename, this.project_uuid, "0").subscribe((data: any) => {
        //console.log(data);

        var upload_obj = data['obj'];

        //console.log(upload_obj);

        if (data.status == "success") {

          var file_extension = filename.split('.').pop();
          var file_path = data.file_path;
          var content_type = ""

          // Reject if file is not a pdf or docx or doc or pptx or ppt or xlsx or xls or txt or csv
          if (file_extension != "pdf" && file_extension != "docx" && file_extension != "doc" && file_extension != "pptx" && file_extension != "ppt" && file_extension != "xlsx" && file_extension != "xls" && file_extension != "txt" && file_extension != "csv") {
            alert("we currently support only pdf, docx, doc, pptx, ppt, xlsx, xls, txt, csv files.")
            console.error('File is not a pdf or docx or doc or pptx or ppt or xlsx or xls or txt or csv.');
            return;
          }


          // set upload content type
          if (file_extension == "pdf") {
            content_type = "application/pdf";
          }
          else if (file_extension == "docx") {
            content_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          }
          else if (file_extension == "doc") {
            content_type = "application/msword";
          }
          else if (file_extension == "pptx") {
            content_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          }
          else if (file_extension == "ppt") {
            content_type = "application/vnd.ms-powerpoint";
          }
          else if (file_extension == "xlsx") {
            content_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          else if (file_extension == "xls") {
            content_type = "application/vnd.ms-excel";
          }
          else if (file_extension == "txt") {
            content_type = "text/plain";
          }

          //console.log(content_type, file_path);

          try {
            Storage.put(file_path, this.fileToUpload, {
              resumable: true,
              contentType: content_type, // Set the appropriate content type
              level: 'public', // Optional: Set the access level for the uploaded file,
              progressCallback: (progress) => {
                var percent_uploaded = Math.round((progress.loaded / progress.total) * 100);
                //console.log(`Uploaded (${percent_uploaded}%)`);
                this.upload_progress_text = `Uploaded (${percent_uploaded}%)`;

                if (percent_uploaded == 100) {
                  this.upload_progress_text = "File uploaded. Indexing..."
                }
              },

              completeCallback: (event) => {
                //console.log(`Successfully uploaded ${event.key}`);
                //console.log("Indexing Call");
                //console.log(this.project_uuid, upload_obj['object_uuid']);
                this.dataService.cd_startFileIndexing(this.project_uuid, upload_obj['object_uuid']).subscribe((data: any) => {
                  //console.log(data);
                  this.upload_index_progress = false;
                  this.upload_progress_text = "";
                  this.closeModal();
                  this.fileToUpload = null;
                  this.pdfUrl = '';
                  // this.getObjectsForProject();
                  this.projects_polling = setInterval(() => {
                    // this.getObjectsForProject();
                  }, 3000);
                });
              },

              errorCallback: (err) => {
                alert('Unexpected error while uploading');
              }


            })

          } catch (error) {
            console.error('Error uploading file:', error);
          }

        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
    else if (this.pdfUrl) {
      this.upload_index_progress = true;
      this.upload_progress_text = "Indexing file..."
      this.dataService.cd_addFileUrlAndIndex(this.pdfUrl, this.project_uuid, false, "None").subscribe((data: any) => {
        //console.log(data);



        if (data.status == "success") {

          this.upload_index_progress = false;
          this.upload_progress_text = "";
          this.closeModal();
          this.pdfUrl = '';

          // call get objects for projects until all objects status is "done"

          // this.getObjectsForProject();

        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
  }


  retyFileIndex(file, e) {
    e.stopPropagation();
    //console.log(file);
    file.object_status = "indexing";
    // return
    if (file.is_external) {

      this.dataService.cd_addFileUrlAndIndex(file.file_path, this.project_uuid, true, file.object_id).subscribe((data: any) => {

        //console.log(data);





        if (data.status == "success") {

          this.upload_index_progress = false;
          this.upload_progress_text = "";
          this.closeModal();
          this.pdfUrl = '';

          // call get objects for projects until all objects status is "done"
          this.projects_polling = setInterval(() => {
            // this.getObjectsForProject();
          }, 3000);


        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });

    } else {
      this.dataService.cd_startFileIndexing(this.project_uuid, file.object_id).subscribe((data: any) => {
        //console.log(data);
        this.upload_index_progress = false;
        this.upload_progress_text = "";
        this.closeModal();
        this.fileToUpload = null;
        this.pdfUrl = '';
        // this.getObjectsForProject();
        this.projects_polling = setInterval(() => {
          // this.getObjectsForProject();
        }, 3000);
      });
    }
  }


  // Interactions code

  current_interaction: any;
  user_interactions = [
    {
      "interaction_name": "Solar System Bot",
      "interaction_time": "2023-06-27 04:05:11.053184",
      "interaction_type": "chat",
      "interaction_uuid": "92003c4b-2658-4693-96cd-5261f107bfb7",
      "project_uuid": "d9aea68a-5544-4e81-a995-3811a2122fb9"
    }
  ];

  newChat() {

    this.dataService.cd_addInteraction(this.project_uuid, 'chat', 'Untitled Chat').subscribe((data: any) => {
      //console.log(data);
      this.current_interaction = data['interaction'];
      this.chat_messages = [];
      this.chathistory = [];
      this.mode = "interact";
      this.user_interactions.push(data['interaction']);
    }
    );
  }

  getUserInteractions() {
    // this.dataService.cd_getInteractions(this.project_uuid, this.share_code).subscribe((data: any) => {
    //   //console.log(data);
    //   this.user_interactions = data['interactions'];

    // }
    // );
  }


  loading_messages: boolean = false;
  goToChat(interaction) {
    this.current_interaction = interaction;
    this.getInteractionMessages();
    // this.makeChathistory();
    this.mode = "interact";

  }

  makeChathistory() {
    let i = 0;
    let chat_len = this.chat_messages.length;
    this.chathistory = []
    // 1. find the first user message
    // 2. find the bot message
    while (i < chat_len) {
      let user_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'user') {
          user_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      let bot_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'bot') {
          bot_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      this.chathistory.push([user_message, bot_message]);
    }
    //console.log("chathistory", this.chathistory);
    this.scrollChatEnd('');
  }

  currentInteractionNameEdited() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      //console.log("chat interaction name edited....");
      // TODO: need to call the corresponding api to update the chat interaction name
      this.dataService.cd_updateInteraction(this.current_interaction).subscribe(data => {
        //console.log(data);
      }, err => console.log(err));
    }, 2000);
  }

  goToSavedChat(interaction) {
    this.current_interaction = interaction;
    this.getInteractionMessages();
    this.shared_mode_tab = "saved_chat_view";

  }

  getInteractionMessages() {

    this.chat_messages = [{
      "text": "Hello! Ask me something about the solar system",
      "actor": "bot",
      "sources": [
      ]
    }];

    // this.dataService.cd_getInteractionMessages(this.project_uuid, this.current_interaction['interaction_uuid'], this.share_code).subscribe((data: any) => {
    //   //console.log(data);
    //   this.current_interaction['messages'] = data['messages'];
    //   this.loading_messages = false;
    //   // this.chat_messages = data['messages'];
    //   data['messages'].forEach(element => {

    //     var msg = {}
    //     // msg['text'] = element['message'];

    //     if (element['actor'] == "user") {
    //       this.chat_messages.push(element['message']);
    //     }
    //     else {
    //       this.chat_messages.push(element['message']);
    //     }

    //   }
    //   );
    //   // this.scrollChatEnd('');
    //   this.makeChathistory();
    // }
    // );

  }

  backtoChats() {
    this.mode = "base";
    this.chat_messages = [];
    this.current_interaction = {};
  }

  saveChatMessage(message, actor) {
    this.chat_messages.push(message);
    // if (!this.is_shared && !this.embed_show) {

    //   this.dataService.cd_addInteractionMessage(this.project_uuid, this.current_interaction['interaction_uuid'], message, actor).subscribe((data: any) => {
    //     //console.log(data);
    //   }
    //   );

    // }

  }

  modal_class_delete_interaction = "modal-close";

  deleteInteractionModalOpen() {
    this.modal_class_delete_interaction = "modal-open";
  }


  deleteInteraction() {
    // this.dataService.cd_deleteInteraction(this.current_interaction['interaction_uuid']).subscribe((data: any) => {
    //   //console.log(data);
    //   this.backtoChats();
    //   this.getUserInteractions();
    //   this.modal_class_delete_interaction = "modal-close";;
    // }
    // );
  }


  openShareModal() {
    this.modal_class_share_project = "modal-open";
  }

  // utils function to format the file size
  formatFileSize(fileSize: any): string {
    if (fileSize != null) {
      let f_size_bytes = parseInt(fileSize);
      if (f_size_bytes < 1024)
        return `${f_size_bytes} B`
      if (f_size_bytes < 1024 * 1024)
        return `${(f_size_bytes / 1024).toFixed(2)} KB`
      if (f_size_bytes < 1024 * 1024 * 1024)
        return `${(f_size_bytes / 1024 / 1024).toFixed(2)} MB`
    }
    return "0 B"
  }



  // Embed Chat Code

  modal_class_embed = "modal-close";
  sample_chat_messages = [
    // { "text": "👋 Hi! I am ChatDocs bot!, ask me anything about!", "actor": "bot" },
    { "text": "Hi, how are you?", "actor": "user" },
    { "text": "I'm good! How are you?", "actor": "bot" }

  ]


  openEmbedModal() {
    this.modal_class_embed = "modal-open";
    // this.getLogoImage(this.embed.logo_path);
  }
  closeEmbedModal() {
    this.modal_class_embed = "modal-close";
  }

  saveBotSettings() {
    // //console.log(this.embed.embed_uuid, this.embed);
    // this.dataService.cd_updateEmbed(this.embed.embed_uuid, this.embed).subscribe((data: any) => {
    //   //console.log(data);
    //   // this.closeEmbedModal();
    // }
    // );
  }

  onImageClick() {
    const fileInput = document.querySelector('input[type=file]') as HTMLInputElement;
    fileInput.click();
  }


  getLogoImage(fname) {
    Storage.get(fname, {
      level: 'public'
    }).then((url) => {
      this.embed.logo = url;
      this.embed.logo_path = fname;
      this.saveBotSettings();
    });

  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadFile(file);
  }


  private async uploadFile(file: File) {
    // //console.log(file.name);
    var extension = file.name.split('.')[1];
    // //console.log(extension);
    var f_name = 'projects/' + this.project_uuid + "/embeds/logos/" + this.embed.embed_uuid + "." + extension;
    const result = await Storage.put(f_name, file, {
      level: 'public',
    }).then(() => {
      this.embed.logo_path = f_name;
      this.getLogoImage(f_name);
    });
  }




}

<div class="modal {{modal_state}} flex flex-col " style="z-index: 999999;">
    <div class=" w-full  p-0 ng-white h-auto rounded-sm"
        style="background:#fff !important; width: 800px !important; height:600px">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Add OpenAI API Key
            </div>

            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_state = 'modal-close'; resetText()" style="
            background: #fff !important;
        ">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start" style="height: 85%;">

            <div class="p-0 px-8 mt-0 mb-8 flex flex-col justify-start items-start w-full"
                *ngIf="limits?.is_key_generated">

                <label class="mb-2 text-sm">
                    API Key added on : {{limits?.added_on | date:'shortDate' }}
                </label>

                <div
                    class="text-green-500 px-4 py-2 rounded border bg-gray-50 flex justify-between items-center w-full">

                    <span>
                        sk-********-********-********-********
                    </span>

                    <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-sm" type="button"
                        *ngIf="limits?.is_key_generated" style="background: #E93F36 !important;" (click)="deleteKey()">
                        Delete</button>


                </div>



            </div>

            <label class="mb-2 py-4 px-4 bg-blue-50 border border-blue-500 rounded-lg text-base flex  w-full mb-4"
                *ngIf="!limits?.is_key_generated">
                <img src="assets/keys.png" class="w-12 h-12 mr-4" />
                <div class="flex flex-col pl-0">

                    Unlock unlimited messages with your own OpenAI API Key. We will start using your API Key once you
                    add it here. Please note that you will be charged by OpenAI for the messages generated using your
                    API Key.
                    <a href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                        target="_blank" class="text-blue-500 mb-2 mt-2">Learn more</a>

                </div>

            </label>


            <label class="mb-2 text-base" *ngIf="!limits?.is_key_generated">
                Please add your OpenAI API Key to continue
            </label>

            <label class="mb-2 text-base" *ngIf="limits?.is_key_generated">
                Update Key
            </label>


            <div class="p-0 w-full border rounded-lg bg-white flex justify-start items-center"
                style="border:solid 1px #bab8b8 !important; box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);">

                <input type="text" [(ngModel)]="key_string"
                    class="bg-white text-gray-800 rounded-lg px-3 py-3 text-sm mr-0 w-full h-full focus:outline-none"
                    style="padding-top:6px;padding-bottom:6px" placeholder="OpenAI API Key" />

            </div>

            <div class="my-2 text-sm text-red-500" *ngIf="add_key_error">
                Invalid Key. Please check again.
            </div>


            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string !== '' && !limits?.is_key_generated && !add_key_clicked"
                style="background: var(--app-theme-color) !important;" (click)="addKey($event)">
                Add</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string === '' && !limits?.is_key_generated && !add_key_clicked"
                style="background: #c9c8c8 !important;" disabled>
                Add</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="add_key_clicked" style="background: #c9c8c8 !important;" disabled>
                <i class="fa-regular fa-spinner-third fa-spin"></i>
                Adding</button>

            <button class="bg-none text-white py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string !== '' && limits?.is_key_generated && !add_key_clicked"
                style="background: var(--app-theme-color) !important;" (click)="addKey($event)">
                Update</button>

            <button class="bg-none text-gray-500 py-2 px-3 my-2 mt-3 rounded text-base" type="button"
                *ngIf="key_string === '' && limits?.is_key_generated && !add_key_clicked"
                style="background: #c9c8c8 !important;" disabled>
                Update</button>





        </div>

        <div class="w-full h-40">

        </div>


    </div>



</div>
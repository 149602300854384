import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Storage } from 'aws-amplify';
// import { DataService } from '../../../../services/data.service';
import { ApiService } from '../../../../backend/services/api.service';
import { PlansService } from '../../../../backend/services/plans.service';

declare var bootbox: any;


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Input() limits: any;
  @Input() tracker: any;
  @Input() project_uuid: any;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Output() notifyParentIndexingStatus: EventEmitter<any> = new EventEmitter();

  declare $: any;

  modal_state = 'modal-close';
  pdfUrl = "";
  fileToUpload: any;
  uploading_file_extension: string = "";
  upload_index_progress = false;
  upload_progress_text = "";
  uploading_file_name = "";
  upload_obj: any;
  valid = true;
  error_message = "";

  constructor(private api: ApiService, private planService: PlansService) { }

  ngOnInit(): void {
  }

  example_method() {
    alert("Example Method");
  }

  open_modal() {
    var validation = this.planService.validateAction('upload_file', this.limits, this.tracker);
    this.valid = validation.status;

    if (!this.valid) {
      this.error_message = validation.message;
      bootbox.alert({
        size: 'medium',
        title: 'Oops!',
        message: this.error_message
      });
      return;
    }



    this.modal_state = 'modal-open';
  }

  close_modal() {
    this.modal_state = 'modal-close';
  }

  handleFileInput(event: any) {
    this.fileToUpload = event.target.files[0];
    this.uploading_file_name = this.fileToUpload.name;
    this.uploading_file_extension = this.fileToUpload.name.split('.').pop().toLowerCase();
  }

  removeUpload(e) {
    e.preventDefault();
    this.fileToUpload = null;
    this.uploading_file_name = "";
    this.uploading_file_extension = "";
  }

  async uploadPdf() {

    if ((this.pdfUrl.trim() === '' || this.pdfUrl === null) && !this.fileToUpload) {
      console.error('No file selected.');
      return;
    }

    const filename = this.fileToUpload.name;
    let file_extension = filename.split('.').pop();
    // Reject if file is not a pdf or txt file
    if (file_extension.toLowerCase() != "pdf" && file_extension.toLowerCase() != "txt") {
      alert("we currently support only pdf,  txt files.")
      return;
    }
    this.upload_index_progress = true;
    this.upload_progress_text = "Uploading file..."

    if (this.fileToUpload) {

      this.api.cd_getFileUploadPath(filename, this.project_uuid, "0").subscribe((data: any) => {
        console.log(data);
        this.emitUploadEvent(data['file_obj']);
        this.pollFileIndexStatus(data['file_obj']['file_uuid']);
        this.upload_obj = data['obj'];

        if (data.status == "success") {

          var file_extension = filename.split('.').pop();
          var file_path = data.file_path;
          var content_type = ""




          // set upload content type
          if (file_extension.toLowerCase() == "pdf") {
            content_type = "application/pdf";
          }
          else if (file_extension == "txt") {
            content_type = "text/plain";
          }

          try {
            Storage.put(file_path, this.fileToUpload, {
              resumable: true,
              contentType: content_type, // Set the appropriate content type
              level: 'public', // Optional: Set the access level for the uploaded file,
              progressCallback: (progress) => {
                var percent_uploaded = Math.round((progress.loaded / progress.total) * 100);
                this.upload_progress_text = `Uploaded (${percent_uploaded}%)`;

                if (percent_uploaded == 100) {
                  this.upload_progress_text = "File uploaded. Indexing..."
                }
              },

              completeCallback: (event) => {

                this.startFileIndexing();

              },

              errorCallback: (err) => {
                alert('Unexpected error while uploading');
              }


            })

          } catch (error) {
            console.error('Error uploading file:', error);
          }

        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
    else if (this.pdfUrl) {
      this.upload_index_progress = true;
      this.upload_progress_text = "Indexing file..."
      this.api.cd_addFileUrlAndIndex(this.pdfUrl, this.project_uuid, false, "None").subscribe((data: any) => {

        if (data.status == "success") {

          this.upload_index_progress = false;
          this.upload_progress_text = "";
          this.close_modal();
          this.pdfUrl = '';
        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
  }

  redirectToPricingPage() {

  }

  emitUploadEvent(f) {
    this.notifyParent.emit(f);
  }


  pollFileIndexStatus(file_uuid) {

    this.api.cd_getFileIndexStatus(file_uuid).subscribe((data: any) => {
      console.log(data);
      if (data.status == "indexing") {

        setTimeout(() => {
          this.pollFileIndexStatus(file_uuid);
        }, 5000);

      }
      else {
        data['file_uuid'] = file_uuid;
        data['file_size'] = data['file_size'];
        data['object_id'] = data['object_uuid'];
        this.notifyParentIndexingStatus.emit(data);
        return true;
      }

    }
    );

  }


  startFileIndexing() {

    this.api.cd_startFileIndexing(this.project_uuid, this.upload_obj['object_uuid']).subscribe((data: any) => {
      this.upload_index_progress = false;
      this.upload_progress_text = "";
      this.close_modal();
      this.fileToUpload = null;
      this.pdfUrl = '';

    });

  }

  testInstance() {
    alert(this.upload_obj);
    console.log(this.upload_obj);
  }



}

import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@aws-amplify/storage';
import { Observable, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { data } from 'jquery';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  BASE_URL: string;

  constructor(private http: HttpClient) {

    //change

    if (environment.production) {
      this.BASE_URL = "https://apiv2.knibble.ai/";
    } else {
      this.BASE_URL = "https://apiv2.knibble.ai/";
      // this.BASE_URL = "http://localhost:5002/";
    }
  }

  cd_getUser() {
    return this.http.get(this.BASE_URL + "api/user/get", {
      withCredentials: true
    });
  }

  // api/user/projects/get POST cd_getUserProjects
  cd_getUserProjects() {
    return this.http.post(this.BASE_URL + "api/user/projects/get", {}, {
      withCredentials: true
    });
  }

  cd_isUpgraded() {
    return this.http.get(this.BASE_URL + "api/isUpgraded", {
      withCredentials: true
    });
  }

  cd_getLimit(plan) {
    return this.http.post(this.BASE_URL + "api/user/get/limits", { "plan": plan }, {
      withCredentials: true
    })
  }


  cd_addUrlAndIndex(url, project_uuid) {
    return this.http.post(this.BASE_URL + "api/add_url_start_indexing", { "url": url, "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  cd_addFileUrlAndIndex(url, project_uuid, retry, object_id) {
    return this.http.post(this.BASE_URL + "api/add_file_url_start_indexing", { "url": url, "project_uuid": project_uuid, "retry": retry, "object_id": object_id }, {
      withCredentials: true
    });
  }


  // api/user/project/get POST
  cd_getUserProject(project_uuid: string, share_code: string, chat_id: string) {
    let data = {
      "project_uuid": project_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    if (chat_id != "") {
      data["chat_id"] = chat_id;
    }
    return this.http.post(this.BASE_URL + "api/user/project/get", data, {
      withCredentials: true
    });
  }

  cd_deleteProject(project_uuid) {
    return this.http.post(this.BASE_URL + "api/user/project/delete", { "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  // /api/delete_interaction POST interaction_uuid
  cd_deleteInteraction(interaction_uuid) {
    return this.http.post(this.BASE_URL + "api/delete_interaction", { "interaction_uuid": interaction_uuid }, {
      withCredentials: true
    });
  }


  // /api/user/add/key POST key
  cd_addKey(key) {
    return this.http.post(this.BASE_URL + "api/user/add/key", { "key": key }, {
      withCredentials: true
    });
  }

  // c/api/user/delete/key POST key
  cd_deleteKeyForAccount() {
    return this.http.post(this.BASE_URL + "api/user/delete/key", {}, {
      withCredentials: true
    });
  }

  // /api/user/interaction/update
  cd_updateInteraction(interaction) {
    return this.http.post(this.BASE_URL + "api/user/interaction/update", interaction, {
      withCredentials: true
    });
  }

  // api/user/project/update POST 
  cd_updateUserProject(project: any) {
    return this.http.post(this.BASE_URL + "api/user/project/update", project, {
      withCredentials: true
    });
  }

  cd_deleteObject(object_uuid, project_uuid, file_size) {
    return this.http.post(this.BASE_URL + "api/delete_object", {
      "object_uuid": object_uuid,
      "project_uuid": project_uuid,
      "file_size": file_size
    }, {
      withCredentials: true
    });
  }

  // /api/project/embed/get POST embed_uuid
  cd_getEmbed(embed_uuid) {
    return this.http.post(this.BASE_URL + "api/project/embed/get", { "embed_uuid": embed_uuid }, {
      withCredentials: true
    });
  }

  // /api/project/embed/update POST embed_uuid embed
  cd_updateEmbed(embed_uuid, embed) {
    return this.http.post(this.BASE_URL + "api/project/embed/update", { "embed_uuid": embed_uuid, "embed": embed }, {
      withCredentials: true
    });
  }


  // /api/project/chatbot-config/get
  cd_getChatbotConfig(project_uuid: string) {
    let data = {
      "project_uuid": project_uuid
    }
    return this.http.post(this.BASE_URL + "api/project/chatbot-config/get", data, {
      withCredentials: true
    });
  }
  // /api/project/chatbot-config/update
  cd_updateChatbotConfig(project_uuid: string, persona: string, temperature: string) {
    let data = {
      "project_uuid": project_uuid,
      "temperature": temperature,
      "persona": persona
    }
    return this.http.post(this.BASE_URL + "api/project/chatbot-config/update", data, {
      withCredentials: true
    });
  }


  hasDeclared() {
    return this.http.post(this.BASE_URL + "api/user/hasdeclared", {}, {
      withCredentials: true
    });
  }

  declare() {
    return this.http.post(this.BASE_URL + "api/user/declare", {}, {
      withCredentials: true
    });
  }


  update_user_image(key) {

    var data = { "key": key };
    return this.http.post(this.BASE_URL + "api/update_user_image", data, {
      withCredentials: true
    });

  }

  updateUserAliasandName(alias, name) {
    return this.http.post(this.BASE_URL + "api/user/update", { "name": name, "alias": alias }, {
      withCredentials: true
    });
  }

  getCustomerPortal() {
    return this.http.get(this.BASE_URL + "api/customerportal", {
      withCredentials: true
    });
  }


  //api/user/get POST
  getUser() {
    return this.http.get(this.BASE_URL + "api/user/get", {
      withCredentials: true
    });
  }


  cd_getFileUploadPath(filename, project_uuid, file_size) {
    return this.http.post(this.BASE_URL + "api/upload_path", { "filename": filename, "project_uuid": project_uuid, "file_size": file_size }, {
      withCredentials: true
    })

  }

  //api/get_file_index_status POST cd_getFileIndexStatus file_uuid
  cd_getFileIndexStatus(file_uuid) {
    return this.http.post(this.BASE_URL + "api/get_file_index_status", { "file_uuid": file_uuid }, {
      withCredentials: true
    });
  }


  cd_startFileIndexing(project_id, object_id) {
    return this.http.post(this.BASE_URL + "api/start_indexing", { "project_uuid": project_id, "object_uuid": object_id }, {
      withCredentials: true
    });
  }


  cd_getUserProjectForEmbed(project_uuid: string, share_code: string, chat_id: string) {
    let data = {
      "project_uuid": project_uuid
    }
    if (share_code != "") {
      data["share_code"] = share_code;
    }
    if (chat_id != "") {
      data["chat_id"] = chat_id;
    }
    return this.http.post(this.BASE_URL + "api/user/projectforembed/get", data, {
      withCredentials: true
    });
  }

  // api/get_answer POST project_uuid question
  cd_getAnswer(project_uuid, question, chathistory) {
    return this.http.post(this.BASE_URL + "api/get_answer", { "project_uuid": project_uuid, "query": question, "chathistory": chathistory }, {
      withCredentials: true
    });
  }

  // /api/get_share POST project_uuid
  cd_getShare(project_uuid) {
    return this.http.post(this.BASE_URL + "api/get_share", { "project_uuid": project_uuid }, {
      withCredentials: true
    });
  }

  // /api/create_share POST project_uuid
  cd_createShare(project_uuid, permissions) {
    return this.http.post(this.BASE_URL + "api/create_share", { "project_uuid": project_uuid, "permissions": permissions }, {
      withCredentials: true
    });
  }


  // /api/update_share_permissions POST project_uuid permissions
  cd_updateSharePermissions(project_uuid, permissions) {
    return this.http.post(this.BASE_URL + "api/update_share_permissions", { "project_uuid": project_uuid, "permissions": permissions }, {
      withCredentials: true
    });
  }

  logout() {
    return this.http.get(this.BASE_URL + "api/logout", {
      withCredentials: true
    });
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthComponent } from './components/pages/auth/auth.component'
import { HomeComponent } from './components/home/home.component'
import { AuthButtonComponent } from './components/plugins/authbutton/authbutton.component';
import { AuthcatcherComponent } from './components/plugins/authcatcher/authcatcher.component';
// import { AuthHttpInterceptor } from '@auth0/auth0-angular';


import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxColorsModule } from 'ngx-colors';

import { NgxPopper } from 'angular-popper';



import { ResizableModule } from 'angular-resizable-element';
import { ResizabledivComponent } from './components/plugins/resizablediv/resizablediv.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FooterComponent } from './components/plugins/footer/footer.component';

// import { ChartModule } from 'angular2-chartjs';
// import { ChartBuilderComponent } from './components/charts/chart-builder/chart-builder.component';
import { SuccessComponent } from './components/pages/success/success.component';
import { AccountComponent } from './components/pages/account/account.component';
import { HelpComponent } from './components/pages/help/help.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Authcatcher2Component } from './components/plugins/authcatcher2/authcatcher2.component';
import { NewauthComponent } from './components/newauth/newauth.component';
import { GetStartedComponent } from './components/pages/get-started/get-started.component';

import { DeleteAccountComponent } from './components/pages/delete-account/delete-account.component';
import { IEditorComponent } from './components/plugins/ieditor/ieditor.component';
import { DataService } from './services/data.service';
import { MainTreeComponentComponent } from './components/ui/trees/main-tree-component/main-tree-component.component';
// import { DashboardComponent } from './components/app-ui/main/dashboard/dashboard.component';
import { DashboardComponentNew } from './components/app-ui/main/dashboard/dashboard.component';
import { HeaderComponent } from './components/ui/repeatable/header/header.component';
import { PublicheaderComponent } from './components/ui/repeatable/publicheader/publicheader.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ToastComponent } from './components/ui/repeatable/toast/toast.component';
import { PrivacyPolicyComponent } from './components/pages/terms/privacy-policy/privacy-policy.component';
import { TermsComponent } from './components/pages/terms/terms/terms.component';
import { SliceWordsPipePipe } from './components/pipes/slice-words-pipe.pipe';
import { SpaComponent } from './components/try/spa/spa.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ProjectComponent } from './project/project.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { MainComponent } from './components/pages/main/main.component';
import { TryitoutComponent } from './tryitout/tryitout.component';
import { UrlPipe } from './components/pipes/url.pipe';
import { ProjectMainComponent } from './components/pages/project-main/project-main.component';
import { SidebarComponent } from './components/blocks/sidebar/sidebar/sidebar.component';
import { FilecardComponent } from './components/blocks/cards/filecard/filecard.component';
import { UploadFileComponent } from './components/blocks/modals/upload-file/upload-file.component';
import { TruncatePipePipe } from './components/pipes/truncate-pipe.pipe';
import { AddWebpagesComponent } from './components/app-ui/main/add-webpages/add-webpages.component';
import { AddNewProjectComponent } from './components/blocks/modals/add-new-project/add-new-project.component';
import { InteractionCardComponent } from './components/blocks/cards/interaction-card/interaction-card.component';
import { DeleteProjectComponent } from './components/blocks/modals/delete-project/delete-project.component';
import { DeleteChatComponent } from './components/blocks/modals/delete-chat/delete-chat.component';
import { ApiKeyComponent } from './components/app-ui/main/api-key/api-key.component';
import { CustomizeComponent } from './components/app-ui/main/customize/customize.component';
import { UserAccountComponent } from './components/app-ui/main/user-account/user-account.component';
import { ChatembedComponent } from './components/app-ui/main/chatembed/chatembed.component';
import * as bootstrap from "bootstrap";
import * as $ from "jquery";

// let AUTH_ENDPOINT = "https://widgetbox.app"
// let API_ENDPOINT = "https://apiv2.widgetbox.app";

let AUTH_ENDPOINT = "http://localhost:4200"
let API_ENDPOINT = "http://localhost:1699"

let domain = 'dev-deyc75ze.us.auth0.com';
let clientid = 'sAvQlqQaxYXkk8qkkXUN8ZUFqkN0BlOJ';

// let domain = 'dev-deyc75ze.us.auth0.com';
// let clientid = 'cImZcBWKJcZLDuzOC6FwLBlvEUO6PHNU';



@NgModule({
  declarations: [
    AppComponent,

    AuthComponent,
    HomeComponent,
    AuthButtonComponent,
    AuthcatcherComponent,
    ResizabledivComponent,
    FooterComponent,
    SuccessComponent,
    AccountComponent,
    HelpComponent,
    Authcatcher2Component,
    NewauthComponent,
    GetStartedComponent,
    DeleteAccountComponent,
    IEditorComponent,
    MainTreeComponentComponent,
    DashboardComponentNew,
    HeaderComponent,
    ChatbotComponent,
    PublicheaderComponent,
    PricingComponent,
    ToastComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    SliceWordsPipePipe,
    SpaComponent,
    ProjectComponent,
    MainComponent,
    TryitoutComponent,
    UrlPipe,
    ProjectMainComponent,
    SidebarComponent,
    FilecardComponent,
    UploadFileComponent,
    TruncatePipePipe,
    AddWebpagesComponent,
    AddNewProjectComponent,
    InteractionCardComponent,
    DeleteProjectComponent,
    DeleteChatComponent,
    ApiKeyComponent,
    CustomizeComponent,
    UserAccountComponent,
    ChatembedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    GooglePlaceModule,
    NgxColorsModule,
    ResizableModule,
    SelectDropDownModule,
    AmplifyAuthenticatorModule,
    PickerModule,
    PdfViewerModule,
    NgxDocViewerModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    DataService,
    HttpClientModule
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




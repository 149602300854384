<app-delete-chat [hidden]="loading_project_progress !== 100 || project_uuid === null"
    (notifyDeletSuccess)="delChatConfirmed($event)"></app-delete-chat>

<div class=" flex flex-col justify-center items-center w-full h-screen" id="loading-project">

    <span class="text-sm mb-3">Loading Project</span>

    <progress class="progress progress-primary w-56" value="{{loading_project_progress}}" max="100"
        [hidden]="page === 'dashboard'"></progress>
    <img src="assets/dots-loader.gif" class="w-16" [hidden]="page !== 'dashboard'">

</div>
<div class="w-full px-8 py-2 bg-yellow-100 text-sm" *ngIf="limits?.is_own_key && !limits?.is_key_generated">
    <i class="fa-light fa-warning mr-2"></i>
    {{byok_error}} <a
        href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
        class="text-blue-700">Learn more</a>
</div>
<div class="w-full h-full flex">



    <app-sidebar [pid]="project_uuid" [plan]="plan" [limits]="limits" [tracker]="tracker" [page]="page"
        [user_upgrade]="user_upgrade" (notifyShowDashboard)="openDashboard()" (notifyKeyAdded)="keyAdded()"
        (notifyShowAccount)="showAccount()" class="hidden h-screen overflow-auto" id="sidebar"></app-sidebar>
    <div class=" hidden  flex-grow flex-1 h-screen overflow-auto app-main" id="desktop-main">

        <app-upload-file [limits]="limits" [tracker]="tracker" [project_uuid]="project_uuid"
            [hidden]="loading_project_progress !== 100" (notifyParentIndexingStatus)="updateIndexingStatus($event)"
            (notifyParent)="uploadedFileCallback($event)"></app-upload-file>




        <div class="flex flex-col flex-1 h-full py-3 pr-3 pl-0  overflow-hidden" style="background: #000000;">

            <div class="flex flex-col flex-grow w-full overflow-y-scroll rounded-l rounded-r   p-0 main-view-box"
                id="main-chat-window">


                <div class="w-full h-full bg-white overflow-hidden flex main_view_window" id="chat_view">


                    <div class="flex flex-col flex-grow h-full border-r" style="max-width: 67%;">

                        <div class="flex w-full justify-between items-center border-b h-20" id="chatbox-header">

                            <div class="flex justify-start items-center pl-8 " *ngIf="page === 'project'">
                                <!-- <div class="w-10 h-10 rounded-full mr-2 {{project?.color}} mask mask-hexagon-2"
                                    style="">
                                    <div class="flex justify-center items-center h-full ">
                                        <div class="text-lg text-white capitalize font-semibold"
                                            style="color:#000000 !important">
                                            {{project?.project_name[0]}}
                                        </div>
                                    </div>
                                </div> -->
                                <div class="flex justify-start items-center pl-0 gap-x-2   font-semibold outline:focus-none border-0"
                                    *ngIf="get_project_call" contenteditable="true"
                                    (input)="project.project_name = $event.target.textContent"
                                    [textContent]="project.project_name" (keyup)="projectNameEdited()" style="background: #fff;font-size: 24px;
                                    /* font-family: 'Lato' !important; */
                                    color: #2e2e2e;">

                                </div>



                            </div>



                            <div class="mx-4 gap-x-0 flex justify-end items-center">
                                <button class="py-0 px-2 mr-2 bg-inherit text-white text-base rounded font-" style="background-image: linear-gradient(60deg, #de41de, #f0950c);
                                        -webkit-background-clip: text; background-clip: text;
                                        color: transparent !important;" (click)="customize()" data-toggle="tooltip"
                                    data-placement="top" title="Customize and embed chatbot">
                                    <i class="fa-solid fa-code mr-1 -ml-2"></i>

                                </button>
                                <!-- 
                                <button class="py-0 px-3 bg-inherit text-white text-base rounded font-" style="background-image: linear-gradient(60deg, #515151, #515151);
                            -webkit-background-clip: text; background-clip: text;
                            color: transparent !important;">
                                   
                                    <img src="assets/menu.svg" class="w-8">

                                </button> -->


                                <div class="dropdown px-0">
                                    <button class="btn bg-inherit focus:outline-none dropdown-toggle px-4" type="button"
                                        id="dropdownMenuButton" style="background: inherit !important;"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="assets/menu.svg" class="w-8">
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item py-1 border-b" (click)="openShareModal()">
                                            <i class="fa-solid fa-share mr-1"></i>
                                            Share
                                        </a>
                                        <a class="dropdown-item" (click)="delCurrentProject($event)">Delete Project</a>
                                    </div>
                                </div>


                            </div>

                        </div>



                        <div class="w-full flex flex-col py-0 px-0 mt-0 flex-1 flex-grow overflow-y-scroll inset_shadow_"
                            id="chat-box" style="
                        
                        
                        ">

                            <!-- box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 10%); -->

                            <div *ngFor="let message of current_interaction['messages']"
                                class=" flex justify-start items-start flex-col">


                                <div class=" w-full px-8 py-4 border-b flex justify-start items-start"
                                    *ngIf="message.message.actor === 'user'">
                                    <div class="flex justify-start items-start mr-3" style="z-index:99999"
                                        *ngIf="message.message.actor === 'user'">
                                        <div class="w-8 h-8 p-1 bg-blue-100  flex justify-center items-center text-white text-lg font-semibold mask mask-squircle"
                                            *ngIf="user_pic_url === ''"
                                            style="font-size:24px;min-width: 2rem; max-width: 2rem;">
                                            <img class=" bg-none" src="assets/default_user.svg" style=""
                                                cors="anonymous" />
                                        </div>
                                        <img class=" bg-none w-8 h-8 mask mask-squircle" src="{{user_pic_url}}"
                                            *ngIf="user_pic_url !== ''" cors="anonymous" />
                                    </div>
                                    <div class="mt-1"
                                        style="background: #fff;color:#000; font-size: 16px; overflow: auto;"
                                        [innerHTML]="filterAllTagsOtherThanLinks(message.message.text)">
                                    </div>
                                </div>
                                <div class="w-full px-8 py-4 border-b flex flex-col justify-start items-start" style="background: rgb(248 248 248); max-width: 100%;
                                color: #10171b;font-size: 16px" *ngIf="message.message.actor === 'bot'">

                                    <div class="flex justify-start items-start" style="max-width: inherit;">

                                        <div class="w-8 h-8  flex justify-center items-center mask mask-squircle text-white text-xl font-bold mr-3"
                                            style="background: #000; font-size:26px; min-width: 2rem; max-width: 2rem;">
                                            <img src="assets/logo-re2.svg" alt="" style="width:14px">

                                        </div>
                                        <div class="flex flex-col w-full justify-start items-start">

                                            <div class="mt-1"
                                                [innerHTML]="filterAllTagsOtherThanLinks(message.message.text)"
                                                style="white-space: break-spaces;    max-width: 100%;overflow: auto;">

                                            </div>

                                            <a (click)="sources(message.message.sources)"
                                                class="mt-2 text-blue-500">sources</a>


                                        </div>


                                    </div>





                                </div>

                            </div>

                            <div class="w-full px-8 py-4 border- flex justify-start items-start" style="background: #f8f8f8;
                            color: #3b464d;font-size: 16px" *ngIf="typing">

                                <div class="w-8 h-8  flex justify-center items-center mask mask-hexagon-2 text-white text-xl font-bold mr-3"
                                    style="background: #171924; font-size:26px; min-width: 2rem; max-width: 2rem;">
                                    <img src="assets/logo-re2.svg" alt="" style="width:14px">

                                </div>
                                <div class="mt-1" style="white-space: break-spaces;    max-width: 100%;">
                                    <img src="assets/dots-loader.gif" alt="" class="rounded-full w-10" *ngIf="typing">
                                </div>
                                <!-- <a (click)="sources(message.message.sources)" class="mt-2 text-blue-500">sources</a> -->
                            </div>

                            <!-- <div class="p-2 flex justify-center items-center">
                                <img src="assets/dots-loader.gif" alt="" class="rounded-full w-10" *ngIf="typing">
                            </div> -->


                        </div>

                        <div
                            class="flex flex-col bottom-0 py-4 px-8 items-center inset-y-0 border-t sm:flex w-full gap-y-4 drop-shadow-sm relative">

                            <div class="w-full  border hidden bg-gray-50 rounded-t-xl" id="chat-config">

                                <div class="px-8 py-2 leading-normal text-sm opacity-80 ">

                                    <div class=" pt-0 pb-4">
                                        <label class="edit_bot_label text-lg font-semibold mb-1">Add Persona</label>
                                        <p class="py-2 text-sm mb-2" *ngIf="limits?.is_key_generated">Persona lets you
                                            define the role of the chatbot.
                                            For
                                            example,
                                            you
                                            can
                                            ask the chatbot to be "A helpful assistant" or "An expert at answering math
                                            questions".
                                            This
                                            text will be included in the prompts to the AI. Please note that this will
                                            increase your
                                            token usage.</p>


                                        <textarea class="textarea textarea-bordered w-full h-40" maxlength="500"
                                            *ngIf="limits?.is_key_generated" [(ngModel)]="chatBotConfig.persona"
                                            name="persona" placeholder="Max 500 words"></textarea>

                                        <div *ngIf="!limits?.is_key_generated" class="bg-yellow-100 border px-3 py-2">
                                            Chat settings are available when you add your own API key. <a
                                                href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                                                class="text-blue-700">Learn more</a>
                                        </div>

                                        <div *ngIf="limits?.is_key_generated">

                                            <button (click)="updateChatbotConfig()" *ngIf="!persona_saved"
                                                class="px-3 py-2 bg-gray-100 rounded text-sm">
                                                Save
                                            </button>

                                            <button (click)="updateChatbotConfig()" *ngIf="persona_saved"
                                                class="px-3 py-2 bg-green-50 rounded">
                                                <i class="fas fa-check mr-1"></i>
                                                Saved
                                            </button>

                                        </div>



                                    </div>



                                </div>

                            </div>

                            <div class="flex w-full justify-center items-center border-2 shadow-sm rounded">

                                <!-- <i class="fa-light fa-gear text-lg ml-4 text-gray-500 hover:text-gray-800"></i> -->
                                <a (click)="toggleChatConfig()" class="cursor-pointer" class="pr-2">
                                    <img src="assets/setting.svg" alt="" class="w-6 ml-3" id="chat-config-gear">
                                    <i class="fa-solid fa-times-circle text-lg ml-3 text-red-500 hover:text-gray-800 hidden"
                                        id="chat-config-close"></i>
                                </a>
                                <!-- <img src="assets/setting.svg" alt="" class="w-6 ml-3"> -->

                                <!-- <textarea
                                    class="w-full py-3 bg-transparent body2 text-n-7 outline-none resize-none placeholder:text-n-4/75 dark:text-n-1 dark:placeholder:text-n-4"
                                    placeholder="Ask Brainwave anything" style="max-height: 144px !important;">



                                </textarea> -->
                                <div class="textarea w-full py-3 px-4 text-base border-0 rounded shadow-none focus:outline-none input-box"
                                    id="input-box" contenteditable="true" placeholder="Your question here ..."
                                    (keyup.enter)="getAnswer($event)"
                                    (input)="current_input_message = $event.target.textContent">
                                </div>

                                <button class="ml-4 mr-4" (click)="getAnswer($event)" style="background-image: linear-gradient(60deg, #de41de, #f0950c);
                             -webkit-background-clip: text; background-clip: text;
                             color: transparent !important;">
                                    <i class="fa-solid fa-paper-plane-top text-xl"></i>
                                </button>

                            </div>

                        </div>

                    </div>

                    <div class="h-full flex flex-col inset_shadow" style="width:33%; max-width: 33%;">

                        <div class="flex gap-x-2 border-b bg-white">

                            <button class=" h-20 w-1/3  flex justify-center items-center "
                                (click)="resources_tab = 'files'"
                                [ngClass]="{'font-semibold border-b-2 border-black': resources_tab === 'files'}"
                                style="font-size: 17px;">
                                <i class="fa-duotone fa-folder mr-2 text-gray-400"
                                    [ngClass]="{'text-yellow-400': resources_tab === 'files'}"></i>
                                <!-- <img src="assets/copy-file.svg" alt="" class="w-6 mr-1"> -->
                                Files
                            </button>

                            <button class="font- w-1/3  flex justify-center items-center"
                                (click)="resources_tab = 'urls'"
                                [ngClass]="{'font-semibold border-b-2 border-black':resources_tab === 'urls'}"
                                style="font-size: 17px;">
                                <!-- <i class="fa-light fa-link-simple mr-1" style="color: #000"></i> -->
                                <i class="fa-duotone fa-globe mr-2 text-gray-400"
                                    [ngClass]="{'text-indigo-400': resources_tab === 'urls'}"></i>
                                <!-- <img src="assets/global.svg" alt="" class="w-6 mr-1"> -->
                                Web
                            </button>

                            <button class="  font- w-1/3  flex justify-center items-center"
                                (click)="resources_tab = 'history'"
                                [ngClass]="{'font-semibold border-b-2 border-black':resources_tab === 'history'}"
                                style="font-size: 17px;">
                                <i class="fa-duotone fa-messages mr-1 text-gray-400"
                                    [ngClass]="{'text-green-400': resources_tab === 'history'}"></i>
                                <!-- <img src="assets/clock.svg" alt="" class="w-6 mr-1"> -->
                                Chats
                            </button>

                        </div>

                        <div class="w-full p-0 h-full overflow-y-scroll flex flex-col mt-0"
                            style="background: transparent;">

                            <div class="flex flex-col flex-1 flex-grow overflow-scroll">

                                <div class="w-full max-h-full px-4 overflow-y-scroll mt-4"
                                    *ngIf="resources_tab === 'files'">

                                    <div *ngFor="let file of project_objects" class="w-full">

                                        <app-filecard *ngIf="file.file_type === 'pdf' || file.file_type === 'txt'"
                                            (deleteNotif)="deleteObject($event)" (seeFile)="seeFile($event)"
                                            [file]="file" [project_uuid]="project_uuid"></app-filecard>

                                    </div>



                                </div>

                                <div class="w-full max-h-full px-4 overflow-y-scroll mt-4"
                                    *ngIf="resources_tab === 'urls'">

                                    <div *ngFor="let file of project_objects" class="w-full">

                                        <app-filecard *ngIf="file.file_type === 'url'" [file]="file"
                                            [project_uuid]="project_uuid"
                                            (deleteNotif)="deleteObject($event)"></app-filecard>

                                    </div>

                                </div>

                                <div class="w-full max-h-full px-4 overflow-y-scroll mt-4 flex flex-col justify-start items-start"
                                    *ngIf="resources_tab === 'history'">

                                    <button *ngFor="let interaction of user_interactions"
                                        class="w-full flex justify-between items-center p-4 border-b"
                                        [ngClass]="{'bg-gray-app-light rounded-xl': current_interaction['interaction_uuid'] === interaction.interaction_uuid}"
                                        (click)="goToChat(interaction)">

                                        <div class="flex items-center justify-start">

                                            <!-- <i class="fa-light fa-messages mr-2 mt-1" style="color: #000"></i> -->
                                            <img src="assets/intchat.svg" alt="" class="w-6 mr-2">

                                            <div class="flex justify-start items-center outline:focus-none border-0"
                                                contenteditable="true" [textContent]="interaction?.interaction_name"
                                                (input)="interaction.interaction_name = $event.target.textContent"
                                                (keyup)="currentInteractionNameEdited(interaction)">

                                            </div>


                                        </div>

                                        <button (click)="delChat(interaction.interaction_uuid)"
                                            class=" mr-1 text-gray-300 hover:text-red-500"><i
                                                class="fa-solid fa-times-circle" style="font-size: 14px;"></i></button>


                                    </button>

                                </div>


                            </div>




                            <div
                                class="flex flex-col bottom-0 px-4 pb-8 items-center inset-y-0 border-0 sm:flex w-full gap-y-4">


                                <div class="flex w-full justify-start items-center" *ngIf="resources_tab === 'files'">

                                    <button class="w-full flex justify-center items-center gap-x-2 py-2 px-4 rounded-md"
                                        (click)="uploadFile()" style="background:var(--app-theme-color)">

                                        <i class="fa-regular fa-plus text-xl text-white"></i>

                                        <span class=" text-white">Upload File</span>

                                    </button>

                                </div>

                                <div class="flex w-full justify-start items-center" *ngIf="resources_tab === 'urls'">

                                    <button class="w-full flex justify-center items-center gap-x-2 py-2 px-4 rounded-md"
                                        (click)="uploadUrl()" style="background:var(--app-theme-color)">

                                        <i class="fa-regular fa-plus text-xl text-white"></i>

                                        <span class=" text-white">Add Webpages</span>

                                    </button>

                                </div>


                                <div class="flex w-full justify-start items-center" *ngIf="resources_tab === 'history'">

                                    <button class="w-full flex justify-center items-center gap-x-2 py-2 px-4 rounded-md"
                                        (click)="newChat()" style="background:var(--app-theme-color)">

                                        <i class="fa-regular fa-plus text-xl text-white"></i>

                                        <span class=" text-white">New Chat</span>

                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>


                </div>


                <div class="w-full h-full bg-white  hidden main_view_window justify-start items-start" id="upload_view">

                    <app-add-webpages [project_uuid]="project_uuid" class="w-full"
                        (notifyParentCloseUrlPage)="urlClose()"></app-add-webpages>

                </div>






            </div>

            <div class=" flex-col flex-grow w-full overflow-y-scroll rounded-l rounded-r p-0  hidden"
                id="main-customize-window">

                <div class="w-full h-full bg-white overflow-hidden flex main_view_window" id="customize_view"
                    *ngIf="page === 'project' && get_project_call">

                    <app-customize [embed_uuid]="project.embed_uuid" [project_uuid]="project_uuid" class="w-full"
                        [limits]="limits" [page]="page" (closeNotif)="closeCustomize()" [plan]="plan"></app-customize>
                </div>


            </div>

            <!-- <div class=" flex-col flex-grow w-full overflow-y-scroll rounded-l rounded-r p-0 main-view-box hidden"
            id="main-dashboard-window">

            <div class="w-full h-full bg-white overflow-hidden flex justify-center items-start main_view_window"
                id="dashboard_view">


                <app-dashboard-new class="w-full h-full flex justify-center items-center"></app-dashboard-new>

            </div>


        </div> -->

        </div>



        <!-- Sliders -->

        <div class="w-1/2 h-full absolute max-h-full overflow-y-scroll top-0 right-0 bg-white shadow hidden p-4  flex-col sliders"
            style="z-index:9999999" id="sources-slide">
            <div class="w-full flex justify-between items-center py-2">
                <div></div>
                <button class="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-500" (click)="closeSources()">
                    <i class="fa-solid fa-times-circle" style="font-size: 24px;"></i>
                </button>
            </div>

            <div>
                <div *ngFor="let source of current_sources" class="p-8 border-b">
                    <div class="flex flex-col">
                        <div class="flex justify-between items-center">
                            <div class="text-base" *ngIf="source.fileobj.is_external===false">In file
                                {{source.fileobj['file_name']}} ...</div>
                            <!-- <a href="{{source.fileobj['file_name']}}" class="text-base text-blue-500"
                                *ngIf="source.fileobj.is_external===true">{{source.fileobj['file_name']}} ...
                            </a>

                            <a href="{{source.fileobj['file_name']}}" class="text-base text-blue-500"
                                *ngIf="source.fileobj.is_external==='True'">{{source.fileobj['file_name']}} ...
                            </a> -->

                            <a href="{{source.fileobj['file_name']}}" class="text-base text-blue-500"
                                *ngIf="source.fileobj.domain!==undefined">{{source.fileobj['file_name']}}
                            </a>

                            <a *ngIf="source.fileobj.is_external===false" class="cursor-pointer" style="color:#045fb4"
                                (click)="goToSource(source.page, source.fileobj, source.source_text)">

                                See file</a>

                        </div>
                        <div class="text-sm p-3 bg-gray-50 mt-2 rounded" [innerHTML]="source.source_text"
                            [hidden]="source.fileobj.is_external===true">
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="w-3/4 h-full absolute max-h-full overflow-y-scroll top-0 right-0 bg-white shadow hidden p-4  flex-col sliders"
            style="z-index:9999999" id="file-slide">

            <div class="w-full flex justify-between items-center py-2">
                <div></div>
                <button class="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-500" (click)="closeFile()">
                    <i class="fa-solid fa-times-circle" style="font-size: 24px;"></i>
                </button>
            </div>

            <div class="w-full h-full bg-white border-b flex flex-col p-0 mt-4" *ngIf="current_tab === 'view_pdf'"
                style="position: relative;">

                <ngx-extended-pdf-viewer [src]="pdfSrc" [height]="'99%'" [useBrowserLocale]="true" [textLayer]="true"
                    [showHandToolButton]="true" [showToolbar]="true" [showSidebarButton]="false"
                    [showFindButton]="false" [showPagingButtons]="true" [showDrawEditor]="false"
                    [showTextEditor]="false" [showZoomButtons]="true" [showPresentationModeButton]="true"
                    [showOpenFileButton]="false" [showPrintButton]="false" [showSecondaryToolbarButton]="true"
                    [showRotateButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
                    [showBorders]="false" [showSpreadButton]="false"
                    [showPropertiesButton]="false"></ngx-extended-pdf-viewer>

            </div>


            <div class="w-full px-0 flex justify-between items-center shadow-sm mb-0"
                *ngIf="current_tab === 'view_txt'">

                <div class="truncate ml-4 font-semibold">
                    <i
                        class="fa-solid fa-{{current_view_obj['icon']}} mr-1 text-{{current_view_obj['iconcolor']}}-500"></i>
                    {{current_view_obj['file_name']}}

                </div>



            </div>

            <div class="w-auto h-full bg-white border flex flex-col p-4 m-4" *ngIf="current_tab === 'view_txt'"
                style="position: relative; overflow-y: scroll;">
                {{textData}}
            </div>

        </div>


    </div>

    <div class=" hidden  flex-grow flex-1 h-screen overflow-auto app-main" id="dashboard-main">

        <div class="flex flex-col flex-1 h-full p-0  overflow-hidden" style="background: #161924;"
            [hidden]="loading_dashboard">

            <app-dashboard-new [page]="page" [limits]="limits" [tracker]="tracker" [upgraded]="upgraded"
                [hidden]="page !== 'dashboard'" (openCreateProjectModal)="openCreateProjectModal()"
                class="w-full h-full flex justify-center items-center"></app-dashboard-new>


        </div>

    </div>


    <div class=" hidden  flex-grow flex-1 h-screen overflow-auto app-main" id="account-main">

        <div class="flex flex-col flex-1 h-full p-3  overflow-hidden" style="background: #161924;">

            <app-user-account [upgraded]="upgraded"
                class="w-full h-full flex justify-center items-center rounded-l rounded-r overflow-hidden"></app-user-account>


        </div>

    </div>





</div>

<div class="modal {{modal_share}} " style="z-index: 999999;">
    <div class="modal-box w-full md:w-6/12 max-w-5xl p-0 ng-white h-auto rounded-sm"
        style="background:#fff !important; ">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Share Chatbot

            </div>

            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_share = 'modal-close';" style="
            background: #fff !important;
        ">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start" style="height: 85%;">

            <div class="w-full flex-1 flex-grow  overflow-scroll">
                <div class="py-0 px-8 mt-2 flex flex-col justify-start items-start " style="height: 85%;">

                    <div class="flex mt-3 mb-6">
                        <input type="checkbox" class="toggle toggle-success" [(ngModel)]="permissions.enable_sharing"
                            (change)="onSharingToggle($event)" />
                        &nbsp;&nbsp;Enable Sharing
                    </div>




                    <div *ngIf="permissions.enable_sharing">
                        Shareable Link:
                    </div>

                    <div class="my-3 bg-yellow-50 border p-4">
                        Anyone with the link below can chat with this project. Please note that the messages will be
                        counted towards your monthly limits.
                    </div>

                    <div class="py-3 flex flex-col gap-y-2 justify-start items-start w-full"
                        *ngIf="permissions.enable_sharing">
                        <div class="w-full p-0 border-0 rounded text-sm mr-2 code-div">
                            <!-- <input #shareLinkInput type="text" class="share-link-input" readonly
                                class="w-full focus:outline-none"
                                [value]="'https://knibble.ai/embed/bot/' + (project['embed_uuid'])"> -->
                            <pre class="p-0">
                                <code class="p-2 flex justify-start">https://knibble.ai/embed/bot/{{project['embed_uuid']}}</code>
                            </pre>
                        </div>
                        <a class="ml-0 mr-2 bg-gray-800 px-3 py-1 rounded-lg mt-2" (click)="copyNotionLink()"
                            style="background: var(--app-theme-color);">

                            <!-- <div class="flex text-white text-sm " *ngIf="!isCopied">
                                <i class="fa-regular fa-copy text-sm text-white"></i>&nbsp;Copy
                            </div> -->

                            <div class="flex justify-center items-center text-white rounded-lg" *ngIf="!isCopied">

                                <i class="fa-regular fa-copy text-xl text-white mr-1"></i>
                                Copy

                            </div>
                            <div class="flex justify-center items-center text-white rounded-lg" *ngIf="isCopied">

                                <i class="fa-regular fa-circle-check text-xl text-white mr-1"></i>
                                Copied

                            </div>

                        </a>
                    </div>







                </div>
            </div>

        </div>


    </div>

</div>
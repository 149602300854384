<div class="modal {{modal_state}} " style="opacity:100% !important; z-index: 999999;">
    <div class="modal-box w-full md:w-6/12 max-w-5xl p-0 bg-white  rounded"
        style="background:#fff !important; opacity:100% !important; width:550px">
        <div class="py-2 px-8 border-b text-gray-800 font-semibold text-xl flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">
                Are you Sure ?
            </div>
            <button _ngcontent-xis-c92="" for="my-modal-5" class="m-0 btn bg-none text-gray-800 p-0 border-0"
                (click)="modal_state = 'modal-close'" style="background: #fff !important;">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>

        <div class="py-4 px-8 mt-2 flex flex-col justify-start items-start">

            <div class="flex justify-between w-full px-2 py-2 mb-2">
                <div class="p-2 w-full bg-white flex justify-start items-center">
                    Deleting the Project, deletes all the resources associated with it.
                </div>

            </div>
            <div class="flex justify-center items-center w-full px-2 py-2 mb-2">
                <button class=" text-white font-repl mr-0 ml-2 py-2 px-4 rounded "
                    style="background: var(--app-theme-color);" type="button" (click)="deleteProject()">confirm</button>
            </div>

        </div>

    </div>

</div>
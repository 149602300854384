import { Component, OnInit, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../../../../../app/backend/services/api.service';
import { Storage } from 'aws-amplify';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-chatembed',
  templateUrl: './chatembed.component.html',
  styleUrls: ['./chatembed.component.css']
})
export class ChatembedComponent implements OnInit {

  embed_uuid: string;
  loading_chatbot: boolean = true;
  project_uuid: string;
  embed: any;

  constructor(private aroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.embed_uuid = this.aroute.snapshot.paramMap.get('embed_uuid');

    if (this.embed_uuid != null) {
      this.api.cd_getEmbed(this.embed_uuid).subscribe((data: any) => {
        console.log(data);
        this.embed = data['embed'];
        this.getLogoImage(this.embed['logo_path']);
        this.project_uuid = this.embed['project_uuid'];
        this.getProjectForEmbed();
        setTimeout(() => {
          this.loading_chatbot = false;
          console.log(this.embed);
        }, 1000);
      }, err => {
        console.log("error: " + err);
        this.loading_chatbot = false;
      });

    }
  }

  getLogoImage(fname) {
    if (fname != null && fname != undefined && fname != "") {

      Storage.get(fname, {
        level: 'public'
      }).then((url) => {
        this.embed.logo = url;
        this.embed.logo_path = fname;
        // this.saveBotSettings();
      });

    }
  }

  share_code = "";
  project: any;
  limits: any;
  tracker: any;
  loading_project: boolean = true;
  no_access: boolean = false;

  getProjectForEmbed() {
    this.api.cd_getUserProjectForEmbed(this.project_uuid, this.share_code, this.embed_uuid).subscribe((data: any) => {
      // console.log("project", data);
      // this.project = data?.project;
      // console.log("hhh", this.project);

      this.limits = data['plan_details'];
      this.tracker = data['tracker_obj'];
      console.log("tracker", this.tracker);
      console.log("limits", this.limits);
      this.getEmbed(this.embed_uuid);

    }, err => {
      console.log("error: " + err);
      this.loading_project = false;
      this.no_access = true;
    }
    );
  }


  getEmbed(embed_uuid) {
    this.api.cd_getEmbed(embed_uuid).subscribe((data: any) => {
      console.log(data);
      if (data) {

        this.embed = data['embed'];
        // if (this.embed['logo_rounded'] == true) {
        //   alert("rounded");
        // }
        this.getLogoImage(this.embed['logo_path']);
      }
    }
    );
  }


  current_input_message: string = "";
  typing: boolean = false;
  current_message_tab: string = "";
  current_sources = [];
  take_last_n_messages = 3;
  chathistory = [];
  chat_messages = [];


  saveChatMessage(message, actor) {
    this.chat_messages.push(message);
  }

  pushErrorMessage() {
    var message = { "message": { "text": '<span class="text-red-500">There was an error processing your request. Please try again later</span>', "actor": "bot" }, "actor": "bot" };
    this.saveChatMessage(message, "bot");
  }

  removeATags(text) {
    const regex = /<a\b[^>]*>(.*?)<\/a>/g;
    return text.replace(regex, '$1');
  }


  scrollChatEnd(id) {

    setTimeout(() => {
      $('#' + id).scrollTop($('#' + id)[0].scrollHeight);
    }, 100);

  }

  getAnswer() {
    this.typing = true;
    this.scrollChatEnd('chat-box');
    var question = this.current_input_message;
    this.current_input_message = "";

    // console.log("question", this.tracker['n_questions'], this.limits['max_messages_per_month']);
    console.log("question", question);

    $('#input-box').html('');

    if (parseInt(this.tracker['n_questions']) >= parseInt(this.limits['max_messages_per_month'])) {
      console.log("max messages reached");
      this.pushErrorMessage();
      this.typing = false;
      return;
    }


    console.log("Here");



    if (1 == 1) {

      this.saveChatMessage({ "text": question, "actor": "user" }, "user");
      this.scrollChatEnd('chat-box');
      // console.log("Calling Get Answer", this.chathistory.slice(-this.take_last_n_messages));

      this.api.cd_getAnswer(this.project_uuid, question, this.chathistory.slice(-this.take_last_n_messages)).subscribe((data: any) => {
        this.saveChatMessage({ "text": data['answer'], "actor": "bot" }, "bot");;
        this.typing = false;
        this.chathistory.push([question, this.removeATags(data['answer'])])
      });

    } else {

    }

  }



  filterAllTagsOtherThanLinks(message: string): SafeHtml {
    // Use DOM manipulation to filter and process the HTML content

    let message_formatted = message.replace(/([\n])/gs, '<br>').replace(/```([\s\S]*?)```/gs, (_, capturedText) => {
      capturedText = capturedText.replace(/<br>/g, '\n');
      // remove links from the code part
      capturedText = capturedText.replace(/<\/?a[^>]*>/g, '')
      return `<div class="code-div"><pre><code class="code-block">${capturedText}</code></pre></div>`;
    });
    // console.log(message_formatted);
    const wrapper = document.createElement('div');
    wrapper.innerHTML = message_formatted;

    // Recursively process child nodes
    this.processChildNodes(wrapper.childNodes);

    // Sanitize the processed HTML for security
    const processedHTML = wrapper.innerHTML;
    const sanitizedHTML = this.sanitizer.sanitize(SecurityContext.HTML, processedHTML);

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHTML);
  }

  private processChildNodes(nodes: NodeListOf<ChildNode>): void {
    nodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        if (element.tagName.toLowerCase() === 'a') {
          // If the node is an <a> element, make it clickable
          element.setAttribute('target', '_blank');
        } else {
          // If the node is not an <a> element, process its child nodes recursively
          this.processChildNodes(element.childNodes);
        }
      }
    });
  }

}

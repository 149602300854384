import { Component, ElementRef, ViewChild } from '@angular/core';

interface Message {
  text: string;
  user: boolean;
}

@Component({
  selector: 'app-chatbot-doc',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})


export class ChatbotComponent {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  messages: Message[] = [{ text: 'Hi there, how can I help you?', user: false }];
  userMessage = '';
  isTyping: boolean = false;

  sendMessage() {
    //   if (!this.userMessage.trim()) {
    //     return;
    //   }


    this.messages.push({
      text: this.userMessage,
      user: true
    });
    //   this.isTyping = true;

    //   this.dataService.askAI(this.sectionId, this.userMessage).subscribe(response => {
    //     // //console.log(this.sectionId);

    //     this.messages.push({
    //       text: response['message'],
    //       user: false
    //     });
    //     this.isTyping = false;
    //   });

    this.userMessage = '';
  }

  copyMessage(message: string) {
    navigator.clipboard.writeText(message);
  }

  scrollToBottom(): void {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../../app/backend/services/api.service';

@Component({
  selector: 'app-filecard',
  templateUrl: './filecard.component.html',
  styleUrls: ['./filecard.component.css']
})
export class FilecardComponent implements OnInit {

  @Input() file: any;
  @Input() project_uuid: any;
  @Output() seeFile: EventEmitter<any> = new EventEmitter();
  @Output() deleteNotif: EventEmitter<any> = new EventEmitter();

  constructor(private api: ApiService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    console.log(this.file);
  }

  get_domain(data) {
    var a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }

  formatFileSize(fileSize: any): string {
    if (fileSize != null) {
      let f_size_bytes = parseInt(fileSize);
      if (f_size_bytes < 1024)
        return `${f_size_bytes} B`
      if (f_size_bytes < 1024 * 1024)
        return `${(f_size_bytes / 1024).toFixed(2)} KB`
      if (f_size_bytes < 1024 * 1024 * 1024)
        return `${(f_size_bytes / 1024 / 1024).toFixed(2)} MB`
    }
    return "0 B"
  }

  seeFileCliked() {
    if (this.file.file_type == "url") {
      window.open(this.file.file_name, "_blank");
      return;
    }
    this.seeFile.emit(this.file);
  }

  deleteObject(e) {
    console.log("deleteObject");
    console.log(this.file);
    e.stopPropagation();
    this.deleteNotif.emit(this.file);
  }



  pollFileIndexStatus(file_uuid) {

    this.api.cd_getFileIndexStatus(file_uuid).subscribe((data: any) => {
      console.log(data);
      if (data.status == "indexing") {

        setTimeout(() => {
          this.pollFileIndexStatus(file_uuid);
        }, 5000);

      }
      else {
        // alert(data.status);
        this.file.object_status = data.status;
        this.file.file_size = data['file_size'];
      }

    }
    );

  }


  retryFileIndexing(e) {

    // alert("startFileIndexing");

    e.stopPropagation();

    // console.log(this.file);
    // return;

    this.file.object_status = "indexing";

    this.api.cd_startFileIndexing(this.project_uuid, this.file.object_id).subscribe((data: any) => {
      this.pollFileIndexStatus(this.file.file_uuid);
    });

  }



}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceApp {

  BASE_URL: string;
  // BASE_URL = "https://api.leapp.ai/";



  constructor(private http: HttpClient) {

    if (environment.production) {
      this.BASE_URL = "https://apiv2.knibble.ai/";
    } else {
      this.BASE_URL = "https://apiv2.knibble.ai/";
      // this.BASE_URL = "http://localhost:5002/";

    }

  }

  login(data) {
    // //console.log("in login");
    // //console.log(data);
    // alert("Logging IN ");
    return this.http.post(this.BASE_URL + "api/origlogin", data, {
      withCredentials: true
    });
  }

  logout() {
    alert("New");
    return this.http.get(this.BASE_URL + "api/protected", {
      withCredentials: true
    });
  }


  getToken() {

  }



}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../../../services/data.service'
import { Auth } from 'aws-amplify';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Storage } from '@aws-amplify/storage'
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { formatDistanceToNowStrict } from 'date-fns';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any;

  constructor(private dataService: DataService, private route: Router, private authenticatorService: AuthenticatorService) { }

  page: any;
  user_pic_url = "";
  user_logged_in = false;
  i_agree = false;

  ngOnInit(): void {

    this.authenticatorService.subscribe(() => {

      const { route } = this.authenticatorService;

      // alert(route);

      if (route === 'authenticated') {

        this.user_logged_in = true;

        // this.isUpgraded();
        // this.hasDeclared();


        this.dataService.getUser().subscribe((user) => {
          //console.log(user);
          this.user_logged_in = true;
          this.user = user;

          // //console.log(this.user);
          // if (!this.user.picture.includes("https://")) {
          //   this.getUserImage(this.user.picture);
          // } else {
          //   this.user_pic_url = this.user.picture;
          // }
        },
          (error) => {
            // //console.log(error);
          }
        );

        // // //console.log(this.route.url);
        // var url = this.route.url;
        // // if url contains dashboard set page to dashboard
        // if (url.includes("dashboard")) {
        //   this.page = "dashboard";
        // } else if (url.includes("kb")) {
        //   this.page = "kb";
        // }
        // else if (url.includes("browse")) {
        //   this.page = "browse";
        // }

        // else if (url.includes("learn")) {
        //   this.page = "learn";
        // }

        // this.getNotifications();

      } else {
        this.user_logged_in = false;
      }

    });


  }

  getMoment(datetime) {
    const dateString = datetime;
    const utcDate = new Date(Date.UTC(
      parseInt(dateString.substr(0, 4)),     // Year
      parseInt(dateString.substr(5, 2)) - 1, // Month (0-based)
      parseInt(dateString.substr(8, 2)),     // Day
      parseInt(dateString.substr(11, 2)),    // Hour
      parseInt(dateString.substr(14, 2)),    // Minute
      parseInt(dateString.substr(17, 2))     // Second
    ));

    return formatDistanceToNowStrict(utcDate, { addSuffix: true });
  }


  getRandomTailwindColor() {
    var colors = ["bg-red-800", "bg-blue-800", "bg-green-800", "bg-yellow-500", "bg-indigo-500", "bg-purple-500", "bg-pink-500"];
    var rand = Math.floor(Math.random() * 7);
    return colors[rand];
  }

  getUserImage(fname) {
    Storage.get(fname, {
      level: 'public'
    }).then((url) => {
      this.user_pic_url = url;
      this.user.picture = fname;
    });

  }

  user_upgrade = false;
  user_declared;
  plan = "";
  declaration: any;
  modal_class_declaration: string = "modal-close";


  hasDeclared() {
    this.dataService.hasDeclared().subscribe((data) => {

      //console.log(data);
      this.declaration = data;
      if (data['hasdeclared'] == true) {
        this.user_declared = true;
        this.modal_class_declaration = "modal-close";

      } else {
        this.user_declared = false;
        this.modal_class_declaration = "modal-open";
        // //console.log(this.modal_class_declaration);
      }
    }
    );
  }

  declare() {
    this.dataService.declare().subscribe((data) => {

      this.hasDeclared();

    }
    );
  }
  isUpgraded() {
    this.dataService.isUpgraded().subscribe((data) => {

      if (data['isUpgraded'] == true) {
        this.user_upgrade = true;
        this.plan = data['plan'];
      } else {
        this.user_upgrade = false;
      }
    }
    );
  }

  user_notifications = [
    // {
    //     "created_at": "2023-05-19 02:52:16.681050",
    //     "icon": "circle",
    //     "link": "/account",
    //     "notif": "test notification2",
    //     "notif_id": "606c2829-0534-45ee-82e6-1dc50eb871a6",
    //     "status": "unread",
    //     "user_id": "sureddy1abhishek@gmail.com"
    // },
    // {
    //     "created_at": "2023-05-19 02:52:05.208884",
    //     "icon": "default",
    //     "link": "/account",
    //     "notif": "test notification",
    //     "notif_id": "552d0773-61f3-403f-bb31-0cf3a0f18711",
    //     "status": "read",
    //     "user_id": "sureddy1abhishek@gmail.com"
    // },
    // {
    //     "created_at": "2023-05-16 14:40:24.406223",
    //     "link": "/account",
    //     "notif": "Update your profile",
    //     "notif_id": "d38206ec-f516-43ff-89cc-d75a55dcd396",
    //     "status": "unread",
    //     "user_id": "sureddy1abhishek@gmail.com"
    // }
  ]
  getUnreadNotifications() {
    return this.user_notifications.filter(x => x.status == 'unread').length;
  }
  getNotifications() {
    this.dataService.getNotifications().subscribe((data: any) => {
      // //console.log("notifications polled...", data);
      this.user_notifications = data['content'];
    });
  }

  updateNotification(notification: any) {
    if (notification.status == 'unread') {
      notification.status = 'read';
      if (notification.icon == null) {
        notification.icon = "default";
      }
      this.dataService.updateNotification(notification).subscribe(data => {
        // //console.log(data);
        //console.log("notification updated");
        // TODO: need to update the status in frontend??
      }, err => console.log(err));
    }
  }

  goToLink(link: string) {
    this.route.navigate([link]);
  }


  logout() {
    // alert("logout");
    try {

      this.dataService.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  ngOnDestroy() {
    this.dataService.stopPollingNotification();
  }
  // logout() {
  //   alert("logout");
  //   this.dataService.logout().subscribe((data) => {
  //     // //console.log(data);
  //     // redirect
  //     // window.location.href = "https://frontend223a496c-223a496c-dev.auth.us-east-1.amazoncognito.com/logout?client_id=3mhqeqkn8sn8kdfmu0ngj9ja11&logout_uri=http://localhost:4200/auth2/";
  //   });
  // }

}

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../backend/services/api.service';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {



  @Input() upgraded: any;
  imageUrl: any;
  user: any;
  user_loaded = false;
  user_pic_name = "";
  user_pic_url = "";

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.getUser().subscribe((user) => {
      console.log(user);
      this.user = user;

      if ('picture' in this.user && this.user.picture != null && this.user.picture != "") {
        console.log("Picture exists");
        if (this.user.picture.includes("https://")) {

          this.user_pic_url = this.user.picture;
        } else {

          this.getUserImage(this.user.picture);
        }
      } else {
        console.log("Picture doesnt exist");
        this.user_pic_url = "";
      }
      this.user_loaded = true;
    });

  }

  hello(e) {
    alert("hello");
  }

  onProfileImageClick() {
    const fileInput = document.querySelector('#image-dropzone-file') as HTMLInputElement;
    console.log(fileInput);
    //set value to null so that same file can be selected again
    fileInput.value = null;
    fileInput.click();
  }

  onFileSelectedImage(event: Event) {
    console.log("File selected");
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadProfilePic(file);
  }

  getUserImage(fname) {
    Storage.get(fname, {
      level: 'public'
    }).then((url) => {
      this.user_pic_url = url;
      this.user.picture = fname;
    });

  }



  private async uploadProfilePic(file: File) {
    console.log(file.name);
    var extension = file.name.split('.')[1];
    console.log(extension);
    var f_name = 'users/profile/' + this.user.user_id + "/" + this.user.user_id + "." + extension;
    this.user_pic_name = f_name;
    const result = await Storage.put(f_name, file, {
      level: 'public',
    }).then(() => {


      Storage.get(f_name, {
        level: 'public'
      }).then((url) => {
        this.imageUrl = url;
        // Update plan image
        this.api.update_user_image(f_name).subscribe((data: any) => {
          console.log(data);
          this.getUserImage(this.user_pic_name);
        }
        );
        // //console.log(this.imageUrl);
      });


      // // //console.log(signedURL);

    });



  }


  updatingprofile = false;
  updateUserNameAlias() {
    this.updatingprofile = true;
    this.api.updateUserAliasandName(this.user.alias, this.user.given_name).subscribe((data: any) => {
      // //console.log(data);
      if (data.success) {
        // this.toast.showToastNnotification("Successfully updated the profile!!", "success");
      }
      if (!data.success && data.message == "User handle already exists") {
        this.user.alias = data.alias;
        this.user.given_name = data.given_name;
        // this.toast.showToastNnotification(data.message, "failed");
      }
      this.updatingprofile = false;
    }
    );
  }

  customerportal = "";
  isLoading = true;

  goToCustomerPortal() {
    this.api.getCustomerPortal().subscribe((data: any) => {
      // //console.log(data);
      this.customerportal = data.session_url;
      this.isLoading = false;
      // Navigate to customer portal
      // window.location = this.customerportal; doesnt work
      window.open(this.customerportal, "_blank");
    }
    );
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
// import { AuthService } from '@auth0/auth0-angular';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { Storage } from '@aws-amplify/storage'
import { ToastComponent } from '../../ui/repeatable/toast/toast.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  @ViewChild(ToastComponent) toast: ToastComponent;

  sub_data: any;
  upgraded: any;
  customerportal = "";
  profile = null;
  isLoading = true;
  picture = "";
  user: any;
  imageUrl: string;


  constructor(public dataservice: DataService, private route: Router, private authenticatorService: AuthenticatorService) { }


  onImageClick() {
    // alert("image clicked" + plan.lp_id);
    // this.current_lp_image = plan.lp_id;
    // this.current_action_lp = plan;
    const fileInput = document.querySelector('input[type=file]') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadFile(file);
  }

  getUserImage(fname) {
    Storage.get(fname, {
      level: 'public'
    }).then((url) => {
      this.user_pic_url = url;
      this.user.picture = fname;
    });

  }

  user_pic_name = "";
  user_pic_url = "";

  private async uploadFile(file: File) {
    // //console.log(file.name);
    var extension = file.name.split('.')[1];
    // //console.log(extension);
    var f_name = 'users/profile/' + this.user.user_id + "/" + this.user.user_id + "." + extension;
    this.user_pic_name = f_name;
    const result = await Storage.put(f_name, file, {
      level: 'public',
    }).then(() => {


      Storage.get(f_name, {
        level: 'public'
      }).then((url) => {
        this.imageUrl = url;
        // Update plan image
        this.dataservice.update_user_image(f_name).subscribe((data: any) => {
          // //console.log(data);
          this.getUserImage(this.user_pic_name);
        }
        );
        // //console.log(this.imageUrl);
      });


      // // //console.log(signedURL);

    });



  }

  user_upgrade = false;
  plan = "";


  isUpgraded() {
    this.dataservice.isUpgraded().subscribe((data) => {

      if (data['isUpgraded'] == true) {
        this.user_upgrade = true;
        this.plan = data['plan'];
      } else {
        this.user_upgrade = false;
      }
    }
    );
  }

  updatingprofile = false;
  updateUserNameAlias() {
    this.updatingprofile = true;
    this.dataservice.updateUserAliasandName(this.user.alias, this.user.given_name).subscribe((data: any) => {
      // //console.log(data);
      if (data.success) {
        this.toast.showToastNnotification("Successfully updated the profile!!", "success");
      }
      if (!data.success && data.message == "User handle already exists") {
        this.user.alias = data.alias;
        this.user.given_name = data.given_name;
        this.toast.showToastNnotification(data.message, "failed");
      }
      this.updatingprofile = false;
    }
    );
  }

  user_logged_in = false;

  logout() {
    // alert("logout");
    try {

      this.dataservice.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  ngOnInit(): void {





    this.authenticatorService.subscribe(() => {
      const { route } = this.authenticatorService;


      if (route === 'authenticated') {


        this.dataservice.getUser().subscribe((user) => {
          this.user = user;
          this.user_logged_in = true;
          // //console.log(this.user);
          // this.getUserImage(this.user.picture);
          this.dataservice.isUpgraded().subscribe((data: any) => {
            // //console.log(data);
            this.upgraded = data;

          });
          if (!this.user.picture.includes("https://")) {
            this.getUserImage(this.user.picture);
          } else {
            this.user_pic_url = this.user.picture;
          }
        }
        );





      } else {
        // this.auth.loginWithRedirect();
        this.route.navigate(["/"]);
      }

    },
    );

  }


  goToCustomerPortal() {
    this.dataservice.getCustomerPortal().subscribe((data: any) => {
      // //console.log(data);
      this.customerportal = data.session_url;
      this.isLoading = false;
      // Navigate to customer portal
      // window.location = this.customerportal; doesnt work
      window.open(this.customerportal, "_blank");
    }
    );
  }


  checkOut() {

    // this.dataservice.checkout().subscribe((data) => {
    //   // //console.log(data);
    //   window.location = data['cc_url'];
    // })

  }

}



<input type="checkbox" id="my-modal-5" class="modal-toggle" />
<div class="modal {{modal_class_declaration}} " style=" z-index: 999999;" *ngIf="!declaration?.hasdeclared">
    <div class="modal-box w-6/12 max-w-5xl p-0 ng-white" style="background:#fff !important">
        <div class="py-3 px-8 border-b text-gray-800 font-semibold text-lg flex justify-between items-center">
            <div class="flex p-0 justify-start items-center">

                Consent

            </div>


        </div>

        <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start">

            <div class="block text-gray-700 font-normal text-base mb-2 p-4 bg-gray-100 rounded" for="name">
                Please note that AI generated may not be accurate, may be biased and may not be factually correct.
            </div>

            <div class="block text-gray-700 font-normal text-base mb-4 p-4 bg-gray-100 rounded mt-2" for="name">
                Leapp.ai relies on third party APIs and services for generating content. Leapp.ai does not guarantee the
                accuracy of the AI generated content.
            </div>

            <!-- <select [(ngModel)]="current_collection_for_add_url" class="select select-bordered w-full max-w-xs py-4">

                <option *ngFor="let coll of user_collections; let i = index" value="{{coll.coll_uuid}}">
                    {{coll.collection_name}}{{coll.coll_uuid}}</option>

            </select> -->

            <div class="flex justify-start items-center p-0">
                <input type="checkbox" [(ngModel)]="i_agree" /><span class="ml-2">I understand and agree to <a
                        href="/termsandconditions" target="_blank" class="text-blue-700">Terms & Conditions</a></span>
            </div>



            <button
                class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                *ngIf="i_agree" type="button" (click)="declare()">Continue</button>

            <button
                class="bg-blue-400 hover:bg-blue-700 text-white font-normal mt-8 mx-0 text-sm py-2 px-4 mb-4 rounded "
                *ngIf="!i_agree" disabled>Continue</button>

        </div>

    </div>

</div>
<div class="header-wrapper w-full bg-gray-900">

    <div id="header-container" class="flex justify-between items-center bg-inherit text-white px-4 py-0 mx-auto"
        style="max-width: 1300px;">

        <div class="flex justify-center items-center ">



            <div *ngIf="!user_logged_in">

                <a href="/" class="text-white bg-inherit hover:bg-gray-800 px-1 text-sm"
                    style=" text-decoration: none;">
                    Home
                </a>

                <a href="/pricing" class="text-white bg-inherit hover:bg-gray-800 px-1 ml-3 text-sm"
                    style="text-decoration: none; ">
                    Pricing
                </a>

            </div>

            <div class="text-white  flex justify-start items-center gap-x-10" style="margin-top:0px"
                *ngIf="user_logged_in">

                <!-- <a href="/dashboard" class="text-white bg-inherit hover:bg-gray-800 px-1"
                    [ngClass]="{ 'border-b-2  border-yellow-400': page === 'dashboard'}"
                    style="font-size:16px; font-weight: 600px; padding-top: 16px; padding-bottom: 16px; text-decoration: none;">
                    Learning Plans
                </a>

                <a href="/browse" class="text-white bg-inherit hover:bg-gray-800 px-1"
                    [ngClass]="{ 'border-b-2  border-yellow-400': page === 'browse'}"
                    style="font-size:16px; font-weight: 600px;text-decoration: none; padding-top: 16px; padding-bottom: 16px;">
                    Explore
                </a> -->

                <!-- <button class="text-white bg-inherit border-none" style="font-size:16px; font-weight: 600px;">
                    Learning Plans
                </button> -->

                <!-- <button class="text-white bg-inherit border-none" style="font-size:16px; font-weight: 600px;">
                    Browse
                </button> -->

                <!-- <a href="/kb" class="text-white bg-inherit  hover:bg-gray-800 px-1"
                    style="font-size:16px; font-weight: 600px;text-decoration: none; padding-top: 16px; padding-bottom: 16px;"
                    [ngClass]="{ 'border-b-2  border-yellow-400': page === 'kb'}">
                    Knowledge Base
                </a>

                <div *ngIf="page==='learn'" class="text-white bg-inherit  hover:bg-gray-800 px-1"
                    style="font-size:16px; font-weight: 600px; padding-top: 16px; padding-bottom: 16px;"
                    [ngClass]="{ 'border-b-2  border-yellow-400': page === 'learn'}">
                    Learn
                </div> -->



                <!-- <button class="text-white bg-inherit border-none" style="font-size:16px; font-weight: 600px;">
                    Notes
                </button> -->



            </div>

            <div class="text-white  flex justify-start items-center gap-x-10" style="margin-top:0px">

                <div class="bg-inherit border-0  px-1 text-gray-900"
                    style="font-size:16px; font-weight: 600px; padding-top: 16px; padding-bottom: 16px;">
                    @
                </div>

            </div>


        </div>



        <div class="flex justify-end items-center">
            <!-- <div class="dropdown px-2 mr-8" *ngIf="user_logged_in">
                <button class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                    type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <div class="avatar mr-0" style="position: relative;">
                        <div class="w-8 rounded-full rinng ring-primary ring-offset-base-100 ring-offset-2">
                            <i class="fa-light fa-bell text-gray-100 text-lg"></i>
                        </div>
                        <div class="w-4 h-4 rounded-full ring-primary  bg-yellow-400 text-gray-900 text-xs flex justify-center items-center"
                            style="margin-left: -15px;">
                            {{this.getUnreadNotifications()}}
                        </div>

                    </div>

                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                    style="width: 350px; max-height: 400px; overflow-y: auto;" id="style-1">
                    <div *ngFor="let notification of this.user_notifications" class="px-2 py-2 border-b dropdown-item"
                        style="border-bottom: solid 1px #f2f2f2;">
                        <div (click)="updateNotification(notification); goToLink(notification?.link);"
                            class="flex justify-start items-start ">
                            <div class="w-3 px-2" style="z-index:100000">
                                <i class="fa-solid fa-circle  text-xs text-green-300"
                                    *ngIf="notification.status==='read'"></i>
                                <i class="fa-solid fa-circle text-yellow-300 text-xs"
                                    *ngIf="notification.status==='unread'"></i>
                            </div>
                            <div class="px-2 ml-2 ">
                                <a class="text-wrap text-sm text-gray-800" *ngIf="notification.status==='unread'">
                                    {{notification.notif}} <br><span
                                        class="text-gray-400 font-">{{this.getMoment(notification.created_at)}}</span>
                                </a>
                                <a class="text-wrap text-sm text-gray-400" *ngIf="notification.status!=='unread'">
                                    {{notification.notif}}, <br><span
                                        class="text-gray-400 font-">{{this.getMoment(notification.created_at)}}</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div> -->


            <!-- <div *ngIf="user_logged_in" class="mr-8 flex">

                <div *ngIf="user_upgrade" class="text-sm flex justify-center items-center">
                    <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                    {{plan}} plan
                </div>

                <div *ngIf="!user_upgrade" class="text-sm flex justify-center items-center">
                    <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                    Basic plan
                </div>

                <div *ngIf="!user_upgrade" class="ml-4">
                    <a href="/pricing" class="text-white bg-inherit hover:bg-gray-800 p-2 text-sm"
                        style=" font-weight: 600px;text-decoration: none; border:solid 1px #a189f9">
                        Upgrade
                    </a>
                </div>

            </div> -->




            <div class="dropdown px-0" *ngIf="user_logged_in">
                <button class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                    type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <div class="avatar mr-0">
                        <!-- <div class="w-8 rounded-full rihng ring-primary ring-offset-base-100 ring-offset-2"
                            *ngIf="user?.picture">
                            <img src="{{user_pic_url}}" referrerpolicy="no-referrer" />
                        </div> -->
                        <div class="w-8 h-8 rounded-full rinng ring-primary ring-offset-base-800 ring-offset-0 bg-yellow-200"
                            *ngIf="!user?.picture">
                            <div class="flex justify-center items-center h-full">
                                <div class="text-base text-gray-800 capitalize mt-1 font-semibold">
                                    {{user?.user_uuid[0]}}
                                </div>
                            </div>
                        </div>
                    </div>



                    <i _ngcontent-ilr-c103="" class="fa-solid text-base text-gray-100 fa-caret-down ml-1"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item font-semibold py-1 border-b">{{user?.user_uuid}}</a>
                    <a class="dropdown-item" href="/account">Account</a>

                    <a class="dropdown-item" href="https://tally.so/r/mVj8QN">Contact Us</a>
                    <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                    <a class="dropdown-item text-gray-500" href="#">Delete Account</a>
                </div>
            </div>

            <!-- <a class="cursor-pointer" (click)="logout()">Logout</a> -->




        </div>



        <div class="text-white  flex justify-start items-center gap-x-4" style="margin-top:0px" *ngIf="!user_logged_in">



            <div class="ml-4">
                <a href="https://frontend223a496c-223a496c-dev.auth.us-east-1.amazoncognito.com/signup?response_type=code&client_id=7u1aheh1q4r8gcf3n27ftfj39l&redirect_uri=https://leapp.ai/auth2/"
                    class="text-white bg-inherit hover:bg-gray-800 p-2 text-sm"
                    style="text-decoration: none; border:solid 1px #a189f9">
                    Sign Up
                </a>
            </div>

            <div class="ml-0 px-0">
                <a href="https://frontend223a496c-223a496c-dev.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=7u1aheh1q4r8gcf3n27ftfj39l&redirect_uri=https://leapp.ai/auth2/"
                    class="text-white bg-inherit hover:bg-gray-800 p-0 text-sm"
                    style=" font-weight: 600px;text-decoration: none; border:solid 0px #a189f9">
                    Login
                </a>
            </div>




        </div>




    </div>



</div>

<div class="header-wrapper w-full bg-blue-50" *ngIf="!user_logged_in">

    <div id="header-container"
        class="flex justify-between items-center bg-blue-50 text-gray-500 text-sm px-4 py-2 mx-auto"
        style="max-width: 1300px; font-family: 'Poppins';">
        Login to customize this learning plan or to create your own learning plan
    </div>

</div>




<!-- <div class="" style="position:absolute; top:0; left:0; right:0; bottom:0; background: #fff; opacity: 0%;z-index: 99999;"
    *ngIf="!user_declared && user_logged_in">

</div> -->
<div class=" flex w-full px-32 border-0">

    <div class="flex relative container mx-auto justify-between items-center py-0">
        <div class=" w-4/12  py-4 text-2xl font-semibold text-gray-800">
            <div class="text-gray-800 flex justify-start items-center"
                style="margin-left: 5px !important;font-weight: 600;">
                <i class="fa-regular fa-box-open mr-2 text-pink-400" style="font-size: 22px;"></i>
                <!-- <i class="fa-regular text-3xl fa-box-open"></i> -->
                <!-- &nbsp;WidgetBox -->
            </div>
        </div>

        <div class="w-8/12 flex justify-end px-0 items-end ">
            <!-- <div class=" rounded-2xl w-8 h-8 border border-white  flex mr-4" *ngIf="!auth.user$ | async as user">
                <img [src]="user.picture" alt="User's profile picture" class="rounded-full inline" />
            </div> -->




            <!-- <div class=" mr-2 rounded-2xl text-gray-400 flex justify-center items-center">
                <a href="https://izub6f5z3hn.typeform.com/to/ztIBqmE8"
                    class="p-2 focus:outline-none font-bold hover:bg-gray-800 hover:text-white rounded no-underline"
                    style="text-decoration: none;">Feedback/Support</a>
            </div> -->




            <!-- <div
                class="mr-2 bg-gray-800 text-white ml-2 rounded-2xl hover:bg-gray-800 hover:text-gray-800 text-gray-800 bg-white border-0 hover:bg-pink-500   border-pink-500  flex justify-center items-center">
                <a href="https://www.notion.so/WidgetBox-Gallery-e4e329ea9064463da9e0b0899d25bf63"
                    class="p-2 bg-white  text-gray-600 border-0 rounded"
                    style="text-decoration: none; border:solid 0 !important">
                    <i class="fa-solid fa-gallery-thumbnails"></i>&nbsp;Gallery</a>
            </div> -->



            <!-- <div
                class="mr-2  ml-2 rounded px-4 hover:bg-gray-800 hover:text-gray-800 text-gray-800 bg-white border hover:bg-pink-500  border-pink-500  flex justify-center items-center">
                <button (click)="auth.loginWithRedirect()" class="font-bold focus:outline-none   p-2 rounded">Log
                    in</button>
            </div> -->


            <div
                class="mr-2 bg-gray-800 text-white ml-2 rounded-2xl hover:bg-gray-800 hover:text-gray-800 text-gray-800 bg-white border-0 hover:bg-pink-500   border-pink-500  flex justify-center items-center">
                <a href="https://tally.so/r/wMe6QY" class="p-2 bg-white  text-gray-600 border-0 rounded"
                    style="text-decoration: none; border:solid 0 !important">
                    <i class="fa-regular fa-message"></i>&nbsp;Contact us</a>
            </div>











            <!-- <div class=" rounded-lg  p-2 flex mr-4 justify-center items-center">
                <i class="fa-solid fa-message-lines"></i>&nbsp; Feedback
            </div> -->
        </div>

    </div>

</div>


<div class="container px-32 mx-auto flex justify-center items-center h-full" style="margin-top:80px">


    <div class="px-10 flex justify-start items-start">

        <!-- <button (click)="siginWithGoogle()">Open
            Google</button> -->



        <amplify-authenticator class="mr-4" [services]="services" [loginMechanisms]="['email']" [socialProviders]="[]"
            [initialState]="state">
            <ng-template amplifySlot=" authenticated" let-user="user" let-signOut="signOut">
                <h1>Logging in ....</h1>
                <!-- <button (click)="signOut()">Sign Out</button> -->
            </ng-template>
        </amplify-authenticator>

        <div class=" px-10 m-0 border-l w-2/3 h-full flex flex-col" role="alert" *ngIf="state != 'signUp'">
            <span class="text-lg font-bold"><i class="fa-regular fa-triangle-exclamation "></i>&nbsp; Please Note</span>



            <span class="my-4">We have migrated to a more secure authentication system.</span>

            <!-- <span class="mb-0">If you are a new user signing up, ignore this message and continue to "Create
                Account"</span>


            <span class="mt-10 font-bold">If you signed
                up
                with your
                Google account:</span>

            <ul class="pl-4 pt-2">
                <li><i class="fa-regular fa-circle-chevron-right"></i>&nbsp;Please continue to
                    "Sign
                    In with Google."
                </li>

            </ul> -->


            <span class="font-bold mt-0">If you signed up with email and password before August 3rd 2022:</span>

            <ul class="pl-4 pt-2">
                <li><i class="fa-regular fa-circle-chevron-right"></i>&nbsp;Please re-create your account by clicking
                    "Create
                    Account"
                </li>
                <li><i class="fa-regular fa-circle-chevron-right"></i>&nbsp;Use the same email that you used previously.
                </li>

            </ul>





            <span class="mt-4">We apologize for any inconvinience. Please <a href="https://tally.so/r/wMe6QY"
                    class="text-blue-500">contact
                    us</a> if you have trouble signing up or
                logging in.</span>



        </div>

    </div>


</div>

<div class="flex container mx-auto px-32 mt-10 justify-center">




</div>
<div *ngIf="this.status==='success'" id="toast-bottom-right"
    class="fixed flex items-center justify-start w-fit p-2  text-gray-500 bg-green-100 rounded-lg shadow mx-auto bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-green-100"
    style="border: 0px solid green; background:#000;opacity: 70%; color:white; position:absolute; margin:auto; left:0; right:0; top:10;z-index: 9999999;"
    role="alert">

    <i class="fa-light fa-circle-check text-xl mr-2 ml-2 text-green-500"></i>

    <div class="px-1 text-sm font-normal">{{this.message}}</div>
</div>

<div *ngIf="this.status==='failed'" id="toast-bottom-right"
    class="fixed flex items-center justify-start w-fit  p-2  text-gray-500 bg-green-100 rounded-lg shadow mx-auto bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-green-100"
    style="border: 0px solid green; background:#000;opacity: 70%; color:white; position:absolute; margin:auto; left:0; right:0; top:10;z-index: 9999999;"
    role="alert">

    <i class="fa-light fa-warning text-xl ml-2 mr-2 text-red-500"></i>

    <div class="px-1 text-sm font-normal">{{this.message}}</div>
</div>
import { Component, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { Storage } from 'aws-amplify';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { th, tr } from 'date-fns/locale';
import { set } from 'date-fns';
import { Auth } from 'aws-amplify';
import { SliceWordsPipePipe } from '../components/pipes/slice-words-pipe.pipe';
import { UrlPipe } from '../components/pipes/url.pipe';
import { ToastComponent } from '../components/ui/repeatable/toast/toast.component';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


declare var bootbox: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent {
  @ViewChild(ToastComponent) toast: ToastComponent;
  @ViewChild('shareLinkInput', { static: false }) shareLinkInput: ElementRef;


  copyied_text = false;

  isCopied = false;
  p_start: any;
  loading_limits = true;
  limits: any = {};
  tracker: any = {};
  embed: any = {};
  resources_view_type = 'grid';

  // ...

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Add any visual feedback for dragover event (e.g., change background color)
  }

  handleDragEnter(event: DragEvent) {
    event.preventDefault();
    // Add any visual feedback for dragenter event (e.g., change border color)
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Add any visual feedback for dragleave event (e.g., revert border color)
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      // Handle the dropped files (e.g., read file data, process, etc.)
      this.handleFileInput(files);
    }
    // Reset any visual feedback (e.g., revert border color)
  }

  copyTextToClipboard() {
    const code = `<knibble-bot [chatbot_id]="` + this.project['embed_uuid'] + `" #chatbot></knibble-bot>
<script src="https://s3.amazonaws.com/knibble.ai.assets/package.js" type="module"></script>`;
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

  copyNotionLink() {
    const code = `https://knibble.ai/chat/` + this.project['embed_uuid'];

    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

  // Function to copy the text to clipboard
  copyTexttoClipboard() {
    // alert("Copied to clipboard");
    // Select the input element
    this.copyied_text = true;
    this.shareLinkInput.nativeElement.select();

    // Execute the copy command
    document.execCommand('copy');

    // Deselect the input element
    this.shareLinkInput.nativeElement.setSelectionRange(0, 0);
    setTimeout(() => {
      this.copyied_text = false;
    }
      , 1000);
  }

  current_tab = "files";
  current_tab2 = "chat";
  current_subtab = "all";
  selectedProject = "";
  fileToUpload: File;
  upload = false;
  modal_class_declaration: string = "modal-close";
  modal_class_add_url: string = "modal-close";
  modal_class_share_project: string = "modal-close";
  modal_class_delete_project: string = "modal-close";
  modal_class_nomore_files: string = "modal-close";
  project_objects: any = [];
  project_objects_display: any = [];
  project_objects_display_search: any = [];
  project_uuid: any;
  project_name: string = "Untitled project"
  project: any;
  upload_index_progress = false
  pdfSrc = "";
  txtSrc = "";
  pageVariable = 0
  mode = 'base';
  timerId = null;
  add_menu_hidden = true;
  embed_chat_messages: any = [];
  chathistory: Array<[string, string]> = [];
  chat_id = "";
  plan = "";
  can_upload_new_file = false;
  can_upload_new_url = false;
  can_create_new_chat = false;
  max_file_size_uploadable;

  public highlightAll = false;
  public matchCase = false;
  public wholeWord = false;
  public ignoreAccents = false;
  current_message_tab = "chat";
  current_sources: any = [];
  permissions = {
    'enable_sharing': false,
    'view_files': true,
    'download_files': true,
    'view_chats': true
  }
  share_code = "";
  is_shared = false;

  shared_mode_tab = "interact";

  temp_chat_messages: any;
  embed_show: any = null;
  projects_polling = null;
  scrollContainer: any;
  take_last_n_messages = 3;


  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @ViewChild('chatScroll', { static: false }) private myScrollContainer: ElementRef;
  @ViewChild('chatScroll2') private myScrollContainer2: ElementRef;

  toggleAddMenu() {
    // this.add_menu_hidden = !this.add_menu_hidden;
    $('#' + 'button-drawer').toggle('fast', 'linear');
    $('#' + 'button-plus').toggle('fast', 'linear');
    $('#' + 'button-minus').toggle('fast', 'linear');

    // alert($('#' + 'button-drawer').is(":visible"));
  }

  user_upgrade = false;

  isUpgraded() {
    this.dataService.isUpgraded().subscribe((data) => {

      // console.log(data);

      if (data['isUpgraded'] == true) {
        this.user_upgrade = true;
        this.plan = data['plan'];
      } else {
        this.plan = "Basic";
      }
      // this.getAllowedFunctions();
      this.getLimits();
    }
    );
  }

  getAllowedFunctions() {
    this.dataService.cd_getAllowedFunctions(this.plan).subscribe((data: any) => {
      //console.log(data);
      this.p_start = data;
      this.can_upload_new_file = data?.add_new_files;
      this.can_upload_new_url = data?.add_new_urls;
      this.can_create_new_chat = data?.create_new_chat;
      this.max_file_size_uploadable = data?.max_file_size_bytes;
    }, err => console.log("error:", err));
  }

  getLimits() {
    console.log("getting limits");
    this.loading_limits = true;
    this.dataService.cd_getLimit(this.plan).subscribe((data: any) => {
      this.loading_limits = false;
      console.log(data);
      this.limits = data['plan_details'];
      this.tracker = data['track_obj'];
      // this.limits['max_messages_per_month'] = 5;
    }, err => console.log("error:", err));
  }


  projectNameEdited() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      //console.log("project name edited....");
      // TODO: need to call the corresponding api to update the project name
      this.dataService.cd_updateUserProject(this.project).subscribe(data => {
        //console.log("data", data);
      }, err => console.log(err));
    }, 2000);
  }

  setSharedModeTab(tab) {
    if (tab != "interact") {
      this.temp_chat_messages = this.chat_messages;
    } else {
      this.chat_messages = this.temp_chat_messages;
    }
    this.shared_mode_tab = tab;
  }


  share_info: any;
  getShare() {
    this.dataService.cd_getShare(this.project_uuid).subscribe((data: any) => {
      //console.log(data);
      if (data) {
        this.permissions = data['share']['permissions'];
        this.share_info = data['share'];
      }
    }
    );
  }

  onSharingToggle(event: any) {

    //console.log(this.permissions, event);
    if (this.permissions['enable_sharing']) {

    }
    this.dataService.cd_createShare(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
      this.share_info = data['share'];
    }
    );
  }

  onPermissionChange(event: any) {
    //console.log(this.permissions, event);
    this.dataService.cd_updateSharePermissions(this.project_uuid, this.permissions).subscribe((data: any) => {
      //console.log(data);
    }
    );
  }

  scrollChatEnd(id) {

    // //console.log("scrolling to bottom", $('#' + 'message_window').html());
    setTimeout(() => {

      document.querySelector('#' + 'end_of_chat').scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });

    }, 300);

    // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 20;

  }
  scrollToBottom(): void {

    //console.log("scrolling to bottom");
    //console.log("scrolling to bottom", this.myScrollContainer.nativeElement);
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      // this.myScrollContainer2.nativeElement.scrollTop = this.myScrollContainer2.nativeElement.scrollHeight;
    } catch (err) {

      //console.log("error in scrolling to bottom", err);
    }
  }


  search(stringToSearch: string) {
    this.pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch, type: 'again', caseSensitive: false, findPrevious: true, highlightAll: true, phraseSearch: true
    });
  }

  Old(text) {


    if (this.ngxExtendedPdfViewerService.findMultiple(
      text,
      {
        highlightAll: true,
        matchCase: this.matchCase,
        wholeWords: this.wholeWord,
        ignoreAccents: this.ignoreAccents
      })) {
      //  this._searchtext = text;
    }
  }

  search_display_objs = "";
  displayed_objects;
  searchObjects() {
    if (this.search_display_objs === "") {
      this.project_objects_display = this.project_objects_display_search;
    } else {
      this.project_objects_display = this.project_objects_display_search.filter(object => {
        if (object.file_type !== "url")
          return object.file_name.toLowerCase().indexOf(this.search_display_objs.toLowerCase()) !== -1;

        else
          return object.title.toLowerCase().indexOf(this.search_display_objs.toLowerCase()) !== -1;
      });
    }
    //console.log(this.project_objects_display);
  }


  constructor(public dataService: DataService, private aroute: ActivatedRoute, private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService, private route: Router, private authenticatorService: AuthenticatorService, private sanitizer: DomSanitizer) {

    pdfDefaultOptions.assetsFolder = 'assets';
  }


  no_access = true;

  loading_project = true;

  user: any = null;
  user_logged_in = false;

  show_login = false;

  logout() {
    // alert("logout");
    try {

      this.dataService.logout().subscribe((data) => {

        // alert(data);
        Auth.signOut().then(() => {
          this.route.navigate(["/"]);
          location.reload();
        });
      });


    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

  getUser() {
    this.dataService.getUser().subscribe((user) => {
      //console.log(user);
      this.user_logged_in = true;
      this.user = user;

    },
      (error) => {
        console.log(error);
      }
    );
  }

  embed_tab = "customize";

  setEmbedScreen(tab) {
    this.embed_tab = tab;
  }

  domain_url = "";

  ngOnInit(): void {


    if (this.aroute.snapshot.paramMap.get('chat_id') != null) {


      this.embed_show = true;
      // alert("chat id is " + this.aroute.snapshot.paramMap.get('chat_id'));

      this.chat_id = this.aroute.snapshot.paramMap.get('chat_id');
      this.dataService.cd_getEmbed(this.chat_id).subscribe((data: any) => {

        this.embed = data['embed'];

        this.getLogoImage(this.embed['logo_path']);

        // get Project

        this.project_uuid = this.embed['project_uuid'];
        this.getProjectForEmbed();

      });

    }



    else {

      this.authenticatorService.subscribe(() => {

        const { route } = this.authenticatorService;
        if (route === 'authenticated') {

          this.domain_url = this.dataService.BASE_URL;



          // bootbox.alert('This is the default alert!');
          if (this.aroute.snapshot.paramMap.get('share_code') == null) {


            this.dataService.getUser().subscribe((user) => {
              //console.log(user);
              this.user_logged_in = true;
              this.user = user;
              this.no_access = false;
              this.project_uuid = this.aroute.snapshot.paramMap.get('ppid');
              //console.log();
              this.isUpgraded();
              this.getProject()
              this.getObjectsForProject();
              this.getUserInteractions();
              // this.getShare();
              // this.getChatbotConfig();
              // this.getChatbotConfig()
              // this.updateChatbotConfig("this is test persona", "1.0");
              this.loading_project = false;

            },
              (error) => {
                //console.log(error);
                this.user_logged_in = false;
                this.no_access = true;
                this.loading_project = false;
              }
            );




          } else {
            // this.getUser();
            this.share_code = this.aroute.snapshot.paramMap.get('share_code');
            this.is_shared = true;

            //console.log(this.share_code, this.user_logged_in);
            this.dataService.getUser().subscribe((user) => {

              this.user_logged_in = true;
              this.user = user;

              this.dataService.cd_getShareByCode(this.share_code).subscribe((data: any) => {
                console.log(data);
                if (data) {



                  this.permissions = data['share']['permissions'];
                  if (data['share']['permissions']['enable_sharing']) {
                    this.no_access = false;

                    this.project_uuid = data['share']['share_uuid'];

                    this.getProjectForShare();
                    // this.getProjectForEmbed();

                  }
                  else {

                    this.no_access = true;
                    this.loading_project = false;

                  }


                }
              }, (error) => {
                this.no_access = true;
                this.loading_project = false;
                //console.log(error);
              }
              );
            },
              (error) => {
                // alert(error);
                this.user_logged_in = false;
                this.no_access = true;
                this.loading_project = false;
                //console.log(this.no_access + this.loading_project.toString() + this.user_logged_in.toString());
              }
            );

          }



        } else {
          this.route.navigate(["/"]);
        }

      });

    }






  }



  getEmbed(embed_uuid) {
    this.dataService.cd_getEmbed(embed_uuid).subscribe((data: any) => {
      console.log(data);
      if (data) {

        this.embed = data['embed'];
        // if (this.embed['logo_rounded'] == true) {
        //   alert("rounded");
        // }
        this.getLogoImage(this.embed['logo_path']);
      }
    }
    );
  }


  getProject() {
    this.dataService.cd_getUserProject(this.project_uuid, this.share_code, this.chat_id).subscribe((data: any) => {
      this.project = data.project;
      console.log("hhh", this.project);
      if (!this.is_shared) {
        this.getEmbed(this.project['embed_uuid']);
      }


    }, err => {
      //console.log("error: " + err);
      this.loading_project = false;
      this.no_access = true;
    }
    );
  }


  getProjectForShare() {
    this.dataService.cd_getUserProject(this.project_uuid, this.share_code, this.chat_id).subscribe((data: any) => {
      this.project = data.project;
      console.log("hhh", this.project);
      if (!this.is_shared) {
        this.getEmbed(this.project['embed_uuid']);
      }
      this.getObjectsForProject();
      this.getUserInteractions();
      this.loading_project = false;

    }, err => {
      //console.log("error: " + err);
      this.loading_project = false;
      this.no_access = true;
    }
    );
  }

  getProjectForEmbed() {
    this.dataService.cd_getUserProjectForEmbed(this.project_uuid, this.share_code, this.chat_id).subscribe((data: any) => {
      console.log("project", data);
      this.project = data?.project;
      //console.log("hhh", this.project);

      this.limits = data['plan_details'];
      this.tracker = data['tracker_obj'];
      console.log("tracker", this.tracker);
      console.log("limits", this.limits);
      if (!this.is_shared) {
        this.getEmbed(this.project['embed_uuid']);
      }

    }, err => {
      //console.log("error: " + err);
      this.loading_project = false;
      this.no_access = true;
    }
    );
  }

  getIconAndColor(element: any) {
    if (element['file_type'] == "pdf") {
      element['icon'] = "file-pdf";
      element['iconcolor'] = "red";
    }
    else if (element['file_type'] == "docx" || element['file_type'] == "doc") {
      element['icon'] = "file-word";
      element['iconcolor'] = "blue";
    }
    else if (element['file_type'] == "pptx" || element['file_type'] == "ppt") {
      element['icon'] = "file-powerpoint";
      element['iconcolor'] = "orange";
    }
    else if (element['file_type'] == "xlsx" || element['file_type'] == "xls") {
      element['icon'] = "file-excel";
      element['iconcolor'] = "green";
    }
    else if (element['file_type'] == "txt") {
      element['icon'] = "file-lines";
      element['iconcolor'] = "gray";
    }

    return element;
  }

  indexing = false;
  atleast_one_indexing = false;
  loading_project_objects = true;

  getObjectsForProject() {



    this.dataService.cd_getObjectsForProject(this.project_uuid, this.share_code).subscribe((data: any) => {
      //console.log(data['objects']);

      this.atleast_one_indexing = false;

      this.project_objects = [];

      data['objects'].forEach((element: any) => {

        // add font awesome icon

        // alert(element.object_status)

        element = this.getIconAndColor(element);

        this.project_objects.push(element);
        this.setCurentSubTab(this.current_subtab);

        if (element['object_status'] == "indexing") {
          // alert("indexing");
          this.atleast_one_indexing = true;
        }


        //console.log(element);
      });

      this.loading_project_objects = false;

      this.indexing = this.atleast_one_indexing;

      if (!this.indexing) {
        clearInterval(this.projects_polling);
      }
      //console.log("project_objects", this.project_objects);

      setTimeout(() => {

        if (this.project_objects.length == 0) {
          this.toggleAddMenu();
          // $('#button-drawer').addClass('animate-ping');
        } else {
          // $('#button-drawer').removeClass('animate-ping');
        }

      }, 200);


    }
    );

  }

  setCurentSubTab(tab: string) {
    //console.log(tab);
    this.current_subtab = tab;
    // this.current_tab = tab;
    if (tab === 'all')
      this.project_objects_display = this.project_objects;
    else if (tab === 'links') {
      this.project_objects_display = [];
      this.project_objects.forEach(element => {
        if (element?.file_type === 'url')
          this.project_objects_display.push(element);
      });

    }
    else if (tab === 'files') {
      this.project_objects_display = [];
      this.project_objects.forEach(element => {
        if (element?.file_type !== 'url')
          this.project_objects_display.push(element);
      });
    }

    this.project_objects_display_search = this.project_objects_display;
    this.searchObjects();
    //console.log(this.project_objects);
    //console.log(this.project_objects_display);

  }

  setCurrentTab(tab: string) {
    this.current_tab = tab;

  }
  setCurrentTab2(tab: string) {
    this.current_tab2 = tab;

  }

  uploading_file_name = "";
  uploading_file_extension = "";
  handleFileInput(event: any) {
    this.fileToUpload = event.target.files[0];
    this.uploading_file_name = this.fileToUpload.name;
    // assuming almost all file extensions work with this
    this.uploading_file_extension = this.fileToUpload.name.split('.').pop().toLowerCase();
  }

  openModal() {

    if (this.limits.is_own_key && !this.limits.is_key_generated) {
      alert("Please add your OpenAI API key to continue.");
      return;
    }

    var total_file_size = (this.tracker['total_file_size'] - this.tracker['total_del_file_size']) / 1024 / 1024;
    var allowed_file_size = this.limits['max_total_storage_mb'];
    var total_files_uploaded = this.tracker['n_files'] - this.tracker['n_del_files'];
    var allowed_files_uploaded = this.limits['max_files'];

    console.log("total_file_size", total_file_size);
    console.log("allowed_file_size", allowed_file_size);
    console.log("total_files_uploaded", total_files_uploaded);
    console.log("allowed_files_uploaded", allowed_files_uploaded);
    // if (!this.p_start.add_new_files) {
    //   this.modal_class_nomore_files = "modal-open";
    //   return
    // }

    // allowed_files_uploaded = 10;

    // test with 1 mb
    // allowed_file_size = 1;
    // allowed_files_uploaded = 1;

    if (total_file_size >= allowed_file_size || total_files_uploaded >= allowed_files_uploaded) {
      this.modal_class_nomore_files = "modal-open";
      return
    }
    this.upload = true;
    this.modal_class_declaration = "modal-open";
  }

  openDeleteModal() {
    this.modal_class_delete_project = "modal-open";
  }

  closeModalEve(e) {
    this.closeModal();
    this.removeUpload(e);
  }

  closeModal() {
    this.upload = false;
    this.modal_class_declaration = "modal-close";
  }

  confirmDeleteProject() {
    this.dataService.cd_deleteProject(this.project_uuid).subscribe(data => {
      this.redirectToMain();
    }, err => console.log(err));
  }

  redirectToMain() {
    this.route.navigate(['/main']);
  }
  removeUpload(e) {
    e.preventDefault();
    this.fileToUpload = null;
    this.uploading_file_name = "";
    this.uploading_file_extension = "";
  }



  current_view_obj = {};
  textData = "";
  viewFile(obj) {

    //console.log(obj);

    if (obj.file_type == "url") {
      // open url in new tab
      window.open(obj['file_name'], '_blank');
      return;
    }
    else if (obj.is_external) {
      window.open(obj['file_path'], '_blank');
      return;
    }
    else if (obj.file_type == "txt") {
      this.current_tab = "view_txt";
      this.textData = "";

      this.current_view_obj = this.getIconAndColor(obj);

      Storage.get(obj['file_path'])
        .then((url) => {

          //console.log(url);
          //  read data from url
          try {
            //console.log(url);
            fetch(url).then((response) => response.text()).then((data) => {

              this.textData = data;
              //console.log(data);

            }

            ).catch((err) => {
              //console.log(err);
            });;
          } catch (err) {
            //console.log(err);
          }
        },
          (error) => {
            //console.log(error);
          }
        );
      return;

    }



    this.current_tab = "view_pdf";

    this.current_view_obj = this.getIconAndColor(obj);
    //console.log(obj, !obj['is_external'])
    if (!obj['is_external']) {
      Storage.get(obj['file_path']).then((data: any) => {
        //console.log(data);
        this.pdfSrc = data;
      });

    }
    else {
      this.pdfSrc = obj['file_path'];
    }



    // this.pdfSrc = obj['file_path'];
    // 
  }

  viewUrl(obj) {

    //console.log(obj);

    this.current_tab = "view_url";

    this.current_view_obj = this.getIconAndColor(obj);




    // this.pdfSrc = obj['file_path'];
    // 
  }

  // Deleting resources code
  deleteObject(e, obj) {
    e.stopPropagation();
    this.dataService.cd_deleteObject(obj?.object_id, this.project_uuid, obj?.file_size).subscribe(data => {
      //console.log("successfully deleted the object...", data);
      let doc_idx = this.project_objects.map(x => x?.object_id).indexOf(obj?.object_id);
      if (doc_idx != -1) {
        //console.log("index found...", doc_idx);
        this.project_objects.splice(doc_idx, 1);
        // To set the displaying resources also
        this.setCurentSubTab(this.current_subtab);
        // this.getAllowedFunctions();
        this.getLimits();
      }
    }, err => console.log(err));
  }


  chat_messages = [];
  current_input_message = "";
  typing = false;

  get_domain(data) {
    var a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }


  toggleSlideover() {
    document.getElementById('slideover-container').classList.toggle('invisible');
    // document.getElementById('slideover-bg').classList.toggle('opacity-0');
    // document.getElementById('slideover-bg').classList.toggle('opacity-50');
    document.getElementById('slideover').classList.toggle('translate-x-full');
  }


  getAnswer() {
    this.typing = true;
    var question = this.current_input_message;
    this.current_input_message = "";

    // console.log("question", this.tracker['n_questions'], this.limits['max_messages_per_month']);

    if (parseInt(this.tracker['n_questions']) >= parseInt(this.limits['max_messages_per_month'])) {
      console.log("max messages reached");
      this.pushErrorMessage();
      this.typing = false;
      return;
    }


    console.log("Here");



    if (1 == 1) {

      this.saveChatMessage({ "text": question, "actor": "user" }, "user");
      this.scrollChatEnd('');

      // console.log("Calling Get Answer", this.chathistory.slice(-this.take_last_n_messages));

      this.dataService.cd_getAnswer(this.project_uuid, question, this.chathistory.slice(-this.take_last_n_messages)).subscribe((data: any) => {
        //console.log(data);
        var sources = data['sources'];
        sources.forEach((element: any) => {
          this.project_objects.forEach((element2: any) => {
            if (element['object_id'] == element2['object_id']) {
              element['fileobj'] = element2;
            }
          });
        });
        //console.log({ "text": data['answer'], "actor": "bot", "sources": sources });
        this.saveChatMessage({ "text": data['answer'], "actor": "bot", "sources": sources }, "bot");
        this.scrollChatEnd('');
        this.typing = false;
        // Add to the chathistory
        this.chathistory.push([question, data['answer']])
      });

    } else {

    }

  }

  sources(sources) {
    this.current_message_tab = "sources";
    this.current_sources = sources;
  }

  closeSources() {
    this.current_message_tab = "chat";
    this.current_sources = [];
  }


  fixTextSpacing(text: string): string {
    // Fix space before commas
    let fixedText = text.replace(/(\s+),/g, ',');

    // Fix space after '
    fixedText = fixedText.replace(/'\s+/g, "'");

    return fixedText;
  }


  searchtext(text: string, page?: number) {
    var p_start = page - 1;
    var p_end = page + 1;
    var p_range = p_start + "," + page;
    //console.log(text, page, p_range);
    if (this.ngxExtendedPdfViewerService.find(
      text,
      {
        highlightAll: this.highlightAll,
        matchCase: this.matchCase,
        wholeWords: this.wholeWord,
        ignoreAccents: this.ignoreAccents,
        fuzzySearch: true,
        pageRange: p_range
      })) {
      // this._searchtext = text;
    }
  }

  // viewing = false;

  goToSource(page, obj, text) {

    //console.log(page, obj, text);



    this.viewFile(obj);




    setTimeout(() => {

      // alert(page);

      this.pageVariable = page;

      this.searchtext(text, page);
    }, 2000);


  }


  modal_class_add_text = "";
  add_text_current = "";
  add_text_name = "";
  openAddText() {
    if (this.limits.is_own_key && !this.limits.is_key_generated) {
      alert("Please add your OpenAI API key to continue.");
      return;
    }
    this.modal_class_add_text = "modal-open";
  }
  closeAddText() {
    this.modal_class_add_text = "";

  }

  resetAddText() {
    this.add_text_current = '';
    this.add_text_name = "";
  }

  addText() {

    if (this.add_text_name.trim() === '' || this.add_text_name === null || this.add_text_current.trim() === '' || this.add_text_current === null) {
      console.error('Fill all the details.');
      return;
    }

    this.upload_index_progress = true;

    const filename = this.add_text_name + '.txt';




    //console.log(this.add_text_name, this.add_text_current);
    this.dataService.cd_getFileUploadPath(filename, this.project_uuid, "0").subscribe((data: any) => {
      //console.log(data);

      var upload_obj = data['obj'];

      //console.log(upload_obj);

      if (data.status == "success") {

        var file_extension = 'txt';
        var file_path = data.file_path;
        var content_type = "text/plain";

        try {
          Storage.put(file_path, this.add_text_current, {
            contentType: content_type, // Set the appropriate content type
            level: 'public' // Optional: Set the access level for the uploaded file
          }).then(() => {
            // Indexing Call
            //console.log("Indexing Call");
            //console.log(this.project_uuid, upload_obj['object_uuid']);
            this.dataService.cd_startFileIndexing(this.project_uuid, upload_obj['object_uuid']).subscribe((data: any) => {
              //console.log(data);
              this.upload_index_progress = false;
              this.closeAddText();
              this.resetAddText();
              this.projects_polling = setInterval(() => {
                this.getObjectsForProject();
              }, 3000);
              //console.log("Indexing Call Successful");
              if (data.status === "success")
                console.log("Indexing Call Successful");
              // this.toast.showToastNnotification("Successfully trained on the given text!!", "success");
              else
                this.toast.showToastNnotification("Failed to train on the given text!!", "failed");
            },
              (error) => {
                this.upload_index_progress = false;
                this.closeAddText();
                this.resetAddText();
                console.error('Uknown error occured. Please try again.');
                this.toast.showToastNnotification("Uknown error occured. Please try again!!", "failed");
              });

          });
          //console.log('File uploaded successfully.');
        } catch (error) {
          this.upload_index_progress = false;
          console.error('Error uploading file:', error);
          this.toast.showToastNnotification("Failed to train on the given text!!", "failed");
        }

      }
      else {
        this.upload_index_progress = false;
        this.toast.showToastNnotification("Uknown error occured. Please try again.", "failed");
        console.error('Uknown error occured. Please try again.');
        return [];
      }

    });
  }

  add_url_current: string = "";
  add_url_current_element: any = {};
  show_abort_banner = false;
  sub_urls = [];
  show_add_url_loop = false;
  crawl_status = "Not Started";
  selected_url_count = 0;
  success_url_count = 0;

  selectAllSubUrls() {
    this.selected_url_count = 0;
    this.sub_urls.forEach((elem) => {
      elem.checked = true;
      this.selected_url_count++;
    });

  }

  unselectAllSubUrls() {
    this.sub_urls.forEach((elem) => {
      elem.checked = false;
    });
    this.selected_url_count = 0;
  }

  updateSelectedUrlCount() {
    this.selected_url_count = 0;
    this.sub_urls.forEach((elem) => {
      if (elem.checked)
        this.selected_url_count++;
    });
  }

  startOver() {
    this.show_add_url_loop = false;
    this.sub_urls = [];
    this.crawl_status = "Not Started";
    this.selected_url_count = 0;
    this.add_url_current = "";
    this.add_url_current_element = {};
    this.show_abort_banner = false;
    this.success_url_count = 0;
  }


  getSubUrls() {
    this.crawl_status = "Scraping";
    this.dataService.cd_addUrlStartCrawler(this.add_url_current).subscribe((data: any) => {
      console.log(data);

      data['sub_urls'].forEach(element => {

        this.sub_urls.push({
          url: element,
          status: "not started",
          checked: true
        });
        this.selected_url_count++;


      });

      this.crawl_status = "Scraped";
      this.show_add_url_loop = true;
    },

      (error) => {

        alert("Error occured while scraping the url. Please try again.");

      });

    return;
  }

  isAllUrlsAdded() {

    // check if none of the urls are pending, then set crawl_status to "Indexed"
    var all_urls_added = true;
    this.sub_urls.forEach((elem) => {
      if (elem.status === "pending")
        all_urls_added = false;
    });

    if (all_urls_added) {
      this.crawl_status = "Indexed";
      this.getObjectsForProject();
      this.getLimits();
    }

  }


  addUrlLoop() {
    this.crawl_status = "Indexing";
    this.sub_urls.forEach((elem) => {
      if (elem.checked) {
        elem.status = "pending";
        this.add_url_current_element = elem;
        this.addUrl(elem);
      }

    }
    );


  }



  addUrl(elem) {
    this.show_abort_banner = false;
    if (elem.url.trim() === '' || elem.url === null) {
      console.error('No Url provided.');
      elem.status = "failed";
      // TODO: add toast here
      // this.toast.showToastNnotification("No Url provided...", "failed");
      return;
    }
    // this.upload_index_progress = true;
    //console.log(elem.url);


    this.dataService.cd_addUrlAndIndex(elem.url, this.project_uuid).subscribe((data: any) => {
      //console.log(data);



      if (data.status == "success") {
        elem.status = "success";
        // this.upload_index_progress = false;
        // this.closeAddUrlToCollectionModal();

        this.success_url_count++;

      }
      else if (data.status == "abort") {
        elem.status = "failed";
        // this.upload_index_progress = false;
        this.show_abort_banner = true;
        // this.toast.showToastNnotification(data.message, "success");
      } else {
        elem.status = "failed";
        this.toast.showToastNnotification("Invalid URL, please try again.", 'failed');
        console.error('Uknown error occured. Please try again.');
        return [];
      }

      this.isAllUrlsAdded();

    },
      (error) => {
        // this.upload_index_progress = false;
        elem.status = "failed";
        // alert("Error. Please check the url and try again.")
        console.error('Uknown error occured. Please try again.');
        this.isAllUrlsAdded();
        // return [];
      });
  }

  openAddUrlToCollectionModal() {
    if (this.limits.is_own_key && !this.limits.is_key_generated) {
      alert("Please add your OpenAI API key to continue.");
      return;
    }
    var total_urls = (this.tracker['n_urls'] - this.tracker['n_del_urls']);
    var allowed_urls = this.limits['max_urls'];

    console.log("total_urls", total_urls);
    console.log("allowed_urls", allowed_urls);
    // if (!this.p_start.add_new_files) {
    //   this.modal_class_nomore_files = "modal-open";
    //   return
    // }

    // allowed_files_uploaded = 10;

    // test with 1 mb
    // allowed_file_size = 1;
    // allowed_files_uploaded = 1;

    if (total_urls >= allowed_urls) {
      this.modal_class_nomore_files = "modal-open";
      return
    }
    this.modal_class_add_url = "modal-open";

    // focus on the input field
    setTimeout(() => {
      document.getElementById("starting_url").focus();
    }
      , 1000);
  }

  closeAddUrlToCollectionModal() {
    this.modal_class_add_url = "";
  }

  resetAddUrlPopUp() {
    this.add_url_current = '';
    this.startOver();
  }

  fileViewer() {
    this.current_tab = this.current_subtab;
    this.pdfSrc = "";
    this.textData = "";
    this.current_view_obj = {};
  }





  pdfUrl = "";
  upload_progress_text = "";
  async uploadPdf() {



    if ((this.pdfUrl.trim() === '' || this.pdfUrl === null) && !this.fileToUpload) {
      console.error('No file selected.');
      return;
    }



    const filename = this.fileToUpload.name;
    let file_extension = filename.split('.').pop();
    // Reject if file is not a pdf or docx or doc or pptx or ppt or xlsx or xls or txt or csv
    if (file_extension.toLowerCase() != "pdf" && file_extension.toLowerCase() != "txt") {
      alert("we currently support only pdf,  txt files.")
      console.error('File is not a pdf or docx or doc or pptx or ppt or xlsx or xls or txt or csv.');
      return;
    }
    this.upload_index_progress = true;
    this.upload_progress_text = "Uploading file..."

    if (this.fileToUpload) {
      // const filename = this.fileToUpload.name;


      this.dataService.cd_getFileUploadPath(filename, this.project_uuid, "0").subscribe((data: any) => {
        //console.log(data);

        var upload_obj = data['obj'];

        //console.log(upload_obj);

        if (data.status == "success") {

          var file_extension = filename.split('.').pop();
          var file_path = data.file_path;
          var content_type = ""




          // set upload content type
          if (file_extension.toLowerCase() == "pdf") {
            content_type = "application/pdf";
          }
          // else if (file_extension == "docx") {
          //   content_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          // }
          // else if (file_extension == "doc") {
          //   content_type = "application/msword";
          // }
          // else if (file_extension == "pptx") {
          //   content_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          // }
          // else if (file_extension == "ppt") {
          //   content_type = "application/vnd.ms-powerpoint";
          // }
          // else if (file_extension == "xlsx") {
          //   content_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          // }
          // else if (file_extension == "xls") {
          //   content_type = "application/vnd.ms-excel";
          // }
          else if (file_extension == "txt") {
            content_type = "text/plain";
          }

          //console.log(content_type, file_path);

          try {
            Storage.put(file_path, this.fileToUpload, {
              resumable: true,
              contentType: content_type, // Set the appropriate content type
              level: 'public', // Optional: Set the access level for the uploaded file,
              progressCallback: (progress) => {
                var percent_uploaded = Math.round((progress.loaded / progress.total) * 100);
                //console.log(`Uploaded (${percent_uploaded}%)`);
                this.upload_progress_text = `Uploaded (${percent_uploaded}%)`;

                if (percent_uploaded == 100) {
                  this.upload_progress_text = "File uploaded. Indexing..."
                }
              },

              completeCallback: (event) => {
                //console.log(`Successfully uploaded ${event.key}`);
                //console.log("Indexing Call");
                //console.log(this.project_uuid, upload_obj['object_uuid']);
                this.dataService.cd_startFileIndexing(this.project_uuid, upload_obj['object_uuid']).subscribe((data: any) => {
                  //console.log(data);
                  this.upload_index_progress = false;
                  this.upload_progress_text = "";
                  this.closeModal();

                  this.fileToUpload = null;
                  this.pdfUrl = '';
                  // this.getAllowedFunctions();
                  this.getLimits();
                  this.getObjectsForProject();
                  this.projects_polling = setInterval(() => {
                    this.getObjectsForProject();
                  }, 3000);
                });
              },

              errorCallback: (err) => {
                alert('Unexpected error while uploading');
              }


            })

          } catch (error) {
            console.error('Error uploading file:', error);
          }

        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
    else if (this.pdfUrl) {
      this.upload_index_progress = true;
      this.upload_progress_text = "Indexing file..."
      this.dataService.cd_addFileUrlAndIndex(this.pdfUrl, this.project_uuid, false, "None").subscribe((data: any) => {
        //console.log(data);



        if (data.status == "success") {

          this.upload_index_progress = false;
          this.upload_progress_text = "";
          this.closeModal();
          this.pdfUrl = '';

          // call get objects for projects until all objects status is "done"

          this.getObjectsForProject();

        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });
    }
  }


  retyFileIndex(file, e) {
    e.stopPropagation();
    //console.log(file);
    file.object_status = "indexing";
    // return
    if (file.is_external) {

      this.dataService.cd_addFileUrlAndIndex(file.file_path, this.project_uuid, true, file.object_id).subscribe((data: any) => {

        //console.log(data);





        if (data.status == "success") {

          this.upload_index_progress = false;
          this.upload_progress_text = "";
          this.closeModal();
          this.pdfUrl = '';

          // call get objects for projects until all objects status is "done"
          this.projects_polling = setInterval(() => {
            this.getObjectsForProject();
          }, 3000);


        }
        else {
          alert("Uknown error occured. Please try again.")
          console.error('Uknown error occured. Please try again.');
          return [];
        }

      });

    } else {
      this.dataService.cd_startFileIndexing(this.project_uuid, file.object_id).subscribe((data: any) => {
        //console.log(data);
        this.upload_index_progress = false;
        this.upload_progress_text = "";
        this.closeModal();
        this.fileToUpload = null;
        this.pdfUrl = '';
        this.getObjectsForProject();
        this.projects_polling = setInterval(() => {
          this.getObjectsForProject();
        }, 3000);
      });
    }
  }


  // Interactions code

  current_interaction: any;
  user_interactions: any = [];

  newChat() {

    var total_interactions = this.user_interactions.length;

    if (this.user_interactions.length >= this.limits['max_interactions']) {
      alert("You have reached the maximum number of chats you can create. Please upgrade your plan to add more Chats.");
      return;
    }

    this.dataService.cd_addInteraction(this.project_uuid, 'chat', 'Untitled Chat').subscribe((data: any) => {
      //console.log(data);
      this.current_interaction = data['interaction'];
      this.chat_messages = [];
      this.chathistory = [];
      this.mode = "interact";
      this.user_interactions.push(data['interaction']);
      // this.getAllowedFunctions();
      this.getLimits();
    }
    );
  }

  getUserInteractions() {
    this.dataService.cd_getInteractions(this.project_uuid, this.share_code).subscribe((data: any) => {
      //console.log(data);
      this.user_interactions = data['interactions'];

    }
    );
  }


  loading_messages: boolean = false;
  goToChat(interaction) {
    this.current_interaction = interaction;
    this.getInteractionMessages();
    // this.makeChathistory();
    this.mode = "interact";

  }

  makeChathistory() {
    let i = 0;
    let chat_len = this.chat_messages.length;
    this.chathistory = []
    // 1. find the first user message
    // 2. find the bot message
    while (i < chat_len) {
      let user_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'user') {
          user_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      let bot_message = "";
      while (i < chat_len) {
        if (this.chat_messages[i]['actor'] == 'bot') {
          bot_message += this.chat_messages[i]['text'] + '\n';
          i = i + 1;
        }
        else break;
      }
      this.chathistory.push([user_message, bot_message]);
    }
    //console.log("chathistory", this.chathistory);
    this.scrollChatEnd('');
  }

  currentInteractionNameEdited() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      //console.log("chat interaction name edited....");
      // TODO: need to call the corresponding api to update the chat interaction name
      this.dataService.cd_updateInteraction(this.current_interaction).subscribe(data => {
        //console.log(data);
      }, err => console.log(err));
    }, 2000);
  }

  goToSavedChat(interaction) {
    this.current_interaction = interaction;
    this.getInteractionMessages();
    this.shared_mode_tab = "saved_chat_view";

  }

  getInteractionMessages() {

    this.chat_messages = [];

    this.dataService.cd_getInteractionMessages(this.project_uuid, this.current_interaction['interaction_uuid'], this.share_code).subscribe((data: any) => {
      //console.log(data);
      this.current_interaction['messages'] = data['messages'];
      this.loading_messages = false;
      // this.chat_messages = data['messages'];
      data['messages'].forEach(element => {

        var msg = {}
        // msg['text'] = element['message'];

        if (element['actor'] == "user") {
          this.chat_messages.push(element['message']);
        }
        else {
          this.chat_messages.push(element['message']);
        }

      }
      );
      // this.scrollChatEnd('');
      this.makeChathistory();
    }
    );

  }

  backtoChats() {
    this.mode = "base";
    this.chat_messages = [];
    this.current_interaction = {};
  }

  saveChatMessage(message, actor) {
    // console.log(this.is_shared, this.embed_show, message, actor);
    this.chat_messages.push(message);
    if (!this.is_shared && !this.embed_show) {

      this.dataService.cd_addInteractionMessage(this.project_uuid, this.current_interaction['interaction_uuid'], message, actor).subscribe((data: any) => {
        //console.log(data);
        this.getLimits();
      }
      );

    } else {
      if (actor == 'bot') {

        // console.log("bot message");

        this.dataService.cd_incrementNQuestions(this.project_uuid).subscribe((data: any) => {
          //console.log(data);
          // this.getLimits();
          this.getProjectForEmbed();
        }
        );

      }

    }

  }

  pushErrorMessage() {
    var message = { "text": '<span style="color:red">There was an error in processing your request. Please try again later.</span>', "actor": "bot" };
    this.chat_messages.push(message);
  }

  modal_class_delete_interaction = "modal-close";

  deleteInteractionModalOpen() {
    this.modal_class_delete_interaction = "modal-open";
    // this.getAllowedFunctions();
    this.getLimits();
  }


  deleteInteraction() {
    this.dataService.cd_deleteInteraction(this.current_interaction['interaction_uuid']).subscribe((data: any) => {
      //console.log(data);
      this.backtoChats();
      this.getUserInteractions();
      this.modal_class_delete_interaction = "modal-close";;
    }
    );
  }

  deleteInteractionById(iuuid, e) {

    e.stopPropagation();

    // confirm before deleting
    if (!confirm("Are you sure you want to delete this chat?")) {
      return;
    }

    this.dataService.cd_deleteInteraction(iuuid).subscribe((data: any) => {
      //console.log(data);
      // this.backtoChats();
      this.getUserInteractions();

    }
    );
  }


  openShareModal() {
    this.modal_class_share_project = "modal-open";
  }

  // utils function to format the file size
  formatFileSize(fileSize: any): string {
    if (fileSize != null) {
      let f_size_bytes = parseInt(fileSize);
      if (f_size_bytes < 1024)
        return `${f_size_bytes} B`
      if (f_size_bytes < 1024 * 1024)
        return `${(f_size_bytes / 1024).toFixed(2)} KB`
      if (f_size_bytes < 1024 * 1024 * 1024)
        return `${(f_size_bytes / 1024 / 1024).toFixed(2)} MB`
    }
    return "0 B"
  }



  // Embed Chat Code

  modal_class_embed = "modal-close";
  sample_chat_messages = [
    // { "text": "👋 Hi! I am ChatDocs bot!, ask me anything about!", "actor": "bot" },
    { "text": "Hi, how are you?", "actor": "user" },
    { "text": "I'm good! How are you?", "actor": "bot" }

  ]


  openEmbedModal() {
    this.modal_class_embed = "modal-open";
    // this.getLogoImage(this.embed.logo_path);
  }
  closeEmbedModal() {
    this.modal_class_embed = "modal-close";
  }

  saveBotSettings() {
    //console.log(this.embed.embed_uuid, this.embed);
    this.dataService.cd_updateEmbed(this.embed.embed_uuid, this.embed).subscribe((data: any) => {
      //console.log(data);
      // this.closeEmbedModal();
    }
    );
  }

  onImageClick() {
    const fileInput = document.querySelector('input[type=file]') as HTMLInputElement;
    fileInput.click();
  }


  getLogoImage(fname) {
    if (fname != null && fname != undefined && fname != "") {

      Storage.get(fname, {
        level: 'public'
      }).then((url) => {
        this.embed.logo = url;
        this.embed.logo_path = fname;
        this.saveBotSettings();
      });

    }


  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadFile(file);
  }


  private async uploadFile(file: File) {
    // //console.log(file.name);
    var extension = file.name.split('.')[1];
    // //console.log(extension);
    var f_name = 'projects/' + this.project_uuid + "/embeds/logos/" + this.embed.embed_uuid + "." + extension;
    const result = await Storage.put(f_name, file, {
      level: 'public',
    }).then(() => {
      this.embed.logo_path = f_name;
      this.getLogoImage(f_name);
    });
  }

  redirectToPricingPage() {
    this.route.navigate(['/pricing']);
  }

  // filterAllTagsOtherThanLinks(content: string): string {
  //   const result: string[] = [];
  //   let startIndex = 0;

  //   while (true) {
  //     const openTagIndex = content.indexOf('<a', startIndex);
  //     const closeTagIndex = content.indexOf('</a>', startIndex);

  //     if (openTagIndex === -1 && closeTagIndex === -1) {
  //       // No more <a> or </a> tags found, add remaining content and break
  //       result.push(content.slice(startIndex));
  //       break;
  //     }

  //     if (openTagIndex === -1 || (closeTagIndex !== -1 && closeTagIndex < openTagIndex)) {
  //       // Found closing </a> tag before opening <a> tag
  //       result.push(content.slice(startIndex, closeTagIndex + 4)); // Include the closing </a> tag
  //       startIndex = closeTagIndex + 4;
  //     } else {
  //       // Found opening <a> tag
  //       result.push(content.slice(startIndex, openTagIndex));
  //       startIndex = openTagIndex;
  //     }
  //   }
  //   console.log(result);

  //   let res = result.join('');
  //   return res;
  // }

  filterAllTagsOtherThanLinks(message: string): SafeHtml {
    // Use DOM manipulation to filter and process the HTML content

    let message_formatted = message.replace(/([\n])/gs, '<br>').replace(/```([\s\S]*?)```/gs, (_, capturedText) => {
      capturedText = capturedText.replace(/<br>/g, '\n');
      // remove links from the code part
      capturedText = capturedText.replace(/<\/?a[^>]*>/g, '')
      return `<div class="code-div"><pre><code class="code-block">${capturedText}</code></pre></div>`;
    });
    // console.log(message_formatted);
    const wrapper = document.createElement('div');
    wrapper.innerHTML = message_formatted;

    // Recursively process child nodes
    this.processChildNodes(wrapper.childNodes);

    // Sanitize the processed HTML for security
    const processedHTML = wrapper.innerHTML;
    const sanitizedHTML = this.sanitizer.sanitize(SecurityContext.HTML, processedHTML);

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHTML);
  }

  private processChildNodes(nodes: NodeListOf<ChildNode>): void {
    nodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        if (element.tagName.toLowerCase() === 'a') {
          // If the node is an <a> element, make it clickable
          element.setAttribute('target', '_blank');
        } else {
          // If the node is not an <a> element, process its child nodes recursively
          this.processChildNodes(element.childNodes);
        }
      }
    });
  }

  // string {
  //   console.log("filterAllTagsOtherThanLinks called...");
  //   // Use DOM manipulation to filter and process the HTML content
  //   const wrapper = document.createElement('div');
  //   wrapper.innerHTML = message;

  //   // Process all child nodes to convert non-<a> elements to text nodes
  //   Array.from(wrapper.childNodes).forEach(node => {
  //     if (node.nodeType !== Node.ELEMENT_NODE || (node as Element).tagName.toLowerCase() !== 'a') {
  //       const textNode = document.createTextNode(node.textContent);
  //       node.parentNode.replaceChild(textNode, node);
  //     }
  //   });

  //   // Sanitize the processed HTML for security
  //   const processedHTML = wrapper.innerHTML;
  //   const sanitizedHTML = this.sanitizer.sanitize(SecurityContext.HTML, processedHTML);

  //   return sanitizedHTML;
  // }

}

<div class=" flex w-full px-32 border-0">

    <div class="flex relative container mx-auto justify-between items-center py-0">
        <div class=" w-4/12  py-4 text-2xl font-semibold text-gray-800">
            <div class="text-gray-800 flex justify-start items-center"
                style="margin-left: 5px !important;font-weight: 600;">
                <i class="fa-regular fa-box-open mr-2 text-pink-400" style="font-size: 22px;"></i>

            </div>
        </div>

        <div class="w-8/12 flex justify-end px-0 items-end ">



            <div
                class="mr-2 bg-gray-800 text-white ml-2 rounded-2xl hover:bg-gray-800 hover:text-gray-800 text-gray-800 bg-white border-0 hover:bg-pink-500   border-pink-500  flex justify-center items-center">
                <a href="https://tally.so/r/wMe6QY" class="p-2 bg-white  text-gray-600 border-0 rounded"
                    style="text-decoration: none; border:solid 0 !important">
                    <i class="fa-regular fa-message"></i>&nbsp;Contact us</a>
            </div>

        </div>

    </div>

</div>


<div class="container px-32 mx-auto flex justify-center items-center h-full" style="margin-top:80px">


    <div class="px-10 flex justify-start items-start">


        <amplify-authenticator class="mr-4" [services]="services" [initialState]="'signUp'">

        </amplify-authenticator>



    </div>


</div>

<div class="flex container mx-auto px-32 mt-10 justify-center">




</div>
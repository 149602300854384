import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../../app/backend/services/api.service';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.css']
})



export class CustomizeComponent implements OnInit {



  @Input() embed_uuid: string;
  @Input() project_uuid: string;
  @Input() plan: string;
  @Input() page: string;
  @Input() limits: any;
  @Output() closeNotif: EventEmitter<any> = new EventEmitter();

  embed: any = {};

  declare $: any;

  tab = 'customize';
  resources_tab = 'settings';



  sample_chat_messages = [
    { "text": "Hi, how are you?", "actor": "user" },
    { "text": "I'm good! How are you?", "actor": "bot" }

  ]

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    if (this.page == 'project') {
      this.getEmbed(this.embed_uuid);
      this.getChatbotConfig();
    }

  }

  getEmbed(embed_uuid) {
    this.api.cd_getEmbed(embed_uuid).subscribe((data: any) => {
      console.log(data);
      if (data) {
        this.embed = data['embed'];
        this.getLogoImage(this.embed['logo_path']);
      }
    }
    );
  }

  getLogoImage(fname) {
    if (fname != null && fname != undefined && fname != "") {
      Storage.get(fname, {
        level: 'public'
      }).then((url) => {
        this.embed.logo = url;
        this.embed.logo_path = fname;
        this.saveBotSettings();
      });
    }
  }

  saveBotSettings() {
    console.log(this.embed);
    this.api.cd_updateEmbed(this.embed.embed_uuid, this.embed).subscribe((data: any) => {
    }
    );
  }

  onLogoClick() {
    const fileInput = document.querySelector('#bot-logo-zone') as HTMLInputElement;
    fileInput.value = null;
    fileInput.click();
  }

  onLogoFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadFile(file);
  }


  private async uploadFile(file: File) {
    // //console.log(file.name);
    var extension = file.name.split('.')[1];
    // //console.log(extension);
    var f_name = 'projects/' + this.project_uuid + "/embeds/logos/" + this.embed.embed_uuid + "." + extension;
    const result = await Storage.put(f_name, file, {
      level: 'public',
    }).then(() => {
      this.embed.logo_path = f_name;
      this.getLogoImage(f_name);
    });
  }


  chatBotConfig = {
    "persona": "",
    "temperature": "0"
  }

  persona_saved = false;

  // get chatbot context and open-ai parameters settings tab
  getChatbotConfig() {
    this.api.cd_getChatbotConfig(this.project_uuid).subscribe((data: any) => {
      this.chatBotConfig['persona'] = data?.persona;
      this.chatBotConfig['temperature'] = data?.temperature;
      console.log("chatBot config:", this.chatBotConfig);
    }, err => console.log("error:", err));
  }

  // put or update chatbot context and open-ai parameters settings tab
  updateChatbotConfig() {
    var persona = this.chatBotConfig['persona'];
    var temperature = this.chatBotConfig['temperature'];

    this.api.cd_updateChatbotConfig(this.project_uuid, persona = persona, temperature = temperature).subscribe((data: any) => {
      // do in case of success
      this.chatBotConfig['persona'] = persona;
      this.chatBotConfig['temperature'] = temperature;
      this.persona_saved = true;
      setTimeout(() => {
        this.persona_saved = false;
      }, 1000);
    }, err => console.log("error:", err));
  }


  toggle(id) {
    $('#' + id).toggle();
  }

  setTab(tab) {
    this.tab = tab;
  }

  close() {
    this.closeNotif.emit();
  }

  isCopied = false;
  copyied_text = false;

  copyTextToClipboard() {
    const code = `<knibble-bot [chatbot_id]="` + this.embed_uuid + `" #chatbot></knibble-bot>
                  <script src="https://s3.amazonaws.com/knibble.ai.assets/package.js" type="module"></script>`;
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../backend/services/api.service';
import { PlansService } from '../../../../backend/services/plans.service';


@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponentNew implements OnInit {

  @Input() limits: any;
  @Input() tracker: any;
  @Input() page: any;
  @Input() upgraded: any;
  @Output() openCreateProjectModal: EventEmitter<any> = new EventEmitter();

  i_agree = false;
  modal_class_declaration = "modal-close";
  declaration;
  user_declared;
  loading = true;

  a_tot_storage = 0;
  a_used_storage = 0;

  a_tot_files = 0;
  a_tot_used_files = 0;

  a_tot_questions = 0;
  a_tot_used_questions = 0;

  a_tot_urls = 0;
  a_tot_used_urls = 0;

  projects = 9999;

  byok_error = "";

  constructor(private api: ApiService, private planService: PlansService) { }

  ngOnInit(): void {


    // this.updateLimits();
    // this.hasDeclared();

    console.log(this.upgraded);

  }

  initDashboard() {

    console.log(this.page);
    console.log(this.upgraded);

    this.hasDeclared();
    this.byok_error = this.planService.getByokError();

  }

  createProject() {
    // alert("create project");
    this.openCreateProjectModal.emit();
  }


  ngAfterViewInit() {

  }

  test() {
    console.log(this.limits);
    console.log(this.tracker);
  }

  updateLimits() {

    console.log(this.limits);
    console.log(this.tracker);

    // if tracker doesnt have total_del_file_size or n_del_files or n_del_urls then set them to 0
    if (!this.tracker['total_del_file_size']) {
      this.tracker['total_del_file_size'] = 0;
    }
    if (!this.tracker['n_del_files']) {
      this.tracker['n_del_files'] = 0;
    }
    if (!this.tracker['n_del_urls']) {
      this.tracker['n_del_urls'] = 0;
    }


    var u_s = (this.tracker['total_file_size'] - this.tracker['total_del_file_size']) / 1024 / 1024;
    this.a_used_storage = Math.round((u_s + Number.EPSILON) * 100) / 100;
    this.a_tot_storage = this.limits['max_total_storage_mb'];

    // if a_used_storage is NaN then set it to 0
    if (isNaN(this.a_used_storage)) {
      this.a_used_storage = 0;
    }

    this.a_tot_files = this.limits['max_files'];
    this.a_tot_used_files = this.tracker['n_files'] - this.tracker['n_del_files'];

    if (this.a_tot_used_files < 0 || isNaN(this.a_tot_used_files)) {
      this.a_tot_used_files = 0;
    }


    this.a_tot_questions = this.limits['max_messages_per_month'];


    this.a_tot_used_questions = this.tracker['n_questions'];

    if (this.a_tot_used_questions < 0 || isNaN(this.a_tot_used_questions)) {
      this.a_tot_used_questions = 0;
    }



    this.a_tot_urls = this.limits['max_urls'];
    this.tracker['n_urls'] = this.tracker['n_urls'] ? this.tracker['n_urls'] : 0;
    this.a_tot_used_urls = this.tracker['n_urls'] - this.tracker['n_del_urls'];

    if (this.a_tot_used_urls < 0 || isNaN(this.a_tot_used_urls)) {
      this.a_tot_used_urls = 0;
    }

    this.loading = false;

  }


  updatingprofile = false;
  alias_exists = false;
  modal_alias = "modal-close";
  updateUserNameAlias() {
    this.updatingprofile = true;
    this.api.updateUserAliasandName(this.upgraded.user.alias, this.upgraded.user.given_name).subscribe((data: any) => {
      // //console.log(data);
      if (data.success) {
        // this.toast.showToastNnotification("Successfully updated the profile!!", "success");
        this.modal_alias = "modal-close";
      }
      if (!data.success && data.message == "User handle already exists") {
        this.alias_exists = true;
        // this.user.alias = data.alias;
        // this.user.given_name = data.given_name;
        // this.toast.showToastNnotification(data.message, "failed");
      }
      this.updatingprofile = false;
    }
    );
  }


  openAliasModal() {
    if (!('alias' in this.upgraded.user) || this.upgraded.user.alias == null || this.upgraded.user.alias == "") {
      this.modal_alias = "modal-open";
    }
  }


  hasDeclared() {
    this.api.hasDeclared().subscribe((data) => {

      //console.log(data);
      this.declaration = data;
      if (data['hasdeclared'] == true) {
        this.user_declared = true;
        this.modal_class_declaration = "modal-close";
        this.openAliasModal();

      } else {
        this.user_declared = false;
        this.modal_class_declaration = "modal-open";
        // //console.log(this.modal_class_declaration);
      }
    }
    );
  }

  click_declared = false;
  declare() {
    this.click_declared = true;
    this.api.declare().subscribe((data) => {
      this.modal_class_declaration = 'modal-close';
      this.declaration['hasdeclared'] = true;
      this.click_declared = false;
      this.openAliasModal();
    }, err => console.log(err));
  }

}

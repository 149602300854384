import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../../app/backend/services/api.service';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.css']
})
export class ApiKeyComponent implements OnInit {


  @Input() limits: any;
  @Input() tracker: any;
  @Output() notifySuccess: EventEmitter<any> = new EventEmitter();

  modal_state = 'modal-close';
  key_string = "";
  add_key_error = false;
  add_key_clicked = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }


  open_modal() {
    this.modal_state = 'modal-open';
  }

  close_modal() {
    this.modal_state = 'modal-close';
  }

  setLimits(limits) {
    this.limits = limits;
  }

  addKey(e) {


    this.add_key_clicked = true;
    this.api.cd_addKey(this.key_string).subscribe((data: any) => {
      this.add_key_error = false;
      this.add_key_clicked = false;
      this.key_string = "";
      this.notifySuccess.emit();
      this.close_modal();
    }, err => {

      this.add_key_error = true;
      this.add_key_clicked = false;

    });

  }

  deleteKey() {
    this.api.cd_deleteKeyForAccount().subscribe((data: any) => {
      this.limits.is_key_generated = false;
    }, err => console.log(err));
  }

  resetText() {
    this.key_string = "";
  }

}

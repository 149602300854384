import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../backend/services/api.service';
// import { ToastComponent } from '../../../ui/repeatable/toast/toast.component';


@Component({
  selector: 'app-delete-project',
  templateUrl: './delete-project.component.html',
  styleUrls: ['./delete-project.component.css']
})
export class DeleteProjectComponent implements OnInit {


  @Output() notifyDeletSuccess: EventEmitter<any> = new EventEmitter();

  project_to_delete: any;

  constructor(private api: ApiService) { }


  modal_state = "modal-close";

  ngOnInit(): void {
  }

  open_modal() {
    this.modal_state = 'modal-open';
  }

  close_modal() {
    this.modal_state = 'modal-close';
  }

  deleteProject() {
    {

      this.api.cd_deleteProject(this.project_to_delete.project_uuid).subscribe((data: any) => {
        if (data.status === "success") {
          this.notifyDeletSuccess.emit(this.project_to_delete.project_uuid);
          this.close_modal();
        }


        this.project_to_delete = "";
      }, err => {
        // this.toast.showToastNnotification("Project deletion failed!!", "failed");
      });
    }
  }

  setProjectToDelete(project: any) {
    this.project_to_delete = project;
  }

}

<div class="header-wrapper w-full bg-inherit py-4">

    <div id="header-container" class="flex justify-between items-center bg-inherit text-gray-900 px-4 py-3 mx-auto"
        style="max-width: 1200px;">


        <div class="flex gap-x-10">

            <div class="text-xl font-bold text-gray-900 flex justify-start items-center" style="font-size:23px">
                <img src="assets/generic2.svg" style="width:33px; margin-top: -4px;" alt="">

                <div style="font-size: 21px;
                font-family: 'Sora';
                font-weight: bolder;
                color: #000;" class=" ml-0">Knibble<span class="text-gray-400">.ai</span><span
                        class="text-sm ml-2 text-gray-300 -mt-3"></span></div>
            </div>

            <div class="flex">




            </div>



        </div>



        <div>





            <div class="flex justify-end items-center ml-8">









                <a href="https://tally.so/r/mVj8QN"
                    class="bg-inherit border-none font-normal text-gray-700 cursor-pointer hover:text-blue-600 ml-4"
                    style="font-size:14px; font-weight: 800px;">
                    Contact Us
                </a>


            </div>

        </div>

    </div>

</div>

<div class="w-full p-16">



    <div style="width:1000px" class="mx-auto">

        <div class="w-full py-2">

        </div>
        <h3>Terms of service</h3>
        <p>These terms and conditions (“Terms”) govern your use of the Knibble.ai website and its associated services
            (collectively, the “Services”). Please read them carefully.</p>

        <div class="w-full py-2">

        </div>

        <div class="w-full py-2">

        </div>
        <h3>Acceptance of Terms</h3>
        <p>By using the Services, you agree to be bound by these Terms, which constitute a binding agreement between you
            and
            Knibble.ai. If you do not agree to these Terms, you may not use the Services.</p>
        <div class="w-full py-2">

        </div>
        <h3>User Conduct</h3>
        <p>You agree that you will not use the Services to:</p>
        <ul>
            <li>Post any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar,
                obscene,
                libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable
            </li>
            <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a
                person or
                entity</li>
            <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content
                transmitted
                through the Services</li>
            <li>Use any automated means to access the Services or collect any information from the Services</li>
            <li>Interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any
                requirements, procedures, policies or regulations of networks connected to the Services</li>
            <li>Intentionally or unintentionally violate any applicable local, state, national or international law, and
                any
                regulations having the force of law</li>
        </ul>
        <div class="w-full py-2">

        </div>
        <h3>Ownership of Content</h3>
        <p>You retain all rights to your content that you submit to the Services. However, by submitting content to the
            Services, you grant Knibble.ai a non-exclusive, royalty-free, transferable, sublicensable, worldwide license
            to use,
            store, display, reproduce, modify, create derivative works, perform, and distribute your content on and
            through the
            Services.</p>
        <div class="w-full py-2">

        </div>
        <h3>Intellectual Property</h3>
        <p>All intellectual property rights in the Services and any content displayed on or through the Services,
            including
            without limitation trademarks, copyrights, patents, and trade secrets, are the property of Knibble.ai or its
            licensors. You may not use any of these intellectual property rights without the prior written consent of
            Knibble.ai.
        </p>
        <div class="w-full py-2">

        </div>
        <h3>Limitation of Liability</h3>
        <p>Knibble.ai will not be liable for any damages, including without limitation any indirect, consequential,
            special,
            incidental, or punitive damages arising out of or related to these Terms or your use of the Services.</p>
        <div class="w-full py-2">

        </div>
        <h3>Termination</h3>
        <p>Knibble.ai may terminate or suspend your access to all or any part of the Services at any time, with or
            without
            cause,
            with or without notice, effective immediately. If you wish to terminate your account, you may do so by
            contacting
            Knibble.ai.</p>
        <div class="w-full py-2">

        </div>
        <h3>Changes to Terms</h3>
        <p>Knibble.ai reserves the right to modify these Terms at any time, in its sole discretion, by posting updated
            Terms on
            the Services. Your continued use of the Services after any such changes constitutes your acceptance of the
            new
            Terms.</p>
        <p>If you have any questions about these Terms, please contact us at <a
                href="mailto:support@Knibble.ai">support@Knibble.ai</a>.</p>
        <div class="w-full py-2">

        </div>

        <div class="w-full py-2">

        </div>


        <h3>Copyright</h3>
        <p>Content published on this website is subject to copyright laws. Reproduction, editing, distribution as well
            as the
            use of any kind outside the scope of the copyright law require a written permission of the author or
            originator.
            Downloads and copies of these websites are permitted for private use only. The commercial use of our
            contents
            without permission of the originator is prohibited.</p>
        <p>Copyright laws of third parties are respected as long as the contents on these websites do not originate from
            the
            provider. Contributions of third parties on this site are indicated as such. However, if you notice any
            violations
            of copyright law, please inform us. Such contents will be removed immediately.</p>

    </div>

</div>

<
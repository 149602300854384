<div class="header-wrapper w-full bg-inherit py-4">

    <div id="header-container" class="flex justify-between items-center bg-inherit text-gray-900 px-4 py-3 mx-auto"
        style="max-width: 1200px;">


        <div class="flex gap-x-10">

            <div class="text-xl font-bold text-gray-900 flex justify-start items-center" style="font-size:23px">
                <img src="assets/generic2.svg" style="width:33px; margin-top: -4px;" alt="">

                <div style="font-size: 21px;
                font-family: 'Sora';
                font-weight: bolder;
                color: #000;" class=" ml-0">Knibble<span class="text-gray-400">.ai</span><span
                        class="text-sm ml-2 text-gray-300 -mt-3"></span></div>
            </div>

            <div class="flex">




            </div>



        </div>



        <div>





            <div class="flex justify-end items-center ml-8">









                <a href="https://tally.so/r/mVj8QN"
                    class="bg-inherit border-none font-normal text-gray-700 cursor-pointer hover:text-blue-600 ml-4"
                    style="font-size:14px; font-weight: 800px;">
                    Contact Us
                </a>


            </div>

        </div>

    </div>

</div>

<div class="w-full p-16">

    <div class="mx-auto" style="width: 1000px;">

        <div class="w-full py-2">

        </div>
        <h3>Privacy Policy</h3>
        <p>At Knibble.ai, we take privacy seriously. This policy describes what personal information we collect and how
            we
            use
            it.</p>

        <div class="w-full py-2">

        </div>
        <h3>Information We Collect</h3>
        <p>When you use Knibble.ai, we collect the following types of information:</p>
        <ul>
            <li>
                <strong>Personal Information:</strong> We collect personal information you provide to us, such as your
                name
                and email address when you sign up for an account.
            </li>
            <li>
                <strong>Usage Information:</strong> We collect usage information about how you use our website,
                including
                the
                pages you view and the actions you take.
            </li>
        </ul>

        <div class="w-full py-2">

        </div>
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ol>
            <li>To provide and improve our services.</li>
            <li>To communicate with you about our services and respond to your inquiries.</li>
            <li>To personalize your experience on our website.</li>
        </ol>

        <div class="w-full py-2">

        </div>
        <h3>Sharing Your Information</h3>
        <p>We do not share your personal information with third parties, except in the following cases:</p>
        <ul>
            <li>With your consent.</li>
            <li>To comply with applicable laws, regulations, or legal processes.</li>
            <li>To enforce our policies, including investigations of potential violations.</li>
        </ul>

        <div class="w-full py-2">

        </div>
        <h3>Data Retention</h3>
        <p>We retain your personal information for as long as necessary to fulfill the purposes for which we collected
            it,
            unless a longer retention period is required by law.</p>

        <div class="w-full py-2">

        </div>
        <h3>Your Rights</h3>
        <p>You have the right to access, update, and delete your personal information. You can do this by logging into
            your
            account on our website or by contacting us at <a href="mailto:support@Knibble.ai">support@Knibble.ai</a>.
        </p>

        <div class="w-full py-2">

        </div>
        <h3>Changes to this Policy</h3>
        <p>We may update this privacy policy from time to time. If we make significant changes, we will notify you by
            email
            or by posting a notice on our website.</p>

        <div class="w-full py-2">

        </div>
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this privacy policy, please contact us at <a
                href="mailto:support@Knibble.ai">support@Knibble.ai</a>.</p>


    </div>

</div>
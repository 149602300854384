<div class="bg-gray-900 flex justify-between items-center">

    <div class="flex gap-x-8 justify-center text-white items-center py-3">

        <!-- <i class="fa-brands fa-dochub ml-4 text-xl mr-1" style="color:#ffe1d0"></i> -->
        <a href="/" style="font-size: 23px;
                    font-family: 'Poppins';
                    font-weight: bold;     
                    color: #e2e0e0;" class=" ml-4">
            <img src="assets/logo-re2.svg" alt="" style="min-width:27px">
        </a>

        <a class="py-0 px-2 w-full bg-inherit text-white text-base rounded" href="/project-new">
            <i class="fa-duotone fa-grid-2 ml-2 fa fa-fw"></i> Dashboard
        </a>

    </div>


    <div class="flex justify-center text-white items-center w-1/3">



    </div>


    <div class="flex justify-end text-white items-center w-1/3 px-4">

        <div class="dropdown px-0 dropleft" *ngIf="user_logged_in">
            <button class="btn bg-inherit focus:outline-none dropdown-toggle flex justify-center items-center px-0"
                type="button" id="dropdownMenuButton" style="background: inherit !important;" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <div class="w-8 h-8 rounded-xl bg-inherit"
                    style="border:solid 0px var(--app-theme-color) !important; background:var(--app-theme-color);">
                    <div class="flex justify-center items-center h-full">
                        <div class="text-base text-white capitalize font-semibold" style="color:#fff !important">
                            {{user?.user_uuid[0]}}
                        </div>
                    </div>
                </div>



                <i _ngcontent-ilr-c103="" class="fa-solid text-base text-gray-100 fa-caret-down ml-1"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item font-semibold py-1 border-b">{{user?.user_uuid}}</a>
                <a class="dropdown-item" href="/account">Account</a>

                <a class="dropdown-item" href="https://tally.so/r/mVj8QN">Contact Us</a>
                <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                <a class="dropdown-item text-gray-500" href="#">Delete Account</a>
            </div>
        </div>

        <div *ngIf="user_logged_in" class="mx-8 flex hidden">

            <div *ngIf="user_upgrade" class="text-sm flex justify-center items-center">
                <img src="assets/{{plan}}.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                {{plan}} plan
            </div>

            <div *ngIf="!user_upgrade" class="text-sm flex justify-center items-center">
                <img src="assets/Free.svg" alt="{{plan}}" style="width: 23px;" class="mr-1  -ml-1">
                Basic plan
            </div>

        </div>


    </div>
</div>


<section class="py-12 bg-white sm:py-16 lg:py-20 hidden">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">


        <table class="styled-table" style="margin: 0px;
        width: 100% !important;">

            <thead>

                <tr>

                    <th>

                        <div class="flex justify-center items-center">
                            Monthly &nbsp; <input type="checkbox" class="toggle toggle-success" [(ngModel)]="yearly" />
                            &nbsp;Yearly
                        </div>



                    </th>
                    <th *ngFor="let plan of plans" class="font-semibold justify-center items-center align-middle"
                        style="text-align: center;">
                        <div class="flex flex-col justify-center items-center p-4">
                            <div>
                                {{plan}}
                            </div>
                            <button class="px-3 py-2 text-sm font-normal text-white rounded justify-center items-center"
                                *ngIf="!yearly" style="text-align: center; background: var(--app-theme-color);"
                                (click)="createCheckoutSession(plan_map[plan]['price_id_monthly'])">Get </button>
                        </div>
                    </th>

                </tr>

            </thead>

            <tbody>

                <tr *ngFor="let fk of feature_keys">
                    <td>
                        {{features_values[fk]}}
                    </td>
                    <td *ngFor="let plan of plans">
                        {{plan_map[plan][fk]}}
                    </td>
                </tr>

                <!-- <tr>
                    <td></td>
                    <td *ngFor="let plan of plans">
                        <button class="px-3 py-2 text-sm font-normal text-white rounded justify-center items-center"
                            style="text-align: center; background: var(--app-theme-color);"
                            (click)="createCheckoutSession(plan_map[plan]['price_id_monthly'])">Buy Monthly</button>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td *ngFor="let plan of plans">
                        <button class="px-3 py-2 text-sm font-normal text-white rounded justify-center items-center"
                            style="text-align: center; background: var(--app-theme-color);"
                            (click)="createCheckoutSession(plan_map[plan]['price_id_yearly'])">Buy Yearly</button>
                    </td>
                </tr> -->

            </tbody>


        </table>


    </div>

</section>


<section class="py-12 bg-white sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-xl px-8 mx-auto text-center md:px-0">
            <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Flexible Pricing</h2>
            <div class="px-3 py-4  text-lg text-gray-600">

                <!-- <div class="py-2 px-3 border rounded w-auto"> -->

                <i class="fa-solid fa-sparkles text-pink-500 text-xl mr-2"></i>
                Now unlock unlimited messages with your own
                API key (Starter plan and above). <a
                    href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                    target="_blank" class="text-blue-500">Learn more</a>

                <!-- </div> -->


            </div>
            <div class="flex justify-center items-center mt-2 mb-4">

                Monthly &nbsp; <input type="checkbox" class="toggle toggle-success" [(ngModel)]="yearly" />
                &nbsp;Yearly


            </div>
        </div>



        <div
            class="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-2 text-center md:text-left md:mt-16 md:max-w-6xl md:grid-cols-3">



            <div class="relative hidden">
                <div class="absolute -inset-4">
                    <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
                        style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)">
                    </div>
                </div>

                <div class="relative overflow-hidden bg-gray-900 border border-gray-200 rounded-2xl">
                    <div class="p-6 lg:px-10 lg:py-8">
                        <h3 class="text-lg font-bold text-white font-pj">BYOK - Bring your own key</h3>
                        <div class="mt-3 text-5xl font-bold text-white font-pj" *ngIf="!yearly">$15<br>
                            <div class="text-xs text-gray-500">per month</div>
                        </div>
                        <div class="mt-3 text-5xl font-bold text-white font-pj" *ngIf="yearly">$150<br>
                            <div class="text-xs text-gray-500">per year</div>
                        </div>
                        <p class="mt-5 text-base font-normal leading-7 text-gray-400 font-pj">Bring your own OpenAI key
                            to knibble and enjoy all features</p>
                        <a href="https://knibble.notion.site/About-Bring-Your-Own-Key-BYOK-Plan-2eac9e15301543f3aa28224bcaaf0c53?pvs=4"
                            target="_blank" class="text-blue-500">Learn more
                            about bringing your own key
                            <i class="fa-solid fa-external-link-alt text-xs"></i>
                        </a>
                        <a (click)="createCheckoutSession('price_1NRSz4L9Nmd4RiSMjRsh2acB')"
                            *ngIf="!yearly && user_logged_in" class="
                                inline-flex
                                items-center
                                justify-center
                                px-8
                                py-3.5
                                w-full
                                mt-8
                                text-base
                                font-bold
                                text-gray-900
                                bg-white
                                transition-all
                                duration-200
                                border-2 border-transparent
                                focus:ring-offset-gray-900
                                rounded-xl
                                font-pj
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white
                                hover:bg-opacity-90
                            " role="button">
                            Get Monthly Plan
                        </a>

                        <a (click)="createCheckoutSession('price_1NRSz4L9Nmd4RiSMNRqUPbNy')"
                            *ngIf="yearly && user_logged_in" class="
                                inline-flex
                                items-center
                                justify-center
                                px-8
                                py-3.5
                                w-full
                                mt-8
                                text-base
                                font-bold
                                text-gray-900
                                bg-white
                                transition-all
                                duration-200
                                border-2 border-transparent
                                focus:ring-offset-gray-900
                                rounded-xl
                                font-pj
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white
                                hover:bg-opacity-90
                            " role="button">
                            Get Yearly Plan
                        </a>

                        <p class="mt-8 text-base font-bold text-white font-pj">What's included:</p>

                        <ul
                            class="mt-4 space-y-3 text-base font-medium w-full font-pj flex flex-col justify-start items-center text-white">
                            <li class="flex justify-start items-center w-full"><i
                                    class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                Unlimited questions
                            </li>
                            <li class="flex justify-start items-center w-full"><i
                                    class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                Unlimited files
                            </li>
                            <li class="flex justify-start items-center w-full"><i
                                    class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                Unlimited urls
                            </li>
                            <li class="flex justify-start items-center w-full"> <i
                                    class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                1 GB total storage
                            </li>
                            <li class="flex justify-start items-center w-full"><i
                                    class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                50 Embeddable Chatbots
                            </li>
                            <li class="flex justify-start items-center w-full">
                                <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                40 MB file size per upload

                            </li>



                            <li class="flex justify-start items-center w-full">
                                <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                All chatbot customizations

                            </li>



                            <li class="flex justify-start items-center w-full">
                                <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                Remove Knibble.ai branding

                            </li>

                            <li class="flex justify-start items-center w-full">
                                <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                                Embed on unlimited websites

                            </li>


                        </ul>

                    </div>
                </div>
            </div>


            <div class="overflow-hidden bg-white border border-gray-200 rounded-2xl" *ngFor="let plan of non_key_plans">
                <div class="p-6 lg:px-10 lg:py-8">
                    <h3 class="text-lg font-bold text-gray-900 font-pj">{{plan?.plan_uuid}}</h3>
                    <div class="mt-3 text-5xl font-bold text-gray-900 font-pj" *ngIf="!yearly">
                        ${{plan?.price_monthly}}<br>
                        <div class="text-base text-gray-500 font-normal">per month</div>
                    </div>
                    <div class="mt-3 text-5xl font-bold text-gray-900 font-pj" *ngIf="yearly">${{plan?.price_yealy}}
                        <br>
                        <div class="text-base text-gray-500 font-normal">per year</div>
                    </div>
                    <!-- <p class="mt-5 text-base font-normal leading-7 text-gray-600 font-pj">Best for small business
                        owners, startups who needs landing page for their business.</p> -->


                    <a *ngIf="plan.plan_uuid === 'BASIC' && user_logged_in" class="
                            inline-flex
                            items-center
                            justify-center
                            px-8
                            py-3.5
                            w-full
                            mt-8
                            text-base
                            font-bold
                            
                            transition-all
                            duration-200
                            
                            rounded-xl
                            font-pj
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                            hover:bg-gray-900
                            focus:bg-gray-900
                            hover:text-white
                            focus:text-white
                            hover:border-gray-900
                            focus:border-gray-900
                            bg-gray-50
                            text-gray-300
                        " role="button">
                        Free
                    </a>


                    <a (click)="createCheckoutSession(plan.price_id_monthly)"
                        *ngIf="!yearly && plan.plan_uuid !== 'BASIC' && user_logged_in" class="
                            inline-flex
                            items-center
                            justify-center
                            px-8
                            py-3.5
                            w-full
                            mt-8
                            text-base
                            font-bold
                            text-gray-900
                            transition-all
                            duration-200
                            border-2 border-gray-400
                            rounded-xl
                            font-pj
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                            hover:bg-gray-900
                            focus:bg-gray-900
                            hover:text-white
                            focus:text-white
                            hover:border-gray-900
                            focus:border-gray-900
                        " role="button">
                        Get Monthly Plan
                    </a>

                    <a (click)="createCheckoutSession(plan.price_id_yearly)"
                        *ngIf="yearly && plan.plan_uuid !== 'BASIC' && user_logged_in" class="
                            inline-flex
                            items-center
                            justify-center
                            px-8
                            py-3.5
                            w-full
                            mt-8
                            text-base
                            font-bold
                            text-gray-900
                            transition-all
                            duration-200
                            border-2 border-gray-400
                            rounded-xl
                            font-pj
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                            hover:bg-gray-900
                            focus:bg-gray-900
                            hover:text-white
                            focus:text-white
                            hover:border-gray-900
                            focus:border-gray-900
                        " role="button">
                        Get Yearly
                    </a>



                    <p class="mt-8 text-base font-bold text-gray-900 font-pj">What's included:</p>
                    <ul
                        class="mt-4 space-y-3 text-base font-medium w-full font-pj flex flex-col justify-start items-center text-gray-800">
                        <li class="flex justify-start items-center w-full"><i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_messages_per_month}} questions per
                            month
                        </li>
                        <li class="flex justify-start items-center w-full"><i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            Bring your own key
                        </li>

                        <li class="flex justify-start items-center w-full"><i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_files}} files
                        </li>
                        <li class="flex justify-start items-center w-full"><i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_urls}} urls
                        </li>
                        <li class="flex justify-start items-center w-full"> <i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_total_storage_mb}} MB total storage
                        </li>
                        <li class="flex justify-start items-center w-full"><i
                                class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_projects}} Embeddable Chatbots
                        </li>
                        <li class="flex justify-start items-center w-full">
                            <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            {{plan?.max_file_size_mb}} MB file size per upload

                        </li>

                        <li class="flex justify-start items-center w-full" *ngIf="plan.plan_uuid === 'BASIC'">
                            <i class="fa-regular fa-circle-xmark text-red-500 mr-2"></i>
                            All chatbot customizations

                        </li>

                        <li class="flex justify-start items-center w-full" *ngIf="plan.plan_uuid !== 'BASIC'">
                            <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            All chatbot customizations

                        </li>

                        <li class="flex justify-start items-center w-full"
                            *ngIf="plan.plan_uuid === 'BASIC' || plan.plan_uuid === 'STARTER'">
                            <i class="fa-regular fa-circle-xmark text-red-500 mr-2"></i>
                            Remove Knibble.ai branding

                        </li>

                        <li class="flex justify-start items-center w-full"
                            *ngIf="plan.plan_uuid !== 'BASIC' && plan.plan_uuid !== 'STARTER'">
                            <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            Remove Knibble.ai branding

                        </li>

                        <li class="flex justify-start items-center w-full">
                            <i class="fa-regular fa-circle-check text-green-500 mr-2"></i>
                            Embed on unlimited websites

                        </li>


                    </ul>
                </div>
            </div>









        </div>
    </div>
</section>
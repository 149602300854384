import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { ApiService } from '../../../../backend/services/api.service';
import { UtilsService } from '../../../../backend/services/utils.service';
import { PlansService } from '../../../../backend/services/plans.service';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { AuthService } from '../../../../backend/services/auth.service';
import { AddNewProjectComponent } from '../../../blocks/modals/add-new-project/add-new-project.component';
import { DeleteProjectComponent } from '../../../blocks/modals/delete-project/delete-project.component';
import { ApiKeyComponent } from '../../../app-ui/main/api-key/api-key.component';


declare var bootbox: any;

// ADD THIS


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  declare $: any;

  @ViewChild(AddNewProjectComponent) addProjectRef!: AddNewProjectComponent;
  @ViewChild(DeleteProjectComponent) delProjectRef!: DeleteProjectComponent;
  @ViewChild(ApiKeyComponent) addKeyRef!: ApiKeyComponent;

  @Input() pid: any;
  @Input() plan: any;
  @Input() page: any;

  @Input() limits: any;
  @Input() tracker: any;
  @Input() user_upgrade: any;

  @Output() notifyKeyAdded: EventEmitter<any> = new EventEmitter();
  @Output() notifyShowDashboard: EventEmitter<any> = new EventEmitter();
  @Output() notifyShowAccount: EventEmitter<any> = new EventEmitter();



  user_logged_in: boolean = false;
  user: any;
  user_projects: any = [];
  showSidebar: boolean = true;

  constructor(private api: ApiService, private app_auth: AuthService, private planService: PlansService, private utils: UtilsService, private route: Router) { }

  ngOnInit(): void {
    this.getUser();
    this.getUserProjects();
    console.log(this.plan);


  }

  ngAfterViewInit() {
    (<any>$(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }))
  }

  showAccount() {
    this.notifyShowAccount.emit();
  }


  getUser() {
    this.api.cd_getUser().subscribe((user) => {
      console.log(user);
      this.user_logged_in = true;
      this.user = user;

    },
      (error) => {
        console.log(error);
      }
    );
  }

  // logout() {
  //   try {
  //     this.app_auth.logout();
  //   } catch (error) {
  //   }
  // }







  getUserProjects() {

    var i = 0;
    this.api.cd_getUserProjects().subscribe((data: any) => {
      console.log(data);
      data['projects'].forEach((project: any) => {
        var color_id = this.utils.getUniqueColor(project['project_uuid']);
        console.log(color_id);
        project['color'] = 'text' + color_id;
        i++;
        if (i > 5) {
          i = 0;
        }
        this.user_projects.push(project);
      }
      );
    });
  }

  goToProject(project_uuid: any) {
    window.location.href = "/project-new/" + project_uuid;
  }

  addProject() {

    var validation = this.planService.validateAction('add_project', this.limits, this.tracker);
    if (!validation.status) {
      bootbox.alert({
        size: 'medium',
        title: 'Oops!',
        message: validation.message
      });
      return;
    }

    this.addProjectRef.open_modal();
  }

  delProject(p, e) {
    e.stopPropagation();
    this.delProjectRef.setProjectToDelete(p);
    this.delProjectRef.open_modal();
  }

  projectDeletionHandler(uuid) {
    this.user_projects = this.user_projects.filter(project => project.project_uuid !== uuid);
    if (this.pid == uuid) {

      console.log("Navigating to dashboard");
      this.route.navigate(["project-new"]);
      // window.location.reload();

    }
  }


  addAPIKey() {
    this.addKeyRef.setLimits(this.limits);
    this.addKeyRef.open_modal();
  }

  keyAdded() {
    this.notifyKeyAdded.emit();
  }


  dashboard() {
    this.notifyShowDashboard.emit();
  }


  logout() {

    try {

      this.api.logout().subscribe((data) => {
        Auth.signOut().then(() => {
          window.location.href = "/";
          location.reload();
        });
      });

    } catch (error) {
      // //console.log('error signing out: ', error);
    }
  }

}

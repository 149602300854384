import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spa',
  templateUrl: './spa.component.html',
  styleUrls: ['./spa.component.css']
})
export class SpaComponent implements OnInit {

  pdfsrc = "assets/lp.pdf";
  current_tab = "Chat";

  projects = [
    {
      "name": "project-1"
    },
    {
      "name": "project-1"
    },
    {
      "name": "project-1"
    },
    {
      "name": "project-1"
    },
    {
      "name": "project-1"
    },
    {
      "name": "project-1"
    }
  ]

  constructor() { }

  ngOnInit(): void {

  }
  setCurrentTab(tab: string) {
    this.current_tab = tab;
    
  }
  

}

<div class="p-4">

    <!-- <input type="text" [(ngModel)]="email" class="border" />
    <input type="text" [(ngModel)]="password" class="border" /> -->

    <!-- <button (click)="this.auth.logout()">Signup</button>
    <button (click)="logout()">Logout</button> -->


</div>

<app-auth-button></app-auth-button>
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment } from './environments/environment';
// alert(environment.production);
var redirectUrl = "";
if(environment.production){
redirectUrl = "https://knibble.ai/auth2/";
}else{
redirectUrl = "https://dev.knibble.ai/auth2/";
}
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2920c4a9-2cc0-4bf6-ba5e-206f114935b1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7U0H4ug69",
    "aws_user_pools_web_client_id": "1av89dcacj1d5gqu509i4d7prt",
    "oauth": {
        "domain": "chatdocsfa8e9a49-fa8e9a49-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": redirectUrl,
        "redirectSignOut": redirectUrl,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "chatdocsec548677469e406bb3336003fd417b32230533-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

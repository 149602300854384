import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlTrunc'
})
export class UrlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value == null) return null;

    return value;

    var returnVal = "";
    // value is a url
    var tokens = value.split('/');
    if (tokens.length < 6) {
      return value
    }
    else {
      returnVal = tokens[0] + '//' + tokens[2] + '...' + tokens[tokens.length - 1];
    }
    if (returnVal.length > 100) {
      returnVal = returnVal.slice(0, 100) + '...';
    }
    return returnVal;
  }

}

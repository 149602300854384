import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-resizablediv',
  templateUrl: './resizablediv.component.html',
  styleUrls: ['./resizablediv.component.css']
})
export class ResizabledivComponent implements OnInit {

  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {
  }

  ///////// --------

  height = 150;
  width = 150;
  y = 100;
  oldY = 0;
  oldX = 0;
  grabber = false;
  onMouseMove(event: MouseEvent) {
    // // //console.log("mousemove", event.clientY);
    if (!this.grabber) {
      return;
    }
    this.resizer(event.clientY - this.oldY, event.clientX - this.oldX);
    this.oldY = event.clientY;
    this.oldX = event.clientX;
  }


  onMouseUp(event: MouseEvent) {
    // // //console.log("mouseup", event.clientY);
    this.grabber = false;
  }

  resizer(offsetY: number, offsetX: number) {

    // // //console.log("resizer", offsetY);
    this.height += offsetY;
    this.width += offsetX;
  }



  onMouseDown(event: MouseEvent) {
    // // //console.log("mousedown", event.clientY);
    this.grabber = true;
    this.oldY = event.clientY;
    this.oldX = event.clientX;
  }



  //////// --------

}

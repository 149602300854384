<div class=" flex flex-col h-full w-full bg-white overflow-y-scroll"
    style="background:#fff !important; width:100% !important">
    <div class="w-full py-2 px-4 bg-yellow-100" *ngIf="crawl_status === 'Indexing'">
        <i class="fa-light fa-spinner fa-spin mr-2"></i>
        Adding webpage. Please do not close this window.
    </div>

    <div class="w-full py-2 px-4 bg-green-100 flex justify-between items-center" *ngIf="crawl_status === 'Indexed'">
        <div>
            <i class="fa-light fa-circle-check  mr-2 text-green-500"></i>
            Successfully added {{success_url_count}} webpages.
        </div>

        <div class="flex gap-x-2">

            <button class="text-sm text-white bg-green-700 rounded p-2" (click)="closePage()">Close</button>
            <button class="text-sm text-gray-800 bg-green-200 p-2 rounded" (click)="startOver()">Start over</button>

        </div>



    </div>
    <div
        class="py-2 px-8 border-b text-gray-800  text-2xl font-bold flex justify-between items-center sticky top-0 bg-white">
        <div class="flex p-0 justify-start items-center" *ngIf="crawl_status === 'Not Started'">

            <!-- <i class="fa-light fa-globe mr-2 text-blue-600"></i> -->
            Add Webpages

        </div>

        <div class="flex p-0 justify-start items-center truncate text-base font-semibold mr-3" contenteditable="true"
            *ngIf="crawl_status === 'Scraped' || crawl_status === 'Indexing' || crawl_status === 'Indexed'">

            {{add_url_current}}

        </div>

        <div class="flex gap-x-4">

            <button class="bg-red-50 rounded text-gray-800 px-3 py-2 border-0 text-sm font-normal"
                *ngIf="crawl_status === 'Scraped'" (click)="startOver()">
                Cancel
            </button>

            <button _ngcontent-xis-c92="" class="m-0 btn bg-none text-gray-800 p-0 border-0" (click)="closePage()"
                *ngIf="crawl_status !== 'Indexing' && crawl_status !== 'Scraping'" style="
                background: #fff !important;
            ">
                <i class="fa-regular fa-times"></i>
            </button>

        </div>


    </div>

    <div class="py-4 px-8 mt-0 flex flex-col justify-start items-start"
        *ngIf="!upload_index_progress && crawl_status !== 'Scraped' && crawl_status !== 'Indexed' && crawl_status !== 'Indexing'">

        <div class="w-full flex justify-between items-center mb-2 bg-yellow-50" *ngIf="show_abort_banner">
            <div class="px-4 py-2 text-sm text-gray-700">
                Please Use 'upload file' option to upload text and pdf urls.
            </div>
            <button class="m-0 btn bg-yellow-50 text-gray-800 px-2 border-0"
                style="background: rgb(254, 252, 232) !important;" (click)="this.show_abort_banner=false">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>
        <div class="w-3/4 mx-auto flex flex-col gap-y-4 justify-start items-start"
            *ngIf="crawl_status === 'Not Started'">
            <input [(ngModel)]="add_url_current"
                class="w-full border focus:outline-none text-sm font-normal border-gray-600 py-3 px-4 rounded"
                type="text" name="name" id="starting_url"
                placeholder="Starting webpage. Example: https://example.com/docs" required>


            <button class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 text-sm py-2 px-4  rounded w-40 "
                *ngIf="add_url_current !== '' && add_url_current.startsWith('http')"
                style="background: var(--app-theme-color); color:#fff !important;" type="button"
                (click)="getSubUrls(); ">Fetch Links</button>

            <button class="bg-gray-700 hover:bg-blue-700 text-white font-normal mt-0 text-sm py-2 px-4  rounded w-40 "
                *ngIf="add_url_current === '' || !add_url_current.startsWith('http')" style=" color:#fff !important;"
                type="button">Fetch Links</button>


        </div>

        <div class="w-full flex justify-start items-center" *ngIf="crawl_status === 'Scraping'">
            <i class="fa-light fa-spinner fa-spin mr-2"></i>
            Fetching links... Please do not close this window.
        </div>



    </div>

    <div *ngIf="!upload_index_progress && crawl_status !== 'Scraped' && crawl_status !== 'Indexed' && crawl_status !== 'Indexing'"
        class="px-4 py-2 text-sm text-gray-500 w-3/4 mx-auto">
        This will fetch all the links found on the webpage given above. Any external links not belonging to the
        same domain will be ignored. Files will also be ignored.
    </div>

    <div class="px-6 w-full flex flex-col justify-start items-start">

        <div class="w-full flex justify-between items-center gap-x-4 px-2 py-4 sticky top-16 bg-white"
            *ngIf="crawl_status === 'Scraped'">
            <div class="text-lg font-semibold">
                Selected {{selected_url_count}} of {{sub_urls.length}} urls
            </div>

            <div class="flex justify-end items-center gap-x-4">

                <div class="flex gap-x-2" *ngIf="crawl_status === 'Scraped'">
                    <a (click)="selectAllSubUrls()" class="text-sm text-blue-500 mx-1 cursor-pointer">Select
                        All</a>
                    <i class="fa-light fa-ellipsis-vertical text-gray-300"></i>
                    <a (click)="unselectAllSubUrls()" class="text-sm text-blue-500 mx-1 cursor-pointer">Unselect
                        All</a>
                </div>

                <button *ngIf="show_add_url_loop"
                    class="bg-blue-700 hover:bg-blue-700 text-white font-normal mt-0 text-sm py-2 px-4 rounded"
                    style="background: var(--app-theme-color); color:#fff !important;" type="button"
                    (click)="addUrlLoop(); ">+
                    Add Selected Urls</button>

            </div>


        </div>


        <div class="flex flex-col justify-start items-start w-full gap-y-1 py-4" *ngIf="crawl_status === 'Indexing'">
            Added {{success_url_count}} of {{selected_url_count}} pages
            <progress class="progress progress-error w-full " value="{{success_url_count}}" style="height: 4px;"
                max="{{selected_url_count}}"></progress>

        </div>



        <table class="table-auto w-full">
            <tr *ngFor="let url of sub_urls" class="border-b w-full">
                <td class="p-2" *ngIf="crawl_status !== 'Indexing' && crawl_status !=='Indexed'">
                    <input type="checkbox" [(ngModel)]="url.checked" (change)="updateSelectedUrlCount()"
                        class="p-2 text-sm">
                </td>

                <td class="p-2 text-base" style="font-family: proxima-nova;">
                    <a href="{{url.url}}" class="text-blue-500" target="_blank">{{url.url | urlTrunc}}</a>
                </td>

                <td class="p-2">
                    <i class="fa-light fa-circle-check text-green-500" *ngIf="url.status === 'success'"></i>
                    <i class="fa-light fa-times text-red-500" *ngIf="url.status === 'failed'"></i>
                    <i class="fa-light fa-spinner fa-spin text-blue-500" *ngIf="url.status === 'pending'"></i>
                    <span class="text-sm text-gray-500" *ngIf="!url.checked">Excluded</span>
                </td>

                <td class="p-2">

                </td>

            </tr>
        </table>

        <div class="w-full h-40">

        </div>

    </div>




    <div *ngIf="upload_index_progress" class="flex justify-center items-center w-full h-60">

        <!-- <img src="assets/progress.gif" alt="loader" class="mx-auto w-24"> -->

        <div class="flex">

            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                </circle>
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
            </svg>

            <div>
                Adding Url. Please wait...
            </div>

        </div>



    </div>

</div>
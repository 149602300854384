import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  colors = [
    "-blue-300",
    "-cyan-300",
    "-green-300",
    "-rose-300",
    "-orange-300",
    "-purple-300",
    "-yellow-300",
  ]

  constructor() { }

  getUniqueColor(uuid: string) {
    // Convert the UUID to a numeric value
    let numericValue = 0;
    for (let i = 0; i < uuid.length; i++) {
      numericValue += uuid.charCodeAt(i);
    }

    // Map the numeric value to a value between 1 and 5
    const minValue = 1;
    const maxValue = 7;
    const range = maxValue - minValue + 1;
    const mappedValue = ((numericValue % range) + range) % range + minValue;

    return this.colors[mappedValue - 1];
  }
}

import {Component, OnInit, OnDestroy} from '@angular/core';
import { Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { timer } from 'rxjs';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit{

    message: string;
    status: string; // success or failed or neutral
    timer = 5000;

    ngOnInit(): void {
        
    }

    showToastNnotification(message: string, status: string): void {
        this.message = message;
        this.status = status;
        timer(this.timer).subscribe(x => this.status='');
    }

}
